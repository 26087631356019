<template>
    <div>
        <!-- Brick: Store Locator -->
        <store-locator-page v-if="brick.type == 'brick--store_locator' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />
        <store-near-you-brick v-if="brick.type == 'brick--closest_poi' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Sustainability presentation -->
        <sustainability-presentation v-if="brick.type == 'brick--earth_video' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Sustainability projects -->
        <sustainability-vote v-if="brick.type == 'brick--counter_earth_project_parent' && brick.status" :key="setUID(brick.id)" :brickContent="brick" :pageType="pageType" />

        <!-- Brick: Basic text/image content | Citation center --- DEPRECATED | Nespresso What Else --- DEPRECATED -->
        <basic-text-image-content
            v-if="(brick.type == 'brick--citation_center' || brick.type == 'brick--basic_text_image_content' || brick.type == 'brick--nespresso_what_else_') && brick.status"
            :key="setUID(brick.id)"
            :brickContent="brick"
        />

        <!-- Brick: Quote -->
        <quote v-if="brick.type == 'brick--quote' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Text blocks -->
        <text-blocks v-if="brick.type == 'brick--text_blocks' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Related content -->
        <related-content v-if="brick.type == 'brick--related_content' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Text/Media Slider -->
        <text-media-slider v-if="brick.type == 'brick--text_media_slider' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Text/Insert -->
        <TextInsert v-if="brick.type == 'brick--text_insert' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Image/Insert -->
        <ImageInsert v-if="brick.type == 'brick--image_insert' && brick.status" :key="brick.id" :brickContent="brick" />


        <!-- Brick: Free HTML -->
        <template v-if="brick.type == 'brick--free_html' && brick.status">
            <div :id="brick.anchor_identifier" :key="brick.id">
                <div v-html="brick.html_content.value" :id="brick.id"></div>
            </div>
        </template>

        <!-- Brick: Masterclass Event List -->
        <masterclass-event-list v-if="brick.type === 'brick--masterclass' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Video -->
        <Video v-if="brick.type == 'brick--video' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: CTA -->
        <cta v-if="brick.type == 'brick--cta' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Coffee Range -->
        <coffee-range v-if="brick.type == 'brick--coffee' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Commercial offer  -->
        <commercial-offer v-if="brick.type == 'brick--commercial_offer_list' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!--
            Brick: Contact Form
            Fields:
            - Text
            - Number
            - Selection
            - Radio button
            - Checkbox button
            - Date
            - URL (Same as text + pattern)
            - Textarea
            - Phone number
            X Submit button
        -->
        <contact-form v-if="brick.type == 'brick--contact_form' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Products Add To Cart Slider -->
        <products-add-to-cart v-if="brick.type == 'brick--products_with_add_to_cart' && brick.status" :key="setUID(brick.id)" :brickContent="brick" /><!-- Brick: FAQ -->

        <!-- Brick: FAQ -->
        <faq v-if="brick.type == 'brick--faq' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: List of elements (previously: machine key features) -->
        <list-of-elements v-if="brick.type == 'brick--machine_key_features' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Main header -->
        <header-n v-if="brick.type == 'brick--header_v2' && brick.status" :key="setUID(brick.id)" :brickContent="brick"/>

        <!-- Brick: Main header -->
        <main-header v-if="brick.type == 'brick--header' && brick.status" :key="setUID(brick.id)" :brickContent="brick" :background-parallax="backgroundParallaxHeader" />

        <!-- Brick: Hero Slider -->
        <hero v-if="brick.type == 'brick--hero' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Image Full Width -->
        <image-full-width v-if="brick.type == 'brick--image_full_width' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Image Gallery -->
        <image-gallery v-if="brick.type == 'brick--image_gallery' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Map Locator -->
        <map-locator v-if="brick.type == 'brick--map' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Masterclass Simple text -->
        <simple-text v-if="brick.type === 'brick--simple_centered_text_title' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Recipes -->
        <recipes v-if="brick.type == 'brick--recipes' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Footer services -- DEPRECATED -->
        <footer-services v-if="brick.type == 'brick--footer_services' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Simple Title -->
        <simple-title v-if="brick.type == 'brick--title' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Sticky breadcrumb -->
        <sticky-breadcrumb-menu v-if="brick.type == 'brick--breadcrumbs' && brick.status" :key="setUID(brick.id)" :brickContent="brick" />

        <!-- Brick: Popin (not displayed but called from other components) -->
        <Popin v-if="brick.type == 'brick--popin' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Articles Slider -->
        <ArticlesSlider v-if="brick.type == 'brick--horizontal_slider_of_articles' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Articles List -->
        <ArticlesList v-if="brick.type == 'brick--article_list' && brick.status" :key="brick.id" :brickContent="brick" />

        <!-- Brick: Articles Teaser -->
        <ArticlesTeaser v-if="brick.type == 'brick--article_teaser'" :key="brick.id" :brickContent="brick" />

        <!-- Brick: meet our coffee experts -->
        <meet-our-coffee-experts v-if="brick.type == 'brick--meet_our_coffee_experts'" :brickContent="brick" />
    </div>
</template>

<script>
import BasicTextImageContent from '@/components/BasicTextImageContent.vue';
import ContactForm from '@/components/contact-form/ContactForm.vue';
import Faq from '@/components/Faq.vue';
import FooterServices from '@/components/FooterServices.vue';
import Hero from '@/components/Hero.vue';
import Popin from '@/components/Popin.vue';
import Video from '@/components/Video.vue';
import ImageFullWidth from '@/components/ImageFullWidth.vue';
import ImageGallery from '@/components/ImageGallery.vue';
import ListOfElements from '@/components/ListOfElements.vue';
import MainHeader from '@/components/MainHeader.vue';
import MapLocator from '@/components/map/MapLocator.vue';
import ProductsAddToCart from '@/components/products-add-to-cart/ProductsAddToCart.vue';
import Recipes from '@/components/recipes/Recipes.vue';
import SimpleTitle from '@/components/SimpleTitle.vue';
import StickyBreadcrumbMenu from '@/components/StickyBreadcrumbMenu.vue';
import Cta from '@/components/Cta.vue';
import CommercialOffer from '@/components/commercial-offer/CommercialOffer.vue';
import CoffeeRange from '@/components/CoffeeRange.vue';
import TextInsert from '@/components/image-text-insert/TextInsert.vue';
import ImageInsert from '@/components/image-text-insert/ImageInsert.vue';

// Masterclass components registration
import MasterclassEventList from '@/components/masterclass/MasterclassEventList.vue';
import SimpleText from '@/components/masterclass/SimpleText';
// Sustainability components
import SustainabilityPresentation from '@/components/sustainability/SustainabilityPresentation';
import SustainabilityVote from '@/components/sustainability/SustainabilityVote';
// StoreLocator components
import StoreLocatorPage from '@/pages/store-locator/StoreLocatorPage';
import StoreNearYouBrick from '../components/store-locator/brick/StoreNearYouBrick.vue';
import ArticlesSlider from '../components/articles/ArticlesSlider.vue';
import ArticlesList from '../components/articles/ArticlesList.vue';
import ArticlesTeaser from '../components/articles/ArticlesTeaser.vue';
import HeaderN from '../components/HeaderN.vue';
import Quote from '../components/Quote.vue';
import TextBlocks from '../components/TextBlocks.vue';
import RelatedContent from '../components/RelatedContent.vue';
import TextMediaSlider from '../components/TextMediaSlider.vue';
import MeetOurCoffeeExperts from '@/components/MeetOurExperts.vue';

export default {
    name: 'Brick',
    props: {
        brick: { type: Object, default: null },
        pageType: { type: String, default: null },
        backgroundParallaxHeader: { type: Object, default: null },
    },
    components: {
      MeetOurCoffeeExperts,
        BasicTextImageContent,
        ContactForm,
        Faq,
        FooterServices,
        Hero,
        Popin,
        ImageFullWidth,
        ImageGallery,
        ListOfElements,
        MainHeader,
        MapLocator,
        HeaderN,
        Video,
        ProductsAddToCart,
        Recipes,
        RelatedContent,
        SimpleTitle,
        StickyBreadcrumbMenu,
        TextBlocks,
        TextMediaSlider,
        Quote,
        Cta,
        CommercialOffer,
        CoffeeRange,
        // Masterclass components registration
        MasterclassEventList,
        SimpleText,
        // Sustainability components
        SustainabilityPresentation,
        SustainabilityVote,
        StoreLocatorPage,
        StoreNearYouBrick,
        ArticlesSlider,
        ArticlesList,
        ArticlesTeaser,
        TextInsert,
        ImageInsert
    },
    mounted() {
        if (this.brick.type === 'brick--free_html') {
            this.executeScripts(this.brick.id);
        }
    },
    methods: {
        executeScripts(id) {
            if (document.getElementById(id) !== null) {
                const container = document.getElementById(id);
                const scripts = container.getElementsByTagName('script');
                const scriptsArray = Array.from(scripts);

                scriptsArray.forEach((node) => {
                    let script = document.createElement('script');
                    script.text = node.innerHTML;
                    for (let i = node.attributes.length - 1; i >= 0; i--) {
                        script.setAttribute(node.attributes[i].name, node.attributes[i].value);
                    }
                    node.parentNode.replaceChild(script, node);
                });
            } else {
                // In case the brick div is not yet on the page
                setTimeout(() => {
                    this.executeScripts(id);
                }, 200);
            }
        },
        setUID(e) {
            const key = Math.random().toString(16).slice(2);
            const uid = 'brick-' + e + '-' + key;
            return uid;
        },
    },
};
</script>
