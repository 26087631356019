<template>
  <div class="search-bar">
    <GeolocatedButton />
    <SearchAutocomplete />
    <FilterButton />
  </div>
</template>

<script>
import GeolocatedButton from "@/components/store-locator/partials/GeolocatedButton";
import FilterButton from "@/components/store-locator/partials/FilterButton";
import SearchAutocomplete from "@/components/store-locator/partials/SearchAutocomplete.vue";

export default {
  name: "SearchBar",
  components: {
    GeolocatedButton,
    SearchAutocomplete,
    FilterButton,
  },
};
</script>

<style lang="scss">
.g {
  .search-bar {
    display: flex;
    align-items: stretch;
    position: relative;
    min-height: 40px;
    width: 100%;
    padding: 12px 0;
  }
}
</style>
