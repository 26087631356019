<template>
    <div class="store-near-you-map">
        <GmapMap ref="map" :center="center" :zoom="zoom" :options="mapOptions">
            <GmapMarker
                :position="boutique.position"
                :clickable="true"
                :icon="marker"
                @click="handleMarkerClick()"
                :zIndex="5"
            />
        </GmapMap>
    </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import MAP_THEME from "@/map_theme.json";

export default {
    props: {
        boutique: { type: Object },
        linkUrl: {type: String},
        static: { type: [Boolean, null], default: false },
        zoom: { type: Number, default: 15 },
    },
    data() {
        let marker = {
            url : this.$distUrl + "/front-app/dist/" + require('../partials/markers/boutique.svg'),
        };
        if (this.boutique.primaryStoreType.marker) {
            marker.url = this.boutique.primaryStoreType.marker.url;
        }
        const mapOptions = {
            styles: MAP_THEME,
            zoomControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
        };
        if (this.static !== false) {
            mapOptions.gestureHandling = "none";
            mapOptions.zoomControl = false;
        }
        return {
            center: {
                lat: this.boutique.position.lat,
                lng: this.boutique.position.lng,
            },
            marker,
            mapOptions: mapOptions,
        };
    },
    computed: {
        google: gmapApi,
    },
    methods: {
        handleMarkerClick() {
          window.location.href = this.linkUrl
        },
    },
};
</script>
<style lang="scss">
.store-near-you-map {
    position: relative;
    width: 100%;
    height: 100%;
    .vue-map-container {
        height: 100%;
    }
}
</style>
