<template>
  <div class="filter">
    <portal-target name="storelocator-filter" />
    <portal :to="portalPosition">
      <button class="filter__button" @click="toggleModaleFilter">
        <h5 class="filter__title" v-html="title"/>
        <div class="filter__icon">
        <img :src="$distUrl + '/front-app/dist/' + filterIcon" alt="">
        </div>
      </button>
    </portal>

    <FilterModale :data-modale="modale"  />
  </div>
</template>$

<script>
import { mapGetters, mapState } from 'vuex';
import FilterModale from './FilterModale';

export default {
  name: 'FilterButton',
  components: {
    FilterModale
  },
  data() {
    return {
      modale: false,
      filterIcon: require('../../../icons/filter-white.svg')
    }
  },
  computed: {
    ...mapState('store_locator', ["isMobile"]),
    ...mapGetters('store_locator', ['filtersCount']),
    title() { return `${this.$i18nSL.filter} (${ this.filtersCount })` },
    portalPosition(){
      return this.isMobile ? "storelocator-bottom-filter-btn" : "storelocator-filter" ;
    }
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed(){
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize(){
      if(this.modale){
        if(window.innerWidth > 991){
          document.querySelector('html').classList.toggle("g_scrollLock", false);
        }else{
          document.querySelector('html').classList.toggle("g_scrollLock", true);
        }
      }
    },
    toggleModaleFilter() { 
      this.modale = !this.modale
      if(this.isMobile){
        window.scrollTo(0, 0);
        document.querySelector('html').classList.toggle("g_scrollLock", this.modal);
      }
    },
  }
}
</script>

<style lang="scss">
.g {
  .filter {
    color: #000;

    &__button {
      position: absolute;
      left: 50%;
      z-index: 6;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      height: 40px;
      padding: 0 10px;
      border-radius: 4px;
      background: #000;
      transform: translate(-50%, 0);

      @media (min-width: 992px) {
        position: relative;
        top: 0; left: 0;
        width: 134px;
        transform: translate(0, 0);
      }
    }

    &__title {
      font-weight: 800;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      color: #ffffff;
    }

    &__icon { display: flex; align-items: center; }
  }
}
</style>
