<template>
  <masterclass-layout>
    <masterclass-event-register :registration-type="getRegistrationType()" />
  </masterclass-layout>
</template>
<script>
import MasterclassLayout from "@/pages/masterclass/MasterclassLayout"
import MasterclassEventRegister from "@/components/masterclass/MasterclassEventRegister"
export default {
  name: 'EventRegistration',
  components: {
    MasterclassLayout,
    MasterclassEventRegister
  },
  methods: {
    getRegistrationType: function () {
      let path = this.$route.path.split('/')

      return path[2]
    }
  }
}
</script>
