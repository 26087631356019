<template>
    <section :id="brickContent.anchor_identifier" class="tabulation-brick g_section">
        <section :class="`tabulation-brick__header ${theme}`">
            <div class="g_restrict">
                <h3 v-if="brickContent.title" class="g_h3" v-html="brickContent.title"></h3>
                <article v-if="this.brickContent.description" class="g_wysiwyg g_txt_L text-center" v-html="this.brickContent.description" />
            </div>
        </section>
        <template>
            <div class="tab">
                <section :class="`tab__nav-wrapper ${theme}`">
                    <div class="g_restrict">
                        <ul class="tab__navigation">
                            <li v-for="(tab, index) in brickContent.tabs" :key="index">
                                <button :data-tab-id="'panel-id-' + tab.id" class="tab__control" :class="index === preselectedIndex ? 'tab__control--is-active' : ''" @click="tabClick">
                                    {{ tab.title }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </section>

                <section class="tab__panels">
                    <div v-for="(tab, index) in brickContent.tabs" :key="index" :id="'panel-id-' + tab.id" class="tab__panel" :class="index < 1 ? 'tab__panel--is-active' : ''">
                        <template v-for="brick in tab.tab">
                            <Brick :brick="brick" :key="brick.id" />
                        </template>
                    </div>
                </section>
            </div>
        </template>
    </section>
</template>

<script>
import Brick from '@/pages/Brick.vue';

export default {
    name: 'Tabulation',
    props: {
        brickContent: { type: Object, default: null },
    },
    components: {
        Brick,
    },
    data() {
        return {
            theme: null,
            content: null,
            preselectedIndex: 0,
            backgroundParallaxHeader: !this.$isMasterclass
                ? null
                : {
                      capsules: [
                          {
                              url: 'img/capsules_2.png',
                              class: 'bp__caps--left two-caps-little main-header',
                              start: 'top-100',
                              y: '70',
                          },
                      ],
                      trigger: 'bpWrapperMainHeader',
                  },
        };
    },
    mounted() {
        this.theme = this.brickContent.theme ? 'theme_' + this.brickContent.theme : '';
        this.brickContent.tabs.forEach((tab) => {
            if (tab.field_technology) {
                tab.field_technology = tab.field_technology.toLowerCase().trim();
            }
        });
        if (this.brickContent.tabs.some((tab) => tab.field_technology === 'vertuo' || tab.field_technology === 'original') && window.napi) {
            this.setUpTabs();
        }
    },
    methods: {
        setUpTabs() {
            window.napi
                .customer()
                .read()
                .then((value) => {
                    if (value.preferredTechnology.includes('vertuo')) {
                        this.preselectTab('vertuo');
                    } else if (value.preferredTechnology.includes('original')) {
                        this.preselectTab('original');
                    }
                });
        },
        preselectTab(tabTechnology) {
            const i = this.brickContent.tabs.findIndex((tab) => tab.field_technology === tabTechnology);
            if (i > -1) {
                const id = this.brickContent.tabs[i].id;
                const button = document.querySelectorAll("button[data-tab-id='panel-id-" + id + "']");
                if (button && button.length) {
                    this.handleTab(button[0]);
                }
            }
        },
        tabClick(event) {
            this.handleTab(event.target);
        },
        handleTab(target) {
            const targetTab = target;
            const targetID = target.dataset.tabId;
            const targetPanel = this.$el.querySelector('#' + targetID);
            const tabs = target.closest('.tab__navigation').querySelectorAll('li .tab__control');
            const panels = this.$el.querySelectorAll('.tab__panel');

            // Handle tab active
            tabs.forEach((tab) => {
                if (tab.classList.contains('tab__control--is-active')) {
                    tab.classList.remove('tab__control--is-active');
                    targetTab.classList.add('tab__control--is-active');
                }
            });

            // Handle panel active
            panels.forEach((panel) => {
                if (panel.classList.contains('tab__panel--is-active')) {
                    panel.classList.remove('tab__panel--is-active');
                    targetPanel.classList.add('tab__panel--is-active');
                }
            });

            // Fix: Re-init Swipper on tab
            window.dispatchEvent(new Event('resize'));
        },
    },
};
</script>

<style lang="scss">
.tabulation-brick {
    &__header {
        width: 100%;
        padding: 2.5rem;
        text-align: center;
    }
    .tab {
        &__nav-wrapper {
            width: 100%;
            padding: 0 0 2.5rem;
            text-align: center;
        }

        &__navigation {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: fit-content;
            gap: 0.5rem;
            margin: 0 auto;
        }

        &__control {
            color: #fff;
            font-size: 12px;
            appearance: none;
            font-weight: 600;
            letter-spacing: 1.2px;
            text-decoration: none;
            text-align: center;
            padding: 0.5rem 1rem;
            cursor: pointer;
            display: inline-block;
            border: 1px solid currentColor;
            background-color: transparent;
            transition: all 0.3s;

            @media (min-width: 768px) {
                font-size: 14px;
                letter-spacing: 1.5px;
                padding: 0.5rem 1.5rem;
            }

            &--is-active {
                border-color: #2c6004;
                background-color: rgba(#2c6004, 0.9);
                pointer-events: none;
            }
        }

        &__panels {
            width: 100%;
            height: auto;
            margin: 0;
        }

        &__panel {
            opacity: 0;
            height: 0;
            width: 0;
            visibility: hidden;

            &--is-active {
                opacity: 1;
                height: unset;
                width: unset;
                display: block;
                visibility: visible;
            }
        }
    }

    .theme_light {
        color: #000;
        background-color: #fff;

        .tab {
            &__control {
                border-color: #1b1b1b;
                color: #000;

                &--is-active {
                    color: #fff;
                    border-color: rgba(#2c6004, 0.9);
                }
            }
        }
    }
}
</style>
