<template>
    <div class="meet-our-experts">
      <h2 class="meet-our-experts__title">{{ brickContent.title }}</h2>
      <div class="meet-our-experts__content">
        <slider :slides="slides" :id="brickContent.id"/>
      </div>
    </div>
</template>

<script>
  import Slider from '@/components/generic/Slider.vue';
  import { DrupalApi } from "@/services/drupal_api";
  import {deserialize} from "@/services/serialize";

  export default{
    name: "MeetOurCoffeeExperts",
    components: { Slider },
    props: {
      brickContent: { type: Object, default: null },
    },
    data() {
      return {
        slides: []
      }
    },
    methods: {
      async fetchBoutiqueTitle(boutiqueId) {
        let drupalApi = new DrupalApi();
        if (boutiqueId) {
          const t = await drupalApi.loadContentType(boutiqueId, 'boutique');
          const deserializedData = [deserialize(t.data), t];
          return deserializedData[0].title;
        }
        return '';
      }
    },
    mounted() {
      this.brickContent.coffee_experts.map((expert) => {
        if (expert.boutique) {
          this.fetchBoutiqueTitle(expert.boutique.id).then((boutique) => {
            expertSlide.boutique = boutique;
          });
        }
        const expertSlide = {
          imgMobile: expert.expert_picture.image_style_uri['1_1'],
          img: expert.expert_picture.image_style_uri['1_1'],
          title: expert.name,
          desc:  expert.description ? expert.description.processed : '',
          boutique: ''
        };
        this.slides.push(expertSlide);
      });
    }
  }
</script>

<style lang="scss">
  .meet-our-experts{
    background-color: #f4eee7;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    margin-top: 0;
    @media screen and (min-width: 993px){
      row-gap: 3.5rem;
      padding-top: 3.5rem;
    }
    &__title{
      text-align: center;
      font-size: 1.875rem;
      color: #000000;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    &__content{
      width: 100%;
      max-width: 1200px;
      margin: auto;
    }
  }
</style>
