<template>
  <div class="image-insert" :style="'--bg-color:'+ backgroundColor">
    <div v-if="brickContent.images && (brickContent.images.length === 3 || brickContent.images.length === 4)" class="multi-image-layout">
        <h2 class="multi-image-layout__title" v-if="brickContent.title" v-html="brickContent.title" :style="{ color: brickContent.field_title_color_image_insert.color }"></h2>
        <div :class="`multi-image-layout multi-image-layout-${brickContent.images.length} multi-image-layout-${brickContent.images.length}--${assertPositionClass}`">
          <AssertBox
              :position="brickContent.field_assert_position_img_insert"
              :insertText="brickContent.field_insert_text_image_insert.value"
              :assertTextBackgroundColor="brickContent.field_assert_background_color.color"
              :assertTextColor="brickContent.field_text_color_image_insert.color">
            </AssertBox>

          <div v-for="(media, index) in brickContent.images" :key="index" :class="`image-${index + 1} ${assertPositionClass}` ">
            <ImageResponsive
              :image="getImageStyle(media, index, 'desktop')"
              :imageMobile="getImageStyle(media, index, 'mobile')" />
          </div>
        </div>
    </div>
    <div v-else-if="brickContent.images && brickContent.images.length === 2" class="two-image-layout">
      <AssertBox
        :position="brickContent.field_assert_position_img_insert"
        :insertText="brickContent.field_insert_text_image_insert.value"
        :assertTextBackgroundColor="brickContent.field_assert_background_color.color"
        :assertTextColor="brickContent.field_text_color_image_insert.color">
      </AssertBox>
      <div v-for="(media, index) in brickContent.images" :key="index" :class="`image-${index + 1}`">
        <div v-if="index === 0 && brickContent.title !== null" class="image-insert__title-container">
          <h2 class="image-insert__title" v-html="brickContent.title" :style="{ color: brickContent.field_title_color_image_insert.color }"></h2>
        </div>
        <ImageResponsive
          :image="getImageStyle(media, index, 'desktop')"
          :imageMobile="getImageStyle(media, index, 'mobile')"
        />
      </div>
    </div>
    <div v-if="brickContent.images && brickContent.images.length === 1" class="assert-box one-image-layout">
      <AssertBox
        :position="brickContent.field_assert_position_img_insert"
        :insertText="brickContent.field_insert_text_image_insert.value"
        :assertTextBackgroundColor="brickContent.field_assert_background_color.color"
        :assertTextColor="brickContent.field_text_color_image_insert.color">
      </AssertBox>
      <image-responsive
        :image="brickContent.images[0].image.image_style_uri['21_9_desktop']"
        :imageMobile="brickContent.images[0].image_mobile ? brickContent.images[0].image_mobile.image.image_style_uri['21_9'] : brickContent.images[0].image.image_style_uri['21_9']" />

    </div>

  </div>
</template>


<script>

import AssertBox from '@/components/generic/AssertBox.vue';
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
  name: 'ImageInsert',
  components: {
    AssertBox,
    ImageResponsive
  },
  props: {
    brickContent: { type: Object, default: null },
  },
  data() {
    return {
      backgroundColor: '#fff',
    }
  },
  computed: {
    assertPositionClass() {
      const positionMap = {
        '0': 'top-left',
        '1': 'bottom-left',
        '2': 'center-top',
        '3': 'center-middle',
        '4': 'center-bottom',
        '5': 'top-right',
        '6': 'bottom-right',
      };
      return positionMap[this.brickContent.field_assert_position_img_insert] || '';
    }
  },
  mounted() {
    if(this.brickContent.field_backgroundcolor){
      this.backgroundColor = this.brickContent.field_backgroundcolor.color;
    }
  },
  methods: {
    getImageStyle(media, index, deviceType) {
      if (!media || !media.image || !media.image.image_style_uri) {
        return '';
      }
      const styles = media.image.image_style_uri;

      // Map of styles based on the number of images and their position
      const styleMap = {
        // 2 images
        2: {
          0: '37_49',
          1: '3_4'
        },
        // 3 images
        3: {
          0: '1_1',
          1: '22_31',
          2: '21_16',
        },
        // 4 images
        4: {
          0: '1_1',
          1: '22_31',
          2: '21_16',
          3: '17_13'
        }
      };

      const nbImages = this.brickContent.images.length;
      const styleKey = styleMap[nbImages] && styleMap[nbImages][index];

      return deviceType === 'desktop' ? styles[styleKey] : styles[styleKey];
    }
  }


}
</script>
<style lang="scss">
  .image-insert{
    background-color: var(--bg-color);
    .multi-image-layout{
      padding: 3rem 0;
      &__title{
        max-width: 1200px;
        margin: auto;
        font-size: 1.625rem;
        line-height: 2rem;
        letter-spacing: 2.5px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 1.5rem;
        padding-left: 1.5rem;
        @media screen and (min-width: 1200px) {
          padding-left: 0;
        }
      }
    }
    &__title{
      font-size: 1.625rem;
      line-height: 2rem;
      letter-spacing: 2.5px;
      font-weight: 700;
      margin-bottom: 2rem;
      margin-left: 1.5rem;
      text-transform: uppercase;
      max-width: 18.75rem;
      @media screen and (min-width: 1200px) {
        margin-left: 0;
      }
    }
    //Layout One image
    position: relative;
    .one-image-layout{
      .assert{
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        &__container{
          margin: 0 1.625rem;
        }
      }
    }

    //Layout 2 images
    .two-image-layout{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 500px;
      margin: 0 auto 0 auto;
      padding: 3.125rem 0;
      @media screen and (min-width: 992px) {
        max-width: 1200px;
        margin: auto;
      }
      .assert{
        order: 2;
        position: relative;
        top: -3rem;
        max-width: 20.3125rem;
      }
      .image{
        &-1{
          width: 100%;
          order: 1;
        }
        &-2{
          width: 100%;
          order: 3;
        }
      }

      //Desktop override
      @media screen and (min-width: 992px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 3rem 0 7rem 0;
        .assert{
          width: 30.5%;
          max-width: 23rem;
          z-index: 2;
          position: relative;
          &__container{
            width: 100%;
            padding: 2rem;
          }

          //Bottom / right
          &--center-bottom{
            align-items: flex-end;
            justify-content: center;
            right: -5rem;
            bottom: -3rem;
            top: initial;
            .assert__container{
              position: relative;
              bottom: -3rem;
            }
          }
          //Bottom / left
          &--center-top{
            align-items: flex-end;
            right: -5rem;
            top: -25.5rem;
          }
          &--center-middle{
            margin: 0 1.5rem;
            top: -15rem;
          }
        }

        .image{
          &-1{
            width: 30.5%;
            align-self: flex-start;
          }
          &-2{
            width: 39%;
          }
        }
      }

      @media screen and (min-width: 1200px) {
        .assert{
          &--center-top{
            top: -30.5rem;
          }
        }
      }
    }

    //Three image display
    .multi-image-layout-3{
      display: flex;
      flex-direction: column;
      max-width: 500px;
      margin: 0 auto -7.5rem auto;
      @media screen and (min-width: 992px) {
        max-width: 1200px;
        margin: auto;
      }
      .assert{
        max-width: 80%;
        position: relative;
        z-index: 4;
      }
      .image{
        &-1{
          width: 71%;
          align-self: flex-end;
          margin-right: 1.5rem;
          position: relative;
          top: -1.5rem;
          z-index: 3;
        }
        &-2{
          width: 63.5%;
          position: relative;
          top: -3.5rem;
          z-index: 2;
        }
        &-3{
          width: 41.3%;
          align-self: center;
          margin-left: 2rem;
          position: relative;
          top: -10rem;
          z-index: 3;
        }
      }

      //Desktop override and variants.
      @media screen and (min-width: 992px) {
        flex-direction: row;
        align-items: center;
        max-width: 1200px;
        margin: auto;
        height: 50.875rem;
        position: relative;
        padding: 4rem 0;
        &--top-right{
          flex-direction: row-reverse;
        }
        .assert{
          width: 100%;
          max-width: 23rem;
          position: absolute;
          &--top-left{
            align-self: center;
            top: 1rem;
            left: 0;
          }
          &--top-right{
            top: 2rem;
            right: 0;
          }
          &--bottom-right{
            bottom: 7.75rem;
            right: 0;
          }
          &--bottom-left{
            bottom: 7.75rem;
            left: 0;
          }
        }
        .image{
          &-1{
            align-self: flex-end;
            top: initial;
            width: 36.25%;
            &.top-left{
              left: 6rem;
              bottom: 2.75rem;
            }
            &.top-right{
              right: 6rem;
              bottom: 2.75rem;
            }
            &.bottom-right{
              left: 0;
              bottom: 6.125rem;
              width: 15.625rem;
              aspect-ratio: 1/1;
            }
            &.bottom-left{
              position: absolute;
              right: 0;
              bottom: 6.125rem;
              width: 250px;
              aspect-ratio: 1/1;
            }
            img{
              margin: 0;
            }
          }
          &-2{
            align-self: flex-end;
            height: 100%;
            width: auto;
            display: flex;
            align-items: flex-end;
            &.top-left{
              top: initial;
              left: 2.5rem;
            }
            &.top-right{
              top: initial;
              right: 2.5rem;
            }
            &.bottom-right{
              position: absolute;
              left: 11.25rem;
              bottom: 0;
            }
            &.bottom-left{
              position: absolute;
              right: 11.25rem;
              bottom: 0;
            }
            .responsive-image{
              height: calc(100% - 7.25rem);
              aspect-ratio: 5/7;
            }
            img{
              margin: 0;
            }
          }
          &-3{
            width: initial;
            align-self: flex-start;
            position: absolute;
            &.top-left{
              top: 0;
              right: 0;
            }
            &.top-right{
              top: 0;
              left: 0;
              margin-left: 0;
            }
            &.bottom-right{
              right: 14.5rem;
              top: 0;
            }
            &.bottom-left{
              left: 12.5rem;
              top: 0;
            }
            img{
              margin: 0;
            }
          }
        }
      }
    }

    .multi-image-layout-4{
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 0 auto -10rem auto;
      max-width: 500px;
      @media screen and (min-width: 992px) {
        max-width: 1200px;
      }
      .assert {
        width: 100%;
        max-width: 23rem;
        position: relative;
        z-index: 4;
      }
      .image{
        &-1{
          width: 48.2%;
          position: relative;
          z-index: 2;
        }
        &-2{
          width: 63.5%;
          align-self: flex-end;
          position: relative;
          top: -5rem;
        }
        &-3{
          width: 41.3%;
          margin-left: 1.5rem;
          position: relative;
          z-index: 2;
          top: -10rem;
        }
        &-4{
          align-self: flex-end;
          width: 71.5%;
          margin-right: 1.5rem;
          position: relative;
          top: -12rem;
          z-index: 3;
        }
      }

      @media screen and (min-width: 992px) {
        flex-direction: row;
        align-items: center;
        max-width: 1200px;
        margin: auto;
        position: relative;
        padding-top: 6.25rem;
        &--top-left{
          flex-direction: row-reverse;
        }
        .assert{
          width: 100%;
          max-width: 23rem;
          position: absolute;
          &--top-right{
            top: 8.75rem;
            right: 0;
          }
          &--top-left{
            top: 8.75rem;
            left: 0;
          }
          &--bottom-left{
            bottom: 8.75rem;
            left: 0;
          }
        }
        .image{
          &-1{
            width: 20.8%;
            &.top-right{
              position: relative;
              z-index: 2;
            }
          }
          &-2{
            height: 45.125rem;
            width: auto;
            display: flex;
            align-items: flex-end;
            &.top-right{
              position: relative;
              z-index: 1;
              left: -2.5rem;
              bottom: -5rem;
            }
            &.top-left{
              position: relative;
              z-index: 1;
              right: -2.5rem;
              bottom: -5rem;
            }
          }
          &-3{
            width: 25.6%;
            margin-left: 0;
            &.top-right{
              position: relative;
              z-index: 2;
              top: -15rem;
              left: -7rem;
            }
            &.top-left{
              position: relative;
              z-index: 2;
              top: -15rem;
              right: -7rem;
            }
          }
          &-4{
            width: 27.9%;
            margin-right: 0;
            &.top-right{
              position: relative;
              z-index: 3;
              left: -15rem;
              top: -15rem;
            }
            &.top-left{
              position: relative;
              z-index: 3;
              right: -15rem;
              top: -15rem;
            }
          }
        }
      }
    }
  }
</style>
