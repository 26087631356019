<template>
    <transition name="fade">
        <div class="cp-modal" role="dialog" aria-modal="true">
            <div class="modal-backdrop" @click="close"></div>

            <div class="modal-content products-add-to-cart-modal">
                <button tabindex="1" :id="'modal-close-btn-' + product.urlFriendlyName" class="modal-close" @click="close">
                    ✕
                    <span class="g_visually_hidden">{{ $t('commercialOffer.closeModal') }}</span>
                </button>

                <div class="modal-inner">
                    <h4 class="g_h4" v-if="product.name">{{ product.name }}</h4>
                    <img v-lazy="'https://www.nespresso.com' + product.responsiveImages.standard" />
                    <div class="g_image" v-lazy:background-image="'https://www.nespresso.com' + product.responsiveImages.standard"></div>
                    <div class="g_headline g_wysiwyg g_txt_M" v-if="product.headline" v-html="product.headline"></div>
                    <div class="modal-inner-description" v-if="product.modal_description" v-html="product.modal_description.processed"></div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { $ } from '@/jquery';

export default {
    name: 'ProductsAddToCardModal',
    props: {
        product: { type: Object, default: null },
    },
    mounted() {
        $('html').addClass('g_scrollLock');
        $('#modal-close-btn-' + this.product.urlFriendlyName).focus();
    },
    methods: {
        close() {
            $('html').removeClass('g_scrollLock');
            setTimeout(() => this.$emit('close'), 100);
        },
    },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 900px) {
    .modal-inner {
        display: flex;
        height: 100%;
        margin: 0 auto;
    }
}

.modal-content {
    max-width: 500px;
    min-width: auto;
}

.modal-inner {
    flex-shrink: 0;
    overflow-y: auto;
    width: 100%;
    background: #f2f2f2;
    background-color: white;
    flex-direction: column;
    padding: 2rem;
    text-align: center;

    img {
        max-width: 280px;
        margin: 0 auto 0.5rem;
    }
}

.g_headline {
    text-transform: uppercase;
}

.modal-close {
    background-color: transparent;
    position: absolute;
    right: 0;
    width: 20px;
    height: 20px;
    left: unset;
    top: 20px;

    &::before,
    &::after {
        content: none;
    }
}

.modal-content.products-add-to-cart-modal {
    max-height: calc(95vh - 34px);
    height: auto;
}
</style>
