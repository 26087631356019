<template>
  <div class="register-form">
    <div class="register-form__content" v-if="emData">
      <h3>{{ this.brick.field_registration_title_text }}</h3>
      <span v-if="emData.eventAvailability">
        {{ emData.eventAvailability }}
        {{
          emData.eventAvailability > 1
            ? brick.field_reg_participations
            : brick.field_registration_participation
        }}
      </span>

      <span v-if="emData.eventAvailability === 0" class="register-form__waiting">
        {{ brick.event_full_text }}
      </span>

      <div class="register-form__error-slot" ref="errorSlot">
        <ul class="register-form__error" v-if="formError">
          <li v-for="item in formError" :key="item">
            <p class="register-form__error--item" v-html="item" />
          </li>
        </ul>
      </div>

      <fieldset class="register-form__main" v-if="formData">

        <div v-if="formData.elements.email" class="register-form__input">
          <label for="mail">
            {{ formData.elements.email["#title"] }}
            <span
              v-if="formData.elements.email['#required']"
              class="required"
            >*</span
            >
          </label>
          <input
            type="email"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="true"
            :aria-required="true"
            id="mail"
            :placeholder="formData.elements.email['#placeholder'] !== undefined ? formData.elements.email['#placeholder'] : ''"
            v-model="emailValue"
          />
        </div>
        <div v-if="formData.elements.employee_id" class="register-form__input">
          <label for="employee_id">
            {{ formData.elements.employee_id["#title"] }}
            <span
              v-if="formData.elements.employee_id['#required']"
              class="required"
              >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.employee_id['#required']"
            :aria-required="formData.elements.employee_id['#required']"
            id="employee_id"
            :placeholder="formData.elements.employee_id['#placeholder'] !== undefined ? formData.elements.employee_id['#placeholder'] : ''"
            v-model="employeeIdValue"
          />
        </div>
        <div v-if="formData.elements.first_name" class="register-form__input">
          <label for="firstname">
            {{ formData.elements.first_name["#title"] }}
            <span
              v-if="formData.elements.first_name['#required']"
              class="required"
            >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.first_name['#required']"
            :aria-required="formData.elements.first_name['#required']"
            id="firstname"
            :placeholder="formData.elements.first_name['#placeholder'] !== undefined ? formData.elements.first_name['#placeholder'] : ''"
            v-model="firstNameValue"
          />
        </div>

        <div v-if="formData.elements.last_name" class="register-form__input">
          <label for="surname">
            {{ formData.elements.last_name["#title"] }}
            <span
              v-if="formData.elements.last_name['#required']"
              class="required"
            >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.last_name['#required']"
            :aria-required="formData.elements.last_name['#required']"
            id="surname"
            :placeholder="formData.elements.last_name['#placeholder'] !== undefined ? formData.elements.last_name['#placeholder'] : ''"
            v-model="lastNameValue"
          />
        </div>

        <div v-if="formData.elements.phone_number" class="register-form__input">
          <label for="phone_number">
            {{ formData.elements.phone_number["#title"] }}
            <span
              v-if="formData.elements.phone_number['#required']"
              class="required"
              >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.phone_number['#required']"
            :aria-required="formData.elements.phone_number['#required']"
            id="phone_number"
            :placeholder="formData.elements.phone_number['#title'] + (formData.elements.phone_number['#required'] ? ' *' : '')"
            v-model="phoneNumberValue"
          />
        </div>

        <div
          v-if="formData.elements.company_address"
          class="register-form__input"
        >
          <label for="company_city">
            {{ formData.elements.company_city["#title"] }}
            <span
              v-if="formData.elements.company_city['#required']"
              class="required"
              >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.company_city['#required']"
            :aria-required="formData.elements.company_city['#required']"
            id="company_city"
            :placeholder="formData.elements.company_city['#placeholder'] !== undefined ? formData.elements.company_city['#placeholder'] : ''"
            v-model="companyCityValue"
          />
        </div>

        <div
          v-if="formData.elements.company_address"
          class="register-form__input"
        >
          <label for="company_address">
            {{ formData.elements.company_address["#title"] }}
            <span
              v-if="formData.elements.company_address['#required']"
              class="required"
              >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.company_address['#required']"
            :aria-required="formData.elements.company_address['#required']"
            id="company_address"
            :placeholder="formData.elements.company_address['#placeholder'] !== undefined ? formData.elements.company_address['#placeholder'] : ''"
            v-model="companyAddressValue"
          />
        </div>

        <div v-if="formData.elements.company_name" class="register-form__input">
          <label for="company_name">
            {{ formData.elements.company_name["#title"] }}
            <span
              v-if="formData.elements.company_name['#required']"
              class="required"
              >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.company_name['#required']"
            :aria-required="formData.elements.company_name['#required']"
            id="company_name"
            :placeholder="formData.elements.company_name['#placeholder'] !== undefined ? formData.elements.company_name['#placeholder'] : ''"
            v-model="companyNameValue"
          />
        </div>

        <div
          v-if="formData.elements.company_postal_code"
          class="register-form__input"
        >
          <label for="company_postal_code">
            {{ formData.elements.company_postal_code["#title"] }}
            <span
              v-if="formData.elements.company_postal_code['#required']"
              class="required"
              >*</span
            >
          </label>
          <input
            type="text"
            @focus="changeStateFocus"
            @blur="changeStateFocus"
            :required="formData.elements.company_postal_code['#required']"
            :aria-required="formData.elements.company_postal_code['#required']"
            id="company_postal_code"
            :placeholder="formData.elements.company_postal_code['#placeholder'] !== undefined ? formData.elements.company_postal_code['#placeholder'] : ''"
            v-model="companyPostalCodeValue"
          />
        </div>
      </fieldset>

      <fieldset v-if="formData.elements.guest" class="register-form__guest">
        <div class="register-form__checkbox">
          <label class="container">
            <span class="text">
              {{ formData.elements.guest["#title"] }}
              <span v-if="formData.elements.guest['#required']" class="required"
                >*</span
              >
            </span>
            <input
              type="checkbox"
              :required="formData.elements.guest['#required']"
              :aria-required="formData.elements.guest['#required']"
              @click="toggleGuestForm()"
              v-model="hasGuest"
            />
            <span class="checkmark"></span>
          </label>
        </div>

        <div v-if="!isHidden">
          <div class="register-form__guest-wrapper">
            <fieldset v-for="(item, index) in guestList" :key="item.id" class="register-form__guest-item">
              <legend class="register-form__guest-item-title">
                {{ brick.guest_label }} n° {{ index + 1 }}
              </legend>
              <div class="register-form__guest-local-actions">
                <button class="register-form__guest-local-delete" aria-label="Remove this guest" @click="removeGuest(item.id)">
                  <CloseIcon/>
                </button>
              </div>
              <div
                v-if="formData.elements.add_guest"
                class="register-form__input"
              >
                <label :for="'guest_firstname_'+index">
                  {{ formData.elements.add_guest['#element'].guest_first_name["#title"] }}
                  <span
                    v-if="formData.elements.add_guest['#element'].guest_first_name['#required']"
                    class="required"
                  >*</span
                  >
                </label>
                <input
                  type="text"
                  v-model="item.guest_first_name"
                  @focus="changeStateFocus"
                  @blur="changeStateFocus"
                  @input="changeGuestList($event, item.id, 'firstname')"
                  :required="formData.elements.add_guest['#element'].guest_first_name['#required']"
                  :aria-required="formData.elements.add_guest['#element'].guest_first_name['#required']"
                  :id="'guest_firstname_' + index"
                  :placeholder="formData.elements.add_guest['#element'].guest_first_name['#placeholder'] !== undefined ? formData.elements.add_guest['#element'].guest_first_name['#placeholder'] : ''"
                />
              </div>

              <div
                v-if="formData.elements.add_guest"
                class="register-form__input"
              >
                <label :for="'guest_lastname_'+ index">
                  {{ formData.elements.add_guest['#element'].guest_last_name["#title"] }}
                  <span
                    v-if="formData.elements.add_guest['#element'].guest_last_name['#required']"
                    class="required"
                  >*</span
                  >
                </label>
                <input
                  type="text"
                  v-model="item.guest_last_name"
                  @focus="changeStateFocus"
                  @blur="changeStateFocus"
                  @input="changeGuestList($event, item.id, 'lastname')"
                  :required="formData.elements.add_guest['#element'].guest_last_name['#required']"
                  :aria-required="formData.elements.add_guest['#element'].guest_last_name['#required']"
                  :id="'guest_lastname_' + index"
                  :placeholder="formData.elements.add_guest['#element'].guest_last_name['#placeholder'] !== undefined ? formData.elements.add_guest['#element'].guest_last_name['#placeholder'] : ''"
                />
              </div>

              <div
                v-if="formData.elements.add_guest"
                class="register-form__input"
              >
                <label :for="'guest_mail_'+index">
                  {{ formData.elements.add_guest['#element'].guest_email["#title"] }}
                  <span
                    v-if="formData.elements.add_guest['#element'].guest_email['#required']"
                    class="required"
                  >*</span
                  >
                </label>
                <input
                  type="email"
                  v-model="item.guest_email"
                  @focus="changeStateFocus"
                  @blur="changeStateFocus"
                  @input="changeGuestList($event, item.id, 'email')"
                  :required="formData.elements.add_guest['#element'].guest_email['#required']"
                  :aria-required="formData.elements.add_guest['#element'].guest_email['#required']"
                  :id="'guest_mail_' + index"
                  :placeholder="formData.elements.add_guest['#element'].guest_email['#placeholder'] !== undefined ? formData.elements.add_guest['#element'].guest_email['#placeholder'] : ''"
                />
              </div>
            </fieldset>
          </div>
          <div class="register-form__add-guest-section" v-if="this.guestList.length > 0 && this.guestList.length < 20">
            <button
              class="register-form__add-guest-btn"
              @click="addGuest()"
              v-html="brick.add_guest_text"
            >
            </button>
          </div>
        </div>
      </fieldset>

      <div class="register-form__consent" v-if="formData.settings.form_exception_message">
        <div class="register-form__consent__message" v-html="formData.settings.form_exception_message" v-if="formData.settings.form_exception_message"></div>
        <Popin :brickContent="consentPopin.attributes" v-if="formData.settings.form_exception_message && consentPopin"/>
      </div>

      <div v-if="formData.status === 'open'" class="register-form__button">
        <button
          class="g_secondaryCta"
          type="submit"
          @click="submitRegistration"
          :class="{
            'button__disabled' : !formValid,
            'button--loading' : isLoading,
          }"
        >
          <!-- If no label is defined for submit button, use trad key for SUBMIT -->
          {{
            formData.elements.actions &&
            formData.elements.actions["#submit__label"]
              ? formData.elements.actions["#submit__label"]
              : $t("form.submit")
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { $ } from "@/jquery";
import {
  fetchMasterclassForms,
  submitMasterclassForms,
  fetchMasterclassConsentPopin
} from "@/services/masterclass";
import _ from 'lodash';
import CloseIcon from '@/components/icons/CloseIcon.vue';
import Popin from '@/components/Popin.vue';

export default {
  name: "MasterclassEventRegisterForm",
  components: { Popin, CloseIcon },
  props: {
    emData: { type: Object, default: null }
  },
  data() {
    return {
      ...this.emData,
      isHidden: true,
      formData: false,
      memberClubIdValue: null,
      firstNameValue: null,
      lastNameValue: null,
      emailValue: null,
      lang: null,
      getUserInfo: null,
      companyAddressValue: null,
      companyCityValue: null,
      companyNameValue: null,
      companyPostalCodeValue: null,
      phoneNumberValue: null,
      employeeIdValue: null,
      formError: [],
      brick:  this.$drupalPage.bricks.find(bricks => bricks.type === "brick--masterclass"),
      isLoading: false,
      consent: false,
      consentPopin: false,
      guestList: [],
      hasGuest: false,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters["event/getUserInfo"];
    },
    formValid() {
      return true;
    }
  },
  watch:{
    userInfo(){
      this.memberClubIdValue = this.userInfo.memberNumber;
      this.emailValue = this.userInfo.email;
      this.lastNameValue = this.userInfo.surname;
      this.firstNameValue = this.userInfo.name;
      this.lang = this.userInfo.language;
      this.phoneNumberValue = this.userInfo.tel;
    },
    formData(){
      if(this.formData){
        this.fetchPopinContent(this.formData.settings.form_exception_message);
      }
    }
  },
  created() {
    this.fetchForms();
    if (this.userInfo) {
      this.memberClubIdValue = this.userInfo.memberNumber;
      this.emailValue = this.userInfo.email;
      this.lastNameValue = this.userInfo.surname;
      this.firstNameValue = this.userInfo.name;
      this.lang = this.userInfo.language;
      this.phoneNumberValue = this.userInfo.tel;
    }
  },
  methods: {
    fetchPopinContent: async function(element){
      var parser = new DOMParser();
      var doc = parser.parseFromString(element, "text/html");
      var popinLink = doc.querySelector('a[data-entity-uuid]');
      if (popinLink.getAttribute('data-entity-uuid')) {
        const data = await fetchMasterclassConsentPopin(popinLink.getAttribute('data-entity-uuid'));
        data.data.data.attributes.id = popinLink.getAttribute('data-entity-uuid');
        this.consentPopin = data.data.data;
      }
    },
    fetchForms: async function() {
      this.formData = await fetchMasterclassForms();
    },
    changeStateFocus: function(e) {
      e.currentTarget.parentElement.classList.toggle("focus");
    },
    triggerLogin: function() {
      $(".Header__customer .ta-login-dropdown--not-logged").trigger("click");
      return false;
    },
    triggerRegister: function() {
      $(".Header__customer .LoginDropdownButton")
        .not(".ta-login-dropdown--not-logged")
        .trigger("click");
      return false;
    },
    toggleGuestForm: function() {
      if(this.guestList.length === 0){
        this.addGuest();
      }
      this.isHidden = !this.isHidden;
      this.hasGuest = !this.hasGuest;
    },
    addGuest: function() {
      if(this.guestList.length < 20 && (this.emData.eventAvailability > (this.guestList.length + 1))){
        this.guestList.push({id: this.guestList.length + 1, guest_first_name: '', guest_last_name: '', guest_email: ''});
      }
    },
    removeGuest: function(id) {
      const indexInList = _.findIndex(this.guestList, {'id': id});
      this.guestList.splice(indexInList, 1);
      if(this.guestList.length === 0){
        this.isHidden = false;
        this.hasGuest = false;
      }
    },
    changeGuestList: function (event, id, prop){
      const value = event.target.value;
      for(let item of this.guestList){
        if(item.id === id){
          if(prop === 'guest_first_name'){
            item.firstname = value;
          }else if(prop === 'guest_last_name'){
            item.lastname = value;
          }else if(prop === 'guest_email'){
            item.email = value;
          }
        }
      }
    },
    submitRegistration: async function() {
      const {events_type} = this.brick

      let data = {
        webform_id: "event_registration",
        last_name: this.lastNameValue,
        first_name: this.firstNameValue,
        email: this.emailValue,
        guest: this.guestList.length > 0 ? '1' : '0',
        add_guest: this.hasGuest ? this.guestList : [],
        event_id: this.$route.params.id,
        customer_id: this.memberClubIdValue,
        masterclass_page: this.$store.getters['event/getPageUrl']
      };

      switch (events_type) {
        case "external_event":
          data = {
            webform_id: "event_external_registration",
            company_address: this.companyAddressValue,
            company_city: this.companyCityValue,
            company_name: this.companyNameValue,
            company_postal_code: this.companyPostalCodeValue,
            email: this.emailValue,
            event_id: this.$route.params.id,
            event_language: this.lang,
            first_name: this.firstNameValue,
            last_name: this.lastNameValue,
            phone_number: this.phoneNumberValue,
            masterclass_page: this.$store.getters['event/getPageUrl']
          };
          break;

        case "internal_event":
          data = {
            webform_id: "event_internal_registration",
            email: this.emailValue,
            employee_id: this.employeeIdValue,
            event_id: this.$route.params.id,
            event_language: this.lang,
            first_name: this.firstNameValue,
            last_name: this.lastNameValue,
            masterclass_page: this.$store.getters['event/getPageUrl']
          };
          break;

        default:
          break;
      }

      this.isLoading = true
      submitMasterclassForms(data)
        .then(response => {
          this.$router.push({
            name: "eventRegistrationSuccess",
            params: {
              id: this.emData.eventId
            }
          });
          return response;
        })
        .catch(err => {
          this.formError = [];
          for (const key in err.response.data.error) {
            this.formError.push(err.response.data.error[key]);
          }
          this.$refs.errorSlot.scrollIntoView({
            behavior: 'auto',
            block: 'center',
            inline: 'center'
          })
        }).finally(()=>{
          this.isLoading = false;
          this.$store.dispatch("event/fetchEvents");
        });
    }
  }
};
</script>

<style lang="scss">
.g {
  .register-form {
    padding: 6.25rem 0;
    @media screen and (min-width: 992px) {
      padding: 3.125rem 0;
    }
    background-color: #f2ede5;
    &__consent{
      margin-top: 1.5rem;
      a{
        color: #000000;
        text-decoration: underline;
        line-height: 1.25rem;
      }
    }
    &--disabled{
      .register-form__input,
      .register-form__checkbox{
        pointer-events: none;
        opacity: 0.3;
        &--clubid{
        opacity: 1;
        }
      }
    }
    &__guest-item{
      border-top: 1px solid #8f7247;
      margin-top: 1.5rem;
      position: relative;
      &-title{
        //Display legend.
        position: initial;
        height: initial;
        width: initial;
        overflow: initial;
        padding-right: 1.5rem;
      }
    }
    &__guest-local{
      &-actions{
        background-color: #f2ede5;
        position: absolute;
        top: -1.5rem;
        right: 0;
        width: 2.5rem;
        display: flex;
        justify-content: flex-end;
      }
      &-delete{
        border: 1px solid #8f7247;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    &__add-guest{
      &-btn{
        border: 1px solid #8f7247;
        border-radius: .5rem;
        text-transform: uppercase;
        text-align: center;
        color: #8f7247;
        font-weight: bold;
        letter-spacing: 1px;
        width: 100%;
        height: 2.625rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: .875rem;
        &:hover{
          box-shadow: 0 .25em .5em rgba(0,0,0,.3);
        }
      }
      &-section{
        margin-top: 2rem;
      }
    }
    &__content {
      min-width: 33%;
      max-width: 400px;
      width: 90%;
      margin: 0 auto;
      text-align: center;

      h3 {
        color: #000000;
        font-size: 30px;
        font-weight: 300;
        letter-spacing: 8px;
        line-height: 48px;
        text-transform: uppercase;
        @media (min-width: 600px) {
          font-size: 36px;
        }
      }
      > span {
        display: block;
        color: #3d8705;
        font-size: 18px;
        font-weight: 300;
        letter-spacing: 1px;
        line-height: 24px;
        margin-top: 24px;

        &.register-form__waiting {
          color: #dd3232;
        }
      }

      .register-form__error {
        margin-top: 24px;
        &--item {
          display: block;
          color: #dd3232;
          margin: 0;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 1.3;
        }
      }
    }

    &__main {
      margin: 30px 0 15px;
    }

    &__input {
      position: relative;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      border: solid 1px #ccc;
      border-radius: 5px;
      margin-top: 16px;

      &.focus {
        border-color: darken($color: #ccc, $amount: 20%);
      }

      input {
        &[type="text"],
        &[type="email"] {
          width: 100%;
          border: none;
          outline: 0;
          font-size: 14px;
          letter-spacing: 1px;
          line-height: 21px;
          padding: 18px 8px 2px;
          background: none;
        }
      }

      label {
        position: absolute;
        top: 4px;
        left: 8px;
        font-size: 12px;
        line-height: 16px;
        color: #8f7247;
        cursor: default;
      }
    }

    &__checkbox {
      margin-top: 16px;
      text-align: left;

      .container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .text {
          color: #000000;
          font-size: 16px;
          font-weight: 300;
          letter-spacing: 1px;
          line-height: 24px;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkmark {
            background-color: #8f7247;

            &:after {
              display: block;
            }
          }
        }
        .checkmark {
          position: absolute;
          top: 50%;
          left: 0;
          height: 25px;
          width: 25px;
          border-radius: 2px;
          background-color: #ccc;
          transition: background-color 0.2s;
          transform: translateY(-50%);

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }

        &:hover input ~ .checkmark {
          background-color: darken($color: #8f7247, $amount: 5%);
        }
      }
    }

    &__info {
      text-align: left;
      color: #8f7247;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 16px;
      margin-top: 4px;

      a {
        color: #8f7247;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-decoration: underline;
      }
    }

    &__button {
      button {
        -webkit-appearance: none;
        border: 1px solid transparent;
        display: block;
        padding: 12px 20px;
        margin-top: 40px;
        width: 100%;
        background: #8f7247;
        color: #ffffff;
        font-size: 14px;
        letter-spacing: 1px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 4px;
        text-align: center;
        transition: all 300ms;

        &:hover {
          background: #a0804f;
          box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.3);
        }

        &.button__disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        }
        &.button--loading{
          opacity: 0.5;
          pointer-events: none;
          position: relative;
          &::before{
            content: "...";
            animation-name: three_dot;
            animation-duration: 1s;
            animation-iteration-count: infinite;
            position: absolute;
            right: 1em;
            text-align: left;
            width: 1em;
          }
          &::after{
            content: "...";
            opacity: 0;
          }
        }
      }
    }
  }
}
@keyframes three_dot {
  0%   {content: ""}
  33%   {content: "."}
  66%   {content: ".."}
  100% {content: "...";}
}
</style>
