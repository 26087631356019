<template>
  <masterclass-layout>
    <masterclass-event-page :event-id="currentEventId" />
  </masterclass-layout>
</template>
<script>
import MasterclassEventPage from "@/components/masterclass/MasterclassEventPage.vue";
import MasterclassLayout from "@/pages/masterclass/MasterclassLayout"
export default {
  name: 'EventDetail',
  components: {
    MasterclassEventPage,
    MasterclassLayout
  },
  data() {
    return {
      currentEventId: null
    }
  },
  created() {
    this.currentEventId = this.$route.params.id
  }
}
</script>
