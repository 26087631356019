<template>
  <time class="ed" v-if="edData" :class="clData ? 'ed--bold' : ''"> <!-- ed for event date -->
    {{ edData.start | moment("ddd, DD.MM.YYYY") }}
  </time>
</template>

<script>
export default {
  name: 'EventDate',
  props: {
    edData: { type: Object, default: null },
    clData: { type: Boolean, default: null }
  },
  data() {
    return {
      ...this.edData,
      ...this.clData
    }
  }
}
</script>

<style lang="scss">
  .g {
    .ed {
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      font-weight: 100;

      &--bold {
        color: #000000;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 24px;

        @media screen and (min-width: 600px) { font-size: 18px; }
      }
    }
  }
</style>
