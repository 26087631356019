import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueLazyload from 'vue-lazyload';
import VueJsonLD from 'vue-jsonld';
Vue.config.devtools = true;
import App from './App.vue';
import store from '@/store';
import VueSocialSharing from 'vue-social-sharing';
import ICS from 'vue-ics';
import { PageLoader } from './services/page_loader';
import getRouter from './routers';
import VueScrollReveal from 'vue-scroll-reveal';
import VueYouTubeEmbed from 'vue-youtube-embed';

import PortalVue from 'portal-vue';
import VueClipboard from 'vue-clipboard2';

import { DrupalApi } from './services/drupal_api';
import { getScriptUrl } from './services/utils';
import { setJquery } from './jquery';
import LazyTube from "vue-lazytube";
import VueRouter from "vue-router";

Vue.config.productionTip = true;

const moment = require('moment');
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(require('vue-moment'), {moment});
Vue.use(VueSocialSharing);
Vue.use(VueYouTubeEmbed);
Vue.use(ICS);
Vue.use(VueScrollReveal);
Vue.use(PortalVue);
Vue.use(VueClipboard);
Vue.use(VueLazyload);
Vue.use(VueJsonLD);
Vue.use(LazyTube);

const load = {
    libraries: 'places',
};
if (window.location.origin.includes('localhost:')) {
    load.client = 'gme-nestlenespressosa';
} else {
    load.key = 'AIzaSyAwCOFt7C703WNz-SuXHjcgIf9AODSTUqU';
}

Vue.use(VueGoogleMaps, { load });

const messages = {
    en: {
        vote: {
            voteLoggedIn: 'You must be logged in to vote.',
            voteUserId: 'Customer number',
            voteUserZip: "Customer's postal code",
            voteMissingIdOrZip: 'Both fields must be filled in on the selected project form.',
            voteSent: 'Vote has been registered.',
            voteError: 'The vote could not be registered. Please try again later.',
        },
        imageGallery: {
            previous: 'Previous image',
            next: 'Next image',
            leaveSliderMode: 'Leave slider mode',
        },
        map: {
            openingHours: 'Opening hours',
            address: 'Address',
            directions: 'Directions',
            phoneNumber: 'Phone number',
            closeDetails: 'Close store details infobox',
        },
        recipes: {
            modalClose: 'Close',
            difficulty: 'Difficulty',
            ingredients: 'Ingredients',
            realisation: 'How to do this recipe ?',
            recommendedCoffee: 'Recommended coffee',
            intensity: 'Intensity',
        },
        hero: {
            useButtons: 'Use the buttons below to expand front or side view',
            frontView: 'Front',
            toggleToFrontView: 'Toggle to Front View',
            frontViewZoom: 'Press ENTER or SPACE to zoom on front view',
            sideView: 'Side',
            toggleToSideView: 'Toggle to Side View',
            sideViewZoom: 'Press ENTER or SPACE to zoom on side view',
            formHelp: 'Use the form below to choose your machine model, change colour, and add to bag',
            chooseColour: 'Choose your colour',
            colours: 'Colours',
            colour: 'Colour',
            reviewProduct: 'Review your product {model}',
        },
        productsAddToCart: {
            useButtons: 'Press ENTER or use arrows keys left or right to navigate between the slides',
            currentSlide: 'Current slide',
            nextSlide: 'Go to next slide',
            previousSlide: 'Go to previous slide',
        },
        form: {
            submit: 'Submit',
            submitSuccess: 'Your email has been sent to Nespresso.',
            requiredFieldsInstructions: 'All fields marked with an asterisk must be completed.',
            dateInstructions: 'Dates should all be typed in the format dd/mm/yyyy, (as in 21/07/2013).',
        },
        commercialOffer: {
            insteadOf: 'instead of',
            openModal: ' Open product details in a  modal',
            closeModal: ' close modal and return to product selection',
            backButton: ' Go back to products list',
            quantityLabel: 'Quantity',
            intensity: 'Intensity',
        },
        articles: {
            share: 'Share article',
            print: 'Print article',
        },
    },
    fr: {
        vote: {
            voteLoggedIn: 'Vous devez être connecté pour pouvoir voter.',
            voteUserId: 'Numéro de client',
            voteUserZip: 'Code postal du client',
            voteMissingIdOrZip: 'Les deux champs doivent être remplis dans le formulaire du projet sélectionné.',
            voteSent: 'Le vote a été enregistré.',
            voteError: "Le vote n'a pas pu être enregistré. Merci de réessayer ultérieurement.",
        },
        imageGallery: {
            previous: 'Image précédente',
            next: 'Image suivante',
            leaveSliderMode: 'Quitter le mode carrousel',
        },
        map: {
            openingHours: "Heures d'ouvertures",
            address: 'Addresse',
            directions: 'Directions',
            phoneNumber: 'Numéro de téléphone',
            closeDetails: 'Fermer les détails du point de vente',
        },
        recipes: {
            modalClose: 'Fermer',
            difficulty: 'Difficulté',
            ingredients: 'Ingrédients',
            realisation: 'Comment réaliser cette recette ?',
            recommendedCoffee: 'Cafés recommandés',
            intensity: 'Intensité',
        },
        hero: {
            useButtons: 'Utilisez les boutons ci-dessous pour agrandir la vue de face ou de côté',
            frontView: 'Face',
            toggleToFrontView: 'Changer à la vue de face',
            frontViewZoom: 'Cliquez sur ENTRÉE ou ESPACE pour zoomer sur la vue de face',
            sideView: 'Côté',
            toggleToSideView: 'Changer à la vue de côté',
            sideViewZoom: 'Cliquez sur ENTRÉE ou ESPACE pour zoomer sur la vue de côté',
            formHelp: "Utilisez le formulaire ci-dessous pour choisir votre modèle de machine, changer la couleur et l'ajouter au panier",
            chooseColour: 'Choisissez votre couleur',
            colours: 'Couleurs',
            colour: 'Couleur',
            reviewProduct: 'Examinez votre produit {model}',
        },
        productsAddToCart: {
            useButtons: 'Appuyez sur la touche ENTRÉE ou utilisez les touches fléchées gauche ou droite pour naviguer entre les diapositives.',
            currentSlide: 'Diapositive actuelle',
            nextSlide: 'Aller à la prochaine diapositive',
            previousSlide: 'Aller à la précédente diapositive',
        },
        form: {
            submit: 'Soumettre',
            submitSuccess: 'Votre mail a bien été envoyé à Nespresso.',
            requiredFieldsInstructions: 'Tous les champs marqués “required” doivent être remplis.',
            dateInstructions: 'Les dates doivent être écrites au format jj/mm/aaaa, (comme 21/07/2013).',
        },
        commercialOffer: {
            insteadOf: 'au lieu de',
            openModal: ' Open product details in a  modal',
            closeModal: ' close modal and return to product selection',
            backButton: ' Go back to products list',
            quantityLabel: 'Quantity',
            intensity: 'Intensité',
        },
        articles: {
            share: 'Partager l\'article',
            print: 'Imprimer l\'article',
        },
    },
    it: {
        vote: {
            voteLoggedIn: 'Per poter votare è necessario aver effettuato il login.',
            voteUserId: 'Numero di cliente',
            voteUserZip: 'Codice postale del cliente',
            voteMissingIdOrZip: 'Entrambi i campi devono essere compilati nella forma del progetto selezionato.',
            voteSent: 'Il voto è stato salvato.',
            voteError: 'Il voto non ha potuto essere registrato. Si prega di riprovare più tardi.',
        },
        imageGallery: {
            previous: 'Immagine precendente',
            next: 'Prossima immagine',
            leaveSliderMode: 'Indietro',
        },
        map: {
            openingHours: 'Orari di apertura',
            address: 'Indirizzo',
            directions: 'Indicazioni',
            phoneNumber: 'Numero di telefono',
            closeDetails: 'Chiudere i dettagli del punto',
        },
        recipes: {
            modalClose: 'Chiudere',
            difficulty: 'Difficoltà',
            ingredients: 'Ingredienti',
            realisation: 'Come preparare questa ricetta?',
            recommendedCoffee: 'Caffè consigliati',
            intensity: 'Intensità',
        },
        hero: {
            useButtons: "Utilizza i pulsanti qui sotto per ingrandire l'immagine sulla parte anteriore o laterale",
            frontView: 'Parte anteriore',
            toggleToFrontView: 'Passare alla vista frontale',
            frontViewZoom: "Premi INVIO o SPAZIO per ingrandire l'immagine sulla parte anteriore",
            sideView: 'Parte laterale',
            toggleToSideView: 'Passare alla vista laterale',
            sideViewZoom: "Premi INVIO o SPAZIO per ingrandire l'immagine sulla parte laterale",
            formHelp: 'Utilizza il modulo qui sotto per selezionare il modello di macchina da caffè desiderato, modificarne il colore e aggiungerlo al carrello',
            chooseColour: 'Seleziona il colore desiderato',
            colours: 'Colori',
            colour: 'colore',
            reviewProduct: 'Controlla il tuo ordine {model} colore',
        },
        productsAddToCart: {
            useButtons: 'Premere INVIO o utilizzare i tasti freccia sinistra o destra per navigare tra le diapositive.',
            currentSlide: 'Diapositiva corrente',
            nextSlide: 'Vai alla prossima diapositiva',
            previousSlide: 'Vai alla diapositiva precedente',
        },
        form: {
            submit: 'Invia',
            submitSuccess: 'La tua e-mail è stata inviata a Nespresso.',
            requiredFieldsInstructions: 'Tutti i campi contrassegnati come "required" devono essere compilati.',
            dateInstructions: 'Le date devono essere tutte digitate nel formato gg/mm/aaaa, (come nel 21/07/2013).',
        },
        commercialOffer: {
            insteadOf: 'invece di',
            openModal: ' Open product details in a  modal',
            closeModal: ' close modal and return to product selection',
            backButton: ' Go back to products list',
            quantityLabel: 'Quantity',
            intensity: 'Intensità',
        },
        articles: {
            share: 'Condividi questo articolo',
            print: 'Stampa questo articolo',
        },
    },
    de: {
        vote: {
            voteLoggedIn: 'Sie müssen eingeloggt sein, um abstimmen zu können.',
            voteUserId: 'Kundennummer',
            voteUserZip: 'Postleitzahl des Kunden',
            voteMissingIdOrZip: 'Beide Felder müssen in der Form des gewählten Projekts ausgefüllt werden.',
            voteSent: 'Die Abstimmung wurde gespeichert.',
            voteError: 'Die Stimme konnte nicht registriert werden. Bitte versuchen Sie es später noch einmal.',
        },
        imageGallery: {
            previous: 'Vorhergeriges Bild',
            next: 'Nächstes Bild',
            leaveSliderMode: 'Slider-Modus verlassen',
        },
        map: {
            openingHours: 'Uhrzeiten',
            address: 'Adresse',
            directions: 'Route',
            phoneNumber: 'Telefonnummer',
            closeDetails: 'Shop Details Infobox schliessen',
        },
        recipes: {
            modalClose: 'Schliessen',
            difficulty: 'Schwierigkeit',
            ingredients: 'Zutaten',
            realisation: 'Zubereitung',
            recommendedCoffee: 'Empfohlene Nespresso Kaffees',
            intensity: 'Intensität',
        },
        hero: {
            useButtons: 'Verwenden Sie die Schaltflächen unten, um die Vorder- oder Seitenansicht zu vergrössern',
            frontView: 'Vorne',
            toggleToFrontView: 'Umschalten zur Vorderansicht',
            frontViewZoom: 'Drücken Sie die EINGABE- oder LEERTASTE, um die Vorderansicht zu vergrössern',
            sideView: 'Seite',
            toggleToSideView: 'Umschalten zur Seitenansicht',
            sideViewZoom: 'Drücken Sie die EINGABE- oder LEERTASTE, um die Vorderansicht zu vergrössern',
            formHelp: 'Mit dem untenstehenden Formular können Sie Ihr gewünschtes Modell wählen, die Farbe ändern und es zum Warenkorb hinzufügen',
            chooseColour: 'Wählen Sie Ihre Farbe',
            colours: 'Farben',
            colour: 'Farbe',
            reviewProduct: 'Ihr Produkt ansehen {model}',
        },
        productsAddToCart: {
            useButtons: 'Drücken Sie ENTER oder verwenden Sie die Pfeiltasten links oder rechts, um zwischen den Folien zu navigieren.',
            currentSlide: 'Aktuelle Folie',
            nextSlide: 'Zur nächsten Folie wechseln',
            previousSlide: 'Zur vorherigen Folie wechseln',
        },
        form: {
            submit: 'Absenden',
            submitSuccess: 'Ihre E-Mail wurde an Nespresso gesendet.',
            requiredFieldsInstructions: 'Die mit "Pflichtfeld" gekennzeichneten Felder müssen ausgefüllt werden.',
            dateInstructions: 'Die Daten sollten alle im Format tt/mm/jjjj, (wie am 21/07/2013) eingegeben werden.',
        },
        commercialOffer: {
            insteadOf: 'statt',
            openModal: ' Open product details in a  modal',
            closeModal: ' close modal and return to product selection',
            backButton: ' Go back to products list',
            quantityLabel: 'Quantity',
            intensity: 'Intensität',
        },
        articles: {
            share: 'Artikel teilen',
            print: 'Artikel drucken',
        },
    },
};

const loadPage = async (id, url, page) => {
    await setJquery();
    // Init Drupal
    let rootUrl = url ? url : getScriptUrl();
    // force trailing slash
    rootUrl = rootUrl.replace(/\/?$/, '/');
    new DrupalApi(rootUrl);

    const loader = new PageLoader();
    const drupalPage = await loader.loadPage(id, page);
    if (!drupalPage) {
        console.error('Following id page seems not exist:', id);
    }

    Vue.prototype.$drupalPage = drupalPage;
    Vue.prototype.$distUrl = rootUrl;
    Vue.prototype.$publicUrl = process.env.NODE_ENV === 'development' ? '' : rootUrl + '/front-app/dist/';

    const i18n = new VueI18n({
        locale: drupalPage.lang,
        fallbackLocale: drupalPage.lang,
        messages,
    });

    moment.locale(i18n.locale);
    Vue.directive('click-outside', {
        bind: function (el, binding, vnode) {
            el.clickOutsideEvent = function (event) {
                // here I check that click was outside the el and his childrens
                if (!(el == event.target || el.contains(event.target))) {
                    // and if it did, call method provided in attribute value
                    vnode.context[binding.expression](event);
                }
            };
            document.body.addEventListener('click', el.clickOutsideEvent);
        },
        unbind: function (el) {
            document.body.removeEventListener('click', el.clickOutsideEvent);
        },
    });

    new Vue({
        el: '#app',
        i18n,
        store,
        router: getRouter(drupalPage),
        render: (h) => h(App),
        components: { App },
    });
};

// Set the lightNest globally so that the user can just write lightNest.loadPage(id)
window.lightNest = {
    loadPage,
};

//89ec0299-4235-457d-b238-74d2445f4c38 external
//dd7fd211-38ed-4cd8-b334-52ead9beb05d internal
//37ba28b7-7e42-4381-84aa-795c06b87224 event

// if (process.env.NODE_ENV !== 'production') {
// Default demo
//------ Original (Comment the next line before pushing!)


// window.lightNest.loadPage('b13811a2-ccdc-41e4-aae9-17604e33cacd', 'http://nespresso-ch-acquia.localhost', 'article');
// window.lightNest.loadPage('ace8fef1-8549-4bf8-bf30-d8254dd98144', 'http://nespresso-ch-acquia.localhost');
// window.lightNest.loadPage('d96208d8-8c75-44d2-98b9-318125fecba2', 'http://nespresso-ch-acquia.localhost');
// window.lightNest.loadPage('fb6ef437-5ca5-42b7-8842-1cceb26401ae', 'https://nespresso-acquia.suisse.sqli.com');

// Components Revamp
// window.lightNest.loadPage('18ec4a18-cfc2-4099-82f4-d32992a1d630', 'https://nespresso-acquia.suisse.sqli.com');

// Article
// window.lightNest.loadPage('12283111-942c-4530-8554-17484e76486d', 'http://nespresso-ch-acquia.localhost', 'article');
// window.lightNest.loadPage('4d2c0466-75aa-44e5-863f-6a03995030a3', 'https://develop-sr3snxi-v6m4zgfd3r6fm.eu-2.platformsh.site');

//------ Preview/Dev (Comment the next line before pushing!)
// window.lightNest.loadPage('98441107-59fc-4a03-a53b-b151d6b24d61', 'https://nespresso-acquia.suisse.sqli.com');

// Masterclass
// window.lightNest.loadPage('89ec0299-4235-457d-b238-74d2445f4c38', 'https://masterclass-nespresso-acquia.suisse.sqli.com')

// Sustainability Landing page demo
// window.lightNest.loadPage('bba8b7d2-4e87-4fca-8777-36a7fc758da5', 'https://sustainability-nespresso-acquia.suisse.sqli.com', 'earth_page')

// Sustainability Kiosk demo
// window.lightNest.loadPage('58dbb178-5687-4310-92b2-4ba7fcf6a214', 'https://sustainability-nespresso-acquia.suisse.sqli.com', 'kiosk')

// Sustainability Call center demo
// window.lightNest.loadPage('5bd8ff47-de40-402a-adf0-550567507482', 'https://sustainability-nespresso-acquia.suisse.sqli.com', 'call_center')
// }
