<template>
  <div class="g_section g_light event-list" :id="brickContent.anchor_identifier">
    <div class="g_restrict">
      <Filters
        :cl-data="allProcessedThemesData"
        :f-data="filtersStrings"
        :aliases="aliasFilters"
        @apply="handleApplyFilters"
        ref="filters"
      />
    </div>
    <background-parallax :cp-data="BackgroundParallaxProps">
      <div class="g_restrict">
        <all-events
          :aliases="aliasFilters"
          :ae-data="allEventsStrings"
          :ec-data="allProcessedThemesData"
          :filters="JSON.parse(JSON.stringify(filters))"
          @clearFilters="clearFilters"
        />
      </div>
    </background-parallax>
  </div>
</template>

<script>
import Filters from "@/components/masterclass/Filters";
import AllEvents from "@/components/masterclass/AllEvents";
import BackgroundParallax from "@/components/masterclass/BackgroundParallax";

export default {
  name: "MasterclassEventList",
  components: {
    Filters,
    AllEvents,
    BackgroundParallax
  },
  props: {
    brickContent: { type: Object, default: null }
  },
  data() {
    return {
      filters: {
        langs: [],
        themes: [],
        boutique: "",
        coffeeExperts: []
      },
      ...this.brickContent,
      BackgroundParallaxProps: {
        capsules: [
          {
            url: "img/capsules-blue-brown.png",
            class: "bp__caps--left two-caps",
            start: "top-50",
            y: "70"
          },
          {
            url: "img/capsules-gold.png",
            class: "bp__caps--right two-caps",
            start: "top-20",
            y: "0"
          }
        ],
        trigger: "bpWrapperEventPage"
      },
      filtersStrings: {
        title: this.brickContent.filters_label,
        geolocation: {
          title: this.brickContent.filter_location_text,
          placeholder: this.brickContent.filter_location_placeholder
        },
        language: {
          title: this.brickContent.filter_language_text,
          lang: [
            { langCode: "EN" },
            { langCode: "FR" },
            { langCode: "DE" },
            { langCode: "IT" }
          ]
        },
        themeTitle: this.brickContent.filter_theme_text,
        btnLabel: this.brickContent.filters_button_label,
        coffeeExpertsTitle: this.brickContent.coffee_expert_section_title,
      },
      allEventsStrings: {
        title: this.brickContent.events_title,
        results: {
          singular: this.brickContent.result_text,
          plural: this.brickContent.results_text
        },
        clear: this.brickContent.filters_reset_text,
        order: {
          label: this.brickContent.sort_title,
          choices: [
            {
              choice: this.brickContent.sort_ascending_text,
              slug: "latest"
            },
            {
              choice: this.brickContent.sort_descending_text,
              slug: "oldest"
            },
            {
              choice: this.brickContent.sort_availability_text,
              slug: "availability"
            }
          ]
        },
        pagination: {
          prev: this.brickContent.events_list_caroussel_prev,
          next: this.brickContent.events_list_caroussel_next
        },
        anchor: this.brickContent.filters_anchor_text
      }
    };
  },
  computed: {
    allProcessedThemesData() {
      return this.$store.getters["event/themesList"];
    },
    allProcessedEventsData() {
      return this.$store.getters["event/eventsList"];
    },
    doneEventsData() {
      return this.$store.getters["event/doneEvents"];
    },
    aliasFilters() {
      return this.$store.getters["event/aliasFiltersList"];
    }
  },
  methods: {
    updateFilter() {
      this.$refs.updateFilter.Updated();
    },
    handleApplyFilters(filters) {
      this.filters = JSON.parse(JSON.stringify(filters));
    },
    clearFilters() {
      this.$refs.filters.clearFilters();
    }
  },
  created() {
    this.$store.commit("event/setBricks", this.brickContent);
    this.$store.dispatch("event/fetchEvents");
    this.$store.dispatch("event/fetchThemes");
    this.$store.dispatch("event/fetchBoutiques");
    this.$store.dispatch("event/fetchCoffeeExperts");
    this.$store.dispatch("event/fetchAliasFilters");
  },
};
</script>

<style lang="scss">
.g {
  .event-list {
    .bp {
      margin-top: 50px;
      .all-events {
        margin-top: -50px;
      }
    }
  }
}
</style>
