<template>
  <div class="store-locator-toast" v-if="boutique && display">
    <div class="store-locator-toast__map">
      <StoreNearYouMap :link-url="linkUrl" :boutique="boutique" static :zoom="14" />
    </div>
    <div class="store-locator-toast__content">
      <Cross :onClick="close" />
      <h3 v-text="title" class="store-locator-toast__content__near"/>
      <p class="store-locator-toast__content__store-type" v-text="brickContent.store_type.name"/>
      <h3 v-html="boutique.title"/>
      <LinkRouter :href="linkUrl">
          {{linkLabel}}
      </LinkRouter>
    </div>
  </div>
</template>

<script>
import Cross from '@/components/store-locator//partials/Cross.vue'
import LinkRouter from '@/components/store-locator//partials/LinkRouter'
import StoreNearYouMap from "@/components/store-locator/brick/StoreNearYouMap.vue";


export default {
  components: {
    LinkRouter,
    Cross,
    StoreNearYouMap
  },
  props: {
    brickContent: { type: Object },
    title: {type: String, default: "Near you"},
    boutique: {type: Object},
    linkUrl: {type: String, default: "#"},
    linkLabel: {type: String, default: "Read"},
    baseUrl: { type: String, default: "/" },

  },
  data() {
    return {
      display: false,
    }
  },
  methods: {
    close() {
      sessionStorage.setItem('StoreLocatorToast', '1')
      this.display = false
    }
  },
  mounted() {
    if(sessionStorage.getItem('StoreLocatorToast')){
      this.display = false;
    }else{
      this.display = true;
    }
  }
}
</script>

<style lang="scss">
.g {
  .store-locator-toast {
    position: fixed;
    right: 40px;
    bottom: 40px;
    z-index: 9999;
    display: flex;
    background: #fff;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;

    &__map {
      position: relative;
      width: 124px;
      overflow: hidden;
      .store-near-you-map{
        position: absolute;
        top: -10px;
        right: -10px;
        left: -10px;
        bottom: -50px;
        width: auto;
        height: auto;
      }
    }

    &__content {
      padding: 10px 16px;
      min-width: 300px;

      h3 {
        font-size: 18px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 3px;
        text-transform: uppercase;
        color: #000000;
        padding-bottom: 8px;
      }

      &__store-type {
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #666666;
      }

      .cross {
        top: 8px;
        right: 8px;
      }
    }
  }
}
</style>
