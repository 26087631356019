<template>
  <section class="g_section g_light">
    <div class="simple__text">
      <h3 v-if="brickContent.title">{{ brickContent.title }}</h3>
      <p
        v-if="brickContent.introduction"
        v-html="brickContent.introduction"
      />
    </div>
  </section>
</template>

<script>
export default {
  name: "SimpleText",
  props: {
    brickContent: { type: Object, default: null }
  },
  data() {
    return {
      ...this.brickContent
    }
  }
}
</script>

<style lang="scss">
.g {
  .simple__text {
    background: #ffffff;
    margin: 70px auto;
    padding: 0 20px;
    &.event-page {
      margin-top: 60px;
      margin-bottom: 64px;
    }
    @media screen and (min-width: 900px) { margin: 100px auto; }

    h3 {
      font: {
        size: 30px;
        weight: 300;
      }
      text: {
        transform: uppercase;
        align: center;
      }
      letter-spacing: 6px;
      line-height: 40px;
      margin: 0 0 30px;

      @media screen and (min-width: 900px) {
       font-size: 36px;
       margin: 0 0 50px;
      }
    }

    p {
      font: {
        size: 18px;
        weight: 300;
      }
      text: {
        align: center;
      }
      letter-spacing: 1px;
      line-height: 1.3;
      max-width: 655px;
      margin: 0 auto;
      @media screen and (min-width: 900px) {
        font-size: 24px;
        letter-spacing: 2px;
      }
    }
  }
}
</style>
