<template>
    <section :id="brickContent.anchor_identifier" class="h h_header"
        :class="[brickContent.insert_text ? 'h_header_with_insert' : '', hasMedia ? 'h_header_with_media' : '']"
        :style="'background-color: ' + brickContent.backgroundcolor.color + '; color: ' + (brickContent.text_color_black_or_white ? 'black' : 'white')">
        <div class="h_header_main">
            <h1 v-html="brickContent.title"></h1>
            <div v-if="brickContent.text" v-html="brickContent.text.value"></div>
            <img :src="$distUrl + '/front-app/dist/'+ arrow">
        </div>

        <image-responsive
          v-if="!brickContent.videos && brickContent.image && brickContent.image.image"
          :image="brickContent.image.image.image_style_uri['60_31_desktop']"
          :imageMobile="brickContent.image.image.image_style_uri['3_4']"
        />

        <div v-if="brickContent.videos">
            <Video :brickContent="brickContent.videos"></Video>
        </div>

        <div class="h_insert__container">
          <div v-if="brickContent.insert_text" class="h_insert" :class="brickContent.insert_position ? 'h_insert_left' : ''" :style="'background-color: ' + brickContent.insert_background_color.color + '; color: ' + (brickContent.insert_text_color ? 'black' : 'white')">
            <div v-html="brickContent.insert_text.value"></div>
          </div>
        </div>
    </section>
  </template>

  <script>
  import Video from "@/components/Video";
  import ImageResponsive from '@/components/ImageResponsive.vue';

  export default {
    name: "MainHeader",
    components: {
      ImageResponsive,
        Video,
    },
    props: {
      brickContent: { type: Object, default: null }
    },
    data() {
      return {
        arrow_light: require('../icons/header_arrow_light.svg'),
        arrow_dark: require('../icons/header_arrow.svg'),
        arrow: null,
        hasMedia: null,
      };
    },
    mounted() {
        this.hasMedia = this.brickContent.videos || (this.brickContent.image && this.brickContent.image.image);
        this.arrow = this.brickContent.text_color_black_or_white ? this.arrow_dark : this.arrow_light;
    }
  };
  </script>
