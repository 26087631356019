<template>
    <div>
    <!-- Brick: Sticky breadcrumb -->
    <sticky-breadcrumb-menu v-if="brickContent.breadcrumb" :key="brickContent.breadcrumb.id" :brickContent="brickContent.breadcrumb" />
        <section
            :id="brickContent.anchor_identifier"
            class="g_section g_light">
            <div :id="'brick-' + brickContent.id">
                <div class="g_article" :class="brickContent.breadcrumb ? 'g_article--w-breadcrumb' : ''">
                  <div class="g_article_title">
                    <h1 v-html="brickContent.title"></h1>
                  </div>
                    <div class="g_article_header">
                        <div>
                            <author
                              v-if="this.brickContent.author"
                              :picture="this.brickContent.author.field_author_picture"
                              :name="this.brickContent.author.name"
                              :position="this.brickContent.author.field_author_position"
                              :description="this.brickContent.author.description ? this.brickContent.author.description.processed: null"
                            />
                            <div v-if="brickContent.is_publication_date_display" class="g_article_date">{{ formatDate()  }}</div>
                            <div class="g_article_topics">
                                <template v-for="topic in brickContent.topics">
                                    <a v-if="topic.list_of_articles && topic.list_of_articles.url" :key="topic.index" :href="topic.list_of_articles.uri">{{ topic.name }}</a>
                                    <span v-if="!topic.list_of_articles || !topic.list_of_articles.url" :key="topic.index">{{ topic.name }}</span>
                                </template>
                            </div>
                        </div>
                        <div class="g_article_buttons g_article_hiddenPDF" v-if="shareConfiguration">
                            <div v-for="(network, name) in shareConfiguration" v-bind:key="name">
                              <button @click="shareOn(name)" class="g_article_shareBtn" v-if="network.is_activate">
                                <img  :src="network.icons"/>
                                <span class="g_visually_hidden">{{ $t('articles.share') }} {{ name }}</span>
                              </button>
                            </div>
                        </div>
                    </div>
                    <div class="g_article_hiddenPDF g_article_container">
                      <div class="g_article_mainImage">
                        <image-responsive
                          v-if="brickContent.image_header && (brickContent.field_original_ratio && brickContent.field_original_ratio !== undefined)"
                          :image="brickContent.image_header.image.image_style_uri['scale_only']"
                          :imageMobile="imageMobile ? imageMobile.image_style_uri['scale_only'] : imageMobile.image_style_uri['scale_only']"
                        />
                        <image-responsive
                          v-if="brickContent.image_header && (!brickContent.field_original_ratio)"
                          :image="brickContent.image_header.image.image_style_uri['60_31_desktop']"
                          :imageMobile="imageMobile ? imageMobile.image_style_uri['60_31'] : brickContent.image_header.image.image_style_uri['60_31']"
                        />
                      </div>
                    </div>
                    <img class="g_article_mainImagePrint" v-if="brickContent.image_header" :src="brickContent.image_header.image.image_style_uri['scale_only']" alt="">
                    <div class="g_article_content">
                        <div v-for="element in brickContent.body" :key="element.id">
                          <div class="g_article_container">
                            <img class="g_article_imagePrint" v-if="element.type === 'brick--image' && element.image" :src="element.image.image_style_uri['scale_only']">
                            <div class="g_article_hiddenPDF" v-if="element.type === 'brick--image' && element.image">
                              <img class="g_article_image" :src="element.image.image_style_uri['scale_only']"/>
                            </div>
                            <div class="g_article_video g_article_hiddenPDF" v-if="element.type === 'brick--video'">
                              <Video :brickContent="element"></Video>
                            </div>
                            <div class="g_richText g_wysiwyg" v-if="element.type === 'brick--text' && element.text" v-html="element.text.processed"></div>                          </div>

                          <div v-if="element.type === 'brick--article_teaser'">
                            <ArticlesTeaser :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--closest_poi'">
                            <store-near-you-brick :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--contact_form'">
                            <contact-form :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--cta'">
                            <cta :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--faq'">
                            <faq :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--free_html'" class="g_article_container">
                            <div :id="element.anchor_identifier" :key="element.id">
                              <div v-html="element.html_content.value" :id="element.id"></div>
                            </div>
                          </div>

                          <div v-if="element.type === 'brick--horizontal_slider_of_articles'">
                            <ArticlesSlider :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--image_gallery'">
                            <image-gallery :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--image_insert'">
                            <ImageInsert :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--image_full_width'">
                            <image-full-width :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--article_list'">
                            <ArticlesList :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--machine_key_features'">
                            <list-of-elements :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--map'">
                            <map-locator :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--meet_our_coffee_experts'">
                            <meet-our-coffee-experts :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--popin'">
                            <Popin :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--quote'">
                            <quote :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--recipes'">
                            <recipes :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--related_content'">
                            <related-content :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--tabulation'">
                            <tabulation :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--text_blocks'">
                            <text-blocks :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--text_insert'">
                            <TextInsert :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--text_media_slider'">
                            <text-media-slider :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--store_locator'">
                            <store-locator-page :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--title'">
                            <simple-title :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--coffee'">
                            <coffee-range :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--products_with_add_to_cart'">
                            <products-add-to-cart :brickContent="element" />
                          </div>

                          <div v-if="element.type === 'brick--basic_text_image_content'">
                            <basic-text-image-content :brickContent="element" />
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Video from '@/components/Video';
import  jsPDF  from 'jspdf';
import { $ } from '@/jquery';
import StickyBreadcrumbMenu from '@/components/StickyBreadcrumbMenu';
import Author from '@/components/Author.vue';
import { DrupalApi } from '@/services/drupal_api';
import Cta from '@/components/Cta.vue';
import MeetOurCoffeeExperts from "@/components/MeetOurExperts.vue";
import Faq from "@/components/Faq.vue";
import ImageFullWidth from "@/components/ImageFullWidth.vue";
import ListOfElements from "@/components/ListOfElements.vue";
import ProductsAddToCart from "@/components/products-add-to-cart/ProductsAddToCart.vue";
import SimpleTitle from "@/components/SimpleTitle.vue";
import TextBlocks from "@/components/TextBlocks.vue";
import Quote from "@/components/Quote.vue";
import CoffeeRange from "@/components/CoffeeRange.vue";
import StoreLocatorPage from "@/pages/store-locator/StoreLocatorPage.vue";
import TextInsert from "@/components/image-text-insert/TextInsert.vue";
import ImageInsert from "@/components/image-text-insert/ImageInsert.vue";
import ImageResponsive from '@/components/ImageResponsive.vue';
import BasicTextImageContent from '@/components/BasicTextImageContent.vue';
import moment from 'moment';
import ArticlesTeaser from "@/components/articles/ArticlesTeaser.vue";
import StoreNearYouBrick from "@/components/store-locator/brick/StoreNearYouBrick.vue";
import ContactForm from "@/components/contact-form/ContactForm.vue";
import ArticlesSlider from "@/components/articles/ArticlesSlider.vue";
import ImageGallery from "@/components/ImageGallery.vue";
import ArticlesList from "@/components/articles/ArticlesList.vue";
import Popin from "@/components/Popin.vue";
import Recipes from "@/components/recipes/Recipes.vue";
import RelatedContent from "@/components/RelatedContent.vue";
import Tabulation from "@/components/Tabulation.vue";
import TextMediaSlider from "@/components/TextMediaSlider.vue";
import MapLocator from "@/components/map/MapLocator.vue";

export default {
    name: 'ArticlePage',
    props: {
        brickContent: { type: Object, default: null }
    },
    components: {
      MapLocator,
      TextMediaSlider,
      Tabulation,
      RelatedContent,
      Recipes,
      Popin,
      ArticlesList,
      ImageGallery,
      ArticlesSlider,
      ContactForm,
      StoreNearYouBrick,
      ArticlesTeaser,
      BasicTextImageContent,
      ImageResponsive,
      Video,
      StickyBreadcrumbMenu,
      Author,
      MeetOurCoffeeExperts,
      Faq,
      ImageFullWidth,
      ListOfElements,
      ProductsAddToCart,
      SimpleTitle,
      TextBlocks,
      Quote,
      Cta,
      CoffeeRange,
      StoreLocatorPage,
      TextInsert,
      ImageInsert
    },
    data() {
        return {
            shareIconTwitter: require('../../icons/twitter.svg'),
            shareIconFacebook: require('../../icons/facebook.svg'),
            shareIconLinkedin: require('../../icons/linkedin.svg'),
            downloadIcon: require('../../icons/download.svg'),
            datePublished: null,
            shareConfiguration: false,
            imageMobile: false,
            formattedData: null
        }
    },
    mounted() {
        this.fetchConfiguration();
        this.datePublished = this.brickContent.field_article_date;
        if(this.brickContent.field_css){
          $("head").append("<style>"+  this.brickContent.field_css +"</style>");
        }
        if(this.brickContent.image_header.field_override_mobile){
          this.imageMobile = this.brickContent.image_header.mobile_image;
        }else{
          this.imageMobile = this.brickContent.image_header.image;
        }
    },
    jsonld() {
        return {
          '@context': 'http://schema.org',
          '@type' : 'Article',
          'articleSection': this.brickContent.length ? this.brickContent.topics[0].name : '',
          'dateCreated': this.brickContent.field_article_date,
          'image': this.brickContent.image_header ? this.brickContent.image_header.image.uri.full_url : '',
          'author': {
            '@type': 'Person',
            'givenName': this.brickContent.author.name,
          },
        }
    },
    methods: {
        formatDate(){
          const langcode = this.brickContent.langcode;
          let format = false;
          switch (langcode){
            case 'en':
              format = "MMMM DD, YYYY";
              break;
            case 'de':
              format = "DD. MMMM YYYY";
              break;
            default:
              format = "DD MMMM, YYYY";
              break;
          }
          return moment(this.brickContent.field_article_date).format(format);
        },
        async fetchConfiguration(){
          const drupalApi = new DrupalApi();
          const response = await drupalApi.getApi('api/share/config')
          if(response.status === 200){
            this.shareConfiguration = response.data;
          }
        },
        getIcon(network){
          switch(network){
            case 'facebook':
              return this.shareIconFacebook;
            case 'x':
              return this.shareIconTwitter;
            case 'linkedin':
              return this.shareIconLinkedin;
            case 'download':
              return this.downloadIcon;
          }
        },
        shareOn(network){
          switch (network){
            case 'facebook':
              window.open('https://www.facebook.com/sharer.php?u=' + window.location.href);
              break;
            case 'x':
              window.open('http://www.twitter.com/share?url=' + window.location.href + '&text=' + this.brickContent.title);
              break;
            case 'linkedin':
              window.open('https://www.linkedin.com/shareArticle?mini=true&url=' + window.location.href);
              break;
            case 'download':
              this.print();
              break;
          }
        },
        print() {
            const source = $('#brick-' + this.brickContent.id)[0];
            const pdf = new jsPDF('p', 'pt', 'a4');
            const width = pdf.internal.pageSize.getWidth();

            $('#print-button-' + this.brickContent.id).addClass('button-loading');

            const specialElementHandlers = {
                '.g_article_hiddenPDF': function () {
                    return true;
                },
            };

            const title = this.brickContent.title.replace(/[^A-Z0-9]+/ig, "_");

            const margins = {
                top: 20,
                bottom: 30,
                left: 20,
                width: width - 60
            };

            const that = this;

            pdf.fromHTML(
            source,
            margins.left,
            margins.top, {
                'width': margins.width,
                'elementHandlers': specialElementHandlers
            },
            function () {
                $('#print-button-' + that.brickContent.id).removeClass('button-loading');
                pdf.save(title + '.pdf');
            }, margins);
        }
    }
}
</script>
