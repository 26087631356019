<template>
  <div class="cp-coffee-range-product" :class="brickContent.field_background_is_light ? 'g_light' : ''">
    <div class="coffee-range-item" @click="openModal(category, $event)">
      <div class="capsules-wrap">
        <template v-for="(position, index) in ['capsules','capsules reflect']">
          <div :class="position" v-bind:key="index">
            <div class="capsule" v-for="(item, index) in category.products" v-bind:key="index">
              <div v-if="item.field_capsule_image.image" class="capsule__img" :style="'background-image: url(' + item.field_capsule_image.image.uri.full_url + ');'">
              </div>
            </div>
          </div>
        </template>
      </div>
      <header>
        <h6 class="gamme-title">{{category.title}}</h6>
        <span class="subtitle">{{category.subtitle}}</span>
      </header>
      <ul class="product-info">
        <li v-for="(size, index) in category.sizes" :key="index">
          <span class="icon">
            <img v-if="brickContent.field_background_is_light" :src="size.field_black_pictogram.image.uri.full_url"/>
            <img v-if="!brickContent.field_background_is_light" :src="size.field_white_pictogram.image.uri.full_url"/>
          </span>
          <span class="label">{{ size.field_value }}</span>
        </li>
      </ul>
      <button>
        <span></span>
      </button>
    </div>

    <offer-lightbox
      :content="selectedContent"
      :popinItems="selectedContent.products"
      :isCoffeeRange="true"
      v-if="isModalActive"
      @close="closeModal" />
  </div>
</template>
<script>
import OfferLightbox from './commercial-offer/OfferLightbox';

export default {
  name: 'CoffeeRangeProduct',
  props: ['brickContent', 'category'],
  components: {
    OfferLightbox
  },
  data() {
    return {
      isModalActive: false,
      selectedContent: null
    }
  },
  mounted() {
      this.getProductDetails();
  },
  methods: {
    getProductDetails() {
      this.category.products.forEach((prod) => {
          window.napi.catalog().getProduct(prod.sku).then((resp) => {
              this.addProductDetails(prod, resp);
          });
      });
    },
    addProductDetails(product, additionalProductInfo) {
        product.displaySku = additionalProductInfo.displaySku;
        product.name = additionalProductInfo.name;
        product.isCoffeeRange = true;
        product.title = additionalProductInfo.name;
        product.headline = additionalProductInfo.headline;
        this.addProductDescription(product, additionalProductInfo.description);
        product.price = additionalProductInfo.price;
        product.capsuleProperties = {
          intensity: additionalProductInfo.capsuleProperties.intensity
        };
        product.images = {
          icon: additionalProductInfo.images.icon
        };
    },
    addProductDescription(product, description) {
      if (description.indexOf('<div class="napi-description">') >= 0 || description.indexOf('<div class=\'napi-description\'>') >= 0) {
        product.hasSpecificNapiDescription = true;
      } else {
        product.hasSpecificNapiDescription = false;
      }

      product.description = description;
    },
    closeModal() {
        this.isModalActive = false;
        this.currentModalButton.focus();
    },
    openModal(content, event) {
        this.currentModalButton = event.target;
        this.selectedContent = content;
        this.isModalActive = true;
    }
  }
};
</script>

<style scoped lang="scss">
.gamme-title {
  letter-spacing: 3px;
}
.cp-coffee-range-product {
  background-color: #1a1a1a;
  box-sizing: border-box;
  font-size: 14px;

  .coffee-range-item {
    cursor: pointer;
    padding: 4em 2em 2.5em;
  }
  .product-info {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;

      + li {
        margin-left: 20px;
      }

      .icon {
        width: 28px;
        height: 28px;
        margin: auto;
        margin-bottom: 4px;

        img {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }
      }

      .icon,
      .label {
        display: block;
        text-align: center;
      }
    }
  }

  // HEADER
  header {
    position: relative;
    padding: 4em 0 2em;

    h6 {
      font-size: 18px;
      text-transform: uppercase;
      font-weight: 800;
      margin: 0;
    }

    .subtitle {
      font-size: 1.28571em;
      line-height: 1.44444;
      letter-spacing: 0.05556em;
      font-weight: 300;
      text-transform: uppercase;
    }
  }

  &:hover .capsules-wrap .capsules .capsule {
    width: 3.57143em;

    &.mug {
      width: 5.1%;
    }
  }

  // CAPSULES INLINE
  .capsules-wrap {
    position: relative;
    height: 100px;
    .capsules {
      display: flex;
      justify-content: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      .capsule {
        width: 2.85714em;
        height: 7.14286em;
        position: relative;
        transition: all 0.25s;

        &:nth-of-type(n + 7) {
          display: none;
        }

        &.mug {
          width: 4.5%;
        }

        &__img {
          width: 11.42857em;
          height: 7.14286em;
          background-size: contain;
          background-position: 50%;
          background-repeat: no-repeat;
          background-position: bottom;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
        }
      }

      &.reflect {
        transform: scaleY(-1);
        filter: brightness(1.2);
        opacity: 0.2;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;

        &::after {
          content: '\00020';
          display: block;
          position: absolute;
          width: calc(100% + 4em);
          transform: translateX(-50%);
          left: 50%;
          top: 0;
          right: 0;
          bottom: 0;
          background: linear-gradient(0deg, rgba(26, 26, 26, 0) 5%, #1a1a1a 40%);
          @media screen and (max-width: 899px) {
            width: 100vw;
          }

        }
      }
    }
  }

  &.g_light {
    color: #000;
    background-color: #fff;

    .capsules-wrap .capsules {
      &.reflect {
        &::after {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0) 5%, #fff 40%);
        }
      }
    }
  }

  button {
    width: 32px;
    height: 32px;
    position: relative;
    border-radius: 100px;
    background-color: #8f7247;
    border: none;
    display: flex;
    margin: 25px auto 0;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;

    span {
      width: 13px;
      height: 13px;
      position: relative;
      display: flex;
      align-items: center;

      &::before,
      &::after {
        content: '\0020';
        display: block;
        height: 1px;
        background-color: #fff;
        width: 13px;
        position: absolute;
      }

      &::after {
        transform: rotateZ(90deg);
      }
    }
  }
}
</style>
