<template>
  <a class="google-maps-redirect" :href="gmapLink" target="_blank">
    <img :src="$distUrl+'/front-app/dist/'+redirectIcon" alt="">
    <span class="google-maps-redirect__title">{{this.$i18nSL.directions}}</span>
  </a>
</template>

<script>
export default {
  name: 'GoogleMapRedirect',
  props: {
    dataBoutique: {type: Object, required: true}
  },
  data() {
    return {
      redirectIcon: require('../../../icons/redirect.svg')
    }
  },
  computed:{
    gmapLink() { return `https://www.google.com/maps/dir/?api=1&destination=${this.dataBoutique.position.lat},${this.dataBoutique.position.lng}` }
  }
}
</script>

<style lang="scss">
  .g {
    .google-maps-redirect {
      display: flex;
      align-items: center;
      padding: 10px;

      background: #000;
      border-radius: 4px;
      cursor: pointer;

      &__title {
        font-weight: 800;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-left: 11px;
      }
    }
  }
</style>
