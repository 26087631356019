<template>
  <section class="g_section g_mapContainer" :id="brickContent.anchor_identifier">
    <div class="map-infos">
      <div class="map-content">
        <h2 class="g_h2" v-if="brickContent.title && !selectedLocation" v-html="brickContent.title" />

        <!-- List of POIs on the map with a link to their details -->
        <div class="map-addresses" v-if="!selectedLocation">
          <button v-for="poi in brickContent.pois" :key="poi.id" :id="poi.id" class="map-address"
            @click="onMarkerClick(poi)">
            <div class="address-marker">
              <img
                v-lazy="poi.isSelected ? poi.marker.pictogram_hover.image.uri.full_url : poi.marker.pictogram.image.uri.full_url"
                alt="">
            </div>
            <div class="address-infos">
              <h3 class="address-title" v-html="poi.title"></h3>
              <div class="address-details">{{ poi.address.address_line1 }} {{ poi.address.address_line2 }},
                {{ poi.address.postal_code }} {{ poi.address.locality }}
              </div>
            </div>
          </button>
        </div>
        <location-detail v-if="selectedLocation" @close="unselectLocation()" :mapHeight="mapHeight"
          :location="selectedLocation" />
      </div>
    </div>

    <!-- Google map -->
    <GmapMap ref="map" :center="center" :zoom="zoom" :options="options" aria-hidden="true">
      <GmapMarker :key="index" v-for="(poi, index) in brickContent.pois"
        :position="{ lat: poi.geolocation.lat, lng: poi.geolocation.lng }"
        :icon="poi.isSelected ? poi.marker.pictogram_hover.image.uri.full_url : poi.marker.pictogram.image.uri.full_url"
        :clickable="true" :draggable="false" @click="onMarkerClick(poi)" />
    </GmapMap>
  </section>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
import LocationDetail from '@/components/map/LocationDetail';

export default {
  name: 'MapLocator',
  props: {
    brickContent: { type: Object, default: null }
  },
  components: {
    LocationDetail
  },
  data() {
    return {
      mapHeight: 500,
      center: { lat: 46.800663464, lng: 8.222665776 },
      zoom: 7,
      options: {
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
        // Google maps style taken from their map
        styles: [
          {
            "featureType": "poi",
            "elementType": "labels",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "elementType": "geometry",
            "stylers": [{
              "color": "#f5f5f5"
            }]
          }, {
            "elementType": "labels.icon",
            "stylers": [{
              "visibility": "off"
            }]
          }, {
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#616161"
            }]
          }, {
            "elementType": "labels.text.stroke",
            "stylers": [{
              "color": "#f5f5f5"
            }]
          }, {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#bdbdbd"
            }]
          }, {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [{
              "color": "#eeeeee"
            }]
          }, {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#757575"
            }]
          }, {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [{
              "color": "#e5e5e5"
            }]
          }, {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          }, {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [{
              "color": "#ffffff"
            }]
          }, {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#ebe6e4"
            }]
          }, {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#757575"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [{
              "color": "#dadada"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#e1e5e5"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [{
              "color": "#eadac7"
            }]
          }, {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#616161"
            }]
          }, {
            "featureType": "road.local",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#eae5e3"
            }]
          }, {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          }, {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [{
              "color": "#e5e5e5"
            }]
          }, {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [{
              "color": "#eeeeee"
            }]
          }, {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [{
              "color": "#c9c9c9"
            }]
          }, {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [{
              "color": "#dbd8d8"
            }]
          }, {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [{
              "color": "#9e9e9e"
            }]
          }, {
            "featureType": "administrative.locality",
            "elementType": "geometry",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          }, {
            "featureType": "administrative.locality",
            "elementType": "geometry.fill",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          }, {
            "featureType": "administrative.locality",
            "elementType": "geometry.stroke",
            "stylers": [
              {
                "visibility": "simplified"
              }
            ]
          }, {
            "featureType": "administrative.locality",
            "elementType": "labels",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          }, {
            "featureType": "administrative.locality",
            "elementType": "labels.text",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          }, {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "visibility": "on"
              }
            ]
          }
        ],
        mapTypeControlOptions: {
          mapTypeIds: ['roadmap', 'terrain']
        }
      },
      selectedLocation: null
    }
  },
  computed: {
    google: gmapApi
  },
  created() {
    const url = this.$distUrl + '/front-app/public/img/';
    if (this.brickContent && this.brickContent.pois) {
      this.brickContent.pois.forEach(poi => {
        // The next 7 lines fixes the issue with the backend not sending the images, could be deleted
        if (poi.image.length) {
          poi.image.forEach(img => {
            if (!img.image) {
              img.image = { uri: { full_url: url + 'placeholder.png' }};
            }
          });
        }

        if (!poi.marker || !poi.marker.image) {
          poi.marker = {
            pictogram: { image: { uri: { full_url: url + 'marker-empty.png' } } },
            pictogram_hover: { image: { uri: { full_url: url + 'marker-empty_gold.png' } } }
          }
        }
      });
    }
  },
  mounted() {
    this.centerMap();
  },
  methods: {
    onMarkerClick(marker) {
      // Set the center of the map to the selected marker's position
      this.center = {
        lat: marker.geolocation.lat,
        lng: marker.geolocation.lng
      };
      this.zoom = 12;
      this.makeAllMarkersBlack();

      // Put the selected location marker in gold;
      this.selectedLocation = marker;
      marker.isSelected = true;
    },
    unselectLocation() {
      const tmp = this.selectedLocation.id;
      this.centerMap();
      this.makeAllMarkersBlack();
      this.selectedLocation = null;

      setTimeout(() => {
        document.getElementById(tmp).focus();
      }, 300);

    },
    makeAllMarkersBlack() {
      // Put back the default color of all the markers on the map
      for (var i = 0; i < this.brickContent.pois.length; i++) {
        this.brickContent.pois[i].isSelected = false;
      }
    },
    centerMap() {
      // Zoom in on the map in order to have all the markers visible
      if (this.brickContent.pois.length > 1) {
        this.zoom = 7;
        this.$gmapApiPromiseLazy().then(() => {
          var bounds = new window.google.maps.LatLngBounds();
          for (var i = 0; i < this.brickContent.pois.length; i++) {
            var marker = new window.google.maps.Marker({
              position: {
                lat: this.brickContent.pois[i].geolocation.lat,
                lng: this.brickContent.pois[i].geolocation.lng
              }
            });
            bounds.extend(marker.getPosition());
          }

          this.$refs.map.fitBounds(bounds);
        });
      } else {
        // If there's just one POI, center map on it (fixes issue with marker being too zoomed in)
        this.center = {
          lat: this.brickContent.pois[0].geolocation.lat,
          lng: this.brickContent.pois[0].geolocation.lng
        };
        this.zoom = 10;
      }
    }
  }
}
</script>
