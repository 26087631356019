<template>
  <div class="boutique">
    <div class="boutique__meta">
      <EventRatings class="boutique__meta__star" v-if="boutique.rating" :er-data="boutique.rating" />
      <span class="boutique__meta__status" v-if="boutique.primaryStoreType" v-text="boutique.primaryStoreType.title"/>
    </div>
    <h3 class="boutique__title" v-html="boutique.title"/>
    <div class="boutique__info">
      <div class="boutique__location">
        <div class="boutique__address">
          <p class="boutique__street" v-text="boutique.address.address_line1"/>
          <p class="boutique__city" v-text="boutique.address.locality"/>
          <p class="boutique__phone" v-if="boutique.phone">
           {{ this.$i18nSL.tel }}: <a :href="`tel:${boutique.phone}`">{{boutique.phone}}</a>
          </p>
        </div>
        <div class="boutique__distance" v-if="userPosition">
        {{distance}}
        </div>
      </div>
      <ul class="boutique__services">
        <template v-for="(service, index) in boutique.services">
          <li v-if="index <= 3" :key="index">
            <svg width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.36055 3.9498L0 5.33611L3.5661 9L11 1.38872L9.6418 0L3.56846 6.21826L1.36055 3.9498Z" fill="black"/>
            </svg>
            <span v-html="service.title"/>
          </li>
        </template>
      </ul>
    </div>
    <div class="boutique__cta">
      <GoogleMapRedirect :data-boutique="boutique" />
      <div v-if="isTeaser">
        <LinkRouter :to="route">
          {{$i18nSL.aboutTheStore}}
        </LinkRouter>
      </div>
      <LinkRouter v-else :to="route">
        {{$i18nSL.aboutTheStore}}
      </LinkRouter>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import EventRatings from '@/components/masterclass/EventRatings';
import LinkRouter from './LinkRouter.vue';
import GoogleMapRedirect from './GoogleMapRedirect.vue';
import { mapState } from 'vuex';
import {getBoutiqueDistanceDisplay} from "@/services/utils";
export default {
  name: 'Boutique',
  components: {
    EventRatings,
    LinkRouter,
    GoogleMapRedirect
  },
  props: {
    dataBoutique: { type: Object, default: null },
    isTeaser: { type: Boolean, default: false }
  },
  data() {
    return { boutique: this.dataBoutique }
  },
  computed: {
    ...mapState('store_locator', ['userPosition']),

    distance() {
      return getBoutiqueDistanceDisplay(this.boutique, this.userPosition);
    },
    route() { return { name: 'boutiqueDetail', params: { slug: this.boutique.slug } } }
  },
  watch: {
    dataBoutique() { this.boutique = this.dataBoutique }
  },
  methods: {
    ...mapMutations("store_locator", ["toggleResultList"]),
  }
}
</script>

<style lang="scss">
.g {
  .boutique {
    padding: 16px 0;

    &__meta {
      line-height: 21px;
      padding-bottom: 8px;

      &__status {
        color: #666666;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__star { float: right; color: #000;}
    }

    &__title {
      padding-bottom: 4px;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000;
    }

    &__location{
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
    }

    &__address { flex-grow: 1; }

    &__distance{
      background-color: #f2f2f2;
      color: #666666;
      border-radius: 2px;
      padding: 5px;
      display: inline-block;
      flex-shrink: 0;
    }

    &__info {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 1px;
      color: #666666;
    }

    &__services {
      li {
        display: inline-flex;
        align-items: center;
        padding-right: 10px;

        svg { width: 24px; }

        span { font-size: 14px; line-height: 21px; }
      }
    }
    &__phone{
      a{ color: inherit }
    }
    &__cta {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      padding: 16px 0 0 0;
      justify-content: space-between;
    }
  }
}
</style>
