<template>
  <div class="er" :class="readableData ? 'er--xl' : ''">
    <!-- er for event ratings -->
    <div class="er__stars">
      <ol class="er__stars--empty">
        <li v-for="index in 5" :key="index">
          <event-star :num-data="index" :readable-data="readableData" />
        </li>
      </ol>

      <div :style="'width: ' + widthClick + '%'" class="er__overlay">
        <ol class="er__stars--full">
          <li v-for="index in 5" :key="index">
            <event-star es-data="true" :num-data="index" :readable-data="readableData" />
          </li>
        </ol>
      </div>

      <div :style="'width: ' + widthHover + '%'" class="er__overlay" v-if="readableData">
        <ol class="er__stars--hover">
          <li v-for="index in 5" :key="index">
            <event-star es-data="true" :num-data="index" :readable-data="readableData" />
          </li>
        </ol>
      </div>
    </div>
    <span class="er__numeral">{{ decimalNumber }}</span>
  </div>
</template>

<script>
import EventStar from "@/components/masterclass/EventStar"
export default {
  name: "EventRatings",
  components: {
    EventStar
  },
  props: {
    erData: { type: Number, default: null },
    readableData: { type: Boolean, default: false }
  },
  data() {
    return {
      valueHover: 0,
      valueClick: 0
    }
  },
  computed: {
    widthClick: function () {
      return this.readableData ? Number.parseFloat(this.valueClick) * 20 : this.percentageNumber
    },
    widthHover: function () {
      return this.readableData ? Number.parseFloat(this.valueHover) * 20 : this.percentageNumber
    },
    decimalNumber: function () {
      return Number.parseFloat(this.erData).toFixed(1)
    },
    percentageNumber: function () {
      return (Number.parseFloat(this.erData) * 100) / 5
    }
  },
  created() {
    if (this.readableData) {
      this.$on("star", (value) => {
        this.changeStar(value, "click")
      })
      this.$on("starHover", (value) => {
        this.changeStar(value, "hover")
      })
    }
  },
  methods: {
    changeStar: function (value, event) {
      if (event == "hover") {
        this.valueHover = value
      } else if (event == "click") {
        this.valueClick = value
        this.$emit('change-rate', value)
      }
    },
  },
};
</script>

<style lang="scss">
.g {
  .er {
    display: flex;
    align-items: center;

    &__stars {
      position: relative;
      cursor: pointer;

      &--empty,
      &--full,
      &--hover {
        list-style: none;
        margin: 0;
        padding: 0;
        display: inline-flex;
        align-items: center;

        li {
          & + li {
            margin-left: 2px;
          }
        }
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      transition: all 0.2s;
    }

    &__numeral {
      margin-left: 5px;
    }

    &--xl {
      justify-content: center;

      .er__stars--full {
        svg { color: #8f7247; }
      }

      .er__stars--hover {
        svg { color: darken(rgba($color: #8f7247, $alpha: 0.95), 5%); }
      }

      .er__numeral { display: none; }
    }
  }
}
</style>
