<template>
    <section class="g_section g_light g_sust-kiosk">
        <template v-if="!hasVotedFor">
            <div v-for="(project, index) in brickContent.components" :key="'bg-img-' + index"
                :style="getBackgroundStyle(project)" class="g_sust-kiosk-background">
            </div>
        </template>

        <div v-if="!hasVotedFor" class="g_sust-kiosk-content">
            <transition name="fade">
                <div class="g_sust-kiosk-intro" v-if="!showProjects">
                    <img class="g_sust-kiosk-intro-logo" v-if="brickContent.image && brickContent.image.image" :src="brickContent.image.image.uri.full_url" alt="">
                    <h1 class="g_h1">{{ brickContent.title}}</h1>
                    <div class="g_richText g_wysiwyg g_txt_XL" v-if="brickContent.description" v-html="brickContent.description.value"></div>
                    <button class="g_sust-kiosk-main-cta" @click="goToProjects()">
                        <span class="g_sust-kiosk-main-cta-content">
                            <span>{{ brickContent.link }}</span>
                            <span>

                                <svg width="58" height="10" viewBox="0 0 58 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M48 6V10L58 5.26316L48 0V4H0V6H48Z" fill="white"/>
                                </svg>
                            </span>
                        </span>
                    </button>
                </div>
            </transition>

            <transition name="fade">
                <div class="g_sust-kiosk-projects" v-if="showProjects && !hasVotedFor">
                    <div v-for="(project, index) in brickContent.components"
                        :key="'project-' + index"
                        :class="'g_sust-kiosk-project g_sust-kiosk-project-' + project.project.name">
                        <div class="g_sust-kiosk-project-col">
                            <div>
                                <h2 class="g_h2" v-html="project.title"></h2>
                                <p class="g_sust-short-description g_txt_L" v-if="project.text" v-html="project.text.value"></p>
                            </div>

                            <div class="g_sust-kiosk-project-details">
                                <div>
                                    <img v-if="project.logo && project.logo.image" class="g_sust-project-logo" :src="project.logo.image.uri.full_url" alt="">
                                    <div v-if="project && project.legend" class="g_txt_M" v-html="project.legend.value"></div>
                                </div>
                                <div>
                                    <img class="g_sust-pictogram" v-if="project.picto && project.picto.image" :src="project.picto.image.uri.full_url" alt="">
                                </div>
                            </div>

                            <div class="g_sust-vote-action">
                                <button :id="'g_sust-kiosk-cta-' + index" class="g_sust-kiosk-cta g_txt_L" :disabled="isSendingVote" @click="submitVote(project, 'g_sust-kiosk-cta-' + index)">
                                    <span class="g_sust-kiosk-cta-icon">
                                        <img v-if="project.picto_cta && project.picto_cta.image" :src="project.picto_cta.image.uri.full_url" alt="">
                                    </span>
                                    <span>{{ project.label }}</span>
                                </button>
                            </div>
                            <div :id="'form-errors-' + project.project.name"  class="form-errors"></div>
                        </div>

                        <div class="g_sust-kiosk-project-col">
                            <button v-if="project.video" @click="openVideoModal(this, project.video)" aria-haspopup="true" class="g_playVideo g_sust-video-container" :style="'background-image: url(https://img.youtube.com/vi/' + project.video.video_embed.video_id + '/sddefault.jpg)'">
                                <i class="fn_videoCircle"></i>
                            </button>
                            <div class="g_sust-kiosk-vote">
                                <div class="g_sust-vote-amount" :id="'g_sust-vote-amount-' + project.project.name" :data-amount="project.vote_amount"></div>
                                <div v-if="project.legend_counter" class="g_sust-vote-legend g_txt_S" v-html="project.legend_counter.value"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>

        <transition name="fade">
            <div v-if="hasVotedFor" :class="'g_sust-kiosk-thanks g_sust-kiosk-thanks-' + hasVotedFor.project.name" :style="getBackgroundStyle(hasVotedFor)">
                <div class="g_sust-kiosk-thanks-content">
                    <img class="g_sust-kiosk-thanks-logo" v-if="brickContent.image && brickContent.image.image" :src="brickContent.image.image.uri.full_url" alt="">
                    <h3 class="g_sust-kiosk-thanks-message-title">{{ brickContent.thanks_title }}</h3>
                    <div class="g_richText g_wysiwyg g_txt_L" v-html="brickContent.thanks_description.value"></div>
                    <img class="g_sust-kiosk-thanks-project-logo" v-if="hasVotedFor.logo && hasVotedFor.logo.image" :src="hasVotedFor.logo.image.uri.full_url" alt="">
                </div>
            </div>
        </transition>

        <video-modal
            v-if="isModalActive"
            :extraClasses="'g_sust-kiosk-video-modal'"
            :video="selectedVideo"
            @close="closeVideoModal"/>
    </section>
</template>

<script>
import VideoModal from '@/components/VideoModal';
import { $ } from "@/jquery";
import { SustainabilityLoader } from "@/services/sustainability.js";

export default {
    name: 'SustainabilityVote',
    components: {
        VideoModal
    },
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            isModalActive: false,
            selectedVideo: null,
            showProjects: false,
            viewed: false,
            userZip: null,
            userId: null,
            hasVotedFor: null,
            isSendingVote: false,
            error: null,
            isFromEmail: true,
            restartTimeout: null,
            sustLoader: null,
            refreshVoteDelay: 1000 * 60 * 30,
        }
    },
    mounted() {
        this.init();
        this.sustLoader = new SustainabilityLoader();
        this.getVoteAmounts();
        this.userZip = this.$route.query.zipCode ? this.$route.query.zipCode : '';
        this.sustLoader.loadData('project_vote').then(response => {
            this.brickContent.components.forEach(project => {
                project.subject = response.data.find(item => item.attributes.name === project.project.name);
            });
        });
        $('body').addClass('sustainability-kiosk');

        let viewPortTag = document.createElement('meta');
        viewPortTag.id = 'viewport';
        viewPortTag.name = 'viewport';
        viewPortTag.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
        document.getElementsByTagName('head')[0].appendChild(viewPortTag);

        let viewPortTagAppleCapable = document.createElement('meta');
        viewPortTagAppleCapable.id = 'apple-mobile-web-app-capable';
        viewPortTagAppleCapable.name = 'apple-mobile-web-app-capable';
        viewPortTagAppleCapable.content = 'yes';
        document.getElementsByTagName('head')[0].appendChild(viewPortTagAppleCapable);
        
        let viewPortTagCapable = document.createElement('meta');
        viewPortTagCapable.id = 'mobile-web-app-capable';
        viewPortTagCapable.name = 'mobile-web-app-capable';
        viewPortTagCapable.content = 'yes';
        document.getElementsByTagName('head')[0].appendChild(viewPortTagCapable);
    },
    methods: {
        init() {
            $('.g_sust-vote-amount').each(function () {
                let val = $(this).attr('data-amount').toString();
                var display = '';
                for (var i = 0; i < val.length; i++) {
                    if (i == (val.length - 4) || i == (val.length - 7)) {
                        display += val.charAt(i) + '’';
                    } else {
                        display += val.charAt(i);
                    }
                }
                $(this).text(display);
            });
        },
        restart(milliseconds) {
            clearTimeout(this.restartTimeout);
            this.restartTimeout = setTimeout(() => {
                this.hasVotedFor = null;
                this.showProjects = false;
            }, milliseconds);
        },
        goToProjects() {
            $('.g_sust-kiosk-main-cta').addClass('is-clicked');
            setTimeout(() => this.showProjects = true, 500);
            this.hasVotedFor = null;
            setTimeout(() => this.startCounter(), 700);

            // Back to the first screen after 6 minutes
            this.restart(360000);
        },
        getVoteAmounts() {
            this.sustLoader.loadData('project_vote_amount').then((response) => {
                this.counter = this.counter + 1;
                this.brickContent.components.forEach((project) => {
                    const projectId = project.project.id;
                    const vote = response.data.find(v => v.relationships.project_vote.data.id === projectId);
                    const oldProjectVoteAmount = project.vote_amount ? project.vote_amount : 0;
                    if (project.project.name === 'Water') {
                        project.vote_amount = vote.attributes.number_vote * 14;
                    } else if (project.project.name === 'Tree') {
                        project.vote_amount = vote.attributes.number_vote / 20;
                    }

                    $("#g_sust-vote-amount-" + project.project.name).each(function() {
                        $(this).prop("Counter", oldProjectVoteAmount ? oldProjectVoteAmount : 0).animate(
                            {
                                Counter: project.vote_amount
                            },
                            {
                                duration: 1000,
                                easing: "swing",
                                step: function(now) {
                                    let val = Math.floor(now).toString();
                                    var display = "";
                                    for (var i = 0; i < val.length; i++) {
                                        if (i == val.length - 4 || i == val.length - 7) {
                                            display += val.charAt(i) + "’";
                                        } else {
                                            display += val.charAt(i);
                                        }
                                    }
                                $(this).text(display);
                            }
                        });
                    });
                });

                if (this.firstLoad) {
                    this.firstLoad = false;
                }

                setTimeout(() => this.getVoteAmounts(), this.refreshVoteDelay);
            }).catch(error => {
                console.error('Could not get vote amounts', error);
            });
        },
        submitVote(project, buttonId) {
            this.isSendingVote = true;
            $('#' + buttonId).addClass('is-clicked');
            const $formErrors = $("#form-errors-" + project.project.name);
            $('.form-errors').removeClass('show');
            $('.form-errors').text('');

            this.sustLoader.submitSustainabilityVote(this.userZip, null, project, 'kiosk').then((response) => {
                this.isSendingVote = false;
                $('.g_sust-kiosk-cta').removeClass('is-clicked');

                if (response.data && response.status === 201) {
                    this.hasVotedFor = project;
                    this.restart(15000);
                } else {
                    console.error('Error while sending vote', response.data);
                    $formErrors.text(this.$t("vote.voteError"));
                    $formErrors.addClass("show");
                }

                return response;
            }).catch(error => {
                this.isSendingVote = false;
                $formErrors.text(this.$t("vote.voteError"));
                $formErrors.addClass("show");
                $('.g_sust-kiosk-cta').removeClass('is-clicked');
                console.error('Error while sending vote', error);
                return error;
            });
        },
        getBackgroundStyle(project) {
            if (project.image.image && project.image.image && project.image.image.uri) {
                return 'background-image: url(' + project.image.image.uri.full_url + ');';
            } else {
                return '';
            }
        },
        startCounter() {
            this.viewed = true;
            $('.g_sust-vote-amount').each(function () {
                $(this).prop('Counter', 0).animate({
                    Counter: $(this).attr('data-amount')
                }, {
                    duration: 1500,
                    easing: 'swing',
                    step: function (now) {
                        let val = Math.floor(now).toString();
                        var display = '';
                        for (var i = 0; i < val.length; i++) {
                            if (i == (val.length - 4) || i == (val.length - 7)) {
                                display += val.charAt(i) + '’';
                            } else {
                                display += val.charAt(i);
                            }
                        }
                        $(this).text(display);
                    }
                });
            });
        },
        closeVideoModal() {
            this.isModalActive = false;
            this.$el.querySelector('.g_playVideo').focus();
        },
        openVideoModal(el, video) {
            this.isModalActive = true;
            this.selectedVideo = video.video_embed;
        }
    }
}
</script>
