<template>
  <div class="video-player" :class="'video-player--'+ sourceType.toLowerCase()">
    <LazyYoutube v-if="sourceType === 'youtube' && poster" class="video-player__external" ref="youtubeLazyVideo" :src="source"  :customThumbnail="poster" :showTitle="false" :aspectRatio="aspectRatio" :autoplay="autoplay" :muted="autoplay"/>
    <LazyYoutube v-if="sourceType === 'youtube' && poster === null" class="video-player__external" ref="youtubeLazyVideo" :src="source"  :showTitle="false" :aspectRatio="aspectRatio" :autoplay="autoplay" :muted="autoplay"/>
    <div class="video-player__overlay" v-if="sourceType === 'youtube' && this.displayPlay" @click="handleClick('playVideo')">
    </div>
    <video :src="source" v-if="sourceType !== 'youtube'" class="video-player__internal" ref="video" :poster="this.poster" :autoplay="this.autoplay" muted="muted"></video>
    <button type="button" class="play-btn" @click="handleClick('playVideo')" v-if="displayPlay">
      Play
      <svg fill="#ffffff" height="35px" width="35px" id="Play" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           viewBox="0 0 17.804 17.804" xml:space="preserve">
      <g>
        <g id="c98_play">
          <path d="M2.067,0.043C2.21-0.028,2.372-0.008,2.493,0.085l13.312,8.503c0.094,0.078,0.154,0.191,0.154,0.313
            c0,0.12-0.061,0.237-0.154,0.314L2.492,17.717c-0.07,0.057-0.162,0.087-0.25,0.087l-0.176-0.04
            c-0.136-0.065-0.222-0.207-0.222-0.361V0.402C1.844,0.25,1.93,0.107,2.067,0.043z"/>
        </g>
        <g id="Play_1_78_">
        </g>
      </g>
    </svg>
    </button>
  </div>
</template>

<script>
import { LazyYoutube } from "vue-lazytube";

export default {
  name: 'VideoPlayer',
  components: {
    LazyYoutube
  },
  props: {
    source: { type: String, default: null },
    sourceType: { type: String, default: null },
    poster: { type: String, default: null },
    isEmbeded: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: false },
    loop: { type: Boolean, default: false },
    aspectRatio: { type: String, default: '5:7' }
  },
  computed: {
    videoElement() {
      return this.$refs.video;
    },
  },
  data() {
    return {
      player: null,
      displayPlay: true,
      customThumbnail: null,
    };
  },
  methods: {
    handleClick(event) {
      if(this.sourceType === 'youtube'){
        this.$refs['youtubeLazyVideo'][event]();
        setTimeout(() => {
          this.displayPlay = false;
        }, 500);
      }else{
        this.videoElement.play();
        this.videoElement.setAttribute('controls', 'controls');
        this.displayPlay = false;
      }
    },
  },
  mounted() {
    if(this.autoplay){
      if(this.sourceType !== 'youtube'){
        this.videoElement.setAttribute('controls', 'controls');
      }
      setTimeout(() => {
        this.handleClick('playVideo');
      }, 500);

    }
    if(this.loop && this.sourceType !== 'youtube'){
      this.videoElement.setAttribute('loop', 'loop');
    }
  }
};
</script>

<style lang="scss">
.video-player{
  position: relative;
  .play-btn{
    position: absolute;
    top: calc(50% - 3.5rem);
    left: calc(50% - 2.5rem);
    background-color: #8f7247;
    width: 5rem;
    height: 5rem;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0;
  }
  &__internal{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .vlt-wrapper{
    --vlt-max-width: 100% !important;
  }
  &--youtube{
    .video-player__overlay{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      &:hover{
        cursor: pointer;
      }
    }
  }
}
</style>
