import {arrayHaveCommonElements, getBoutiqueDistance} from '@/services/utils';

const sortedBoutique = (userPosition, boutiques = []) => {
  if(userPosition !== null &&  typeof userPosition.lat !== "undefined" &&  boutiques.length){
    return [...boutiques].sort((a,b) => {
      return getBoutiqueDistance(a, userPosition) - getBoutiqueDistance(b, userPosition);
    })
  }
  return boutiques;
}
const boutiquesInTarget = (state) =>{
  return state.boutiques.filter(boutique=>{
    return (
      // LNG MATCH
      boutique.position.lng < state.targetZone.NE.lng &&
      boutique.position.lng >  state.targetZone.SW.lng &&
      // LAT MATCH
      boutique.position.lat < state.targetZone.NE.lat &&
      boutique.position.lat > state.targetZone.SW.lat
    )
  })
}
const activeFiltersValues = (filters) => {
  const activeFilters = {
    paymentMethods: [],
    services: [],
    storeTypes: [],
    distance: filters.distance
  }
  const names = ['paymentMethods', 'services', 'storeTypes']
  names.forEach((filterName)=>{
    filters[filterName].forEach(filter=>{
      if(filter.active){
        activeFilters[filterName].push(filter.value)
      }
    })
  })

  return activeFilters

}
const boutiqueMatchFilters = (filterName, activeFilters, boutique) => {
  if(!activeFilters[filterName].length){
    return true;
  }
  const boutiqueFilterIds = boutique[filterName].map(item=>item.id)
  return arrayHaveCommonElements(boutiqueFilterIds, activeFilters[filterName]);
}

const boutiqueMatchDistance = (state, boutique) => {
  if (!state.filters.distance || state.userPosition == null)
    return true
  return getBoutiqueDistance(boutique, state.userPosition) <= state.filters.distance
}
const boutiqueByFilters = (state, boutiques) => {
  const activeFilters = activeFiltersValues(state.filters)
  return boutiques.filter(boutique => {
    return(
      boutiqueMatchFilters('services', activeFilters, boutique) &&
      boutiqueMatchFilters('paymentMethods', activeFilters, boutique) &&
      boutiqueMatchFilters('storeTypes', activeFilters, boutique) &&
      boutiqueMatchDistance(state, boutique)
    )
  });
}

const geolocIsGranted = async () => {
  if(navigator.permissions && navigator.permissions.query){
    const permission = await navigator.permissions.query({ name: 'geolocation' })
    return permission.state === 'granted'
  }
  return false;
}

const geolocOptions = {
  timeout:10000,
  enableHighAccuracy: true,
  maximumAge: Infinity
}


const getCurrentPosition = async () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position)=>{
          const positionObj = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };
          resolve(positionObj)
        },
        (error)=>{
          // user code 1 is: User denied Geolocation
          if(error.code === 1){
            reject("USER_DENIED")
          }else{
            console.error("UNKNOW_ERROR", error);
            reject("UNKNOW_ERROR")
          }
        },
        geolocOptions
      );
    }else{
      reject("BROWSER_OUTDATED");
    }
  })
}


export default {
  boutiqueByFilters,
  boutiqueMatchFilters,
  activeFiltersValues,
  boutiquesInTarget,
  sortedBoutique,
  geolocIsGranted,
  getCurrentPosition
}
