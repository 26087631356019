const API_URL = 'jsonapi';
const BASIC_AUTH = 'Basic aHFyYW1lbGp1bDpOZXN0bGUyMDIyIQ==';
const SQLI_STAGING_URL = 'https://nespresso-acquia.suisse.sqli.com';
const NESPRESSO_STAGING_URL = 'https://stag.content-ch.nespresso.com';


// CURRENCY STRING
const CURRENCY_PREFIX = 'Fr.';

export { CURRENCY_PREFIX, BASIC_AUTH, NESPRESSO_STAGING_URL, SQLI_STAGING_URL, API_URL };
