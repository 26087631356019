<template>
  <div class="map-section">
    <div class="map-section__text">
      <h4>{{ title }}</h4>
      <address v-html="address"></address>
      <a v-if="tel" :href="'tel:' + tel">{{ tel }}</a>
    </div>

    <event-map :map-data="mapData" />
  </div>
</template>

<script>
import EventMap from '@/components/masterclass/EventMap'
export default {
  name: "MasterclassEventLocation",
  components: {
    EventMap
  },
  props: {
    lcData: { type: Object, default: null }
  },
  data () {
    return {
      ...this.lcData,
      mapData: {},
    }
  },
  created() {
    this.mapData = {
      lat: this.lcData.lat,
      lng: this.lcData.lng
    }
  }
}
</script>

<style lang="scss">
.g {
  .map-section {
    width: 100%;
    @media screen and (min-width: 996px) {
      width: 500px;
    }

    &__text {
      color: #8d8a85;
      font-size: 16px;
      letter-spacing: 2px;
      line-height: 24px;
      text-align: left;

      h4 {
        font-weight: 700;
        color: #8d8a85;
        font-size: 16px;
        letter-spacing: 0;
      }

      address {
        font-size: 14px;
        letter-spacing: 0;
        font-style: italic;
        line-height: 14px;
      }

      a {
        font-size: 15px;
        letter-spacing: 0;
        font-style: italic;
        color: #8d8a85;
      }
    }
  }
}
</style>
