<template>
    <div class="g_recipeHeader">
        <!-- If there is no video: show the recipe image -->
        <div v-if="!video || !isVideoValid" class="g_background"  v-lazy:background-image="image"></div>

        <!-- If there is a video: show the video -->
        <div v-if="video && isVideoValid" class="g_video">
            <iframe frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player" :src="embedUrl + '?rel=0&amp;fs=0&amp;autoplay=false&amp;origin=https%3A%2F%2Fwww.nespresso.com&amp;widgetid=1'"></iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RecipeModalHeader',
    props: {
        video: { type: String, default: null },
        image: { type: String, default: null }
    },
    data() {
        return {
            embedUrl: null,
            isVideoValid: false
        }
    },
    mounted() {
        if (this.video) {
            var id = this.getId(this.video);

            if (id == null) {
                this.isVideoValid = false;
            } else {
                this.isVideoValid = true;
                this.embedUrl = 'https://www.youtube.com/embed/' + id;
            }
        }
    },
    methods: {
        // Get the video's ID from the youtube URL
        getId(url) {
            var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            var match = url.match(regExp);

            if (match && match[2].length == 11) {
                return match[2];
            } else {
                return null;
            }
        }
    }
}
</script>

