<template>
    <section :id="brickContent.anchor_identifier" class="h_faq" :style="'background-color: ' + brickContent.backgroundcolor.color + '; color: ' + brickContent.text_color.color">
      <div class="h_faq__container">
        <div class="h_faq_infos">
          <!-- FAQ Title -->
          <h3 class="h_head_caption">{{ brickContent.caption }}</h3>
          <h2 class="h_head_title" :style="'color: ' + brickContent.title_color.color" v-if="brickContent.title" v-html="brickContent.title"></h2>
          <div class="h_head_text" v-html="brickContent.text_faq.value"></div>
        </div>
        <div class="h_faq_content">
          <div class="h_accordion" :id="brickContent.id">
            <ul class="h_isCollapsable">
              <!-- List of questiosn -->
              <li v-for="(q, index) in brickContent.questions_answers" :key="q.index" class="h_accordionItem">
                <!-- Question -->
                <button @click="toggleFAQItem(index)" tabindex="0" aria-expanded="false"
                        :aria-controls="'collapsible' + index" :style="'border-top-color: ' + calculatedBorderColor" class="h_question" :class="'h_question_' + index">
                  <img :src="$distUrl + '/front-app/dist/'+arrow">
                  <p v-html="q.question"></p>
                </button>

                <!-- Answer -->
                <div
                  v-if="q.answer"
                  :id="'collapsible' + index"
                  tabindex="-1"
                  aria-hidden="true"
                  class="h_collapsible h_answer"
                  :class="'h_collapsible_' + index"
                  v-html="q.answer.processed"
                ></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import { $ } from '@/jquery';

export default {
    name: 'Faq',
    props: {
        brickContent: { type: Object, default: null },
    },
    data() {
        return {
            arrow: null,
            arrow_dark: require('../icons/faq_arrow.svg'),
            arrow_light: require('../icons/faq_arrow_light.svg'),
            calculatedBorderColor: null
        }
    },
    mounted() {
        var c = this.brickContent.backgroundcolor.color.substring(1);
        var rgb = parseInt(c, 16);
        var r = (rgb >> 16) & 0xff;
        var g = (rgb >>  8) & 0xff;
        var b = (rgb >>  0) & 0xff;
        var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
        if (luma < 200) {
            this.arrow = this.arrow_light;
            this.calculatedBorderColor = `rgba(255, 255, 255, 0.4)`;
        } else {
            this.arrow = this.arrow_dark;
            this.calculatedBorderColor = `rgba(0, 0, 0, 0.1)`;
        }
    },
    methods: {
        // Open/Close FAQ question
        toggleFAQItem(index) {
            $(`#${this.brickContent.id} .h_question_${index}`).toggleClass('h_open');
            var isOpened = $('.h_question_' + index).hasClass('h_open');
            $(`#${this.brickContent.id} .h_question_${index}`).attr('aria-expanded', isOpened);
            $(`#${this.brickContent.id} .h_collapsible_${index}`).attr('aria-hidden', !isOpened);


            if (isOpened) {
                setTimeout(() => {
                    $(`#${this.brickContent.id} .h_collapsible_${index}`).focus();
                }, 300);
            }
        },
    },
};
</script>
