<template>
  <div class="el"> <!-- el for event list -->
    <div class="el__list">
      <div
        v-for="eventItem in elData"
        :key="eventItem.id"
        class="el__column"
      >
        <event-card
          :ec-data="eventItem"
          :ecateg-data="ecategData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventCard from '@/components/masterclass/EventCard'

export default {
  name: 'EventList',
  components: {
    EventCard
  },
  props: {
    elData: { type: Array, default: null },
    ecategData: { type: Array, default: null }
  },
  data() {
    return {
      ...this.elData,
      ...this.ecategData
    }
  }
}
</script>

<style lang="scss">
  .g {
    .el {
      .el__list {
        display: flex;
        flex-wrap: wrap;
        margin: 10px -10px;
        align-items: stretch;
      }

      .el__column {
        width: 100%;
        @media (min-width: 768px) { width: 33.333333%; }
        padding: 10px;

        > * { height: 100%; }
      }
    }
  }
</style>
