<template>
  <div class="eo">
    <!-- eo for event order -->
    <div class="eo__label__wrapper" @click="toggle ? toggle = !toggle : toggle = !toggle" v-click-outside="hideToggle">
      <span class="eo__label">{{ label }}</span>
      <svg
        width="10px"
        height="6px"
        viewBox="0 0 10 6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>_icons/minus copy</title>
        <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            id="Desktop---Design---Masterclass-"
            transform="translate(-1042.000000, -1289.000000)"
            fill="#000000"
          >
            <g id="_icons/minus-copy" transform="translate(1027.000000, 1272.000000)">
              <polygon
                id="color"
                transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                points="23.8213209 17.2776389 23.9627423 17.2776389 24.6698491 17.9847457 24.6698491 18.126167 20.073655 22.7223611 19.926345 22.7223611 15.3301509 18.126167 15.3301509 17.9847457 16.0372577 17.2776389 16.1786791 17.2776389 20 21.0989598"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>

    <ul class="eo__list" :class="toggle ? 'eo__list--visible' : ''">
      <li
        v-for="item in choices"
        :key="item.index"
        :class="current == item.slug ? 'active' : '' "
        @click="orderEventList(item.slug)"
      >{{ item.choice }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "EventOrder",
  props: {
    eoData: { type: Object, default: null },
  },
  data() {
    return {
      current: "latest",
      ...this.eoData,
      toggle: false,
    }
  },
  methods: {
    orderEventList: function (val) {
      this.current = val;
      this.$emit('triggerOrder', val);
    },
    hideToggle () {
      this.toggle = false
    }
  }
}
</script>

<style lang="scss">
.g {
  .eo {
    position: relative;
    margin-left: auto;
    &__label__wrapper {
      padding: 0.5em 1em;
      cursor: pointer;
      svg {
        transform: rotate(180deg);
      }
    }
    &__label {
      padding-right: 1em;
    }
    &__list {
      position: absolute;
      z-index: 1;
      left: 0;
      opacity: 0;
      background-color: #ffffff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      transform: scale(0.8, 0);
      transform-origin: top;
      transition: all 0.3s;
      &--visible {
        opacity: 1;
        transform: scale(1, 1);
      }
      li {
        padding: 0.5em 1.5em 0.5em 1em;
        cursor: pointer;
        transition: all 0.15s ease-in-out;
        &.active {
          background: rgba(0, 0, 0, 0.05);
        }
        &:hover {
          background: rgba(0, 0, 0, 0.15);
        }
      }
    }
  }
}
</style>
