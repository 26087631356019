<template>
  <div class="nn-slider " :id="'slider-'+this.id">
    <div class="swiper-wrapper nn-slider__wrapper" ref="swiper">
      <slider-slide v-for="(slide, index) in this.slides" v-bind:key="index" :img="slide.img" :imgMobile="slide.imgMobile" :title="slide.title" :desc="slide.desc" :subtitle="slide.boutique" />
    </div>
    <button
      class="nn-slider__pagination-btn nn-slider__pagination-btn--prev"
      @click="swiper.slidePrev()"
      aria-label="Previous slide"
    >
      <img :src="$distUrl + '/front-app/dist/'+ arrow" alt="">
    </button>
    <button
      class="nn-slider__pagination-btn nn-slider__pagination-btn--next"
      @click="swiper.slideNext()"
      aria-label="Next slide"
    >
      <img :src="$distUrl + '/front-app/dist/'+ arrow" alt="">
    </button>
  </div>
</template>

<script>
  import Swiper from 'swiper/bundle';
  import SliderSlide from '@/components/generic/SliderSlide.vue';

  export default {
    name: 'Slider',
    components: { SliderSlide },
    props: {
      slides: {type: Array, default: null },
      id: {type: String, default: null}
    },
    data() {
      return {
        swiper: null,
        arrow: require('../../icons/arrow_slider_light.svg'),
      }
    },
    methods: {
      initSlider(){
        const sliderId = '#slider-'+this.id;
        this.swiper = new Swiper(sliderId, {
          slidesPerView: 1.25,
          spaceBetween: 20,
          keyboard:true,
          loop: false,
          breakpoints: {
            993: {
              slidesPerView: 2,
            },
            1200: {
              slidesPerView: 3,
            }
          },
          on: {
            afterInit: function(){
              const slides = document.querySelectorAll(sliderId + ' .swiper-slide');
              slides.forEach((element, key) => {
                if(key <= 2){
                  element.classList.add('nn-slider-current');
                }
              });
            },
            slideChangeTransitionEnd: function() {
              const slides = document.querySelectorAll(sliderId + ' .swiper-slide');
              slides.forEach((element, key) => {
                if(key >= this.realIndex && key <= (this.realIndex + 2)){
                  element.classList.add('nn-slider-current');
                }else{
                  element.classList.remove('nn-slider-current');
                }
              });
            }
          }
        });
      },
    },
    mounted() {
      setTimeout(() => {
        this.initSlider();
      }, 0);
    },
  }
</script>

<style lang="scss">
  .nn-slider{
    display: flex;
    column-gap: 1rem;
    width: 100%;
    padding: 0 1.5rem 1.5rem 1.5rem;
    max-width: 1920px;
    margin: auto;
    position: relative;
    @media screen and (min-width: 992px){
      padding: 0;
    }
    .swiper-slide{
      &:not(.nn-slider-current){
        opacity: 0.6;
      }
    }
    &__pagination-btn{
      background-color: #000 !important;
      color: #fff;
      width: 3rem;
      height: 3rem;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: calc(50% - 1.5rem);
      left: 0;
      border: none;
      box-shadow: none;
      display: none;
      @media screen and (min-width: 993px) {
        display: flex;
      }
      &:hover{
        background-color: #fff !important;
        img{
          filter: invert(100%) sepia(4%) saturate(7493%) hue-rotate(13deg) brightness(123%) contrast(98%);
        }
      }
      &--next{
        left: initial;
        right: 0;
      }
      &--next{
        img{
          transform: rotate(180deg);
        }
      }
      &--prev{
        &:after{
          transform: rotate(-135deg);
          left: .25rem;
        }
      }
    }
    .swiper-slide{
      height: auto;
    }
    &__wrapper{
      width: 100%;
    }
    &__slide{
      display: flex;
      flex-direction: column;
      color: #000000;
      //max-width: 100%;
      row-gap: 1rem;
      background-color: #e2ddd1;
      &-content{
        padding: 0 1.5rem 1.5rem 1.5rem;
        display: flex;
        flex-direction: column;
        row-gap: .75rem;
      }
      &-title{
        font-weight: bold;
        text-transform: uppercase;
        font-size: 1.25rem;
        letter-spacing: 1px;
      }

      &-subtitle {
        font-weight: bold;
      }

      &-img{
        img{
          width: 100%;
        }
      }
    }
  }
</style>
