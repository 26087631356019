<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_light">
        <div  :id="'brick-' + brickContent.id">
            <div class="g_articlesSlider">
                <div>
                    <div class="v_swiper v_swiper-on" tabindex="0">

                        <button :id="'swiper-button-prev-' + brickContent.id" class="swiper-button-prev swiper-button-disabled" aria-disabled="true">
                            <span class="g_visually_hidden">{{ $t('productsAddToCart.previousSlide') }} </span>
                        </button>

                        <div class="v_swiperOverflow">
                            <div class="swiper-container"
                                :id="'swiper-' + brickContent.id">
                                <ul class="swiper-wrapper">
                                    <li v-for="article in articles" :key="article.index" class="swiper-slide">
                                        <div class="g_articlesSlider_element">
                                            <div class="g_articlesSlider_imageContainer">
                                                <div class="g_articlesSlider_image">
                                                    <img v-if="article.image_teaser" :src="article.image_teaser.image.uri.full_url"/>
                                                    <img v-if="!article.image_teaser" :src="article.image_header.image.uri.full_url"/>
                                                </div>
                                            </div>
                                            <div class="g_articlesSlider_content">
                                                <div class="g_articlesSlider_topics">
                                                    <template v-for="topic in article.topics">
                                                        <a v-if="topic.list_of_articles && topic.list_of_articles.url" :key="topic.index" :href="topic.list_of_articles.uri">{{ topic.name }}</a>
                                                        <span v-if="!topic.list_of_articles || !topic.list_of_articles.url" :key="topic.index">{{ topic.name }}</span>
                                                    </template>
                                                </div>
                                                <h2 v-html="article.title"></h2>
                                                <p v-if="article.is_publication_date_display && article.author == null" class="g_articlesList_date">
                                                  {{ formatDate(article.field_article_date)  }}
                                                </p>
                                                <author
                                                  v-if="article.author !== null"
                                                  :picture="article.author.field_author_picture"
                                                  :name="article.author.name"
                                                  :date="article.is_publication_date_display ? formatDate(article.field_article_date) : null"
                                                  mode="teaser"
                                                />
                                                <p v-html="article.introduction.processed"></p>
                                                <a v-if="article.nespresso_prod_uri" :href="getArticleUrl(article.nespresso_prod_uri.uri, article.field_b2b_website)" class="g_articlesSlider_discover">
                                                  {{ getCtaLabel(article) }}
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button :id="'swiper-button-next-' + brickContent.id" class="swiper-button-next" aria-disabled="false">
                            <span class="g_visually_hidden">{{ $t('productsAddToCart.nextSlide') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/bundle';
import { $ } from "@/jquery";
import { DrupalApi } from "@/services/drupal_api";
import { deserialize } from "@/services/serialize";
import { generateNespressoUrl } from "@/services/utils";
import moment from 'moment';
import Author from '@/components/Author.vue';

export default {
    name: 'ArticlesSlider',
    components: { Author },
    props: {
        brickContent: { type: Object, default: null },
    },
    data() {
        return {
            articles: null,
            defaultConfiguration: null,
            lang: 'en'
        }
    },
    mounted() {
        var drupalApi = new DrupalApi();
        var topics = this.brickContent.topics.map(t => t.drupal_internal__tid).join(',');

        drupalApi.getApi(`en/jsonapi/views/articles_slider/entity_reference_1?views-argument[0]=${topics}&include=topics,image_teaser.image,image_header.image,author,author.field_author_picture`).then(t => {
            const deserializedData = [deserialize(t.data), t];
            this.articles = deserializedData[0];

            if (this.articles && this.articles.length > 1) {
                this.articles.sort((a,b) => {
                    return new Date(this.getDatePublished(b)) - new Date(this.getDatePublished(a));
                });
            }
            setTimeout(() => {
                this.initSwiper();
            }, 0);
        });
    },
    methods: {
        formatDate(date){
          const langcode = this.brickContent.langcode;
          let format = false;
          switch (langcode){
            case 'en':
              format = "MMMM DD, YYYY";
              break;
            case 'de':
              format = "DD. MMMM YYYY";
              break;
            default:
              format = "DD MMMM, YYYY";
              break;
          }
          return moment(date).format(format);
        },
        getArticleUrl(url, isB2B){
          return generateNespressoUrl(url, isB2B, this.lang);
        },
        getDatePublished(article) {
            if (!article.scheduled_transition_state) {
              return article.changed;
            }
            const publishedIndex = article.scheduled_transition_state.findIndex(state => state === 'published');
            if (publishedIndex > -1) {
                return article.scheduled_transition_date[publishedIndex];
            } else {
                return article.changed;
            }
        },
        initSwiper() {
            var $this = this;
            if (this.articles.length) {
                new Swiper('#swiper-' + this.brickContent.id, {
                    a11y:false,
                    direction: 'horizontal',
                    loop: false,
                    slidesPerView: 1.1,
                    spaceBetwen: 0,
                    watchSlidesVisibility:true,
                    keyboard:true,
                    breakpoints: {
                        899: {
                            slidesPerView: 2.15
                        },
                        1200: {
                            slidesPerView: 3.15
                        }
                    },
                    navigation: {
                        nextEl: '#swiper-button-next-' + this.brickContent.id,
                        prevEl: '#swiper-button-prev-' + this.brickContent.id
                    },
                    on: {
                        init: function () {
                            $this.showSlider = true;
                            setTimeout(() => {
                                updateTabIndex();
                            }, 300);
                        }
                    }
                });
            } else {
                setTimeout(() => {
                    this.initSwiper();
                }, 50);
            }

            $('#swiper-' + $this.brickContent.id).on('slideChange', function() {
                setTimeout(() => {
                    $('.swiper-slide-active', $('#swiper-' + $this.brickContent.id)).focus();
                    updateTabIndex();
                }, 300);
            })

            function updateTabIndex() {
                var $currrentSWiper = $('#swiper-' + $this.brickContent.id);
                $('.swiper-slide').attr('tabindex', '-1');
                $('.swiper-slide-visible', $currrentSWiper).attr('tabindex','0');
                var $btnPrev = $('#swiper-button-prev-' + $this.brickContent.id);
                var $btnNext = $('#swiper-button-next-' + $this.brickContent.id);
                $btnPrev.attr('tabindex', '0');
                $btnNext.attr('tabindex', '0');
                $('.swiper-button-disabled').attr('tabindex', '-1');
            }
        },
        getCtaLabel(article) {
            if (article && article.field_label_cta && article.field_label_cta.value) {
                return article.field_label_cta.value;
            }
            if (this.defaultConfiguration && this.defaultConfiguration.cta) {
              return this.defaultConfiguration.cta[this.lang] || this.defaultConfiguration.cta['en'];
            }
            return "Read more";
        },
        async fetchConfiguration() {
            const drupalApi = new DrupalApi();
            this.lang = drupalApi.lang || 'en';
            const response = await drupalApi.getApi('api/article/default_config');
            if (response.status === 200) {
                this.defaultConfiguration = response.data;
            }
        },
      },
      async created() {
          await this.fetchConfiguration();
      },
}
</script>
