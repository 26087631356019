<template>
  <div>
    <button class="results__btn" @click="toggleResults">
      {{this.$i18nSL.viewList}}
    </button>
    <div class="results" :class="{'active': showResultList}">
      <portal-target name="storelocator-results" />
      <portal :to="portalPosition">
        <BoutiqueDetail v-if="allLoaded" />
        <BoutiqueDetailSkeleton v-else />
      </portal>
      <div class="results__scrollbox clean-scroll-bar" ref="scrollbox" @scroll="handleScroll">
        <div class="results__content">
          <button class="results__back" @click="toggleResults">
            <svg fill="currentColor" enable-background="new 0 0 7.1 11.3" viewBox="0 0 7.1 11.3" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m5.7 11.3 1.4-1.4-4.3-4.2 4.2-4.2-1.3-1.5-4.3 4.2-1.4 1.5 1.4 1.4z" fill-rule="evenodd"/></svg>
            {{this.$i18nSL.viewMap}}
          </button>
          <h3 class="results__title" v-html="nbResults + ' ' + title" />
          <template v-for="(boutique, index) in boutiques">
            <Boutique :data-boutique="boutique" :key="index+'_'+boutique.id" />
            <hr v-if="index < boutiques.length - 1" :key="'hr-'+index" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Boutique from "@/components/store-locator/partials/Boutique";
import { mapGetters, mapMutations, mapState } from "vuex";
import BoutiqueDetail from '@/components/store-locator/partials/BoutiqueDetail';
import BoutiqueDetailSkeleton from './BoutiqueDetailSkeleton.vue';

export default {
  name: "Results",
  components: { Boutique, BoutiqueDetail, BoutiqueDetailSkeleton },
  data() {
    return {
      nbDisplay: 10,
      scrollTimeout: null,
      portalPosition: this.getPortalPosition(),
    };
  },
  watch: {
    getActiveBoutiques(){
      this.nbDisplay = 10;
      this.$refs.scrollbox.scrollTop = 0;
    }
  },
  computed: {
    ...mapGetters("store_locator", ["getActiveBoutiques"]),
    ...mapState("store_locator", ["showResultList", "userPosition", "isMobile", "allLoaded"]),
    title(){
      if (this.userPosition)
        return this.nbResults > 1 ? this.$i18nSL.resultsAroundMe : this.$i18nSL.resultAroundMe
      return this.nbResults > 1 ? this.$i18nSL.results : this.$i18nSL.result
    },
    boutiques() { return this.getActiveBoutiques.slice(0, this.nbDisplay) },
    nbResults() { return this.getActiveBoutiques.length }
  },
  mounted(){
    window.addEventListener("resize", this.onResize);
  },
  destroyed(){
    window.removeEventListener("resize", this.onResize);
  },

  methods:{
    ...mapMutations("store_locator", ["toggleResultList"]),
    toggleResults(){
      this.toggleResultList();
      if(this.isMobile){
        window.scrollTo(0, 0);
        document.querySelector('html').classList.toggle("g_scrollLock", this.showResultList);
      }
    },
    getPortalPosition(){
      return window.innerWidth > 991 ? "storelocator-results" : "storelocator-bottom-results";
    },
    onResize(){
      this.portalPosition = this.getPortalPosition()
       if(this.showResultList){
        if(window.innerWidth > 991){
          document.querySelector('html').classList.toggle("g_scrollLock", false);
        }else{
          document.querySelector('html').classList.toggle("g_scrollLock", true);
        }
      }
    },
    handleScroll(event) {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => { this.onScroll(event) }, 120);
    },
    onScroll(event){
      if ( event.target.scrollTop >= (event.target.scrollHeight - event.target.offsetHeight - 50)){
        // Scroll is at bottom of element
        if (this.nbResults > this.nbDisplay) {
          if (this.nbDisplay + 10 > this.nbResults)
            this.nbDisplay = this.nbResults
          else
            this.nbDisplay += 10
        }
      }
    }
  }
};
</script>

<style lang="scss">
.g {
  .results {
    position: absolute;
    top: 0; left: 50%; bottom: 0;
    z-index: 21;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
    background-color: white;
    width: 100vw;
    overflow: hidden;
    transition: transform 0.3s;

    @media (max-width: 991px) { transform: translate(-50%, 100%); &.active { transform: translate(-50%, 0); } }

    @media (min-width: 992px) {
      bottom: 0px; 
      left: 0;
      width: 330px;
    }

    &__scrollbox{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__content {
      padding: 15px;
      color: #000;
      background: #fff;
      overflow-y: auto;
      width: 100%;

      hr { margin: 0 -15px; border-color: #E6E6E6; opacity: 0.5; }
    }

    &__title {
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      text-align: center;
    }

    &__btn{
      padding: 0 10px;
      height: 40px;
      position: absolute;
      bottom: 20px; 
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 19;
      background-color: #fff;
      border: 1px solid #000000;
      color: #000;
      border-radius: 4px;
      font-weight: 800;
      font-size: 14px;
      text-transform: uppercase;

      @media (min-width: 992px) { display: none; }
    }

    &__back {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 1em;

      @media (min-width: 996px) { display: none; }

      svg { width: 6px; margin-right: 10px; }
    }
  }
}
</style>
