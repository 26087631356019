<template>
    <transition name="fade">
        <div class="cp-modal first-view-list" :id="'modal-' + content.id" role="dialog" aria-modal="true" :class="isCoffeeRange ? 'cp-modal-coffee-range' : ''">
            <div class="modal-backdrop" @click="close">
            </div>

            <div class="modal-content">
                <button class="modal-close" @click="close">
                     <span class="g_visually_hidden">{{ $t('commercialOffer.closeModal') }}</span>
                </button>

                <div class="modal-nav">
                    <div class="cp-heading">
                        <h2 class="g_h2" v-html="content.title"></h2>
                        <h3 class="g_h3" v-if="content.subtitle && !popinItems[0].isCoffeeRange" v-html="content.subtitle"></h3>
                        <div class="no-formatting" v-if="content.text && popinItems[0].isCoffeeRange" v-html="content.text.processed"></div>
                    </div>
                    <div class="scroll-container">
                        <div class="scroll-content">
                            <ul class="modal-list-items">

                                <!-- Add class active if item is active -->
                                <li class="modal-list-items__item" v-for="(item, index) in popinItems" :key="index" :class="{ 'active': index == modalCurrentProduct, 'pre-active': index == modalCurrentProduct - 1}">
                                    <div class="cp-product-button-container">
                                        <button class="cp-product-list-item" :class="item.isCoffeeRange ? 'cp-product-list-item--coffee-range' : ''" @click="update(index,$event)" >
                                            <div class="cp-product-list-item__img" v-if="item.pictogram">
                                                <img :src="item.pictogram.image.uri.full_url" alt="">
                                            </div>
                                            <div class="cp-product-list-item__img"  v-if="!item.pictogram && item.image">
                                                <img :src="item.image.image.uri.full_url" alt="">
                                            </div>
                                            <div class="cp-product-list-item__img"  v-if="item.isCoffeeRange && item.images">
                                                <img :src="'https://www.nespresso.com' + item.images.icon" alt="">
                                            </div>
                                            <div class="product-infos">
                                                <h4 class="g_h4 product-name" v-if="item.title">{{item.title}}</h4>
                                                <span class="product-headline" v-if="item.isCoffeeRange && !item.title">Product with SKU "{{item.displaySku}}" is not available in local</span>
                                                <span class="product-headline" v-if="item.isCoffeeRange && item.headline">{{item.headline}}</span>
                                            </div>
                                            <span v-if="!item.isCoffeeRange" class="fn_angleRight"></span>
                                        </button>

                                        <div v-if="item.isCoffeeRange" class="commercial-infos">
                                            <span class="price">{{ currency }} {{ Number(item.price).toFixed(2) }}</span>
                                            <div class="add-to-bag" :data-product-id="item.sku" data-button-size="small"></div>
                                        </div>
                                    </div>

                                    <!-- Remove class active if not opened on mobile  -->
                                    <transition name="modal-product-transition">
                                        <div class="modal-product" :class="{active: index == modalCurrentProduct}" v-if="index == modalCurrentProduct">
                                            <button class="modal-close modal-back" @click="back" v-if="index == modalCurrentProduct">
                                                <span class="g_visually_hidden">{{ $t('commercialOffer.backButton') }}</span>
                                            </button>
                                            <!--
                                                Coffee hub type
                                            -->
                                            <div v-if="popinItems[modalCurrentProduct].isCoffeeRange" class="coffeerange-content cp-product-sheet">
                                                <div class="cp-product-sheet-container">
                                                    <div class="product-header" :style="'background-image: url(' + popinItems[modalCurrentProduct].field_front_image.image.uri.full_url  + ')'">
                                                        <div class="product-header-content">
                                                            <figure class="coffee">
                                                                <img v-if="popinItems[modalCurrentProduct].field_size_cup[0].field_front_image.image" class="cup" :src="popinItems[modalCurrentProduct].field_size_cup[0].field_front_image.image.uri.full_url" alt="">
                                                                <img v-if="popinItems[modalCurrentProduct].field_capsule_image.image" class="capsule" :src="popinItems[modalCurrentProduct].field_capsule_image.image.uri.full_url" alt="">
                                                            </figure>
                                                            <figure class="coffee relflect">
                                                                <img v-if="popinItems[modalCurrentProduct].field_size_cup[0].field_front_image.image" class="cup" :src="popinItems[modalCurrentProduct].field_size_cup[0].field_front_image.image.uri.full_url" alt="">
                                                                <img v-if="popinItems[modalCurrentProduct].field_capsule_image.image" class="capsule" :src="popinItems[modalCurrentProduct].field_capsule_image.image.uri.full_url" alt="">
                                                            </figure>
                                                        </div>
                                                    </div>
                                                    <div class="product-title">
                                                        <header>
                                                            <h3 class="product-name">{{ popinItems[modalCurrentProduct].name }}</h3>
                                                            <span class="product-tagline">{{ popinItems[modalCurrentProduct].headline }}</span>
                                                        </header>
                                                    </div>
                                                    <div class="product-scrollable">
                                                        <div class="product-scrollable-content">
                                                            <span class="product-ml">
                                                                <span class="product-cupSize" v-for="(size, index) in popinItems[modalCurrentProduct].field_size_cup" v-bind:key="index">
                                                                    <i class="icon-cupSize">
                                                                        <img :src="size.field_black_pictogram.image.uri.full_url"/>
                                                                    </i> {{ size.field_value }}
                                                                </span>
                                                            </span>
                                                            <div
                                                                :class="popinItems[modalCurrentProduct].hasSpecificNapiDescription ? 'product-descr has-specific-napi-description' : 'product-descr'"
                                                                v-html="popinItems[modalCurrentProduct].description"></div>
                                                            <br>
                                                            <div class="intensity" v-if="popinItems[modalCurrentProduct].capsuleProperties.intensity !== 0">
                                                                <span>{{ $t('commercialOffer.intensity') }}</span>
                                                                <ul class="intensities">
                                                                <li v-for="item in 12" v-bind:class=" { active: popinItems[modalCurrentProduct].capsuleProperties.intensity >= item }" :key="item"></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <footer class="product-footer">
                                                    <span class="product-price">{{ currency }} {{ Number(popinItems[modalCurrentProduct].price).toFixed(2) }}</span>
                                                    <div class="add-to-cart" :id="'add-to-cart-' + popinItems[modalCurrentProduct].id">
                                                        <div class="add-to-bag" :data-product-id="popinItems[modalCurrentProduct].sku" data-button-size="large"></div>
                                                    </div>
                                                </footer>
                                            </div>
                                            <!--
                                                Rubric type: Product
                                            -->
                                            <div v-if="popinItems[modalCurrentProduct].product" class="product-content">
                                                <!-- Product image (from NAPI) -->
                                                <div class="product-image">
                                                    <img :src="popinItems[modalCurrentProduct].product.image" alt="">
                                                </div>
                                                <!-- Product title (from NAPI) -->
                                                <h3 v-html="popinItems[modalCurrentProduct].product.name" class="g_h3"></h3>
                                                <!-- Product description (from NAPI) -->
                                                <div class="g_richText g_wysiwyg" v-html="popinItems[modalCurrentProduct].product.description"></div>
                                                <!-- Product key features (from BO) -->
                                                <div class="g_richText g_wysiwyg" v-if="popinItems[modalCurrentProduct].text" v-html="popinItems[modalCurrentProduct].text.value"></div>
                                            </div>

                                            <!--
                                                Rubric type: Capsules list
                                            -->
                                            <div v-if="popinItems[modalCurrentProduct].capsules_categories_lists" class="capsules-content">
                                                <div v-for="list in popinItems[modalCurrentProduct].capsules_categories_lists" :key="list.id" class="capsules-list">
                                                    <h3 class="g_h3">{{ list.capsules_category.name }}</h3>
                                                    <ul>
                                                        <li class="capsule-item" v-for="capsule in list.capsule_items_list" :key="capsule.index" :class="capsule.isFakeLocal ? 'capsule-item-fake' : ''">
                                                            <div class="capsule-infos">

                                                                <!-- Capsule quantity (from BO) -->
                                                                <h3 class="g_h3 capsule-qty">
                                                                    <span class="g_visually_hidden">{{ $t('commercialOffer.quantityLabel') }}:</span>
                                                                    {{ capsule.quantity }}X</h3>
                                                                <!-- Capsule image (from NAPI) -->
                                                                <div class="capsule-image">
                                                                    <img :src="capsule.image" alt="">
                                                                </div>
                                                                <!-- Capsule name + headline (from NAPI) -->
                                                                <div class="capsule-text">
                                                                    <h4 class="g_h4">{{ capsule.name }}</h4>
                                                                    <div class="g_txt_M ">{{ capsule.headline }}</div>
                                                                </div>
                                                            </div>

                                                            <!-- Capsule label(s) (from BO) -->
                                                            <div class="capsule-label" v-if="capsule.capsule.label">
                                                                <img v-for="label in capsule.capsule.label" :key="label.index" :src="label.pictogram.image.uri.full_url" alt="">
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>

                                            <!--
                                                Rubric type: Gift
                                            -->
                                            <div v-if="!popinItems[modalCurrentProduct].isCoffeeRange && !popinItems[modalCurrentProduct].product && !popinItems[modalCurrentProduct].capsules_categories_lists" class="gift-content">
                                                <!-- If image -->
                                                <div class="gift-image" v-if="popinItems[modalCurrentProduct].image && popinItems[modalCurrentProduct].display_type == 'image'"
                                                    v-lazy:background-image="popinItems[modalCurrentProduct].image[0].image.uri.full_url">
                                                </div>
                                                <!-- If text -->
                                                <div class="gift-text" v-if="popinItems[modalCurrentProduct].text && popinItems[modalCurrentProduct].display_type == 'text'">
                                                    <div class="g_richText g_wysiwyg" v-html="popinItems[modalCurrentProduct].text.value"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { $ } from "@/jquery";
import A11yUtils from '../../A11yUtils';
import { CURRENCY_PREFIX } from '../../services/params';

export default {
    name: 'OfferLightbox',
    props: {
        content: { type: Object, default: null },
        isCoffeeRange: { type: Boolean, default: false },
        popinItems: { type: Array, default: function () { return [] } }
    },
    data() {
        return {
            isLarge: window.innerWidth > 899 ? true : false,
            isDevMode: process.env.NODE_ENV == 'development',
            modalCurrentProduct: -1,
            mosaicInitiated: false,
            beforeResizeWindowWidth: null,
            currency: CURRENCY_PREFIX
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize);

        if (this.isLarge) {
            this.modalCurrentProduct = 0;
        }

        this.beforeResizeWindowWidth = window.innerWidth;

        $('html').addClass('g_scrollLock');

        this.popinItems.forEach((item) => {
            if (item.product) {
                this.getProductInformations(item);
            }

            if (item.capsules_categories_lists) {
                item.capsules_categories_lists.forEach((list) => {
                    list.capsule_items_list.forEach((capsule) => {
                        this.getCapsuleInformations(capsule);
                    });
                });
            }
        });

        this.initMosaicSmallBtns();
        this.initCurrentModalItemBtn();

        setTimeout(() => this.initA11y(), 300);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize() {
            if (this.beforeResizeWindowWidth < 900 && window.innerWidth > 899) {
                this.modalCurrentProduct = 0;
                this.initCurrentModalItemBtn();
            }

            this.beforeResizeWindowWidth = window.innerWidth;
        },
        initMosaicSmallBtns() {
            if (typeof window.mosaic !== 'undefined' && !this.mosaicInitiated) {
                window.mosaic.initializeAllFreeHTMLModules(document.getElementById('modal-' + this.content.id));
                this.mosaicInitiated = true;
            }
        },
        initCurrentModalItemBtn() {
            const that = this;

            setTimeout(function () {
                if (typeof window.mosaic !== 'undefined') {
                    window.mosaic.initializeAllFreeHTMLModules(document.getElementById('add-to-cart-' + that.popinItems[that.modalCurrentProduct].id));
                }
            }, 100);
        },
        getProductInformations(rubric) {
            window.napi.catalog().getProduct(rubric.product.sku).then((resp) => {
                this.addProductInformations(rubric, resp);
            });
        },
        addProductInformations(rubric, productInformations) {
            rubric.product.description = productInformations.description;
            rubric.product.name = productInformations.name;
            if (productInformations.responsiveImages.standard) {
              rubric.product.image = 'https://www.nespresso.com' + productInformations.responsiveImages.standard;
            } else {
              rubric.product.image = 'https://www.nespresso.com' + productInformations.images.main;
            }
        },
        getCapsuleInformations(capsule) {
            window.napi.catalog().getProduct(capsule.capsule.sku).then((resp) => {
                this.addCapsuleInformations(capsule, resp);
                this.$forceUpdate();
            });
        },
        addCapsuleInformations(capsule, capsuleInformations) {
            capsule.name = capsuleInformations.name;
            capsule.headline = capsuleInformations.headline;
            capsule.image = 'https://www.nespresso.com' + capsuleInformations.images.icon;
        },
        update(idx,event) {
            const that = this;
            that.modalCurrentProduct = idx;
            that.currentModalButton = event.target;

            this.initCurrentModalItemBtn();

            //a11y
            setTimeout(function() {
                //if mobile and modal-product is active, set focusable only within this modal-product window
                //if desktop set focusable on this entire element
                const elt = that.isLarge ? that.$el : that.$el.querySelectorAll('.modal-product.active')[0];
                that.setInnerFocusables(elt);

                if (!that.isLarge) {
                   that.setFocus();
                }
            }, 400);
        },
        close() {
            $('html').removeClass('g_scrollLock');
            setTimeout(() => this.$emit('close'), 100);

            const that=this;
            that.$el.removeEventListener("keydown", that.trapFocusFunc);
            that.$el.removeEventListener('closeModal', function () {});
            that.$el.removeEventListener('updateModalFocusables', function () {});
        },
        back() {
            const that = this;
            that.modalCurrentProduct = -1;

            //a11y
            setTimeout(function() {
                that.setInnerFocusables(that.$el);
                that.currentModalButton.focus();
            }, 400);
        },
        initA11y(){
            /*
            events listeners,
            note: store keyDownEvents func in a new var, because bind() will change the method signature and removeEventListener will not work
            https://stackoverflow.com/questions/10444077/javascript-removeeventlistener-not-working
            */
            const that=this;
            that.trapFocusFunc = that.keyDownEvents.bind(that);
            that.$el.addEventListener("keydown", that.trapFocusFunc);
            that.setInnerFocusables(that.$el);
            that.setFocus();
        },
        setFocus(){
            if(this.innerFocusables.length>1){
                this.innerFocusables[1].focus();}
            else{
                this.innerFocusables[0].focus();
            }
        },
        setInnerFocusables(elt) {
            var that = this;
            //check if focusable is hidden or display none, if not, store it
            that.innerFocusables = [].slice.call(
            elt.querySelectorAll(A11yUtils.FOCUSABLES)
            ).filter(el => !A11yUtils.isHidden(el));
        },
        keyDownEvents(evt) {
            evt = evt || window.event;
            // tab or Maj Tab in elt => capture focus
            if (evt.keyCode === A11yUtils.keyboard.tab && this.innerFocusables.indexOf(evt.target) >= 0) {
                // maj-tab on first element focusable => focus on last
                if (evt.shiftKey) {
                    if (evt.target === this.innerFocusables[0]) {
                        this.innerFocusables[this.innerFocusables.length - 1].focus();
                        evt.preventDefault();
                    }
                } else {
                    // tab on last element focusable => focus on first
                    let lastFocusable=this.innerFocusables[this.innerFocusables.length - 1];
                    if (evt.target === lastFocusable) {
                        this.innerFocusables[0].focus();
                        evt.preventDefault();
                    }
                }
            }

            // close the modal on escape
            if (evt.keyCode === A11yUtils.keyboard.esc) {
                evt.preventDefault();
                this.close();
            }
        }
    }
}
</script>

