<template>
  <div class="ea" :class="availabilityCalc <= 0 ? 'ea--not-available' : ''"> <!-- ea for event availability | ea--not-available -->
    <span v-if="availabilityCalc <= 0" class="ea__full">
      {{bricks.event_full_text}}
    </span>

    <span v-if="availabilityCalc > 0" class="ea__available">
      <span class="ea__number">{{ availabilityCalc }}</span> {{bricks.event_capacity_text}}
    </span>
    <router-link :to="linkData.route" v-if="linkData.route" class="ea__router-button">
        <event-button :eb-data="availabilityCalc" />
    </router-link>
    <event-button :eb-data="availabilityCalc" v-else/>
  </div>
</template>

<script>
import EventButton from '@/components/masterclass/EventButton'

export default {
  name: 'EventAvailability',
  components: {
    EventButton
  },
  props: {
    eaData: { type: Object, default: null },
    linkData: { type: Object, default: null }
  },
  data() {
    return {
      ...this.eaData,
      ...this.linkData
    }
  },
  computed: {
    availabilityCalc: function () {
      return this.eaData.available
    },
    bricks () {
      return this.$store.getters["event/getBricks"];
    }
  }
}
</script>

<style lang="scss">
  .g {
    .ea {
      display: flex;
      flex-direction: column;
      min-height: 110px;

      .ea__full {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #dd3232;
      }

      .ea__available {
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        color: #3d8705;
      }

      .ea__router-button {
        margin-top: auto;
      }

      .eb {
        margin-top: auto;

        .eb__button {  width: 100%; }
      }
    }

    .ec--featured {
      .ea {
        min-height: 0;

        .ea__full { padding: 0 60px; }

        .ea__available {
          position: absolute;
          top: 0;
          right: 20px;
          z-index: 1;
          display: flex;
          flex-direction: column;
          width: 120px;
          background: #ffffff;
          padding: 6px;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 2px;
          color: #8f7247;
          text-transform: uppercase;
          font-weight: 800;

          .ea__number {
            color: #000000;
            line-height: 48px;
            font-size: 36px;
          }
         }

        .eb {
          .eb__button { width: auto; }
        }
      }
    }
  }
</style>
