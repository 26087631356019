<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_carousel"
        :class="sliderStatus">
        <h2 class="g_visually_hidden" v-html="brickContent.title"></h2>
        <div class="g_imageGrid">
            <div v-lazy:background-image="mainImage.displayedImage.image.uri.full_url">
                <span class="g_buttonContainer">
                    <button tabindex="-1" aria-hidden="true" @click="startCarousel(0)"></button>
                </span>
            </div>
            <div>
                <div v-for="(img, index) in otherImages" :key="img.index" v-lazy:background-image="img.displayedImage.image.uri.full_url">
                    <span class="g_buttonContainer">
                        <button tabindex="-1" aria-hidden="true" @click="startCarousel(index + 1)"></button>
                    </span>
                </div>
            </div>
        </div>
        <div class="g_carouselSlider">
            <ul class="g_carouselSliderInner">
                <li v-lazy:background-image="mainImage.displayedImage.image.uri.full_url"></li>
                <li v-for="img in otherImages" :key="img.index" v-lazy:background-image="img.displayedImage.image.uri.full_url"></li>
            </ul>
            <nav>
                <button tabindex="-1" aria-hidden="true" class="g_btnRound g_btnPrev" @click="prev()">
                    <span>{{ $t('imageGallery.previous') }}</span>
                    <i class="fn_angleLeft g_blendDifference"></i>
                    <i class="fn_angleLeft g_blendDodge"></i>
                    <i class="fn_angleLeft g_blendSaturate"></i>
                </button>
                <button tabindex="-1" aria-hidden="true" class="g_btnClose" @click="stopCarousel()">
                    <span class="g_visually_hidden">{{ $t('imageGallery.leaveSliderMode') }}</span>
                </button>
                <button tabindex="-1" aria-hidden="true" class="g_btnRound g_btnNext" @click="next()">
                    <span>{{ $t('imageGallery.next') }}</span>
                    <i class="fn_angleRight g_blendDifference"></i>
                    <i class="fn_angleRight g_blendDodge"></i>
                    <i class="fn_angleRight g_blendSaturate"></i>
                </button>
            </nav>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ImageGallery',
    props: {
        brickContent: { type: Object, default: null },
        componentId: { type: String, default: null },
        title: { type: String, default: null },
        howTo: { type: String, default: null },
        images: { type: Array, default: () => [] },
    },
    data() {
        return {
            mainImage: this.brickContent.images[0],
            otherImages: null,
            isPlaying: false,
            sliderIndex: 0,
            isLarge: null
        }
    },
    created() {
        var img = this.brickContent.images;
        img.splice(0, 1);
        this.otherImages = img;

        // Listen to window resize
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

    },
    computed: {
        sliderStatus() {
            return "g_slide" + this.sliderIndex + (this.isPlaying ? " g_isPlaying" : "")
        }
    },
    methods: {
        startCarousel(index) {
            this.sliderIndex = index;
            this.isPlaying = true;
        },
        stopCarousel() {
            this.isPlaying = false;
        },
        prev() {
            this.sliderIndex === 0 ? this.sliderIndex = 4 : this.sliderIndex--;
        },
        next() {
            this.sliderIndex === 4 ? this.sliderIndex = 0 : this.sliderIndex++;
        },
        handleResize() {
            // If window's width has not been calculated yet
            if (this.isLarge === null) {
                this.isLarge = window.innerWidth > 767;
                if (this.isLarge) {
                    this.otherImages.forEach((image) => this.changeImageToLarge(image));
                    this.changeImageToLarge(this.mainImage);
                } else {
                    this.otherImages.forEach((image) => this.changeImageToSmall(image));
                    this.changeImageToSmall(this.mainImage);
                }
            } else if (this.isLarge === true && window.innerWidth <= 767) {
                // If window's width goes under 768px, use small bg
                this.isLarge = false;
                this.otherImages.forEach((image) => this.changeImageToSmall(image));
                this.changeImageToSmall(this.mainImage);
            } else if (this.isLarge === false && window.innerWidth > 767) {
                // If window's width goes over 767px, use large bg
                this.isLarge = true;
                this.otherImages.forEach((image) => this.changeImageToLarge(image));
                this.changeImageToLarge(this.mainImage);
            }
        },
        changeImageToLarge(image) {
            return image.displayedImage = image.image ? image.image : image.image_mobile;
        },
        changeImageToSmall(image) {
            return image.displayedImage = image.image_mobile ? image.image_mobile : image.image;
        }
    }
}
</script>
