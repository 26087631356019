import { deserialize } from "@/services/serialize";
import { DrupalApi } from "./drupal_api";

let bricks;

function setBricks(data) {
  bricks = data;
}

async function fetchMasterclassThemes() {

  let themeType = "public";

  switch (bricks.events_type) {
    case "external_event":
      themeType = `external`;
      break;

    case "internal_event":
      themeType = `internal`;
      break;

    default:
      break;
  }

  const drupalApi = new DrupalApi();
  const response = await drupalApi.loadTerms("thematics", {cacheBuster: 0});
  const themes = deserialize(response.data);
  return themes.filter(theme => theme.event_type === themeType);
}

/**
 * Getting Masterclass Events, handle filters and orders.
 *
 * @param params
 *   Params of the query.
 * @returns [{}]
 */
async function fetchMasterclassEvents(params = {}) {

  let eventType = "public";

  switch (bricks.events_type) {
    case "external_event":
      eventType = `external`;
      break;

    case "internal_event":
      eventType = `internal`;
      break;

    default:
      break;
  }

  const drupalApi = new DrupalApi();
  const options = {
    filter: {
      status: '1',
      'thematic.event_type': eventType
    }
  };

  //Adding filters parameters to query.
  if(params && params.values){
    //Coffee Experts
    if(params.values.coffeeExperts.length){
      const group = 'experts';
      options.filter[group] = {};
      options.filter[group].values = [];
      options.filter[group].field_name = 'coffee_expert.id';

      params.values.coffeeExperts.forEach((expert_alias) => {
        const expert_val = params.aliases.experts.values.filter(val => val.label === expert_alias );
        if(expert_val.length){
          options.filter[group].values.push(expert_val[0].uuid);
        }
      });
    }

    //Thematics
    if(params.values.themes.length){
      const group = 'thematics';
      options.filter[group] = {};
      options.filter[group].values = [];
      options.filter[group].field_name = 'thematic.id';
      params.values.themes.forEach((theme_alias) => {
        const theme_value = params.aliases.themes.values.filter(val => val.label === theme_alias);
        if(theme_value.length){
          options.filter[group].values.push(theme_value[0].uuid);
        }
      });
    }

    //Languages
    if(params.values.langs.length){
      const group = 'language';
      options.filter[group] = {};
      options.filter[group].values = [];
      options.filter[group].field_name = 'language_event';
      params.values.langs.forEach((lang) => {
        options.filter[group].values.push(lang);
      });
    }
  }

  //Shops
  if(params.values !== undefined && params.values.boutique.length){
    const group = 'shop';
    options.filter[group] = {};
    options.filter[group].values = [];
    options.filter[group].field_name = 'boutique.id';
    params.values.boutique.forEach((boutique) => {
      const boutique_val = params.aliases.shop.values.filter(val => val.label === boutique);
      if(boutique_val.length){
        options.filter[group].values.push(boutique_val[0].uuid);
      }
    });
  }

  //Adding pagination.
  if(params && params.page){
    options.page = params.page;
  }

  //Sort management.
  if(params && params.order){
    if(params.order === 'latest'){
      options.orderby = "event_date_and_time.value";
      options.orderbydirection = "ASC";
    }

    if(params.order === 'oldest'){
      options.orderby = "event_date_and_time.value";
      options.orderbydirection = "DESC";
    }

    if(params.order === 'availability'){
      options.orderby = "capacity_available";
      options.orderbydirection = "DESC";
    }
  }

  //Return data
  const response = await drupalApi.getPaginatedList('event', options);
  return {
    events: await deserialize(response.data),
    totalCount: response.data.meta.count,
    endpoint: response.config.url,
  };
}

async function fetchAliasFilters() {
  const drupalApi = new DrupalApi();

  const response = await drupalApi.get(drupalApi.lang + '/api/masterclass-alias-filters', {});
  return response.data;
}

async function fetchQueryRelatedShop(eventApiCall){
  const drupalApi = new DrupalApi();
  const response = await drupalApi.get(drupalApi.lang + '/api/masterclass-shops?event_query='+ encodeURIComponent(eventApiCall));

  return response.data;
}

function fetchMasterclassBoutiques() {
  const drupalApi = new DrupalApi();
  return drupalApi.loadContentType("all", "boutique", {cacheBuster: 0}).then(response => {
    return deserialize(response.data);
  });
}

function fetchMasterclassEventSingle(eventId) {
  const drupalApi = new DrupalApi();
  return drupalApi.loadContentType(eventId, 'event').then(response => {
    return deserialize(response.data);
  });
}

function fetchMasterclassForms() {
  let formId = "6fa2f3a5-6622-4b77-9ce1-981e7e7e4ba4";

  switch (bricks.events_type) {
    case "external_event":
      formId = "d02b9ab8-32e2-43d9-b58e-6070f231ee83";
      break;

    case "internal_event":
      formId = "c215caee-ff63-44f0-8a67-862a452f5a24";
      break;

    default:
      break;
  }
  const drupalApi = new DrupalApi();

  return drupalApi.loadWebform(formId).then(response => {
    return deserialize(response["data"]);
  });
}

async function fetchMasterclassConsentPopin(uuid){
  const drupalApi = new DrupalApi();
  return await drupalApi.loadBrick(uuid, 'popin');
}

function fetchMasterclassFormsToken() {

  const drupalApi = new DrupalApi();

  return drupalApi.getApi(`session/token`).then(response => {
    return response["data"];
  });
}

function fetchMasterclassCoffeeExperts() {
  const drupalApi = new DrupalApi();

  return drupalApi.loadTerms('coffee_expert', {}).then(response => {
    return response["data"];
  });
}

async function submitMasterclassForms($data) {
  const token = await fetchMasterclassFormsToken();
  let headers = {
    "Content-Type": "application/json",
    "X-CSRF-Token": token
  };

  const data = $data;
  const drupalApi = new DrupalApi();
  return drupalApi.postApi(drupalApi.lang +"/webform_rest/submit", data, {headers})
}

async function fetchZipLocation(zipcode) {
  const response = await new DrupalApi().get(`front-app/dist/json/zipcodes/${zipcode}.json`)
  return {
    lat: response.data.lat,
    lng: response.data.lng
  };
}
export {
  fetchMasterclassThemes,
  fetchMasterclassEvents,
  fetchMasterclassBoutiques,
  fetchMasterclassEventSingle,
  fetchMasterclassForms,
  submitMasterclassForms,
  setBricks,
  fetchZipLocation,
  fetchMasterclassCoffeeExperts,
  fetchAliasFilters,
  fetchQueryRelatedShop,
  fetchMasterclassConsentPopin,
};
