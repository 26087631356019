<template>
    <section :id="brickContent.anchor_identifier" class="h h_textblocks" :class="brickContent.field_text_align ? '' : 'h_textblocks_right'" :style="'background-color: ' + brickContent.backgroundcolor.color + '; color: ' + (brickContent.text_color_black_or_white ? 'black' : 'white')">
        <div class="h_textblocks__container">
            <div class="h_textblocks_infos">
              <h3 class="h_head_caption">{{ brickContent.caption }}</h3>
              <h2 class="h_head_title" v-if="brickContent.title" v-html="brickContent.title"></h2>
              <p class="h_head_text" v-html="brickContent.text.value"></p>
              <a class="h_head_cta h-cta" :class="ctaStyle" v-if="brickContent.cta" :href="brickContent.cta">{{ brickContent.cta_label }}</a>
            </div>
            <div class="h_textblocks_content">
              <div class="h_textblocks_element" v-for="block in brickContent.blocks" :key="block.index">
                <div  :style="'border-top-color: ' + brickContent.text_color_black_or_white ? 'black' : 'white'">
                  <h4>{{ block.title }}</h4>
                  <p v-html="block.text.value"></p>
                </div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TextBlocks',
    props: {
        brickContent: { type: Object, default: null }
    },
    data(){
      return {
        ctaStyle: 'h-cta--'+ this.brickContent.field_cta_style,
      }
    }
}
</script>
