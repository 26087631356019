<template>
    <div class="cp-coffee-range" :id="brickContent.anchor_identifier" :class="brickContent.field_background_is_light ? 'g_light' : ''">
      <div class="g_restrict">
        <div class="content">
            <div class="container">
              <div class="products">
                <template v-for="(item, index) in brickContent.coffee_hub">
                  <coffee-range-product
                    v-bind:key="index"
                    :category="item"
                    :brickContent="brickContent"
                  />
                </template>
              </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
import CoffeeRangeProduct from './CoffeeRangeProduct.vue';

export default {
  name: 'CoffeeRange',
  props: {
    brickContent: { type: Object, default: null }
  },
  data() {
    return {
      coffeeRange: this.brickContent.coffeeRange
    }
  },
  components: {
    CoffeeRangeProduct
  }
};
</script>
<style scoped lang="scss">
.cp-coffee-range {
  overflow: hidden;
  color: #fff;
  text-align: center;
  padding-top: 40px;
  @media screen and (min-width: 767px) {
    .g_restrict { padding: 0 !important; }
  }
  .products {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    justify-content: center;

    .cp-coffee-range-product {
      box-sizing: border-box;
      border: 2px solid #000;
      width: 100%;

      @media screen and (min-width: 900px) {
        width: 50%;
      }
    }
  }

  &.g_light {
    color: #000;
    background-color: #f3f3f3;

    .products {
      .cp-coffee-range-product {
        border: 2px solid #f3f3f3;
      }
    }
  }
}
</style>
