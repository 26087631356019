<template>
  <div class="payment-card">
    <img :src="src" />
    <span class="payment-card__label" v-html="paymentMethod.title" />
  </div>
</template>

<script>
export default {
  name: 'PaymentCard',
  props: {
    paymentMethod: { type: Object, default: null }
  },
  computed:{
    src(){
      return this.paymentMethod.icon ? this.paymentMethod.icon.url : "";
    }
  }
}
</script>

<style lang="scss">
.g {
  .payment-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    img{
      width: auto;
      max-width: 100%;
      max-height: 40px;
      display: block;
      margin-bottom: 3px;
    }
    &__label { font-size: 12px; line-height: 16px; }
  }
}
</style>
