<template>
  <div class="store-locator-map">
    <div class="store-locator-map__wrapper">
      <GmapMap
        ref="map"
        :center="center"
        :zoom="zoom"
        @zoom_changed="handleZoomChange"
        @center_changed="handleCenterChange"
        :options="mapOptions">
        <GmapCluster :zoom-on-click="true" :styles="clusterStyles"
            :gridSize="gridSize"
            :zIndex="2"
            v-if="zoom < disableClusterAt"
         >
         <template v-for="(boutique, index) in boutiques">
          <CustomMarker
            :key="index"
            v-if="(!activeMarker || boutique.id !== activeMarker.id) "
            :position="boutique.position"
            :clickable="true"
            :icon="getMarker(boutique)"
            @click="handleMarkerClick(boutique)"
            :zIndex="5"
            :hoverIcon="getActiveMarker(boutique)"
          />
         </template>
        </GmapCluster>
        <template v-else-if="zoom >= disableClusterAt" v-for="(boutique, index) in boutiques">
          <CustomMarker
            :key="index"
            v-if="(!activeMarker || boutique.id !== activeMarker.id) "
            :position="boutique.position"
            :clickable="true"
            @click="handleMarkerClick(boutique)"
            :zIndex="5"
            :hoverIcon="getActiveMarker(boutique)"
          />
        </template>
        <GmapMarker
            v-if="activeMarker "
            :position="activeMarker.position"
            :clickable="true"
            :icon="getActiveMarker(activeMarker)"
            @click="handleMarkerClick(activeMarker)"
            :label="getLabel(activeMarker)"
            :zIndex="10"
          />
        <GmapMarker
          v-if="userPosition"
          :position="userPosition"
          :draggable="false"
          :icon="noIcon"
          :label="label"
          :zIndex="200"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import MAP_THEME from "@/map_theme.json";
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import GmapCluster from 'vue2-google-maps/dist/components/cluster' // replace src with dist if you have Babel issues
import CustomMarker from "./CustomMarker.vue";
// https://github.com/xkjyeah/vue-google-maps/issues/307
// https://github.com/xkjyeah/vue-google-maps/issues/400
export default {
  name: "Map",
  components: { GmapCluster, CustomMarker },
  data() {
    return {
      ...this.dataLocation,
      northEast: null,
      southWest: null,
      map: null,
      gridSize: 80,
      showMarkers: false,
      noIcon:{
        url : this.$distUrl  + "/front-app/dist/" + require('./markers/empty.png'),
        scaledSize: {width: 0, height: 0},
        labelOrigin: {x: 0, y: 0}
      },
      defaultIcon:{
        url : this.$distUrl + "/front-app/dist/" + require('./markers/boutique.svg'),
        labelOrigin: {x: 12, y: 24}
      },
      defaultActiveIcon:{
        url : this.$distUrl  + "/front-app/dist/" + require('./markers/boutique-active.svg'),
        labelOrigin: {x: 12, y: 24}
      },
      label:{
        text: ".",
        color: "rgb(254, 254, 254)",
        fontSize: "0.000126px" // Hack to target via css
      },
      mapOptions: {
        styles: MAP_THEME,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        gestureHandling: "cooperative",
        mapTypeControl: false,
        labelClass: "my_label",
      },
      zoomTimeout:null,

      clusterStyles: [
        {
          textColor: 'white',
          url: this.$distUrl   + "/front-app/dist/" + require('./markers/Oval.svg'),
          height: 48,
          width: 48
        }
      ],
      fakeClusterIcon:{
        url: this.$distUrl + "/front-app/dist/" + require('./markers/Oval.svg'),
        labelOrigin: {x: 0, y: 30}
      },
      disableClusterAt: 18,
      /*
      zoomMaxPostalClusters: 10,
      zoomMaxRegionClusters: 8,
      zoomMaxCountryCluster: 5,
      fakeClusters: [],
      */
    }
  },
  computed: {
    ...mapState('store_locator',['zoom', 'center', 'userPosition', "activeMarker", "storeLocatorService", "allBoutiques", "isMobile", "isFullscreen"]),
    ...mapGetters("store_locator", ["getActiveBoutiques", "getBoutiques"]),
    google: gmapApi,
    boutiques() { return this.getBoutiques }
  },
  watch: {
    zoom(){
      setTimeout(()=>{
        if(this.zoom > 14){
          this.gridSize = 0
        }else if(this.zoom > 10){
          this.gridSize = 80
        }else if(this.zoom > 6){
          this.gridSize = 100
        }else{
          this.gridSize = 160
        }
      }, 3000)

    },
    isFullscreen(to){
      this.map.setOptions({
        ...this.mapOptions,
        gestureHandling: to ? 'greedy' : 'cooperative'
      })
    }
  },
  methods: {
    ...mapMutations('store_locator', ['updateZoom', 'setMapRef', 'setMap', 'setActiveMarker', 'setCenter']),
    ...mapActions('store_locator', ["updateTargetZone", "updateCenter"]),
    handleMarkerClick(boutique){
      this.setActiveMarker(boutique)
      if(!this.isMobile){
        if (!(JSON.stringify({ name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }) == JSON.stringify({ name: 'boutiqueDetail', params: { slug: boutique.slug }, query: this.$route.query }))){
          this.$router.push({ name: 'boutiqueDetail', params: { slug: boutique.slug }, query: this.$route.query })
        }
      }
    },
    getLabel(boutique){
      if(boutique.id === this.activeMarker.id){
        const div = document.createElement("div")
        div.innerHTML = boutique.title
        const text = div.textContent || div.innerText || ""

        return {
          text: text,
          fontSize: "12.0001px" // Hack to target via css
        }
      }
      return null;
    },
    getMarker(boutique){
      if(boutique.primaryStoreType && boutique.primaryStoreType.marker){
        return {
          url: boutique.primaryStoreType.marker.url,
          labelOrigin: {x: 12, y: 24}
        }
      }else{
        return this.defaultIcon
      }
    },
    getActiveMarker(boutique){
      if(boutique.primaryStoreType && boutique.primaryStoreType.markerHover){
        return {
          url: boutique.primaryStoreType.markerHover.url,
          labelOrigin: {x: 12, y: 24}
        }
      }else{
        return this.defaultActiveIcon
      }
    },
    handleZoomChange(value){
      clearTimeout(this.zoomTimeout)
      this.zoomTimeout = setTimeout(()=>{
        this.updateZoom(value)
        this.updateTargetZone()
        this.updateUrlParams()
      }, 1000);
    },
    handleCenterChange(value){
      clearTimeout(this.zoomTimeout)
      this.zoomTimeout = setTimeout(()=>{
        this.setCenter({
          lat: value.lat(),
          lng: value.lng()
        })
        this.updateTargetZone()
        this.updateUrlParams()
      }, 250);
    },
    updateUrlParams(){
      const query = { ...this.$route.query }
      query.lat = this.center.lat.toString()
      query.lng = this.center.lng.toString()
      query.zm = this.zoom.toString()
      if(JSON.stringify(query) !== JSON.stringify(this.$route.query))
        this.$router.replace({ path: this.$route.path, query })
    },
    async setInitialMapPosition(){
      const {query, name} = this.$route;

      // SKIP INITAL POISTION FOR BOUTIQUE DETAIL (It's define on boutique detail component)
      if(name === "boutiqueDetail") return;

      if ((query.lat && query.lng) && !query.region) {
        this.updateCenter({
          lat: parseFloat(query.lat),
          lng: parseFloat(query.lng)
        })
        if(query.zm){
          this.$store.commit("store_locator/updateZoom", parseInt( query.zm))
        }
      }else{
        const queryRegion = this.storeLocatorService.regions.find(r=>r.name===query.region);
        const initialregion = queryRegion ? queryRegion.name : this.storeLocatorService.initialRegion.name;
        const latlngbounds = new this.google.maps.LatLngBounds();
        let maxLng = null; //  plus c'est à droite la lng est grande
        let maxLat = null; // plus c'st haut plus la lat est grande
        let minLat = null;
        let minLng = null;
        this.allBoutiques.forEach(boutique=>{
          if(boutique.region === initialregion){
            const {lat,lng} = boutique.position;
            if(maxLng === null){
               minLat = maxLat = lat;
               minLng = maxLng = lng;
            }else{
              if(minLat > lat){ minLat = lat; }
              if(minLng > lng){ minLng = lng; }
              if(maxLat < lat){ maxLat = lat; }
              if(maxLng < lng){ maxLng = lng; }
            }

          }
        })
        if ([minLat, maxLat, maxLng, maxLng].some(p=>p===null)){
          return
        }
        latlngbounds.extend(new this.google.maps.LatLng(maxLat,maxLng));
        latlngbounds.extend(new this.google.maps.LatLng(minLat,minLng));
        this.$nextTick(()=>{
          this.map.fitBounds(latlngbounds);
        })

      }
    }
  },
  async mounted() {

    this.$refs.map.$mapPromise.then((map) => {
      this.map = map
      this.setMap(this.map);

      this.google.maps.event.addListenerOnce(this.map, 'idle', () => {
        this.setInitialMapPosition();
        this.showMarkers = true
      })
      this.google.maps.event.addListenerOnce(this.map, 'bounds_changed',() => {
        this.updateTargetZone()
      })
    });
    this.setMapRef(this.$refs.map);
  }
};
</script>

<style lang="scss">
.g {
  .store-locator-map {
    position: relative;
    width: 100%;
    height: 100%;

    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    // MARKER POS
    &__marker { color: #000; font-weight: 600; }
    // Label Marker PO
    .gm-style div[style*="font-size: 12.0001"] {
      transform: translate( calc( 50% + 25px), -8px );
      font-weight: bold;
      max-width: 150px;
      text-shadow: 0px 0px 2px white, -1px -1px 2px white, 1px 1px 2px white, 1px -1px 2px white, -1px 1px 2px white;
      white-space: break-spaces;
      text-align: left;
    }
    // LABEL Faker Cluster
    .gm-style div[style*="font-size: 13.0001"] {
      transform: translate( calc( 50% + 25px), -8px );
      font-weight: bold;
      width: 48px;
      text-align: center;
      white-space: break-spaces;
      color: white !important;
      transform: translate(50%, -40%);
    }

    // MARKER USER
    .gm-style div[style*="font-size: 0.000126px"],
    &__user {

      width: 16px;
      height: 16px;
      background-color: #8F7247;
      border: 1px solid #fff;
      border-radius: 20px;
      display: block;
      position: relative;
      overflow: visible !important;

      &:before,
      &:after {
        content: "\00020";
        display: block;
        width: 60px;
        height: 60px;
        border: 1px solid #fff;
        background-color: #8F7247;
        opacity: 0.2;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 200px;
        animation: pulseMarker 3s infinite ease;
        z-index: -1;
      }

      &::after {
        animation-delay: 1.5s;
      }
    }

    .vue-map-container { height: 100%; }
  }
}
@keyframes pulseMarker {
	0% {
    width: 16px;
    height: 16px;
    opacity: 0.1;
	}
  70% { opacity: 0.1; }
	100% {
    width: 60px;
    height: 60px;
    opacity: 0;
	}
}
</style>
