<template>
  <div>
    <span class="enr">
      <!-- enr for event number results -->
      <span class="enr__number">{{ enrLength }}</span>
      <span class="enr__label">{{ grammar }}</span>
    </span>
    <span class="clean"
      ><button @click="cleanAllFilter">
        {{ bricks.filters_reset_text }}
      </button></span
    >
  </div>
</template>

<script>
export default {
  name: "EventNumberResults",
  props: {
    enrData: { type: Object, default: null },
    enrLength: { type: Number, default: null }
  },
  data() {
    return {
      ...this.enrData,
      ...this.enrLength
    };
  },
  methods: {
    cleanAllFilter() {
      this.$emit("clearFilters");
    }
  },
  computed: {
    grammar() {
      if (this.enrLength > 1) {
        return this.enrData.plural;
      } else {
        return this.enrData.singular;
      }
    },
    bricks() {
      return this.$store.getters["event/getBricks"];
    }
  }
};
</script>

<style lang="scss">
.g {
  .enr {
    font: {
      size: 14px;
      weight: 800;
    }
    letter-spacing: 1px;
    padding-right: 1em;
    &__label {
      display: inline-block;
      margin-left: 4px;
    }
  }
  .clean {
    button {
      color: #8f7247;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 17px;
      text-decoration: underline;
    }
  }
}
</style>
