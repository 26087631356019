<template>
  <span class="cross" @click="onClick"></span>
</template>

<script>
export default {
  name: 'Cross',
  props: { onClick: { type: Function } }
}
</script>

<style lang="scss">
.g {
  .cross {
    position: absolute;
    top: 28px;
    right: 28px;
    width: 16px;
    height: 16px;
    opacity: 0.7;
    cursor: pointer;
    
    &:hover { opacity: 1; }
    
    &:before, &:after {
      position: absolute;
      left: 8px;
      content: ' ';
      height: 16px;
      width: 2px;
      background-color: #000;
    }
    
    &:before { transform: rotate(45deg); }
    
    &:after { transform: rotate(-45deg); }
  }
}
</style>
