<template>
    <div>
        <transition name="slide-inout">
            <div
                class="boutique-detail clean-scroll-bar"
                v-if="$route.name === 'boutiqueDetail'"
            >
                <div class="boutique-detail">
                    LOADING...
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
        };
    },
};
</script>
