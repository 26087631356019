<template>
  <div class="link-router">
    {{ /* eslint-disable-next-line vue/require-component-is */ }}
    <component  v-bind="attributes">
      <span class="link-router__title">
        <slot />
      </span>
      <span class="link-router__icon">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 11.6569L4.58579 10.2426L8.82843 6L4.58579 1.75736L6 0.343146L10.2426 4.58579L11.6569 6L10.2426 7.41421L6 11.6569Z" fill="#8F7247"/>
        </svg>
      </span>
    </component>
  </div>
</template>

<script>
export default {
  name: 'LinkRouter',
  props: {
    to: { type: [Object, Boolean ], default: false, required: false },
    href: { type: [String, Boolean ], default: false, required: false },
    target: {type: [String, Boolean], default: false, required: false}
  },
  computed: {
    attributes(){
          return this.to
        ? { is: "router-link", to: {...this.to, query: this.$route.query} }
        : { is: "a", href: this.href, target: this.target };
    }
  }
};
</script>

<style lang="scss">
.g {
  .link-router {
    a, &__icon { display: flex; align-items: center; }

    &__title, &__icon {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 1px;
      color: #8F7247;
    }
  }
}
</style>
