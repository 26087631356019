<template>
  <div class="filters" id="filterAnchor">
    <h3>{{ fData.title }}</h3>
    <div class="filters__options">
      <event-geolocation
        :translations="fData.geolocation"
        :alias="aliases !== null && aliases !== undefined ? aliases.shop : {}"
        v-model="filters.boutique"
        ref="location"
      />
      <event-language
        :btn-title-data="fData.language.title"
        v-model="filters.langs"
      />
      <categories-list
        :alias="aliases !== null && aliases !== undefined ? aliases.themes : {}"
        :btn-title-data="fData.themeTitle"
        v-model="filters.themes"
      />
      <coffee-experts
        :title="fData.coffeeExpertsTitle"
        :alias="aliases !== null && aliases !== undefined ? aliases.experts : {}"
        v-model="filters.coffeeExperts"
      />
    </div>
  </div>
</template>

<script>
import CategoriesList from "@/components/masterclass/CategoriesList";
import EventLanguage from "@/components/masterclass/EventLanguage";
import EventGeolocation from "@/components/masterclass/EventGeolocation";
import CoffeeExperts from '@/components/masterclass/CoffeeExperts';

export default {
  name: "Filters",
  components: {
    CategoriesList,
    EventLanguage,
    EventGeolocation,
    CoffeeExperts,
  },
  props: {
    fData: { type: Object, default: null },
    aliases: { type: Object, default: () => { return {}} }
  },
  data() {
    return {
      ...this.clData,
      ...this.fData,
      location: null,
      filters: {
        langs: [],
        themes: [],
        boutique: [],
        coffeeExperts: []
      },
      scrollToResult: false,
    };
  },
  methods: {
    applyFilters() {
      this.$emit("apply", this.filters);
    },
    clearFilters() {
      this.filters = {
        langs: [],
        themes: [],
        boutique: [],
        coffeeExperts: []
      };
      this.applyFilters();
    },
    scrollToResultsAction() {
      setTimeout(
        () => {
          const target = document.querySelector('.all-events');
          target.scrollIntoView({ block: 'end',  behavior: 'smooth' });
        }, 2000
      );
    },
    initValues() {
      //Setting filters values from url.
      if(this.aliases !== null && this.aliases !== undefined){
        if(this.$route.query[this.aliases.themes.label] !== undefined && this.$route.query[this.aliases.themes.label].length){
          this.filters.themes = this.$route.query[this.aliases.themes.label].split('+');
          this.scrollToResult = true;
        }

        if(this.$route.query[this.aliases.languages.label] !== undefined && this.$route.query[this.aliases.languages.label].length) {
          this.filters.langs = this.$route.query[this.aliases.languages.label].split('+');
          this.scrollToResult = true;
        }

        if (this.$route.query[this.aliases.experts.label] !== undefined && this.$route.query[this.aliases.experts.label].length) {
          this.filters.coffeeExperts = this.$route.query[this.aliases.experts.label].split('+');
          this.scrollToResult = true;
        }
        if (this.$route.query[this.aliases.shop.label] !== undefined && this.$route.query[this.aliases.shop.label].length) {
          this.filters.boutique = this.$route.query[this.aliases.shop.label].split('+');
          this.scrollToResult = true;
        }
      }

      if (this.scrollToResult === true) {
        //If prefilterd, scroll to Event list.
        this.scrollToResultsAction();
        this.applyFilters();
      }
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.aliases !== undefined) {
          this.$router.replace({
            ...this.$route,
            query: {
              [this.aliases.languages.label]: this.filters.langs.join('+'),
              [this.aliases.shop.label]: this.filters.boutique.join('+'),
              [this.aliases.experts.label]: this.filters.coffeeExperts.join('+'),
              [this.aliases.themes.label]: this.filters.themes.join('+')
            }
          });
        }
      }
    },
    aliases: {
      deep: true,
      handler() {
        if (this.aliases !== null && this.aliases !== undefined) {
          this.initValues();
        }
      }
    }
  },
};
</script>

<style lang="scss">
.g {
  .filters {
    padding: 10px 0 50px 0;
    @media (min-width: 768px) {
      padding: 50px 0;
    }

    &__options {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        row-gap: 1rem;
        column-gap: 1.5rem;
      }
      margin-bottom: 30px;

      & > * {
        flex-grow: 1;
        @media (min-width: 768px) {
          width: calc(50% - (1.5rem / 2));
          .multiselect {
            max-width: 280px;
            margin: auto;
            margin-top: 1rem;
          }
        }
      }
    }

    &__actions {
      text-align: center;
    }

    &__button {
      text-align: center;
      margin: 15px auto 0;
      -webkit-appearance: none;
      border: 1px solid transparent;
      display: inline-block;
      padding: 12px 20px;
      min-width: 200px;
      background: #8f7247;
      color: #fff;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 4px;
      text-align: center;
      transition: all 300ms;

      &:hover {
        background: #a0804f;
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.3);
      }
    }

    h3 {
      font: {
        size: 30px;
        weight: 300;
      }
      text: {
        transform: uppercase;
        align: center;
      }
      letter-spacing: 6px;
      line-height: 40px;
      margin: 0 0 50px;
      @media (min-width: 768px) {
        letter-spacing: 5px;
      }
    }
  }

  .multiselect {
    &__tag {
      background-color: #8f7247;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: .5px;

      &-icon {
        &:hover {
          background-color: darken(#8f7247, 10%);
        }

        &:after {
          color: #fff;
        }
      }
    }

    &__option {
      &--highlight {
        background-color: #dac3b1;

        &:after {
          content: none;
        }
      }
    }
  }
}
</style>
