<template>
    <div class="store-locator-brick" :id="brickContent.anchor_identifier">
        <div v-if="!loading && position">
            <StoreNearYouRow
                v-if="!loading && !error && displayType === 'full'"
                :brick-content="brickContent"
                :boutique="boutique"
                :title="brickContent.title"
                :link-label="linkLabel"
                :link-url="boutiqueUrl"
            />
            <StoreNearYouToast
                v-if="!loading && !error && displayType === 'popup'"
                :brick-content="brickContent"
                :title="brickContent.title"
                :boutique="boutique"
                :link-label="linkLabel"
                :link-url="boutiqueUrl"
            />
        </div>
    </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import StoreNearYouToast from '@/components/store-locator/brick/StoreNearYouToast.vue';
import StoreNearYouRow from '@/components/store-locator/brick//StoreNearYouRow.vue';
import helpers from '@/services/store_locator_helpers';

export default {
    components: { StoreNearYouRow, StoreNearYouToast },
    props: {
        brickContent: { type: Object },
    },
    data() {
        return {
            boutique: null,
            error: false,
            displayType: 'popup',
            loading: true,
            linkLabel: 'Read',
            position: null,
        };
    },
    computed: {
        ...mapState('store_locator', ['boutiques', 'filters', 'userPosition']),
        boutiqueUrl() {
            if (!this.boutique) {
                return this.brickContent.link;
            }
            return `${this.brickContent.link}#/boutique/${this.boutique.slug}`;
        },
    },
    methods: {
        ...mapActions('store_locator', ['fetchData', 'activeSpecificFilter', 'boutiqueBySlug', 'updateUserPosition', 'initStoreLocatorVuex']),
        ...mapMutations('store_locator', ['setBoutiques']),
        async init() {
            this.displayType = this.brickContent.field_display_type;
            this.linkLabel = this.brickContent.link_label;
            await this.setUserPosition();
            if (this.position) {
                await this.fetch();
                await this.initStoreLocatorVuex();
                // BE SURE Boutiques is sorted by position
                if (this.position) {
                    this.updateUserPosition(this.position);
                }
                // Get first boutique item
                if (this.boutiques[0]) {
                    this.boutique = this.boutiques[0];
                    this.error = false;
                } else {
                    this.error = true;
                }
            }
            this.loading = false;
        },
        async setUserPosition() {
            const allow = await helpers.geolocIsGranted();
            if (allow) {
                await this.setPositionByBrowser();
            } else {
                // User doesn't granted location permission
                await this.setPositionByBrowser();
            }
        },
        async setPositionByBrowser() {
            try {
                const position = await helpers.getCurrentPosition();
                this.position = position;
            } catch (e) {
                this.setPositionByIp();
            }
        },
        async setPositionByIp() {},
        async fetch() {
            await this.fetchData(false);
            await this.activeSpecificFilter(this.brickContent.store_type.id);
        },
    },
    mounted() {
        this.init();
    },
};
</script>
