import _ from "lodash";
import {
  fetchMasterclassThemes,
  fetchMasterclassEvents,
  fetchAliasFilters,
  fetchMasterclassBoutiques,
  fetchMasterclassCoffeeExperts,
  fetchQueryRelatedShop,
  setBricks,
  fetchZipLocation
} from "@/services/masterclass";

const state = {
  bricks: [],
  event: [],
  theme: [],
  lang: ["EN", "FR", "DE", "IT"],
  langEnable: [],
  location: [],
  coffeeExperts: [],
  activeFilter: {
    themeId: [],
    lang: [],
    location: [],
    page: [],
    coffeeExperts: []
  },
  tempActiveFilter: {
    themeId: [],
    lang: ["EN"],
    location: [],
    page: [],
    coffeeExperts: []
  },
  userInfo: {},
  pageUrl: '',
  aliasFilters: {},
  queryEvent: [],
  queryShops: [],
  queryEventTotal: 0,
};
const mutations = {
  resetEvents(state) {
    state.event = [];
  },
  constructThemes(state, theme) {
    if (state.theme.length === 0) {
      for (let i = 0; i < theme.length; i++) {
        let tempIteration = {
          id: theme[i].id,
          name: theme[i].name,
          rating: theme[i].rating ? theme[i].rating : 0,
          description: theme[i].description ? theme[i].description.value : "",
          short_description: theme[i].short_description
            ? theme[i].short_description
            : "",
          image: theme[i].picture ? theme[i].picture.image.uri.full_url : ""
        };
        state.theme.push(tempIteration);
      }
    }
  },
  constructQueryEvents(state, res){
    state.queryEvent = [];
    state.queryEventTotal = res.totalCount;
    for (let i = 0; i < res.events.length; i++) {
      if (res.events[i].boutique) {
        let tempIteration = {
          id: res.events[i].id,
          themeId: res.events[i].thematic.id,
          title: res.events[i].title,
          date: {
            start: res.events[i].event_date_and_time
              ? res.events[i].event_date_and_time.value
              : res.events[i].event_date_and_time,
            end: res.events[i].event_date_and_time
              ? res.events[i].event_date_and_time.end_value
              : res.events[i].event_date_and_time
          },
          coffeeExpertId: res.events[i].coffee_expert ? res.events[i].coffee_expert.id : null,
          coffeeExpert: res.events[i].coffee_expert ?  res.events[i].coffee_expert : null,
          description: res.events[i].description
            ? res.events[i].description.value
            : res.events[i].description,
          availability: {
            total: res.events[i].hosting_capacity,
            taken: res.events[i].capacity_taken,
            available: res.events[i].capacity_available
          },
          meta: {
            capacity: res.events[i].hosting_capacity,
            location: res.events[i].boutique.city,
            boutiqueId: res.events[i].boutique.id,
            place: res.events[i].boutique.city,
            lang: res.events[i].language_event.toUpperCase(),
          }
        };
        if (!state.langEnable.includes(tempIteration.meta.lang)) {
          state.langEnable.push(tempIteration.meta.lang);
        }
        state.queryEvent.push(tempIteration);
      }
    }
  },
  constructEvents(state, event) {
    state.event = [];
    state.langEnable = [];
    for (let i = 0; i < event.length; i++) {
      if (event[i].boutique) {
        let tempIteration = {
          id: event[i].id,
          themeId: event[i].thematic.id,
          title: event[i].title,
          date: {
            start: event[i].event_date_and_time
              ? event[i].event_date_and_time.value
              : event[i].event_date_and_time,
            end: event[i].event_date_and_time
              ? event[i].event_date_and_time.end_value
              : event[i].event_date_and_time
          },
          coffeeExpertId: event[i].coffee_expert ? event[i].coffee_expert.id : null,
          coffeeExpert: event[i].coffee_expert ?  event[i].coffee_expert : null,
          description: event[i].description
            ? event[i].description.value
            : event[i].description,
          availability: {
            total: event[i].hosting_capacity,
            taken: event[i].capacity_taken,
            available: event[i].capacity_available
          },
          meta: {
            capacity: event[i].hosting_capacity,
            location: event[i].boutique.city,
            boutiqueId: event[i].boutique.id,
            place: event[i].boutique.city,
            lang: event[i].language_event.toUpperCase(),
          }
        };
        if (!state.langEnable.includes(tempIteration.meta.lang)) {
          state.langEnable.push(tempIteration.meta.lang);
        }
        state.event.push(tempIteration);
      }
    }
    state.loadEvent = true;
  },
  constructAliasFilters(state, aliasData) {
    state.aliasFilters = aliasData;
  },
  constructBoutiques(state, boutique) {
    if (state.location.length == 0) {
      if (!boutique.length) {
        boutique = Array(boutique);
      }
      for (let i = 0; i < boutique.length; i++) {
        let tempIteration = {
          id: boutique[i].id,
          address: boutique[i].address,
          city: boutique[i].city,
          zip: boutique[i].postal_code,
          country: boutique[i].country,
          location: {
            lat: boutique[i].location.lat,
            lng: boutique[i].location.lng
          }
        };
        state.location.push(tempIteration);
      }
    }
  },
  constructInfoUser(state, allData) {
    let tempIteration = {
      name: allData.infoPerso.name,
      surname: allData.infoPerso.surname,
      memberNumber: allData.infoPerso.memberNumber,
      email: allData.infoPerso.email,
      language: allData.infoPerso.language,
      tel: allData.infoAddress[0].firstPhone.number,
      Country: allData.infoAddress[0].country,
      address: allData.infoAddress[0].addressLine1,
      address2: allData.infoAddress[0].addressLine2,
      city: allData.infoAddress[0].city,
      zip: allData.infoAddress[0].zipCode,
      country: allData.infoAddress[0].country,
      location: allData.userLocation
    };
    if (tempIteration.language == null) {
      tempIteration.language = "EN";
    }
    /* Active lang set */
    /*state.activeFilter.lang.push(tempIteration.language.toUpperCase())
    state.activeFilter.lang = _.intersection( state.activeFilter.lang, state.langEnable )*/
    /* Temp Active lang set */
    state.tempActiveFilter.lang.push(tempIteration.language.toUpperCase());
    state.tempActiveFilter.lang = _.intersection(
      state.activeFilter.lang,
      state.langEnable
    );

    /* Temp Active location set */
    //state.tempActiveFilter.location.push(tempIteration.address.toUpperCase())

    state.userInfo = tempIteration;
  },
  setActiveFilter(state, obj) {
    if (Object.keys(state.activeFilter).includes(obj.filterName)) {
      state.activeFilter[obj.filterName] = obj.value;
    } else {
      console.error(obj.filterName + " key doesnt existe on event store");
    }
  },
  setTempActiveFilter(state, obj) {
    if (Object.keys(state.tempActiveFilter).includes(obj.filterName)) {
      state.tempActiveFilter[obj.filterName] = obj.value;
    } else {
      console.error(obj.filterName + " key doesnt existe on event store");
    }
  },
  setEvent(state, event) {
    state.event = event;
  },
  setBricks(state, bricks) {
    state.bricks = bricks;
    setBricks(bricks);
  },
  setPageUrl(state) {
    let pageUrl = window.location.href.split("#")[0];
    state.pageUrl = pageUrl;
  },
  constructCoffeeExperts(state, coffeeExperts){
    if (state.coffeeExperts.length == 0) {
      for (let i = 0; i < coffeeExperts.data.length; i++) {
        const coffeeExpertsObj = {
          id: coffeeExperts.data[i].id,
          name: coffeeExperts.data[i].attributes.name,
          description: coffeeExperts.data[i].attributes.description ? coffeeExperts.data[i].attributes.description.processed : '',
          img: coffeeExperts.included[i]
        };
        state.coffeeExperts.push(coffeeExpertsObj);
      }
    }
  },
  constructQueryRelatedShops(state, shops){
    state.queryShops = shops;
  }
};

const actions = {
  async fetchCoffeeExperts({commit}){
    const coffeeExperts = await fetchMasterclassCoffeeExperts();
    commit("constructCoffeeExperts", coffeeExperts);
  },
  async fetchThemes({ commit }) {
    const themes = await fetchMasterclassThemes();
    commit("constructThemes", themes);
  },
  async fetchEvents({ commit }) {
    commit("resetEvents");
    const events = await fetchMasterclassEvents();
    commit("constructEvents", events);
    this.dispatch("event/fetchInfoUser");
  },
  async fetchQueryEvents({ commit }, params){
    const result = await fetchMasterclassEvents(params);
    commit('constructQueryEvents', result);
    this.dispatch('event/fetchRelatedShop',  result.endpoint);
  },
  async fetchRelatedShop({ commit }, eventEndpoint){
    const result = await fetchQueryRelatedShop(eventEndpoint);
    commit('constructQueryRelatedShops', result);
  },
  async fetchAliasFilters({ commit }) {
    const aliasFilters = await fetchAliasFilters();
    commit("constructAliasFilters", aliasFilters);
  },
  async fetchBoutiques({ commit }) {
    const boutiques = await fetchMasterclassBoutiques();
    commit("constructBoutiques", boutiques);
  },
  async fetchInfoUser({ commit }) {
    if (!window.napi) return;
    try {
      const infoAddress = await window.napi.customer().getAddresses(); // eslint-disable-line
      const infoPerso = await window.napi.customer().getPersonalInfo(); // eslint-disable-line
      const zipCode = infoAddress[0] ? infoAddress[0].zipCode : 1094;
      let userLocation;
      try{
        userLocation = await fetchZipLocation(zipCode);
      }catch(e){
        userLocation = {"lat":46.5086319,"lng":6.6743056}
      }

      commit("constructInfoUser", { infoPerso, infoAddress, userLocation });
    } catch (e) {
      console.error("User probably not logged in", e);
    }
  }
};

const getters = {
  themesList: state => {
    return state.theme;
  },
  eventsList: state => {
    return state.event;
  },
  queryEvent: state => {
    return state.queryEvent;
  },
  queryShops: state => {
    return state.queryShops;
  },
  queryEventTotal: state => {
    return state.queryEventTotal;
  },
  boutiquesList: state => {
    return state.location;
  },
  doneEvents: state => {
    return state.event.filter(event => {
      for (const key in state.activeFilter) {
        if (
          !(
            state.activeFilter[key].includes(event["meta"][key]) ||
            state.activeFilter[key].includes(event[key])
          ) &&
          state.activeFilter[key].length != 0 &&
          (event[key] != undefined ||
            event["meta"][key] != undefined ||
            event["themeId"] != undefined)
        ) {
          return false;
        }
      }
      return true;
    });
  },
  aliasFiltersList: state => {
      return state.aliasFilters;
  },
  getTheme: state => {
    return state.theme;
  },
  getLang: state => {
    return state.lang;
  },
  getLocation: state => {
    return state.location;
  },
  getUserInfo: state => {
    return state.userInfo;
  },
  getLangEnable: state => {
    return state.langEnable;
  },
  getBricks: state => {
    return state.bricks;
  },
  getPageUrl: state => {
    return state.pageUrl;
  },
  active: state => {
    return state.activeFilter;
  },
  activeTheme: state => {
    return state.activeFilter.themeId;
  },
  activeLang: state => {
    return state.activeFilter.lang;
  },
  activeLocation: state => {
    return state.activeFilter.location;
  },
  activePage: state => {
    return state.activeFilter.page;
  },
  tempActiveTheme: state => {
    return state.tempActiveFilter.themeId;
  },
  tempActiveLang: state => {
    return state.tempActiveFilter.lang;
  },
  tempActiveLocation: state => {
    return state.tempActiveFilter.location;
  },
  tempActivePage: state => {
    return state.tempActiveFilter.page;
  },
  getCoffeeExperts: state => {
    return state.coffeeExperts;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
