<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_visualVariations g_light g_center"
        :class="['g_toggleVariation' + selectedProductIndex, 'g_productLength' + brickContent.products.length]">
        <div class="g_restrict" :id="'brick-' + brickContent.id">
            <div class="g_content">
                <div class="g_text">
                    <p class="g_visually_hidden">{{ $t('hero.useButtons') }}</p>
                    <div class="g_visualVariationsSlider">
                        <article class="g_product" v-for="(product, index) in brickContent.products" :key="product.index">
                            <div class="g_restrict" :class="index === selectedProductIndex ? 'g_selectedProduct' : ''">

                                <!-- Image button of the front view -->
                                <button :class="isFrontViewActive ? 'g_activeView' : ''" :tabindex="index !== selectedProductIndex ? '-1' : '0'"
                                    @click="changeToFrontView(true)">
                                    <span class="g_visually_hidden">{{ $t('hero.frontViewZoom') }}</span>
                                    <span class="g_visually_hidden">{{ $t('hero.frontView') }}</span>
                                    <ul>
                                        <li v-for="model in product.models"
                                            :key="model.index"
                                            :class="model === selectedModel ? 'g_activeColor' : ''">
                                            <div class="g_image" v-lazy:background-image="model.front_image.image.uri.full_url"></div>
                                        </li>
                                    </ul>
                                </button>

                                <!-- Image button of the side view -->
                                <button :class="!isFrontViewActive ? 'g_activeView' : ''"  :tabindex="index !== selectedProductIndex ? '-1' : '0'"
                                    @click="changeToFrontView(false)">
                                    <span class="g_visually_hidden">{{ $t('hero.sideViewZoom') }}</span>
                                    <span class="g_visually_hidden">{{ $t('hero.sideView') }}</span>
                                    <ul>
                                        <li v-for="model in product.models"
                                            :key="model.index"
                                            :class="model === selectedModel ? 'g_activeColor' : ''">
                                            <div class="g_image"  v-lazy:background-image="model.side_image.image.uri.full_url"></div>
                                        </li>
                                    </ul>
                                </button>

                            </div>
                        </article>
                    </div>
                    <div class="g_sectionRestrict">
                        <div class="g_sectionContent">

                            <!-- Toggle circle under the images for front/side switch -->
                            <div class="g_frontSideSwitch">
                                <button :class="isFrontViewActive ? 'g_activeView' : ''"
                                    @click="changeToFrontView(true)">
                                    <span class="g_visually_hidden">{{ $t('hero.toggleToFrontView') }}</span>
                                </button>
                                <button :class="!isFrontViewActive ? 'g_activeView' : ''"
                                    @click="changeToFrontView(false)">
                                    <span class="g_visually_hidden">{{ $t('hero.toggleToSideView') }}</span>
                                </button>
                            </div>

                            <div class="g_productConfiguratorPosition">
                                <div class="g_productConfigurator">
                                    <p class="g_visually_hidden">{{ $t('hero.formHelp') }}</p>
                                    <div>
                                        <div v-if="brickContent.products.length > 1" class="g_cell g_productToggle">
                                            <fieldset>
                                                <legend></legend>
                                                <div class="g_rail">
                                                    <!-- Toggle for the products (machine models) -->
                                                    <template v-for="(product, index) in brickContent.products">
                                                        <input :key="product.index"
                                                            type="radio" name="productToggle"
                                                            :id="'productToggle' + index"
                                                            :value="product"
                                                            v-model="selectedProduct"
                                                            @change="onChangeProduct(index)">
                                                        <label :key="product.index"
                                                            :for="'productToggle' + index"
                                                            :class="'g_label_productToggle g_label_productToggle' + index">
                                                            <ImageResponsive
                                                              :image="product.models[0].front_image.image.uri.full_url"
                                                              :imageMobile="product.models[0].front_image.image.uri.full_url"
                                                            />
                                                            <b class="g_thumbTooltip g_thumbTooltip__picto" v-html="product.title"></b>
                                                        </label>
                                                    </template>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div v-if="selectedProduct.models.length > 0" class="g_cell g_toggleColor">
                                            <fieldset>
                                                <legend>{{ $t('hero.chooseColour') }}</legend>
                                                <div>
                                                    <p aria-hidden="true" class="g_visually_hidden">{{ $t('hero.colours') }}</p>
                                                    <div class="g_inputs">
                                                        <!-- Toggle for the "models" (machine colors) -->
                                                        <template v-for="(model, index) in selectedProduct.models">
                                                            <input :key="model.index"
                                                                type="radio"
                                                                :name="'colorToggle' + selectedProduct.title.replace(/\s+/g, '-').toLowerCase()"
                                                                :id="'radio_colorToggle' + selectedProductIndex + '_' + index"
                                                                :value="model"
                                                                v-model="selectedModel">
                                                            <label :key="model.index"
                                                                :for="'radio_colorToggle' + selectedProductIndex + '_' + index"
                                                                :style="'background-color: ' + model.colorCode + ';'">
                                                                <b class="g_thumbTooltip">{{ model.colorName }} <span class="g_visually_hidden">{{ $t('hero.colour') }}</span></b>
                                                            </label>
                                                        </template>
                                                        <div class="g_bullet_selected">
                                                            <span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>

                                        <!-- Price + buy button -->
                                        <template v-for="product in brickContent.products">
                                            <div v-for="model in product.models" :class="model.sku === selectedModel.sku ? 'g_cell g_buyProduct' : 'g_cell g_buyProduct g_buyProductHidden'" :key="model.key">
                                                <fieldset>
                                                    <legend v-html="$t('hero.reviewProduct', { model: model.name })"></legend>
                                                    <div class="g_addToCart">
                                                        <div class="g_priceAndButton track-impression-product" :id="'model-' + model.urlFriendlyName">
                                                            <p v-if="strikeThrough === true" class="g_productPrice strikethrough"><span>{{ currency }} {{ model.unitPrice }}</span><br />{{ currency }} {{ offerPrice }} </p>
                                                            <p v-if="strikeThrough === false" class="g_productPrice">{{ currency }} {{ model.unitPrice }}</p>
                                                            <div :data-product-id="model.id" class="add-to-bag" data-button-size="large"></div>
                                                        </div>
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped lang="scss">
.strikethrough span {
    text-decoration: line-through;
    color: #000;
}

.g {
    .g_visualVariations {
        .g_visualVariationsSlider {
            width: 100%;
            left: 33%;
            position: relative;
            padding-bottom: 1.5rem;
            @media screen and (min-width: 768px) {
                width: unset;
                left: 0;
                position: relative;
                padding: 0;
            }
        }
    }
}
</style>
<script>
import { $ } from "@/jquery";
import { CURRENCY_PREFIX } from '../services/params';
import ImageResponsive from "@/components/ImageResponsive.vue";

export default {
    name: 'Hero',
    components: {ImageResponsive},
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            selectedProduct: this.brickContent.products[0],
            selectedProductIndex: 0,
            isFrontViewActive: true,
            selectedModel: this.brickContent.products[0].models[0],
            strikeThrough: this.brickContent.products[0].models[0].strikethrough,
            offerPrice: this.brickContent.products[0].models[0].offer_price,
            currency: CURRENCY_PREFIX
        }
    },
    mounted() {
        this.getProductDetails();
    },
    methods: {
        // Get product details from napi (only works on the Nespresso website)
        getProductDetails() {
            this.brickContent.products.forEach((product) => {
                product.models.forEach((model) => {
                    window.napi.catalog().getProduct(model.sku).then((resp) => {
                        model.id = resp.id;
                        model.unitPrice = parseFloat(resp.unitPrice).toFixed(2);
                        model.colorName = resp.colorShade.name;
                        model.colorCode = resp.colorShade.cssCode;
                        model.urlFriendlyName = resp.urlFriendlyName;
                        model.name = resp.name;
                        this.initMosaic(model);
                    });
                });
            });
        },


        // Initialize buy button for each model and color, as they're all unique (different SKUs)
        initMosaic(model) {
            if (typeof window.mosaic !== 'undefined') {
                if (document.getElementById('model-' + model.urlFriendlyName) !== null) {
                    window.mosaic.initializeAllFreeHTMLModules(document.getElementById('model-' + model.urlFriendlyName));
                    this.setButtonTypeToBtn();
                } else {
                    setTimeout(() => {
                        this.initMosaic(model);
                    }, 50);
                }
            }
        },

        // This prevents the reloading of the page
        setButtonTypeToBtn() {
            if ($('.AddToBagButtonLarge').length > 0) {
                $('.AddToBagButtonLarge').attr('type', 'button');
            } else {
                setTimeout(() => {
                    this.setButtonTypeToBtn();
                }, 1000);
            }
        },
        changeToFrontView(bool) {
            this.isFrontViewActive = bool;
        },
        onChangeProduct(index) {
            this.selectedProduct = this.brickContent.products[index];
            this.selectedProductIndex = index;
            this.selectedModel = this.brickContent.products[index].models[0];
        }
    }
}
</script>
