import { StoreLocatorService } from '../../services/store_locator'
import helpers from '@/services/store_locator_helpers';
import _ from 'lodash';

const state = {
  storeLocatorService: null,
  allBoutiques: [],
  geolocated: false,
  boutiques: [],
  storeTypes: [],
  activeMarker: null,
  paymentMethods: [],
  services: [],
  zoom: 15,
  isMobile: window.innerWidth <= 991,
  center: { lat: 46.20306, lng: 6.1468 },
  targetZone:{
    SW: {lat: 0, lng: 0},
    NE: {lat: 0, lng: 0},
  },
  showResultList: false,
  isFullscreen: false,
  wrapperRef: null,
  mapRef: null,
  map: null,
  userPosition: null,
  filters: { services: [], storeTypes: [], distance: {}, paymentMethods: [] },
  defaultFilter: { services: [], storeTypes: [], distance: {}, paymentMethods: [] },
  searchQuery: "",
  allLoaded: false,
}

const mutations = {
  setIsMobile(state){
    state.isMobile =  window.innerWidth <= 991
  },
  setAllLoaded(state, value){
    state.allLoaded = value
  },
  setDefaultFilter(state, value) { state.defaultFilter = value },
  setActiveMarker(state,value) {state.activeMarker = value },
  setTargetZone(state, value) { state.targetZone = value },
  setStoreLocatorService(state,value){ state.storeLocatorService = value },
  setBoutiques(state, value) { state.boutiques = helpers.sortedBoutique(state.userPosition, value) },
  setAllBoutiques(state, value) { state.allBoutiques = value },
  setPaymentMethods(state, value) { state.paymentMethods = value },
  setStoreTypes(state, value){ state.storeTypes = value },
  setServices(state, value){ state.services = value },
  toggleResultList(state) {  state.showResultList = !state.showResultList },
  updateZoom(state, value) { state.zoom = value },
  setGeolocated(state, value) { state.geolocated = value },
  setCenter(state, value) {
    state.center = value
  },
  setUserPosition(state, value) { 
    state.userPosition = value
    localStorage.setItem("storeLocatorUserPosition", JSON.stringify(value))
  },
  setWrapperRef(state, value) { state.wrapperRef = value },
  setMapRef(state, value) { state.mapRef = value },
  setMap(state, value) { state.map = value },
  setIsFullscreen(state, value) { state.isFullscreen = value },
  setSearchQuery(state, value) {
     state.searchQuery = value
     localStorage.setItem("storeLocatorSearchQuery", value)
  },
  setFilters(state, value) { state.filters = value },
  resetFilters(state) {
    state.filters = {}
    state.filters.services = []
    state.filters.storeTypes = []
    state.filters.distance = null
    state.filters.paymentMethods = []
    for (const key in state.services) {
      if (state.services[key].filter_label) {
        state.filters.services.push(
          {
            active: false,
            label: state.services[key].filter_label,
            value: key,
            type:"services"
          }
        )
      }
    }
    for (const key in state.storeTypes) {
      state.filters.storeTypes.push(
        {
          active: false,
          label: state.storeTypes[key].title,
          value: key,
          type: "storeTypes"
        }
      )
    }
    for (const key in state.paymentMethods) {
      state.filters.paymentMethods.push(
        {
          active: false,
          label: state.paymentMethods[key].title,
          value: key,
          type: "paymentMethods"
        }
      )
    }
  },
  setUpdateUrlParams(state, {router, route}) {
    const activeFilter = helpers.activeFiltersValues(state.filters)
    const query = { ...route.query }
    delete query.st
    delete query.pm
    delete query.se
    delete query.di

    if (activeFilter.storeTypes.length)
      query.st = activeFilter.storeTypes
    if (activeFilter.paymentMethods.length)
      query.pm = activeFilter.paymentMethods
    if (activeFilter.services.length)
      query.se = activeFilter.services
    if (activeFilter.distance)
      query.di = activeFilter.distance

    if (JSON.stringify(route.query) !== JSON.stringify(query))
      router.replace({ path: route.path, query })
  }
}

const actions = {
  updateTargetZone({state, commit}){
    if (!state.map)
      return
    const bounds = state.map.getBounds()
    // TOP RIGHT CORNER
    var NECorner = bounds.getNorthEast()
    // BOTTOM LEFT Corner
    var SWCorner = bounds.getSouthWest()

    commit("setTargetZone", {
      SW: {lat: SWCorner.lat(), lng: SWCorner.lng()},
      NE: {lat: NECorner.lat(), lng: NECorner.lng()}
    })
  },
  boutiqueBySlug({state}, slug) { 
    return state.allBoutiques.find(boutique => boutique.slug === slug) 
  },
  toggleFullscreen({commit, state}){
    var elem = state.wrapperRef
    commit("setIsFullscreen", !document.webkitFullscreenElement)
    if (document.webkitFullscreenElement)
      document.webkitCancelFullScreen()
    else
      elem.webkitRequestFullScreen()
  },
  async fetchData({state, commit}, asnycLoadAllBoutiques = true) {
    if(!state.storeLocatorService){
      commit("setStoreLocatorService", new StoreLocatorService())
    }
    if(asnycLoadAllBoutiques){
      await state.storeLocatorService.fetchInitialData();
    }else{
      await state.storeLocatorService.fetchAll();
    }
    await state.storeLocatorService.fetchI18n();
    commit("setPaymentMethods", state.storeLocatorService.paymentMethods)
    commit("setServices", state.storeLocatorService.services)
    commit("setStoreTypes", state.storeLocatorService.storeTypes)
    commit("resetFilters");
    commit("setBoutiques", state.storeLocatorService.boutiques)
    commit("setAllBoutiques", state.storeLocatorService.boutiques)
    if(asnycLoadAllBoutiques){
      state.storeLocatorService.fetchSecondaryData().then(() => {
        commit("setAllBoutiques", state.storeLocatorService.boutiques);
        commit("setBoutiques",  helpers.boutiqueByFilters(state, state.storeLocatorService.boutiques));
        commit("setAllLoaded", true)
      });
    }else{
      commit("setAllLoaded", true)

    }
  },
  applyFilters({commit, state}, value) {
    commit("setFilters", value)
    commit("setBoutiques", helpers.boutiqueByFilters(state, state.allBoutiques))
  },
  updateUserPosition({commit}, value) {
    commit("setUserPosition", value)
    commit("setBoutiques", helpers.boutiqueByFilters(state, state.allBoutiques) )
  },
  activeSpecificFilter({commit, state}, filterId){
    const filterTypes = ['paymentMethods', 'services', 'storeTypes'];
    const filters = _.cloneDeep(state.filters);
    filterTypes.forEach(filterType=>{
      filters[filterType].forEach(filter=>{
        if(filter.value === filterId){
          filter.active  = true;
        }
      })
    })
    commit("setFilters", filters);
    commit("setBoutiques", helpers.boutiqueByFilters(state, state.allBoutiques) )
  },
  updateUrlParams({commit}, value) { commit("setUpdateUrlParams", value) },
  updateCenter({commit, state}, value){
    if (state.mapRef){
      state.mapRef.panTo(value)
    }
    commit('setCenter', value)
  },
  initStoreLocatorVuex({commit}){
    const searchQueryStore = localStorage.getItem("storeLocatorSearchQuery")
    const userPositionStore = localStorage.getItem("storeLocatorUserPosition")
    if(searchQueryStore){
      commit("setSearchQuery", searchQueryStore)
    }
    if(userPositionStore && searchQueryStore){
      commit("setUserPosition", JSON.parse(userPositionStore))
    }
  }
}

const getters = {
  geolocated: state => { return state.geolocated && state.userPosition !== null },
  getBoutiques: state => { return state.boutiques },
  getActiveBoutiques: state => { 
    return helpers.boutiquesInTarget(state) 
  },
  getNearestBoutique: state => { return helpers.sortedBoutique(state) },
  getFilter: state => { return state.filters },
  filtersCount: state => {
    let count =  0;
    [state.filters.services, state.filters.storeTypes, state.filters.paymentMethods].forEach(section => {
      section.forEach(item => {
        if (item && item.active)
          count++
      })
    })
    if (Number.isInteger(state.filters.distance) && state.filters.distance !== 0)
      count++
    return count
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
