<template>
  <div class="checkbox__custom">
    <input type="checkbox" :checked="value" @input="$emit('input', $event.target.checked)">
    <span class="checkmark"></span>
    <label>{{ dataLabel }}</label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: Boolean,
    dataLabel: String
  }
}
</script>

<style lang="scss" scoped>
  //custom checkbox
  .checkbox__custom {
    display: flex;
    position: relative;
    align-items: center;
    &:hover input ~ .checkmark { background-color: #eee; }
    
    .checkmark {
      flex-shrink: 0;
      display: block;
      height: 24px;
      width: 24px;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 4px;
      transition: all 0.1s ease-in-out;
      pointer-events: none;

      &:after {
        content: "";
        position: absolute;
        display: block;
        opacity: 0;
        left: 8px;
        top: 12px;
        width: 5px;
        height: 10px;
        border: solid #fff;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
        transition: all 0.1s ease-in-out;
      }
    }

    label {
      flex-shrink: 0;
      padding-left: 8px;
      font-size: 14px;
      line-height: 18px;
      text-transform: capitalize;
      letter-spacing: 1px;
      width: 100%;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 100%;
      width: 100%;
      margin: 0;
      transform: translate(0, 0);
      
      &:checked ~ .checkmark { background-color: #000000; }
      
      &:checked ~ .checkmark:after { opacity: 1; }
    }
  }
</style>
