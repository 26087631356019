<template>
    <section :id="brickContent.anchor_identifier" :class="brickContent.text_color_black_or_white ? 'h_relatedcontent_light' : ''" class="h h_relatedcontent" :style="'background-color: ' + brickContent.backgroundcolor.color + '; color: ' + (brickContent.text_color_black_or_white ? 'black' : 'white')">
      <div class="h_relatedcontent__container">
          <div class="h_relatedcontent_infos">
            <!-- FAQ Title -->
            <h2 class="h_relatedcontent_title" v-if="brickContent.title" v-html="brickContent.title"></h2>
            <p class="h_relatedcontent_text" v-html="brickContent.text.value"></p>
          </div>
          <div class="h_relatedcontent_content">
            <!-- Swiper for elements aligned left -->
            <div class="v_swiper v_swiper-on" tabindex="0">
              <div class="v_swiperOverflow">
                <div class="swiper-container" :id="'swiper-' + brickContent.id">
                  <ul class="swiper-wrapper">
                    <li class="h_relatedcontent_element swiper-slide" v-for="content in brickContent.related_contents" :key="content.index">
                      <div class="h_relatedcontent_content_image">
                        <image-responsive
                          v-if="content.media_image && content.media_image.image"
                          :image="content.media_image.image.image_style_uri['1_1_medium']"
                          :imageMobile="content.media_image.field_override_mobile ? content.media_image.mobile_image.image_style_uri['scale_only'] : content.media_image.image.image_style_uri['1_1']"
                        />
                      </div>
                      <h4 class="h_relatedcontent_content_title" v-if="content.title" v-html="content.title"></h4>
                      <a :href="content.cta" class="h_relatedcontent_content_cta">{{  content.cta_label }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
</template>

<script>
import Swiper from 'swiper/bundle';
import { $ } from "@/jquery";
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
    name: 'RelatedContent',
  components: { ImageResponsive },
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            relatedContentSwiper: null,
            desktop: true,
        }
    },
    mounted() {
        // Listen to window resize
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        handleResize() {
            if (window.innerWidth <= 995) {
                this.desktop = false;
                this.initSwiper();
            } else {
                this.desktop = true;
                if (this.relatedContentSwiper) {
                    this.relatedContentSwiper.destroy(true, true);
                }
            }
        },
        initSwiper() {
            var $this = this;
            if (this.brickContent.related_contents.length) {
                $this.relatedContentSwiper = new Swiper('#swiper-' + this.brickContent.id, {
                    a11y:false,
                    direction: 'horizontal',
                    loop: false,
                    slidesPerView: 1.15,
                    spaceBetwen: 0,
                    watchSlidesVisibility:true,
                    keyboard:true,
                    on: {
                        init: function () {
                            $this.showSlider = true;
                            setTimeout(() => {
                                updateTabIndex();
                            }, 300);
                        }
                    }
                });
            } else {
                setTimeout(() => {
                    this.initSwiper();
                }, 50);
            }

            $('#swiper-' + $this.brickContent.id).on('slideChange', function() {
                setTimeout(() => {
                    $('.swiper-slide-active', $('#swiper-' + $this.brickContent.id)).focus();
                    updateTabIndex();
                }, 300);
            })

            function updateTabIndex() {
                var $currrentSWiper = $('#swiper-' + $this.brickContent.id);
                $('.swiper-slide').attr('tabindex', '-1');
                $('.swiper-slide-visible', $currrentSWiper).attr('tabindex','0');
                var $btnPrev = $('#swiper-button-prev-' + $this.brickContent.id);
                var $btnNext = $('#swiper-button-next-' + $this.brickContent.id);
                $btnPrev.attr('tabindex', '0');
                $btnNext.attr('tabindex', '0');
                $('.swiper-button-disabled').attr('tabindex', '-1');
            }
        }
    }
}
</script>
