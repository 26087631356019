<template>
  <div role="dialog" aria-modal="true" class="g_popin g_popinVideo g_popinOpened" :class="[extraClasses, internalVideo ? 'g_popinVideo--internal': '']">
    <div class="g_popinOverlay"></div>
    <div class="g_popinFixed">
      <div class="g_popinRestrict g_light">
        <button @click="closeModal()" class="g_btnRoundS g_btnClose">
          <span class="g_visually_hidden">Close the video modal</span>
          <span class="fn_close"></span>
        </button>
        <div class="g_popinContent">
          <div v-if="!internalVideo">
            <div class="g_embedVideo v_isPlaying">
              <p class="g_visually_hidden"></p>
              <!--      This is an external video (ex: youtube)      -->
              <div class="g_embedVideoPlayer">
                <iframe v-if="!extraClasses || extraClasses !== 'g_sust-kiosk-video-modal'" frameborder="0" tabindex="0"
                      allowfullscreen="1" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      title="YouTube video player" width="640" height="360"
                      :src="embedUrl + '?enablejsapi=1&amp;rel=0&amp;fs=0&amp;controls=0&amp;origin=https%3A%2F%2Fwww.nespresso.com&amp;widgetid=1'"
                      id="widget2"></iframe>
                <youtube player-width="100%" v-if="extraClasses === 'g_sust-kiosk-video-modal'" @ready="videoReady"
                       @ended="closeModal" :video-id="video.video_id" :player-vars="{ controls: 0, rel: 0 }"></youtube>
              </div>
            </div>
          </div>
          <!--    This is an internal vidéo      -->
          <video v-if="internalVideo" :src="video.uri" class="g_internal-video" controls/>
        </div>
        <!-- A11y hack: need another button .g_visually_hidden to allow the focus to be trapped within the popin -->
        <button @click="closeModal()" class="g_visually_hidden">Close the video modal</button>
      </div>
    </div>
  </div>
</template>

<script>
import { $ } from '@/jquery';
import A11yUtils from '../A11yUtils';

export default {
  name: 'VideoModal',
  props: {
    video: { type: Object, default: null },
    extraClasses: { type: String, default: '' },
    internalVideo: { type: Boolean, default: false }
  },
  data() {
    return {
      embedUrl: null,
      sustVideoTimeout: null,
    };
  },
  mounted() {
    if (this.video) {
      this.embedUrl = 'https://www.youtube.com/embed/' + this.video.video_id;
    }
    setTimeout(() => $('.g_popin').addClass('g_popinOpened'), 20);
    $('html').addClass('g_scrollLock');

    /*
    events listeners,
    note: store keyDownEvents func in a new var, because bind() will change the method signature and removeEventListener will not work
    https://stackoverflow.com/questions/10444077/javascript-removeeventlistener-not-working
    */
    const that = this;
    that.trapFocusFunc = that.keyDownEvents.bind(that);
    that.$el.addEventListener('keydown', that.trapFocusFunc);
    this.setInnerFocusables();
    this.innerFocusables[0].focus();
  },
  methods: {
    videoReady(event) {
      event.target.playVideo();
      this.sustVideoTimeout = setTimeout(() => {
        this.closeModal();
      }, event.target.getDuration() * 1000 - 200);
    },
    closeModal() {
      $('html').removeClass('g_scrollLock');
      $('.g_popin').removeClass('g_popinOpened');

      if (this.sustVideoTimeout) {
        clearTimeout(this.sustVideoTimeout);
      }

      setTimeout(() => this.$emit('close'), 300);
      var that = this;
      that.$el.removeEventListener('keydown', that.trapFocusFunc);
      that.$el.removeEventListener('closeModal', function () { });
      that.$el.removeEventListener('updateModalFocusables', function () { });
    },
    setInnerFocusables() {
      var that = this;
      //check if focusable is hidden or display none, if not, store it
      that.innerFocusables = [].slice.call(that.$el.querySelectorAll(A11yUtils.FOCUSABLES)).filter((el) => !A11yUtils.isHidden(el));
    },
    keyDownEvents(evt) {
      //first update inner focusables array in case if a new focusable appears in the modal
      this.setInnerFocusables();
      //
      evt = evt || window.event;
      // tab or Maj Tab in elt => capture focus
      if (evt.keyCode === A11yUtils.keyboard.tab && this.innerFocusables.indexOf(evt.target) >= 0) {
        // maj-tab on first element focusable => focus on last
        if (evt.shiftKey) {
          if (evt.target === this.innerFocusables[0]) {
            this.innerFocusables[this.innerFocusables.length - 1].focus();
            evt.preventDefault();
          }
        } else {
          // tab on last element focusable => focus on first
          let lastFocusable = this.innerFocusables[this.innerFocusables.length - 1];
          if (evt.target === lastFocusable) {
            this.innerFocusables[0].focus();
            evt.preventDefault();
          }
        }
      }

      // tab outside elt => put it in focus
      if (evt.keyCode === A11yUtils.keyboard.tab && this.innerFocusables.indexOf(evt.target) === -1) {
        evt.preventDefault();
        this.innerFocusables[0].focus();
      }

      // close the modal on escape
      if (evt.keyCode === A11yUtils.keyboard.esc) {
        evt.preventDefault();
        this.closeModal();
      }
    },
  },
};
</script>
