<template>
  <div class="near" v-if="boutiques.length">
    <h4 class="near__title">{{ translations.title }}</h4>
    <div class="near__panel">
      <div class="near__input">
        <VueMultiselect
          v-model="selectedBoutique"
          :searchable="true"
          :multiple="true"
          :options="boutiques"
          track-by="uuid"
          label="displayed_label"
          :placeholder="translations.placeholder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect';

export default {
  name: "EventGeolocation",
  components: {
    VueMultiselect
  },
  props: {
    translations: { type: Object, default: null },
    value: { type: Array, default: null },
    alias: { type: Object, default: null }
  },
  data() {
    return {
      hide: false,
      inputData: [],
      exec: 0,
      selectedBoutique: [],
      needSync: false
    };
  },

  computed: {
    boutiques() {
      return this.$store.getters["event/queryShops"];
    },
    userInfo() {
      return this.$store.getters["event/getUserInfo"];
    }
  },
  watch: {
    alias() {
      this.setSelectedBoutique();
    },
    selectedBoutique() {
      let value = [];
      if(this.selectedBoutique !== '' && this.alias){
        this.selectedBoutique.forEach((shop_selected) => {
          value.push(shop_selected.alias_value);
        });
      }
      this.$emit("input", value);
      this.$parent.applyFilters();
    }
  },
  methods: {
    deg2rad(deg) {
      return deg * (Math.PI / 180);
    },
    getDistanceLngLatInKm(point1, point2) {
      const lat1 = point1.lat;
      const lon1 = point1.lng;
      const lat2 = point2.lat;
      const lon2 = point2.lng;
      const R = 6371; // Radius of the earth in km
      const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
      const dLon = this.deg2rad(lon2 - lon1);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.deg2rad(lat1)) *
        Math.cos(this.deg2rad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c; // Distance in km
      return d;
    },
    setSelectedBoutique(){
      if(this.alias && this.$route.query[this.alias.label] !== undefined && (this.$route.query[this.alias.label].length > 0 || this.$route.query[this.alias.label] === "")){
        const uuidFromLabel = this.alias.values.filter(value => value.label === this.$route.query[this.alias.label]);
        this.selectedBoutique = uuidFromLabel.length ? uuidFromLabel[0].uuid : '';
      }else if (this.boutiques.length && this.userInfo.location) {
        if (this.getDistanceLngLatInKm(this.boutiques[0].location, this.userInfo.location) <= 10) {
          this.selectedBoutique = this.boutiques[0].id;
          this.needSync = true;
        }
      }
    }
  },
  mounted() {
    this.setSelectedBoutique();
  }
};
</script>

<style lang="scss">
.g {
  .near {
    @media (max-width: 767px) {
      margin-bottom: 24px;
    }
    @media (min-width: 768px) {
      text-align: center;
    }

    h4 {
      color: #000;
      font: {
        weight: bold;
        size: 14px;
      }
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 21px;
    }

    &__input {
      position: relative;
      max-width: 280px;
      @media (min-width: 768px) {
        margin: 0 auto;
      }
      margin-top: 16px !important;

      select {
        display: block;
        width: 100%;
        padding: 9px 20px 9px 10px;
        color: #666;
        font-size: 14px;
        letter-spacing: 1px;
        line-height: 21px;
        border: 1px solid lighten($color: #666, $amount: 40%);
        border-radius: 2px;

        &::placeholder {
          color: #666;
        }
      }

      svg {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%) rotate(180deg);
        pointer-events: none;
      }
    }
  }
}
</style>
