<template>
  <div class="ec">
    <!-- ec for event card | ec--featured -->
    <div class="ec__content">
      <div class="ec__wrapper">
        <div class="ec__ratings" v-if="currentCategData">
          <event-ratings
            v-if="currentCategData"
            :er-data="Number.parseFloat(currentCategData.rating)"
          />
        </div>
        <h3 v-if="currentCategData" class="ec__title">{{ currentCategData.name }}</h3>
        <event-date :ed-data="date" />
        <event-meta :em-data="meta" :ed-data="date" />

        <div class="ec__availability">
          <event-availability :ea-data="availability" :link-data="linkData" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import EventMeta from "@/components/masterclass/EventMeta";
import EventDate from "@/components/masterclass/EventDate";
import EventAvailability from "@/components/masterclass/EventAvailability";
import EventRatings from "@/components/masterclass/EventRatings";

export default {
  name: "EventCard",
  components: {
    EventMeta,
    EventDate,
    EventAvailability,
    EventRatings
  },
  props: {
    ecData: { type: Object, default: null },
    ecategData: { type: Array, default: null }
  },
  data() {
    return {
      ...this.ecData,
      ...this.ecategData
    };
  },
  computed: {
    currentCategData() {
      return _.filter(this.ecategData, { id: this.ecData.themeId })[0];
    },
    linkData() {
      return {
        visible: true,
        text: this.ecData.title,
        route: "/event/" + this.ecData.id
      };
    }
  }
};
</script>

<style lang="scss">
.g {
  .ec {
    background: white;
    color: black;
    border-radius: 2px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;

    .ec__content {
      padding: 20px;
      height: 100%;
    }

    .ec__wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .ec__theme {
      font-size: 14px;
      text-transform: uppercase;
      color: #666666;
    }

    .ec__ratings {
      font-size: 12px;
      font-weight: 700;
      margin: 8px 0 0;
    }

    .ec__title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 3px;
      font-weight: 800;
      text-transform: uppercase;
      margin: 5px 0;
    }

    .ed {
      position: absolute;
      top: 17px;
      right: 20px;
    }

    .ec__availability {
      margin-top: auto;
    }
  }
}
</style>
