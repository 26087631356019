<template>
  <div class="pagination" v-if="totalItems > itemPerPage">
    <a v-if="isSmallScreen" href="#anchorTitleMasterclass"
      class="pagination__item"
      :class="selectedPage === 1 ? 'pagination__item--disabled' : ''"
      @click="goToPage('prev'); setvalue(value - 1)"
    >{{ bricks.events_list_caroussel_prev }}</a>
    <a v-else
      class="pagination__item"
      :class="selectedPage === 1 ? 'pagination__item--disabled' : ''"
      @click="goToPage('prev'); setvalue(value - 1)"
    >{{ bricks.events_list_caroussel_prev }}</a>
    <div class="pagination__number">
      <select class="pagination__dropdown" v-if="isSmallScreen" v-model="selectedPage" @change="goToPage(); goToAnchor()">
        <option v-for="i in getPageCount" :key="i" :value="i">
        &nbsp; {{ i }}
        </option>
      </select>
      <div v-else
        v-for="i in getPageCount"
        :key="i"
        @click="goToPage(i); setvalue(i)"
        class="pagination__number__item"
        :class="selectedPage === i ? 'pagination__number__item--active' : ''"
      >
        {{ i }}
      </div>
    </div>
    <a v-if="isSmallScreen" href="#anchorTitleMasterclass"
      class="pagination__item"
      :class="selectedPage === getPageCount ? 'pagination__item--disabled' : ''"
      @click="goToPage('next'); setvalue(value + 1);"
    >{{ bricks.events_list_caroussel_next }}</a>
    <a v-else
      class="pagination__item"
      :class="selectedPage === getPageCount ? 'pagination__item--disabled' : ''"
      @click="goToPage('next'); setvalue(value + 1)"
    >{{ bricks.events_list_caroussel_next }}</a>
  </div>
</template>

<script>
export default {
  name: "EventPagination",
  props: {
    value: { type: Number, default: null },
    totalItems: {type: Number, default: 9 },
    itemPerPage: { type: Number, default: 9 }
  },
  data() {
    return {
      selectedPage: 1,
      isSmallScreen: false
    };
  },
  computed: {
    bricks() {
      return this.$store.getters["event/getBricks"];
    },
    getPageCount() {
      let nbPage = Math.round(this.totalItems / this.itemPerPage);
      if(this.totalItems % this.itemPerPage > 0){
        nbPage = nbPage + 1;
      }
      return nbPage;
    }
  },
  mounted() {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  },
  methods: {
    setvalue(index) {
      this.selectedPage = index; // Update the selectedPage value
    },
    goToPage(val) {
      this.$emit('changePage', val);
    },
    goToAnchor() {
      const anchorElement = document.getElementById('anchorTitleMasterclass');
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    updateScreenSize() {
      this.isSmallScreen = window.innerWidth < 768;
    },

  }
};
</script>

<style lang="scss">
.pagination {
  display: flex;
  align-items: center;

  &__item {
    color: #707070;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    cursor: pointer;

    &:nth-child(1) {
      text-align: right;
    }

    &--disabled {
      opacity: 0.75;
      pointer-events: none;
    }
  }

  &__dropdown {
    width: 40px;
    height: 40px;
    color: #666666;
    font-size: 14px!important;
    letter-spacing: 1px;
    border: 1px solid #cccccc;
    border-radius: 2px;
    background-color: #ffffff;
    text-indent: 8px;
  }

  &__title {
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 21px;
  }

  &__number {
    display: flex;
    padding: 0 20px;

    &__item {
      position: relative;
      color: #707070;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      padding: 0 12px;
      cursor: pointer;

      &--active {
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 27px;
          height: 27px;
          border: 1px solid #707070;
          border-radius: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>
