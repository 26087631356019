<template>
  <div
    class="search-autocomplete"
    role="combobox"
    aria-expanded="false"
    aria-owns="search-listbox"
    aria-haspopup="listbox"
    >
    <div class="search-autocomplete__input">
      <input
        id="input-42"
        ref="autocomplete"
        placeholder="Search"
        v-model="searchString"
        class="search-location"
        type="search"
        aria-autocomplete="list"
        aria-controls="search-listbox"
        aria-label="TODO: Aria label"
        :aria-expanded="inputIsFocus && items.length ? 'true': 'false'"
        :aria-activedescendant="activeDescendant"
        @focus="inputFocusIn"
        @blur="inputFocusOut"
        autocomplete="off"
      />
      <div class="search-autocomplete__input__icon__wrapper">
        <button class="search-autocomplete__input__icon search-autocomplete__input__icon--cross" v-if="searchString" @click="searchString = ''">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.364 2.05024L11.9497 0.636027L7 5.58577L2.05025 0.636027L0.636039 2.05024L5.58579 6.99999L0.636039 11.9497L2.05025 13.3639L7 8.4142L11.9497 13.3639L13.364 11.9497L8.41421 6.99999L13.364 2.05024Z" fill="black"/>
          </svg>
        </button>
        <div class="search-autocomplete__input__icon search-autocomplete__input__icon--wen" v-else>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.6266 11.5128L16 14.8855L14.8855 16L11.5128 12.6266C10.2579 13.6325 8.69706 14.1797 7.08871 14.1774C3.17574 14.1774 0 11.0017 0 7.08871C0 3.17574 3.17574 0 7.08871 0C11.0017 0 14.1774 3.17574 14.1774 7.08871C14.1797 8.69706 13.6325 10.2579 12.6266 11.5128ZM11.0466 10.9284C12.0462 9.90047 12.6044 8.52254 12.6021 7.08871C12.6021 4.04214 10.1345 1.57527 7.08871 1.57527C4.04214 1.57527 1.57527 4.04214 1.57527 7.08871C1.57527 10.1345 4.04214 12.6021 7.08871 12.6021C8.52254 12.6044 9.90047 12.0462 10.9284 11.0466L11.0466 10.9284Z" fill="black"/>
          </svg>
        </div>
      </div>
    </div>


    <ul v-if="inputIsFocus" role="listbox" id="search-listbox" class="search-autocomplete__results" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" @mousewheel="onMouseWheel">
      <template v-if="!loading">
      <li
        v-for="(item, index) in items"
        :key="item.key"
        class="search-autocomplete__item"
        :class="{active: activeIndex === index }"
        :aria-selected="activeIndex === index ? 'true': 'false'"
        @click="clickOnResult(item)"
        >
        <span class="search-autocomplete__icon" :style="{backgroundImage: `url('${getIcon(item, index)}')`}" />
        <span class="search-autocomplete__text">
          <span class="search-autocomplete__title" v-html="item.main_text" />
          <small class="search-autocomplete__subtitle" >{{item.secondary_text}}</small>
        </span>
        <span class="search-autocomplete__action">
          {{item.apply_text}}
        </span>
      </li>
      </template>
      <li v-else class="search-results__loading"></li>
    </ul>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
/*
https://haltersweb.github.io/Accessibility/autocomplete.html
https://kgotgit.medium.com/web-accessibility-autocomplete-combobox-with-manual-selection-angular-component-part-2-2f7bc1388b59
*/
export default {
  props:{
    value: {type: String},
    results: {type:Object},
    loading: {type: Boolean},
  },
  data(){
    return {
      isMouseHover: false,
      activeIndex: 0,
      inputIsFocus: false,
      icons:{
        filter: { default: require('@/icons/filter.svg'), active: require('@/icons/filter-white.svg') },
        place: { default: require('@/icons/geolocation.svg'), active: require('@/icons/geolocation-white.svg') },
        boutique: { default: require('@/icons/nespresso-n.svg'), active: require('@/icons/nespresso-n-white.svg') },
      }
    }
  },
  computed:{
    searchString:{
      get(){
        return this.value;
      },
      set(val){
        this.inputIsFocus = true
        this.$emit('input', val)
      }
    },
    activeDescendant(){
      // This holds id reference to the listbox option and is an inidiation to SR that reference element is the active descendant container it owns (see aria-owns)
      if (this.items>=0)
        return "search-listbox__" + this.activeIndex;
      return false;
    },
    items(){
      const items = [];

      this.results.filters.forEach(filter => {
        items.push({
          type: 'filter',
          main_text: filter.label,
          secondary_text: filter.type,
          apply_text: this.$i18nSL.applyFilter,
          key: filter.value
        });
      })
      this.results.places.forEach(place => {
        items.push({
          type: 'place',
          main_text: place.structured_formatting.main_text,
          secondary_text: place.structured_formatting.secondary_text,
          apply_text: this.$i18nSL.viewOnMap,
          key: place.place_id
        });
      })

      this.results.boutiques.forEach(boutique => {
        const storeType = boutique.primaryStoreType ? boutique.primaryStoreType.title : "";
        items.push({
          type: 'boutique',
          main_text: boutique.title,
          secondary_text: storeType + " "+ boutique.address.locality,
          apply_text: this.$i18nSL.goToPage,
          key: boutique.slug
        });
      })

      return items;
    }
  },
  watch: {
    items(newVal) {
      if (newVal.length === 0)
        this.activeIndex = 0;
    },
  },
  destroyed() {
    this.inputFocusOut()
  },
  methods:{
    ...mapActions('store_locator', ['activeSpecificFilter', 'updateUrlParams']),
    inputFocusIn() {
      this.inputIsFocus = true
      this.$emit('input', this.searchString)
      document.addEventListener('keydown', this.keyup, false)
    },
    inputFocusOut() {
      if (!this.isMouseHover) {
        this.inputIsFocus = false
      }
      document.removeEventListener('keydown', this.keyup)
    },
    keyup(e) {
      switch (e.code) {
        case 'ArrowDown':
          this.selectNext()
          break
        case 'ArrowUp':
          this.selectPrev()
          break
        case 'Enter':
          this.applySelected()
          break
        case 'Escape':
          // Keep native input search behaviour
          this.searchString = "";
          break
        default:
          return
      }
      e.preventDefault()
    },
    selectNext() {
      if (this.activeIndex < this.items.length - 1)
        this.activeIndex++
    },
    selectPrev() {
      if (this.activeIndex > 0)
        this.activeIndex--
    },
    applySelected() {
      const selected = this.items[this.activeIndex]
      this.handleSelectItem(selected)
    },
    getIcon(item, index){
      if (index === this.activeIndex)
        return this.icons[item.type].active
      
      return this.icons[item.type].default
    },
    updateSearchTerm(updated){
      this.searchString = updated
    },
    handleSelectItem(item){
      switch(item.type){
        case 'boutique':
          this.handleSelectBoutique(item)
          this.updateSearchTerm("");
          break;
        case 'place':
          this.updateSearchTerm(item.main_text);
          this.handleSelectPlace(item)
          break;
        case 'filter':
          this.handleSelectFilter(item)
          this.updateSearchTerm("");
          break;
        default:
          console.error(`Item type: ${item.type} seems not exist`, item)
      }
      this.inputIsFocus = false

    },
    handleSelectBoutique(item) { this.$router.push({ name: 'boutiqueDetail', params: { slug: item.key }, query: this.$route.query }) },
    handleSelectFilter(item) { 
      this.activeSpecificFilter(item.key)
      this.updateUrlParams({router: this.$router, route: this.$route})
    },
    handleSelectPlace(item) { this.$emit('select_place', item) },
    clickOnResult(item) { this.handleSelectItem(item) },
    onMouseEnter() { this.isMouseHover = true },
    onMouseLeave() { 
      this.isMouseHover = false 
      if (this.$refs.autocomplete !== document.activeElement) {
        this.inputIsFocus = false
      }
    },
    onMouseWheel(e) {
      if (e.deltaY > 0) {
        this.selectNext()
      }
      else {
        this.selectPrev()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.search-autocomplete{
  &__results{
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 22;
    background-color: white;
    color: black;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
  }
  &__item{
    cursor: pointer;
    display: flex;
    padding: 10px;
    align-items: center;
    &.active{
      background-color: #8f7247;
      color: #fff;
    }
  }
  &__icon{
    width: 1.4em;
    height: 1.4em;
    background-position: center;
    background-size: contain;
    margin-right: 0.5em;
    background-repeat: no-repeat;
  }
  &__subtitle{
    display: inline-block;
    margin-left: 0.5em;
  }
  &__action{
    margin-left: auto;
  }
  &__input{
  position: relative;
    input {
      width: 100%;
      height: 100%;
      padding: 10px 30px 10px 10px;
      border: 1px solid #666666;
      border-radius: 4px;
      font-size: 14px;
      line-height: 21px;
    }
     &__icon {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }
}
</style>
