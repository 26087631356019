<template>
    <div class="store-locator-row store-locator-row--full">
        <div class="store-locator-row__map">
            <StoreNearYouMap :boutique="boutique" :linkUrl="linkUrl" />
        </div>
        <div class="store-locator-row__content">
            <h3 v-text="title" class="store-locator-row__title" />
            <p
                class="store-locator-row__store-type"
                v-text="brickContent.store_type.name"
            />
            <h4 class="store-locator-row__boutique-title" v-html="boutique.title" />
            <div class="store-locator-row__info">
                <div
                    class="store-locator-row__info__distance"
                    v-if="userPosition"
                >
                    {{ distance }}
                </div>
                <p
                    class="store-locator-row__info__address"
                    v-if="boutique.address.address_line1"
                    v-html="boutique.address.address_line1"
                />
                <p
                    class="store-locator-row__info__city"
                    v-if="boutique.address.locality"
                    v-html="
                        boutique.address.postal_code +
                        ' - ' +
                        boutique.address.locality
                    "
                />
                <p
                    class="store-locator-row__info__phone"
                    v-if="boutique.phone"
                    v-html="'Tel: ' + boutique.phone"
                />
            </div>
            <BoutiqueService :separator="false" :services="boutique.services" />
            <LinkRouter :href="linkUrl">
                {{ linkLabel }}
            </LinkRouter>
        </div>
    </div>
</template>

<script>
import LinkRouter from "@/components/store-locator/partials/LinkRouter";
import BoutiqueService from "@/components/store-locator/partials/BoutiqueService.vue";
import StoreNearYouMap from "@/components/store-locator/brick/StoreNearYouMap.vue";
import { mapState } from "vuex";
import { getBoutiqueDistanceDisplay } from "@/services/utils";

export default {
    components: {
        LinkRouter,
        BoutiqueService,
        StoreNearYouMap,
    },
    props: {
        brickContent: { type: Object },
        boutique: { type: Object },
        linkUrl: { type: String, default: "#" },
        linkLabel: { type: String, default: "Read" },
        title: { type: String, default: "Near you" },
    },
    computed: {
        ...mapState("store_locator", ["userPosition"]),
        distance() {
            return getBoutiqueDistanceDisplay(this.boutique, this.userPosition);
        },
    },
    mounted() {},
};
</script>

<style lang="scss">
.g {
    .store-locator-row {
        position: relative;
        width: 100%;
        right: 0;
        bottom: 0;
        border-radius: 0;
        flex-direction: column-reverse;
        display: flex;
        background: #fff;
        color: #000000;
        @media (min-width: 768px) {
            flex-direction: row;
        }
        &__title {
            font-weight: 300;
            font-size: 36px;
            line-height: 48px;
            letter-spacing: 3px;
            text-transform: uppercase;
            color: #000000;
        }
        &__boutique-title{
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 3px;
          font-weight: 800;
          text-transform: uppercase;
        }

        &__content,
        &__map {
            width: 100%;
            @media (min-width: 768px) {
                width: 50%;
            }
        }

        &__map {
            @media (max-width: 767px) {
              &,
              & .store-near-you-map .vue-map-container{
                min-height: 50vh;
              }
            }

            .store-locator-map {
                left: 0;
                transform: translateX(0);
                width: 100%;
            }
        }

        &__content {
            padding: 100px 60px;

            .services {
                padding-bottom: 30px;
            }
        }


        &__store-type {
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1px;
            line-height: 21px;
        }

        &__info {
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 1px;
            color: #666666;
            padding-bottom: 30px;
            max-width: 350px;
            &__distance {
                background-color: #f2f2f2;
                color: #666666;
                border-radius: 2px;
                padding: 5px;
                float: right;
                margin-top: -5px;
            }
        }
    }
}
</style>
