<template>
  <div class="shop-detail" role="dialog" aria-modal="true" aria-labelledby="shop-name">
    <div class="shop-detail-content">

      <!-- Shop header image -->

      <figure class="shop-img" v-if="location.image && location.image.length > 0">
        <div v-for="image of location.image" :key="image.id" class="img"
          v-lazy:background-image="image.image.uri.full_url">
        </div>
      </figure>

      <!-- Shop title -->
      <h3 class="shop-name" id="shop-name" v-html="location.title"></h3>

      <div class="wrapper-infos">
        <div class="shop-content">

          <!-- Shop complementary informations -->
          <div v-if="location.text" class="shop-complementary-informations" v-html="location.text.processed"></div>
          <div class="shop-base-informations">
            <div>

              <!-- Shop address -->
              <div class="shop-address" v-if="location.address">
                <h4 class="shop-detail-subtitle">{{ $t('map.address') }}</h4>
                <span v-if="location.address.organization">
                  {{ location.address.organization }}
                  <br>
                </span>
                <span v-if="location.address.address_line1">
                  {{ location.address.address_line1 }}
                  <br>
                </span>
                <span v-if="location.address.address_line2">
                  {{ location.address.address_line2 }}
                  <br>
                </span>
                <span v-if="location.address.postal_code || location.address.locality">
                  {{ location.address.postal_code }}
                  {{ location.address.locality }}
                  {{ location.address.country_code ? '(' + location.address.country_code + ')' : ''}}
                  <br>
                </span>
              </div>

              <!-- Shop categories (ie. recycling, nespresso cube, etc.) -->
              <ul v-if="location.categories" class="shop-categories">
                <li v-for="category of location.categories" :key="category.index" class="shop-category">
                  <img v-if="category.pictogram" v-lazy="category.pictogram.image.uri.full_url" :title="category.name"
                    :alt="category.name" />
                  <span v-if="!category.pictogram">{{ category.name }}</span>
                </li>
              </ul>

              <!-- Shop URL -->
              <a v-if="location.link" :href="location.link.uri" class="shop-web g_link"
                target="_blank">{{ location.link.title }}</a>

              <!-- Google maps button for location (visible on desktop) -->
              <a v-if="location.address"
                :href="'https://maps.google.com/maps?daddr=' + inlineAddress + '&amp;t=m&amp;hl=FR'"
                class="shop-direction shop-direction-lg" target="_blank"
                title="En cliquant sur &quot;Directions&quot;, une nouvelle fenêtre s'ouvrira"
                aria-label="En cliquant sur &quot;Directions&quot;, une nouvelle fenêtre s'ouvrira">
                <i class="icon icon_direction">
                  <svg viewBox="0 0 24 24" id="itineraire" width="100%" height="100%">
                    <path
                      d="M14.27 14.067l-.97-.97 1.672-1.671H9.201v3.647H7.83v-5.018h7.142L13.3 8.383l.97-.97 3.327 3.327-3.327 3.327zm9.379-2.913L12.846.35a1.194 1.194 0 0 0-1.691 0L.35 11.154a1.194 1.194 0 0 0 0 1.691l10.804 10.804a1.194 1.194 0 0 0 1.69 0L23.65 12.845c.467-.453.467-1.213-.001-1.691z"
                      fill-rule="evenodd"></path>
                  </svg>
                </i>
                {{ $t('map.directions') }}
              </a>

            </div>
            <div>

              <!-- Shop phone number -->
              <div class="shop-phone" v-if="location.phone">
                <h4 class="shop-detail-subtitle">{{ $t('map.phoneNumber') }}</h4>
                <a :href="'tel:' + phoneNoSpace">Tel. {{ location.phone }}</a>
              </div>

              <!-- Shop opening hours -->
              <h4 class="shop-detail-subtitle" v-if="location.opening_hours">{{ $t('map.openingHours') }}</h4>
              <div class="shop-schedules" v-if="location.opening_hours">
                <span v-html="location.opening_hours.value" />
              </div>

              <!-- Google maps button for location (visible on mobile) -->
              <a v-if="location.address"
                :href="'https://maps.google.com/maps?daddr=' + inlineAddress + '&amp;t=m&amp;hl=FR'"
                class="shop-direction shop-direction-sm" target="_blank"
                title="En cliquant sur &quot;Directions&quot;, une nouvelle fenêtre s'ouvrira"
                aria-label="En cliquant sur &quot;Directions&quot;, une nouvelle fenêtre s'ouvrira">
                <i class="icon icon_direction">
                  <svg viewBox="0 0 24 24" id="itineraire" width="100%" height="100%">
                    <path
                      d="M14.27 14.067l-.97-.97 1.672-1.671H9.201v3.647H7.83v-5.018h7.142L13.3 8.383l.97-.97 3.327 3.327-3.327 3.327zm9.379-2.913L12.846.35a1.194 1.194 0 0 0-1.691 0L.35 11.154a1.194 1.194 0 0 0 0 1.691l10.804 10.804a1.194 1.194 0 0 0 1.69 0L23.65 12.845c.467-.453.467-1.213-.001-1.691z"
                      fill-rule="evenodd"></path>
                  </svg>
                </i>
                {{ $t('map.directions') }}
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Close location detail -->
      <button @click="closeLocation()" class="trigger-close" :aria-label="$t('map.closeDetails')"></button>
    </div>
  </div>
</template>

<script>
import { $ } from "@/jquery";
import A11yUtils from '../../A11yUtils';

export default {
  name: 'LocationDetail',
  props: {
    location: { type: Object, default: null },
    mapHeight: { type: Number, default: null }
  },
  data() {
    return {
      imageHeight: 300,
      // Maximum size of the infos wrapper: mapHeight - (padding + image height)
      maxInfosHeight: this.mapHeight ? this.mapHeight - 300 : 300,
      phoneNoSpace: null,
      inlineAddress: null
    }
  },
  mounted() {
    const that = this;
    // For animation
    setTimeout(() => {
      $('.shop-detail').addClass('open');
    }, 20);

    if (that.location.phone) {
      // Remove spaces in phone number for tel url
      that.phoneNoSpace = that.location.phone.replace(/\s/g, '');
    }

    if (that.location.address) {
      // Join address' parts for direction url
      that.inlineAddress = that.location.address.address_line1 + ', ' + that.location.address.postal_code + ' ' + that.location.address.locality;
    }

    /*
        a11y trap focus
        events listeners,
        note: store keyDownEvents func in a new var, because bind() will change the method signature and removeEventListener will not work
        https://stackoverflow.com/questions/10444077/javascript-removeeventlistener-not-working
        */
    that.trapFocusFunc = that.keyDownEvents.bind(that);
    that.$el.addEventListener("keydown", that.trapFocusFunc);

    this.setInnerFocusables();
    this.innerFocusables[0].focus();
  },
  methods: {
    closeLocation() {
      // For animation
      $('.shop-detail').removeClass('open');

      // Wait for the animation to be finished before setting the animation
      setTimeout(() => this.$emit('close'), 500);

      //a11y
      var that = this;
      that.$el.removeEventListener("keydown", that.trapFocusFunc);
      that.$el.removeEventListener('closeModal', function () { });
      that.$el.removeEventListener('updateModalFocusables', function () { });
    },
    setInnerFocusables() {
      var that = this;
      //check if focusable is hidden or display none, if not, store it
      that.innerFocusables = [].slice.call(
        that.$el.querySelectorAll(A11yUtils.FOCUSABLES)
      ).filter(el => !A11yUtils.isHidden(el));
    },
    keyDownEvents(evt) {
      // First update inner focusables array in case if a new focusable appears in the modal
      this.setInnerFocusables();

      evt = evt || window.event;

      // tab or Maj Tab in elt => capture focus
      if (evt.keyCode === A11yUtils.keyboard.tab && this.innerFocusables.indexOf(evt.target) >= 0) {
        // maj-tab on first element focusable => focus on last
        if (evt.shiftKey) {
          if (evt.target === this.innerFocusables[0]) {
            this.innerFocusables[this.innerFocusables.length - 1].focus();
            evt.preventDefault();
          }
        } else {
          // tab on last element focusable => focus on first
          let lastFocusable = this.innerFocusables[this.innerFocusables.length - 1];

          if (evt.target === lastFocusable) {
            this.innerFocusables[0].focus();
            evt.preventDefault();
          }
        }
      }

      // tab outside elt => put it in focus
      if (evt.keyCode === A11yUtils.keyboard.tab && this.innerFocusables.indexOf(evt.target) === -1) {
        evt.preventDefault();
        this.innerFocusables[0].focus();
      }

      // close the modal on escape
      if (evt.keyCode === A11yUtils.keyboard.esc) {
        evt.preventDefault();
        this.closeModal();
      }
    }
  }
}
</script>
