import axios from "axios";
import merge from "lodash/merge";

import {
  BASIC_AUTH,
  NESPRESSO_STAGING_URL,
  SQLI_STAGING_URL,
} from "./params";

/**
 * Singleton drupal API
 */
export class DrupalApi {

  constructor(url) {
    if (DrupalApi._instance) {
      return DrupalApi._instance;
    }
    if (!url) {
      throw new Error("First init of DrupalApi must take url as parameter");
    }
    this.rootUrl = url;
    this.lang = document.documentElement.lang;
    this.axios = axios;
    DrupalApi._instance = this;
  }

  /**
   * Load data from json and fallback to api if json fail
   * @param {string} id uuid or "all"
   * @param {DrupalJsonContentType} contentType
   * @param {string} fallbackEndpoint
   * @param {DrupalApiOptions} options
   * @returns {Promise<AxiosResponse<any>>}
   */
  async load(id, contentType, fallbackEndpoint) {
    return await this.getApi(fallbackEndpoint);
  }

  /**
   *
   * @param {string} id uuid or "all"
   * @param {DrupalContentType} contentType
   * @returns
   */
  async loadContentType(id, contentType = "page") {
    const NODE_NAME =
      contentType === "kiosk" || contentType === "call_center"
        ? "voting"
        : "node";
    const targetId = id === 'all' ? '' : id;
    const fallbackEndpoint = `${this.lang}/jsonapi/${NODE_NAME}/${contentType}/${targetId}`;
    return this.load(id, contentType, fallbackEndpoint);
  }

  async loadWebform(id) {
    const fallbackEndpoint = `${this.lang}/jsonapi/webform/webform/${id}`;
    return this.load(id, "webform", fallbackEndpoint);
  }
  /**
   * Load taxonmy terms list
   * @param {string} taxonomy
   * @returns
   */
  async loadTerms(taxonomy){
    const fallbackUrl = `${this.lang}/jsonapi/taxonomy_term/${taxonomy}`;
    return this.load("all", taxonomy, fallbackUrl);
  }

  /**
   * Load brick list
   * @param {string} brick
   * @returns
   */
  async loadBrick(brick, brickType){
    const fallbackUrl = `${this.lang}/jsonapi/brick/${brickType}/${brick}`;
    return this.load(brick, 'brick', fallbackUrl);
  }

  /**
   * Call Drupal endpoint with access token
   * @param {string} endpoint
   * @returns {Promise<any>}
   */
  async getApi(endpoint, ) {
    let response;
    let requestOptions = await this.getAxiosOptions();
    let success = false;
    try {
      const axiosResponse = await this.get(endpoint, requestOptions);
      success = axiosResponse.status === 200;
      if (success) {
        response = axiosResponse;
      }
    } catch (e) {
      console.error(e);
    }
    return response;
  }

  /**
   * Call drupal ressource/endpoint without any config
   * @param {string} endpoint
   * @param {AxiosRequestConfig} options
   */
  async get(endpoint, options = {}) {
    return axios.get(this.rootUrl + endpoint, options);
  }

  /**
   * Post request to drupal endpoint without any config
   * @param {string} endpoint
   * @param {Object | FormData} data
   * @param {AxiosRequestConfig} options
   */
  async post(endpoint, data, options = {}) {
    return axios.post(this.rootUrl + endpoint, data, options);
  }
  /**
   * Post request to drupal endpoint with token
   * @param {string} url
   * @param {Object | FormData} data
   * @param {DrupalRequestConfig} options
   * @returns {Promise<AxiosResponse<any>>}
   */
  async postApi(endpoint, data, options = {}) {
    let response;
    const doPost = async (endpoint, data, options) => {
      const axiosOptions = await this.getAxiosOptions();
      const requestOptions = this._merge(axiosOptions, options);
      return this.post(endpoint, data, requestOptions);
    };

    response = await doPost(endpoint, data, options);
    return response;
  }

  /**
   * Function to get paginated content.
   *
   * @param contentType
   *   type of the node.
   * @param options
   *   Api call options (filters, pager, ...).
   * @returns {Promise<*>}
   */
  async getPaginatedList(contentType, options){
    let response;
    let endpoint = `${this.lang}/jsonapi/node/${contentType}`;
    if(options){
      endpoint += '?';
      if(options.filter){
        let i = 0;
        for(const [key, value] of Object.entries(options.filter)){

          //if simple filter building it, if group, diving in group definition.
          if(typeof value === 'object'){
            endpoint += '&filter['+ key +'][group][conjunction]=OR';
            for(const [sub_key, val] of Object.entries(value.values)) {
              endpoint += '&filter['+ key +'_'+ sub_key +'][condition][path]='+value.field_name;
              endpoint += '&filter['+ key +'_'+ sub_key +'][condition][value]='+val;
              endpoint += '&filter['+ key +'_'+ sub_key +'][condition][memberOf]='+key;
            }
          }else{
            if(i > 0){
              endpoint += '&';
            }
            endpoint += 'filter['+key+']='+value+'';
          }
          i++;
        }

        //Always filter event list from today.
        if(contentType === 'event'){
          const now = new Date();
          now.setDate(now.getDate() + 1);
          endpoint += '&filter[date-filter][condition][path]=event_date_and_time.value';
          endpoint += '&filter[date-filter][condition][operator]=%3E';
          endpoint += '&filter[date-filter][condition][value]='+ now.toISOString().split('T')[0];
        }
      }
      if(options.page){
        endpoint += '&page[offset]='+ ((options.page - 1) * 9);
      }

      if(options.orderby){
        endpoint += '&sort[order][path]='+options.orderby;
        endpoint += '&sort[order][direction]='+ options.orderbydirection;
      }
    }
    response = await this.getApi(endpoint);
    return response;
  }

  /**
   *
   * @returns {Promise<any>}
   */
  async getAxiosOptions() {
    let headers = {};
    if (
      this.rootUrl === NESPRESSO_STAGING_URL ||
      this.rootUrl === SQLI_STAGING_URL
    ) {
      headers = {
        Authorization: BASIC_AUTH
      };
    }
    return { headers };
  }

  /**
   * Get list of CSS files to integrate in the HEAD
   * @returns {Promise<any[]>}
   */
  getCSSFiles() {
    let url = `${this.rootUrl}/sites/default/files/jsonApiFile/css/${this.lang}/css.json`;
    const jsonPage = new Request(url);

    return fetch(jsonPage)
      .then(response => response.json())
      .then(data => {
        return data;
      });
  }

  /**
   *
   * @param {number} durationInMinutes
   * @returns {string} query version parameter (p.ex ?v=1237417473)
   */
  cacheBuster(durationInMinutes) {
    const date = new Date();
    if(!durationInMinutes){
      return '?v=' + date.getTime();
    }
    const coeff = 1000 * 60 * durationInMinutes;
    const rounded = new Date(Math.round(date.getTime() / coeff) * coeff);
    return '?v=' + rounded.getTime();
  }

  /**
   * Merge two object recursively
   * @private
   * @param {Object} obj1
   * @param {Object} obj2
   * @returns {Object}
   */
  _merge(obj1, obj2) {
    return merge(Object.assign({}, obj1), Object.assign({}, obj2));
  }
}
