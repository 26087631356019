<template>
  <div class="news-block">
    <button class="news-block__close" @click="handleClickClose">
      <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m26.364 15.0502-1.4143-1.4142-4.9497 4.9498-4.9497-4.9498-1.4143 1.4142 4.9498 4.9498-4.9498 4.9497 1.4143 1.4142 4.9497-4.9497 4.9497 4.9497 1.4143-1.4142-4.9498-4.9497z" fill="currentColor" fill-rule="evenodd"/></svg>
    </button>
    <h2 class="news-block__title" v-text="dataNewsTitle"/>
    <div class="news-block__news">
      <div class="news-block__item" :class="news.length > 1 ? 'news-block__item--multiple' : 'news-block__item--solo'"  v-for="(newsItem , index) in news" :key="index">
        <NewsCard :news="newsItem" :align="news.length > 1 ? 'left' : 'center'" />
      </div>
    </div>
  </div>
</template>
<script>
import NewsCard from "@/components/store-locator/partials/NewsCard";
export default {
  components:{
    NewsCard
  },
  props: {
    dataNews: { type: Array },
    dataNewsTitle: { type: String }
  },
  data(){
    return{
      news: [...this.dataNews]
    }
  },
  methods:{
     handleClickClose(){
        this.$emit('close')
    },
  },
  mounted() {
      this.news.forEach(item => {
        item.img = 'https://source.unsplash.com/user/erondu/600x300'
        item.link = {label: this.$i18nSL.discoverMore, src: "https://www.google.com", target: "_blank"}
      });

  }
}
</script>

<style lang="scss" scoped>
.news-block {
  $blockPadding: 34px;
  position: relative;
  background-color: #fff;
  padding: $blockPadding;
  padding-bottom: 0;
  color: #000;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #666666;

    &:hover { color: #000; }
  }

  &__title {
    font-weight: 300;
    font-size: 36px;
    line-height: 48px;
    letter-spacing: 8px;
    text-transform: uppercase;
    margin-bottom: 32px;
  }

  &__news {
    display: flex;
    overflow-y: visible;
    overflow-x: auto;
    margin-left: -$blockPadding;
    margin-right: -$blockPadding;
    padding-bottom: $blockPadding;

    &::before,
    &::after {
      height: 1px;
      width: $blockPadding;
      content: "\0020";
      display: block;
      flex-shrink: 0;
    }
  }

  &__item {
    display: flex;
    flex-basis: 100%;
    width: 200px;
    max-width: calc(100% - #{2*$blockPadding});

    &--multiple { flex-basis: 60%; }

    @media (max-width: 995px) { flex-shrink: 0; flex-grow: 0; }

    & + & { margin-left: 16px; }
  }
}
</style>
