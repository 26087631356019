<template>
    <section
        :id="brickContent.anchor_identifier"
        class="h h_image_full_width">

        <!-- Video -->
        <div v-if="brickContent.videos">
            <Video :brickContent="brickContent.videos"></Video>
        </div>

        <img v-if="!brickContent.videos" :src="imageDisplayed" alt="">
    </section>
</template>

<script>
import Video from "@/components/Video";

export default {
    name: 'ImageFullWidth',
    props: {
        brickContent: { type: Object, default: null }
    },
    components: {
        Video,
    },
    data() {
        return {
            isLarge: null,
            imageDisplayed: null,
        }
    },
    mounted() {
        if (!this.brickContent.videos && this.brickContent.image && this.brickContent.image.image && this.brickContent.image_mobile && this.brickContent.image_mobile.image) {
            // Listen to window resize
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        }
    },
    methods: {
        handleResize() {
            var breakpoint = 995;
            // If window's width has not been calculated yet
            if (this.isLarge === null) {
                this.imageDisplayed = window.innerWidth > breakpoint ? this.brickContent.image.image.uri.full_url : this.brickContent.image_mobile.image.uri.full_url;
                this.isLarge = window.innerWidth > breakpoint;
            } else if (this.isLarge === true && window.innerWidth <= breakpoint) {
                // If window's width goes under 768px, use small bg
                this.isLarge = false;
                this.imageDisplayed = this.brickContent.image_mobile.image.uri.full_url;
            } else if (this.isLarge === false && window.innerWidth > breakpoint) {
                // If window's width goes over 767px, use large bg
                this.isLarge = true;
                this.imageDisplayed = this.brickContent.image.image.uri.full_url;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.g_image_full_width {
  .g_bg { background-size: cover; }
}
</style>
