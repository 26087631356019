<template>
  <section class="g_section g_light g_products g_commercialOffer g_sust-vote g_sust-call-center">
    <div v-if="isReady" class="g_content">
      <div class="g_text">
        <div class="g_sust-vote-slide-container">
            <div v-for="(project, index) in brickContent.counter_parent" :key="index"
                :class="'g_sust-vote-slide g_sust-vote-slide-' + project.type">
                <div class="g_sust-vote-slide-content" :style="getBackgroundStyle(project)">
                <div class="g_restrict">
                    <div class="g_sust-project-content">
                    <h2 class="g_h2" v-html="project.description_call_center.title"></h2>
                    <p class="g_sust-short-description g_txt_L"
                        v-if="project.description_call_center && project.description_call_center.text"
                        v-html="project.description_call_center.text.value"></p>

                    <img v-if="project.accordion_call_center && project.accordion_call_center.media_image"
                        class="g_sust-project-logo" :src="project.accordion_call_center.media_image.image.uri.full_url" alt="" />

                    <div v-if="project.accordion_call_center && project.accordion_call_center.text"
                        class="g_sust-long-description g_richText g_wysiwyg g_txt_S">
                        <div v-html="project.accordion_call_center.text.value"></div>
                    </div>

                    <div class="g_sust-vote-content">
                        <div class="g_sust-vote-details">
                        <div v-if="project.project_earth_component && project.project_earth_component.text"
                            class="g_sust-legend g_txt_S"
                            v-html="project.project_earth_component.text.value"></div>
                        <img class="g_sust-pictogram"
                            v-if="project.project_earth_component && project.project_earth_component.image"
                            :src="project.project_earth_component.image.image.uri.full_url" alt="" />
                        <div v-if="project.project_earth_component && project.project_earth_component.legend"
                            class="g_sust-legend g_txt_S" v-html="project.project_earth_component.legend.value"></div>
                        </div>
                        <div class="g_sust-vote-form">
                            <label for="userID" class="g_sust-vote-form-label">{{ $t('vote.voteUserId') }}</label>
                            <input type="text" class="g_sust-vote-form-input" :id="'userId-' + project.type">
                            <label for="userZip" class="g_sust-vote-form-label">{{ $t('vote.voteUserZip') }}</label>
                            <input type="text" class="g_sust-vote-form-input" :id="'userZip-' + project.type">
                        </div>
                        <div class="g_sust-vote-action">
                            <button :id="'g_sust-cta-' + index" class="g_sust-cta g_txt_S" :disabled="isSendingVote"
                                v-if="project.cta_call_center" @click="submitVote(project, 'g_sust-cta-' + index)">
                                <span>{{ project.cta_call_center.label }}</span>
                                <div class="g_sust-loader-container">
                                <div class="g_sust-loader"></div>
                                </div>
                            </button>
                            <div v-if="project.cta_call_center" :id="'form-errors-' + project.type" class="form-errors"></div>
                            <div v-if="project.cta_call_center" :id="'form-success-' + project.type" class="form-success"></div>
                            <div class="g_sust-vote-amount" :id="'g_sust-vote-amount-' + project.type">{{ project.vote_amount }}</div>
                            <div v-if="project.counter_earth_component" class="g_sust-vote-legend g_txt_S" v-html="project.counter_earth_component.legend"></div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { $ } from "@/jquery";
import { SustainabilityLoader } from "@/services/sustainability.js";

export default {
  name: "SustainabilityCallCenter",
  props: {
    brickContent: { type: Object, default: null },
    pageType: { type: String, default: null }
  },
  data() {
    return {
      hasVotedFor: null,
      isSendingVote: false,
      isReady: false,
      sustLoader: null,
      refreshVoteDelay: 1000 * 60 * 30
    };
  },
  mounted() {
    this.sustLoader = new SustainabilityLoader();
    if (this.brickContent.counter_parent) {
      this.sustLoader.loadData('project_vote').then(response => {
        this.brickContent.counter_parent.forEach(project => {
          const projectVote = response.data.find(
            item => item.id === project.subject.id
          );
          project.type = projectVote.attributes.name;

          project.components.forEach(component => {
            let key = component.type;
            key = key.replace("custom_entity--", "");
            project[key] = component;
          });

          this.getVoteAmounts();
          this.isReady = true;
        });
      });
    }

    $(".g_sust-vote-amount").each(function() {
      let val = $(this).attr("data-amount").toString();
      var display = "";
      for (var i = 0; i < val.length; i++) {
        if (i == val.length - 4 || i == val.length - 7) {
          display += val.charAt(i) + "’";
        } else {
          display += val.charAt(i);
        }
      }
      $(this).text(display);
    });
  },
  methods: {
    submitVote(project, buttonId) {
      this.isSendingVote = true;
      const $button = $("#" + buttonId);
      $(".g_sust-cta").removeClass("is-clicked");
      const $formErrors = $("#form-errors-" + project.type);
      const $formSuccess = $("#form-success-" + project.type);
      $('.form-errors').removeClass('show');

      $button.addClass("is-clicked");

      this.userId = $('#userId-' + project.type).val();
      this.userZip = $('#userZip-' + project.type).val();

      if (!this.userZip || !this.userId) {
        $formErrors.text(this.$t("vote.voteMissingIdOrZip"));
        $formErrors.addClass("show");
        this.isSendingVote = false;
        return;
      }

      this.sustLoader.submitSustainabilityVote(this.userZip, this.userId, project, this.pageType)
        .then(response => {
          this.isSendingVote = false;

          if (response.data && response.status === 201) {
            $formSuccess.text(this.$t("vote.voteSent"));
            $formSuccess.addClass("show");
            $('.form-errors').removeClass('show');
            $('.g_sust-vote-form-input').val('');

            setTimeout(() => {
              $('.form-success').removeClass('show');
            }, 5000);

          } else {
            console.error("Error while sending vote", response.data);
            $formErrors.text(response.data.error);
            $formErrors.addClass("show");
          }
          return response;
        })
        .catch(error => {
          this.isSendingVote = false;
          console.error("Error while sending vote", error);
          $formErrors.text(error);
          $formErrors.addClass("show");
          return error;
        });
    },
    getBackgroundStyle(project) {
      if (project.background_earth_component && project.background_earth_component.image &&
        project.background_earth_component.image.image && project.background_earth_component.image.image.uri) {
        return "background-image: url(" +project.background_earth_component.image.image.uri.full_url +");";
      } else {
        return "";
      }
    },
    getVoteAmounts() {
        this.sustLoader.loadData('project_vote_amount').then((response) => {
            this.brickContent.counter_parent.forEach((project) => {
                const projectId = project.subject.id;
                const vote = response.data.find(v => v.relationships.project_vote.data.id === projectId);
                const oldProjectVoteAmount = project.vote_amount ? project.vote_amount : 0;

                if (project.type === 'Water') {
                    project.vote_amount = vote.attributes.number_vote * 14;
                } else if (project.type === 'Tree') {
                    project.vote_amount = vote.attributes.number_vote / 20;
                }

                $("#g_sust-vote-amount-" + project.type).each(function() {
                    $(this).prop("Counter", oldProjectVoteAmount).animate(
                        {
                            Counter: project.vote_amount
                        },
                        {
                            duration: 1000,
                            easing: "swing",
                            step: function(now) {
                                let val = Math.ceil(now).toString();
                                var display = "";
                                for (var i = 0; i < val.length; i++) {
                                    if (i == val.length - 4 || i == val.length - 7) {
                                        display += val.charAt(i) + "’";
                                    } else {
                                        display += val.charAt(i);
                                    }
                                }
                            $(this).text(display);
                        }
                    });
                });
            });

            if (this.firstLoad) {
                this.isReady = true;
                this.firstLoad = false;
            }

            setTimeout(() => this.getVoteAmounts(), this.refreshVoteDelay);
        }).catch(error => {
            console.log('Could not get vote amounts', error);
        });
    }
  }
};
</script>
