<template>
  <div class="services" v-if="services.length">
    <hr class="separator" v-if="separator"/>
    <h3 class="services__title" v-if="separator" v-html="'services'"/>
    <div class="services__wrapper">
    <div class="services__item" v-for="(item, index) in services" :key="index" @click="onItemClick(item.link && item.link.url)" :class="{ 'clickable': item.link && item.link.url }">
        <img class="services__icon" v-if="item.icon" :src="item.icon.url" />
        <span class="services__icon services__icon--missing" v-else></span>
        <span class="services__label" v-html="item.title" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BoutiqueService',
  props: {
    separator: { type: Boolean, default: true },
    services: { type: Array, default: null }
  },
  methods: {
    onItemClick(url) {
      if (url) {
        window.location.href = url;
      }
    }
  }
}
</script>
<style lang="scss">
.g {
  .services {
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      padding: 13px 0 0 0;
    }

    &__title {
      margin: 0;
      padding-bottom: 4px;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000;
    }

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 25%;
      padding: 0px 25px 10px 0;
      text-align: center;
      &.clickable {
        cursor: pointer;
      }
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      &--missing{
        border-radius: 200px;
        border: 1px solid #000
      }
    }

    &__label {
      padding-top: 4px;
      font-size: 10px;
      font-weight: 700;
      line-height: 12px;
      color: #000;
    }
  }
}
</style>
