import { $ } from "@/jquery";
import { deserialize } from "./serialize";
import { DrupalApi } from "./drupal_api";
export class PageLoader {
  constructor() {
    this.content = null;
    this.bricks = null;
    this.drupalApi = new DrupalApi();
  }
  /**
   *
   * @param {string} id
   * @param {PageLoaderContentType} contentType
   * @returns
   */
  async loadPage(id, contentType = "page") {
    let response;
    let pageData;
    try {
      response = await this.drupalApi.loadContentType(id, contentType, {
        cacheBuster: 0.01,
      });
    } catch (e) {
      console.error(e);
    }
    pageData = this.processRawResponse(response);
    this.hideLoader();
    return pageData;
  }

  /**
   * Raw page data processing: Custom css insertion, Google Tag Manager, Serialize data
   * @param {AxiosResponse} response
   * @returns {PageLoaderResponse}
   */
  processRawResponse(response) {
    const deserializedData = [deserialize(response.data), response];
    this.content = deserializedData[0];
    this.bricks = this.content.bricks;
    // Add Alt tag to images data
    this.handleAltTags(deserializedData[1].data.included);
    // If custom CSS
    if (this.content.css) {
      this.addCustomCSS(this.content.css);
    }

    // If a Google Tag Manager has been defined for the page
    if (this.content.gtm_code) {
      this.addGoogleTagManager(this.content.gtm_code.code);
    }
    return {
      bricks: this.bricks,
      lang: this.drupalApi.lang,
      content: this.content,
      rootUrl: this.drupalApi.rootUrl
    };
  }

  hideLoader() {
    if ($(".acq-loader-container").length) {
      $(".acq-loader-container").remove();
    }
  }

  /**
   * Add Alt tag to images data
   * The JSON deserializer doesn't keep the alt tags so we have to go and get them from the source response.
   * ! Not a lot of alternative texts are needed, as most images are used as background images, where alt tags are not useful.
   * @param {Array} included Included ressources in page cointent
   */
  handleAltTags(included) {
    if (this.bricks) {
      this.bricks.forEach(brick => {
        if (
          brick.type === "brick--header" &&
          brick.anchor_link &&
          brick.anchor_link.length > 0
        ) {
          brick.anchor_link.forEach(anchor => {
            if (anchor.image) {
              const item = included.find(i => i.id === anchor.image.id);
              if (item) {
                anchor.image.alt = item.relationships.image.data.meta.alt;
              } else {
                anchor.image.alt = "";
              }
            }
          });
        }

        if (
          brick.type === "brick--machine_key_features" &&
          brick.key_features &&
          brick.key_features.length > 0
        ) {
          brick.key_features.forEach(feature => {
            if (feature.pictogram) {
              feature.pictogram.alt = included.find(
                i => i.id === feature.pictogram.id
              ).relationships.image.data.meta.alt;
            }
          });
        }

        if (
          brick.type === "brick--footer_services" &&
          brick.services &&
          brick.services.length > 0
        ) {
          brick.services.forEach(service => {
            if (service.pictogram) {
              service.pictogram.alt = included.find(
                i => i.id === service.pictogram.id
              ).relationships.image.data.meta.alt;
            }
          });
        }
      });
    }
  }

  /**
   * Add custom CSS to the head of the page
   * @param {string} css
   */
  addCustomCSS(css) {
    var style = document.createElement("style");
    style.innerHTML = css;
    document.getElementsByTagName("head")[0].appendChild(style);
  }

  /**
   * Add additional CSS files to the head of the page
   */
  getCSS() {
    this.drupalApi.getCSSFiles().then(response => {
      response.data.sort((a, b) =>
        a.attributes.weight > b.attributes.weight ? -1 : 1
      );

      // Add CSS link in the head for each file
      response.data.forEach(file => {
        var link = document.createElement("link");
        link.href = file.attributes.file_url;
        link.type = "text/css";
        link.rel = "stylesheet";

        document.getElementsByTagName("head")[0].appendChild(link);
      });
    });
  }

  addGoogleTagManager(code) {
    var script = document.createElement("script");
    script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${code}');`;
    document.getElementsByTagName("head")[0].appendChild(script);
  }
}
