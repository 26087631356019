import Vue from "vue";
import VueRouter from "vue-router";
import defaultRoutes from "./default-routes";
import masterclassRoutes from "./masterclass-routes";
import storelocatorRoutes from "./store-locator-routes";

export const getRouter = drupalPage => {
  Vue.use(VueRouter);
  let routes = defaultRoutes;
  Vue.prototype.$isMasterclass = false;
  let mode = 'history';
  if (drupalPage.bricks) {
    drupalPage.bricks.forEach(brick => {
      if (brick.type === "brick--masterclass") {
        Vue.prototype.$isMasterclass = true;
        routes = masterclassRoutes
        mode = 'hash';
      }else if (brick.type === "brick--store_locator") {
        routes = storelocatorRoutes;
        mode = 'hash';
      }
    });
  }

  const router = new VueRouter({
    mode,
    routes
  });

  return router;
};
export default getRouter;
