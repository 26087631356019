export default class A11Utils {
  constructor() {}

  static get keyboard() {
    return {
      back: 8, // delete key on mac
      tab: 9,
      enter: 13,
      shift: 16, // shiftKey = true
      ctrl: 17, // ctrlKey = true
      alt: 18, // (a.k.a. option on Mac) altKey = true
      esc: 27,
      space: 32,
      pageUp: 33, // fn + up on mac
      pageDown: 34, // fn + down on mac
      end: 35, // fn + right on mac
      home: 36, // fn + left on mac
      left: 37,
      up: 38,
      right: 39,
      down: 40,
      del: 46, // fn + delete on mac
      command: 91 // metaKey = true (mac and sun machines)
    }
  }

  static get FOCUSABLES() {
    return "a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]";
  }


  static senseClickOutside($evtTarget, $container) {
    if (($evtTarget).closest($container).length === 0) {
      // click target is outside
      return true;
    }
  }


  static announcements($ariaContainer, textToRead) {
    $ariaContainer.text(textToRead);
    setTimeout(function () {
      $ariaContainer.text('');
    }, 5000);
  }

  static isHidden(el) {
    var style = window.getComputedStyle(el);
    return ((style.display === 'none') || (style.visibility === 'hidden'))
  }


}
