<template>
  <div class="text-insert" :class="'text-insert--'+ position.toLowerCase()" :style="'--secondary-background-color: '+ secondaryBackgroundColor+';'">
    <div class="text-insert__container">
      <div class="text-insert__container__content"
           :style="'--color:'+ background"
           :class="{
       'text-insert__container__content--assert-right': position === 'Right',
       'text-insert__container__content--assert-left': position !== 'Right'
     }">
        <AssertBox
          :position="position"
          :insertText="insertText"
          :assertTextBackgroundColor="assertTextBackgroundColor"
          :assertTextColor="assertTextColor">
        </AssertBox>
        <div class="text">
          <div class="text__container">
            <h2 class="text__container__title" v-html="title" :style="{ color: titleColor }"></h2>
            <div class="text__container__text" :style="{ color: textColor }">
              <div v-html="text"></div>
              <div class="text__container__cta">
                <a class="h_head_cta h-cta" :class="ctaStyle" v-if="cta" :href="cta.uri">{{ cta.title }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssertBox from '@/components/generic/AssertBox.vue';
export default {

  name: 'TextInsert',
  components: {
    AssertBox
  },
  props: {
    brickContent: { type: Object, default: null },
  },
  data() {
    return {
      background: null,
      title: null,
      assertTextBackgroundColor: null,
      assertTextColor: null,
      insertText: '',
      position: '',
      textColor: null,
      text: null,
      titleColor: null,
      cta: null,
      ctaStyle: 'h-cta--'+ this.brickContent.field_cta_style,
      secondaryBackgroundColor: '#fff',
    };
  },
  mounted() {
    this.background = this.brickContent.field_bg_color_text_insert.color;
    this.title = this.brickContent.title;
    this.assertTextBackgroundColor = this.brickContent.field_assert_text_bg_color.color;
    this.assertTextColor = this.brickContent.field_assert_text_color.color;
    this.insertText = this.brickContent.field_insert_text_text_insert.value;
    this.position = this.brickContent.field_position_text_insert;
    this.textColor = this.brickContent.field_text_color_text_insert.color;
    this.text = this.brickContent.field_text_text_insert.value;
    this.titleColor = this.brickContent.field_title_color_text_insert.color;
    this.cta = this.brickContent.field_cta_text_insert;
    if(this.brickContent.field_secondary_background_color){
      this.secondaryBackgroundColor = this.brickContent.field_secondary_background_color.color;
    }
  }
}
</script>
<style lang="scss">
  .text-insert {
    background-color: var(--secondary-background-color);
    padding-bottom: 6.25rem;
    &--left{
      padding-top: 6rem;
      .text{
        @media screen and (min-width: 992px) {
          position: absolute !important;
          right: 0;
          top: 0;
        }
      }
    }
    &--right{
      padding-bottom: 6rem;
      .text{
        position: relative;
        top: 3.125rem;
        @media screen and (min-width: 992px) {
          position: absolute !important;
          left: 0;
          top: 0;
        }
      }
    }

    @media screen and (min-width: 992px) {
      .text{
        margin: 0 !important;
      }
    }

    &__container {
      &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        background-color: var(--color);

        @media screen and (min-width: 992px) {
          max-width: 1200px;
          flex-direction: row;
          align-items: flex-start;
          justify-content: space-between;
          position: relative;
          margin: auto;
          min-height: 600px;
          bottom: 0;
          &:before{
            content: '';
            position: absolute;
            top: 0;
            left: calc(((100vw - 100%) / 2) * -1);
            background-color: var(--color);
            width: 100vw;
            height: 100%;
            z-index: 1;
          }
        }

        .text {
          padding: 1.5rem;
          @media screen and (min-width: 992px) {
            flex: 0 0 50%;
            margin-top: 50px;
            position: relative;
            z-index: 2;
            height: 100%;
            display: flex;
            align-items: center;
          }
          &__container {
            width: 100%;
            margin: auto;

            @media screen and (min-width: 992px) {
              max-width: 467px;
              margin: 0;
              padding: 20px 0;
            }

            &__title {
              font-size: 1.625rem;
              margin-bottom: 1.25rem;
              @media screen and (min-width: 992px){
                font-size: 2.125rem;
              }
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              letter-spacing: 1.7px;
              text-transform: uppercase;
            }

            &__text {
              display: flex;
              flex-direction: column;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 27px;
              letter-spacing: 0.8px;
            }

            &__cta {
              margin: 20px 0;
              padding: 0 0 50px;
              @media screen and (min-width: 992px) {
                padding: 0;
              }
              &__link {
                border: 1px solid #FFFFFF;
                display: block;
                text-align: center;
                @media screen and (min-width: 992px) {
                  display: unset;
                }

                padding: 16px;
                font-variant: small-caps;
                &:hover {
                  background-color: #FFFFFF;
                  color: #000000;
                }

                &--black {
                  border: 1px solid #000000;
                  color: #000000;

                  &:hover {
                    background-color: #000000;
                    color: #FFFFFF;
                  }
                }
              }

            }
          }
        }
        .assert{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 1.5rem;
          @media screen and (min-width: 992px) {
            padding: 0;
            position: relative;
            z-index: 2;
          }
        }

        .assert__container {
          @media screen and (min-width: 992px){
            max-width: 370px;
          }
          top: -50px;
          position: relative;
          padding: 2.375rem 3.125rem;
          @media screen and (min-width: 992px) {
            top: unset;
            position: initial;
          }


          //right variant.
          &--right{
            position: relative;
            top: 100px;
            @media screen and (min-width: 992px) {
              position: absolute;
              top: initial;
              bottom: -2rem;
            }
          }
          &--left{
            @media screen and (min-width: 992px) {
              position: absolute;
              top: initial;
              bottom: -2rem;
            }
          }
        }

        &--assert-right {
          flex-direction: column-reverse;
          @media screen and (min-width: 992px) {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }
</style>
