<template>
    <div>
        <div id="app" class="g app app-content">
            <router-view />
        </div>
    </div>
</template>

<script>
import { $ } from "@/jquery";
export default {
    name: "app",
    beforeCreate() {
        this.$store.commit("event/setPageUrl");
    },
    mounted() {
        // dirty fix v-scroll-reveal bug (add height 100% to the body)
        $("body").css("height", "");
    },
};
</script>

<style lang="scss">
@import "scss/main";
#app.app--closestpoi{
  min-height: 0;
}
</style>
