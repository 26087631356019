<template>
  <div class="hr">
    <div class="hr__content">
      <event-ratings v-if="allData.rating" :er-data="Number.parseFloat(allData.rating)" />
      <h2>{{ allData.title }}</h2>
      <event-social :ei-data="allData.data" />
    </div>
  </div>
</template>

<script>
import EventRatings from "@/components/masterclass/EventRatings";
import EventSocial from "@/components/masterclass/EventSocial";

export default {
  name: "HeroRegistration",
  components: {
    EventSocial,
    EventRatings
  },
  props: {
    eiData: { type: Object, default: null }
  },
  data() {
    return {
      ...this.eiData
    };
  },
  computed: {
    allData: function() {
      return {
        rating: this.eiData.rating,
        title: this.eiData.title,
        data: this.eiData
      };
    }
  }
};
</script>

<style lang="scss">
.g {
  .hr {
    padding: 64px 0 56px 0;
    .hr__content {
      max-width: 482px;
      margin: 0 auto;
          svg, svg path {
            fill: #000;
          }
      .ec__theme {
        margin: 0.125rem 0 0;
        font: {
          size: 1rem;
          weight: 300;
        }
        letter-spacing: 1px;
        line-height: 24px;
        text: {
          transform: uppercase;
          align: center;
        }
        
      }
      h2 {
        margin: 0.5rem 0 0;
        font: {
          size: 3rem;
          weight: bold;
        }
        text: {
          transform: uppercase;
          align: center;
        }
        line-height: 64px;
        letter-spacing: 12px;
        @media screen and (max-width: 767px) {
          font-size: 1.125rem;
          letter-spacing: 3px;
          line-height: 24px;
          max-width: 150px;
          margin: 0 auto;
        }
      }
      text: {
        align: center;
      }
      .er {
        width: fit-content;
        margin: 0 auto;
      }
    }
  }
}
</style>
