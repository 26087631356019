<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_services g_autoHeight g_dark">
        <div class="g_restrict">
            <div class="g_content">
                <div class="g_text">

                    <!-- Title -->
                    <h2 class="g_visually_hidden" v-html="brickContent.title"></h2>

                    <!-- List of services with a link, image, title and description -->
                    <ul class="g_row5">
                        <li v-for="service in brickContent.services" :key="service.index">
                            <a :href="service.link.uri" :title="service.link.title">
                                <span>
                                    <img v-lazy="service.pictogram.image.uri.full_url" :alt="service.pictogram.alt">
                                </span>
                                <h3 class="g_h3">{{ service.name }}</h3>
                                <p class="g_txt_M" v-html="service.description.processed"></p>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'FooterServices',
        props: {
            brickContent: { type: Object, default: null}
        }
    }
</script>
