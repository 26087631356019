<template>
  <div class="swiper-slide nn-slider__slide">
    <div class="nn-slider__slide-img">
      <image-responsive
        :imageMobile="this.imgMobile"
        :image="this.img" />
    </div>
    <div class="nn-slider__slide-content">
      <h3 class="nn-slider__slide-title" v-html="this.title"></h3>
      <div class="nn-slider__slide-subtitle" v-html="this.subtitle"></div>
      <div class="nn-slider__slide-desc" v-html="this.desc"></div>
    </div>
  </div>
</template>
<script>
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
  components: { ImageResponsive },
  props: {
    imgMobile: { type: String, default: null},
    img: { type: String, default: null },
    title: { type: String, default: null },
    desc: { type: String, default: null },
    subtitle: { type: String, default: null }
  }
};
</script>
