<template>
    <section class="g_section g_light g_products g_commercialOffer g_sust-presentation">
        <div class="g_restrict">
            <div class="g_content">
                <div class="g_text">
                    <div class="v_swiper v-swiper-on" tabindex="0">
                        <div class="v_swiperOverflow">
                            <div class="swiper-container"
                                :id="'swiper-' + brickContent.id">
                                <ul class="swiper-wrapper">

                                    <li class="swiper-slide">
                                        <div class="g_sust-presentation-content">
                                            <h1 class="g_h1" v-scroll-reveal="{ mobile: false }" v-html="brickContent.title" data-swiper-parallax="-100" data-swiper-parallax-opacity="0"></h1>

                                            <button v-scroll-reveal="{ delay:100, mobile: false }" v-if="brickContent.video" @click="openVideoModal(this)" aria-haspopup="true" class="g_playVideo g_sust-video-container" :style="'background-image: url(https://img.youtube.com/vi/' + brickContent.video.video_embed.video_id + '/sddefault.jpg)'">
                                                <i class="fn_videoCircle"></i>
                                            </button>

                                            <img v-scroll-reveal="{ delay:200, mobile: false }" data-swiper-parallax="-200" data-swiper-parallax-opacity="0" v-if="brickContent.image" class="g_sust-slogan" :src="brickContent.image.image.uri.full_url" :alt="brickContent.image.image.resourceIdObjMeta.alt">
                                        </div>
                                    </li>

                                    <li class="swiper-slide">
                                        <div class="g_sust-presentation-content g_sust-right">
                                            <img data-swiper-parallax="-200" data-swiper-parallax-opacity="0" v-scroll-reveal="{ delay: 100, mobile: false }" v-if="brickContent.pictogram" class="g_sust-earth-logo" :src="brickContent.pictogram.image.uri.full_url"  :alt="brickContent.pictogram.image.resourceIdObjMeta.alt">
                                            <div v-scroll-reveal="{ mobile: false }" data-swiper-parallax="-100" data-swiper-parallax-opacity="0" class="g_richText g_wysiwyg g_txt_S" v-html="brickContent.text.processed"></div>
                                        </div>
                                    </li>
                                </ul>
                                <div class="swiper-pagination" :id="'swiper-pagination-' + brickContent.id"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <video-modal
            v-if="isModalActive"
            :video="brickContent.video.video_embed"
            @close="closeVideoModal"/>
    </section>
</template>

<script>
import VideoModal from '@/components/VideoModal';
import Swiper from 'swiper/bundle';
import { $ } from "@/jquery";

export default {
    name: 'SustainabilityPresentation',
    components: {
        VideoModal
    },
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            isModalActive: false
        }
    },
    mounted() {
        this.initSwiper();
    },
    methods: {
        closeVideoModal() {
            this.isModalActive = false;
            this.$el.querySelector('.g_playVideo').focus();
        },
        openVideoModal() {
            this.isModalActive = true;
        },
        initSwiper() {
            var $this = this;
            if (this.brickContent.text) {
                var brickId = this.brickContent.id;
                var swiper = new Swiper('#swiper-' + brickId, {
                    autoHeight: true,
                    a11y:false,
                    direction: 'horizontal',
                    loop: false,
                    slidesPerView: 1,
                    spaceBetwen: 0,
                    watchSlidesVisibility:true,
                    keyboard:true,
                    speed: 600,
                    parallax: true,
                    breakpoints: {
                        767: {
                            slidesPerView: 3,
                            parallax: false
                        }
                    },
                    navigation: {
                        nextEl: '.swiper-pagination-' + brickId + '-0',
                        prevEl: '.swiper-pagination-' + brickId + '-1'
                    },
                    pagination: {
                        el: '#swiper-pagination-' + brickId,
                        clickable: true,
                        renderBullet: function (index, className) {
                            return '<span class="' + className + ' swiper-pagination-' + brickId + '-' + index + '"></span>';
                        }
                    },
                    on: {
                        init: function () {
                            $this.showSlider = true;
                            setTimeout(() => {
                                updateTabIndex();
                            }, 300);
                        }
                    }
                });
            } else {
                setTimeout(() => {
                    this.initSwiper();
                }, 50);
            }

            swiper.on('slideChange', function() {
                setTimeout(() => {
                    $('.swiper-slide-active', $('#swiper-' + $this.brickContent.id)).focus();
                    updateTabIndex();
                }, 300);
            })

            function updateTabIndex() {
                var $currrentSWiper = $('#swiper-' + $this.brickContent.id);
                $('.swiper-slide').attr('tabindex', '-1');
                $('.swiper-slide-visible', $currrentSWiper).attr('tabindex','0');
                var $btnPrev = $('.swiper-pagination-' + $this.brickContent.id + '-0');
                var $btnNext = $('.swiper-pagination-' + $this.brickContent.id + '-1');
                $btnPrev.attr('tabindex', '0');
                $btnNext.attr('tabindex', '0');
                $('.swiper-button-disabled').attr('tabindex', '-1');
            }
        }
    }
}
</script>
