<template>
  <div>
    <div class="store-locator" :id="brickContent.anchor_identifier" :class="{'store-locator--with-news': newsVisible}" ref="storeLocatorWrapper">
      <div class="store-locator__content">
        <div class="store-locator__topbar">
            <div class="g_restrict">
            <TopBar />
          </div>
        </div>
        <portal-target name="storelocator-bottom-filter-btn" />
        <div class="store-locator__wrap">
            <div class="store-locator__left">
              <Results />
            </div>
            <div class="store-locator__right">
              <div class="store-locator__container g_restrict">
              <Map v-if="$store.state.store_locator.allBoutiques.length"/>
                <BoutiqueTeaserMobile />
                <div class="store-locator__control">
                  <MapControl />
                </div>
                <div class="store-locator__news" v-if="!isMobile && newsVisible">
                  <NewsBlock @close="closeNews" v-if="brickContent.teasers.length > 0" :dataNews="brickContent.teasers" :dataNewsTitle="brickContent.title"/>
                </div>
              </div>
            </div>
        </div>
      </div>
      <portal-target name="storelocator-bottom" />
      <portal-target name="storelocator-bottom-results" />
      <portal-target name="storelocator-bottom-denied-modal" />
      <portal-target name="storelocator-share" />
    </div>
    <NewsBlock v-if="newsVisible && isMobile" @close="closeNews" :dataNews="brickContent.teasers" :dataNewsTitle="brickContent.title"/>
  </div>
</template>

<script>
import Results from '@/components/store-locator/partials/Results.vue'
import Map from '@/components/store-locator/partials/Map.vue'
import TopBar from '@/components/store-locator/partials/TopBar.vue'
import MapControl from '@/components/store-locator/partials/MapControl.vue'
import NewsBlock from '@/components/store-locator/partials/NewsBlock.vue'
import BoutiqueTeaserMobile from '@/components/store-locator/partials/BoutiqueTeaserMobile.vue'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'StoreLocatorPage',
  components: {
    TopBar,
    Results,
    Map,
    BoutiqueTeaserMobile,
    MapControl,
    NewsBlock
  },
  props: {
    brickContent: {type: Object}
  },
  data(){
    return{
      newsVisible: false,
    }
  },
  provide() {
    return {
      brickContent: this.brickContent
    }
  },
  computed: {
    ...mapState('store_locator', ['isMobile'])
  },
  mounted() {
    this.setVh()

    if(sessionStorage.getItem("hideNewsBlock") !== "1"){
      this.newsVisible = true;
    }
    this.$store.commit("store_locator/setWrapperRef", this.$refs.storeLocatorWrapper )
    window.addEventListener("resize", this.onResize);
    this.setIsMobile();
  },
  destroyed(){
    window.removeEventListener("resize", this.onResize);
  },
  methods:{
    ...mapMutations('store_locator', ['setIsMobile']),
    onResize(){
      this.setIsMobile();
      this.setVh();
    },
    /**
     * Set custom property for vh to avoid extra height with address bar on mobile
     * @see https://stackoverflow.com/a/69367449/2838586
     */
    setVh(){
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      let vh = window.innerHeight * 0.01;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--store-locator-vh', `${vh}px`);
    },
    closeNews(){
        sessionStorage.setItem("hideNewsBlock", "1");
        this.newsVisible = false;
    }
  }
}
</script>

<style lang="scss">
#app{
  &.app--closestpoi{
    position: relative;
    z-index: 0;
  }
}
.g {
  .store-locator {
    position: relative;
    display: flex;
    height: calc(100vh - 50px); /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--store-locator-vh, 1vh) * 100 - 50px);
    overflow: hidden;
    transition: height 0.2s;
    &--with-news{
      height: calc(70vh - 50px); // fallback
      height: calc(var(--store-locator-vh, 1vh) * 70 - 50px);

    }
    @media (min-width: 996px ) {
      height: calc(100vh - 180px); // fallback
      height: calc(var(--store-locator-vh, 1vh) * 100 - 180px);
    }

    &-map {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      @media (min-width: 996px ) {
        left: 50%;
        transform: translateX(-50%);
        width: calc(100vw - 330px);
      }
    }
    &__topbar{
      display: flex;
      position: relative;
      z-index: 30;
      @media (min-width: 996px ) {
        box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
      }
    }
    &__right{
      position: relative;
      height: 100%;
      flex-grow: 1;
    }
    &__left{
      @media (min-width: 996px ) {
        position: relative;
        width: 330px;
        height: 100%;
      }
    }

    &__container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      height: auto;
      flex-grow: 1;
      height: 100%;
      @media (max-width: 995px) {
        margin-top: 50px;
      }
    }
    &__wrap{
      position: relative;
      height: 100%;
      display: flex;

    }
    &__content {
      pointer-events: none;
      width: 100%;
      display: flex;
      flex-direction: column;

      & > * { pointer-events: all; }
    }

    &__control {
      margin-left: auto;
    }

    &__news {
      position: absolute;
      right: 0; bottom: 0; left: 0;
      z-index: 20;

      @media (min-width: 995px) {
        bottom: 20px;
        width: 100%;
        max-width: 1160px;
        padding: 0 60px 0 20px
      }
    }
  }
}
</style>
