<template>
  <div class="g_section g_light event-register">
    <background-parallax :cp-data="BackgroundParallaxProps">
      <div class="g_restrict">
        <back-link :bl-data="backlinkData" :bl-inversed="false" />
        <hero-registration :ei-data="event" />
        <event-ticket :ei-data="event" :eti-data="eventTicketData" />
      </div>
    </background-parallax>
  </div>
</template>

<script>
import { fetchMasterclassEventSingle, setBricks } from "@/services/masterclass";
import BackgroundParallax from "@/components/masterclass/BackgroundParallax";
import BackLink from "@/components/masterclass/BackLink";
import HeroRegistration from "@/components/masterclass/HeroRegistration";
import EventTicket from "@/components/masterclass/EventTicket";

export default {
  name: "MasterclassEventRegister",
  components: {
    BackgroundParallax,
    BackLink,
    HeroRegistration,
    EventTicket,
  },
  props: {
    registrationType: { type: String, default: null },
  },
  data() {
    return {
      event: {},
      eventTicketData: null,
      BackgroundParallaxProps: {
        capsules: [
          {
            url: "img/capsules-gold.png",
            class: "bp__caps--right two-caps",
            start: "top-70",
            y: "10",
          },
          {
            url: "img/capsule-brown.png",
            class: "bp__caps--left one-caps",
            start: "top-10",
            y: "50",
          },
        ],
        trigger: "bpWrapperRegister",
      },
    };
  },
  computed: {
    backlinkData () {
      return {
        visible: true,
        text: this.bricks.field_back_masterclass_text,
        route: "/",
      }
    },
    bricks () {
      return this.$store.getters["event/getBricks"]
    }
  },
  created() {
    this.getEventSingleData(this.$route.params.id);
    this.eventTicketData = this.getEventTicketType();
  },
  methods: {
    getEventSingleData: async function (eventId) {
      const bricks = this.$drupalPage.bricks.find(bricks => bricks.type == "brick--masterclass")
      setBricks(bricks)
      this.$store.commit("event/setBricks", bricks);
      const values = await fetchMasterclassEventSingle(eventId);
      this.constructEventData(values);
    },
    constructEventData: function ($data) {
      let processedDescription = $data.thematic.description ? $data.thematic.description.processed.replace(/<[^>]*>/g, "") : ''
      let fetchRatings;

      if ($data.thematic.rating == null) {
        fetchRatings = 0;
      } else {
        fetchRatings = $data.thematic.rating;
      }

      this.event = {
        title: $data.thematic.name,
        description: processedDescription,
        drupal_internal__nid: $data.drupal_internal__nid,
        rating: fetchRatings,
        date: {
          start: $data.event_date_and_time.value,
          end: $data.event_date_and_time.end_value,
        },
        availability: {
          total: $data.hosting_capacity,
          taken: $data.capacity_taken,
          available: $data.capacity_available,
        },
        meta: {
          city: $data.boutique.city,
          address:
            $data.boutique.address +
            ", " +
            $data.boutique.postal_code +
            " " +
            $data.boutique.city,
          capacity: $data.hosting_capacity,
          lang: $data.language_event,
        },
      };
    },
    getEventTicketType: function () {
      if (this.registrationType == "success") {
        this.eventTicketData = {
          type: "confirm",
          content: {
            at: this.bricks.field_at_text,
            title: this.bricks.field_confirm_title,
            subtitle: "",
            paragraph: "",
            cta: this.bricks.field_add_to_cal_text
          }
        }
      } else if (this.registrationType == "cancel") {
        this.eventTicketData = {
          type: "cancel",
          content: {
            at: this.bricks.field_at_text,
            title: this.bricks.field_cancellation_text,
            subtitle: "",
            paragraph: "",
            cta: this.bricks.field_cancel_button_text,
          },
        };
      } else if (this.registrationType == "review") {
        this.eventTicketData = {
          type: "ratings",
          content: {
            at: this.bricks.field_at_text,
            title: this.bricks.field_rating_text,
            subtitle: this.bricks.field_comments_text,
            paragraph: this.bricks.field_explainations_text,
            cta: this.bricks.field_rating_button_text,
          },
        };
      }

      return this.eventTicketData;
    },
  },
};
</script>

<style lang="scss">
.g {
  .event-register {
    .g_restrict {
      .hr {
        padding-top: 150px;
        @media (min-width: 768px) {
          padding-top: 100px;
        }
      }
    }
  }
}
</style>
