<template>
  <ul class="filter-list" :class="activeFilter.length > 0 ? 'filter-list--active' : ''">
    <template v-for="(filter, index) in activeFilter">
      <template v-if="Number.isInteger(filter)">
        <li v-if="filter !== 0" :key="index" class="filter-list__item">
          <span class="filter-list__item__label" v-html="filter + ' km'" />
          <i class="filter-list__item__cross" @click="deleteFilter(filter)"></i>
        </li>
      </template>
      <template v-else>
        <li :key="index" class="filter-list__item">
          <span class="filter-list__item__label" v-html="filter.label" />
          <i class="filter-list__item__cross" @click="deleteFilter(filter.value)"></i>
        </li>
      </template>
    </template>
  </ul>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'

export default {
  name: 'FilterList',
  computed: {
    filters() { return this.$store.getters["store_locator/getFilter"] },
    activeFilter() {
      let activeFilter = [];
      for (const key in this.filters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, key)) {
          const element = this.filters[key];
          if (Number.isInteger(element))
            activeFilter.push(element)
          else if (element != null)
            activeFilter.push(...element.filter(item => item.active == true))
        }
      }
      return activeFilter
    }
  },
  methods: {
    ...mapActions('store_locator', ['applyFilters', 'updateUrlParams']),
    deleteFilter(value) {
      let filter = _.cloneDeep(this.filters)
      for (const key in filter) {
        if (Object.prototype.hasOwnProperty.call(filter, key)) {
          const element = filter[key]
          if (Number.isInteger(element)) {
            if (filter[key] == value)
              filter[key] = null
          }
          else if (element != null) {
            element.forEach(item => {
              if (item.value == value)
                item.active = false
            })
          }
        }
      }
      this.applyFilters(filter)
      this.updateUrlParams({router: this.$router, route: this.$route})
    }
  }
}
</script>

<style lang="scss">
.g {
  .filter-list {
    display: flex;
    flex-wrap: wrap;
    height: auto;
    min-height: 0;
    max-height: 0;
    padding-bottom: 0;
    transition: all 0.2s ease-in-out;

    &--active {
      padding-bottom: 7px;
      min-height: 40px;
      max-height: 100px;
    }

    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f2f2f2;
      color: #000;
      border-radius: 2px;
      padding: 5px 10px;
      margin: 0 5px 5px 0;

      &__label {
        padding-right: 5px;
        font-size: 13px;
      }

      &__cross {
        position: relative;
        display: block;
        width: 10px;
        height: 10px;
        cursor: pointer;

        &:before, &:after {
          content: ' ';
          position: absolute;
          left: 5px;
          height: 10px;
          width: 2px;
          background-color: #000;
        }

        &:before { transform: rotate(45deg); }

        &:after { transform: rotate(-45deg); }
      }
    }
  }
}
</style>
