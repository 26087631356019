<template>
  <div>
    <background-parallax :cp-data="backgroundParallax">
      <section
        :id="brickContent.anchor_identifier"
        class="g_section g_hero g_mainHeader"
        :class="[
          'g_mainHeader--' + brickContent.theme,
          'g_' + brickContent.theme,
          brickContent.is_mobile_bottom ? 'g_mobileBottom' : ''
        ]"
      >

        <image-responsive
          v-if="brickContent.image"
          :image="brickContent.image.image.image_style_uri['21_9_desktop']"
          :imageTablet="brickContent.image.image.image_style_uri['21_9_tablet']"
          :imageMobile="mobileImage"
        />

        <div class="g_restrict">

          <div :class="['g_restrict', { 'backlink_masterclass': brickContent.isMasterclass }]" v-if="brickContent.backlink">
            <back-link
              v-if="brickContent.backlink"
              :bl-data="brickContent.backlink"
              :bl-inversed="brickContent.theme === 'light' ? false : true"
            />
          </div>

          <div class="g_content" :class="['g_' + brickContent.page]">
            <div class="g_text">
              <!-- Icon Nespresso -->
              <div v-if="brickContent.pictogram" class="pictogram">
                <img :src="brickContent.pictogram.image.uri.full_url" alt="" />
              </div>

              <!-- Title -->
              <h1 class="g_h1" v-html="brickContent.title"  v-if="displayTitle"></h1>

              <!-- Video button if video -->
              <button
                v-if="brickContent.video"
                @click="openVideoModal()"
                class="g_playVideo"
                aria-haspopup="true"
              >
                <span class="g_headline">{{
                    brickContent.video_link_text
                  }}</span>
                <i class="fn_videoCircle"></i>
              </button>
              <!-- Social icon if Social -->
              <event-social
                v-if="brickContent.social"
                :ei-data="brickContent"
                :cl-data="true"
              />

              <!-- Place -->
              <span v-if="brickContent.meta" class="g_place"
              >{{ brickContent.meta.place }}</span
              >

              <!-- Anchor links -->
              <div class="g_anchors" v-if="brickContent.anchor_link && brickContent.anchor_link.length">
                <ul class="g_row">
                  <li
                    v-for="anchor in brickContent.anchor_link"
                    :key="anchor.index"
                  >
                    <button
                      class="g_anchor"
                      @click="goToAnchor(anchor.link.url)"
                    >
                      <div v-if="anchor.image && anchor.image.length && anchor.image[0].image">
                        <!-- Anchor link icon -->
                        <img
                          v-lazy="anchor.image[0].image.uri.full_url"
                          :alt="anchor.image[0].alt"
                        />
                      </div>

                      <!-- Anchor link name -->
                      <span v-html="anchor.link.title"></span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <video-modal
          v-if="isModalActive"
          :video="brickContent.video.is_nespresso_cdn_video ? brickContent.video.video_from_nespresso_cdn : brickContent.video.video_embed"
          :internalVideo="brickContent.video.is_nespresso_cdn_video"
          @close="closeVideoModal"
        />
      </section>
    </background-parallax>
  </div>
</template>

<script>
import { $ } from "@/jquery";
import VideoModal from "@/components/VideoModal";
import EventSocial from "@/components/masterclass/EventSocial";
import BackLink from "@/components/masterclass/BackLink";
import BackgroundParallax from "@/components/masterclass/BackgroundParallax";
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
  name: "MainHeader",
  components: {
    ImageResponsive,
    VideoModal,
    EventSocial,
    BackLink,
    BackgroundParallax
  },
  props: {
    brickContent: { type: Object, default: null },
    backgroundParallax: { type: Object, default: null },
    displayTitle: { type: Boolean, default: true }
  },
  data() {
    return {
      ...this.brickContent,
      ...this.parallaxBool,
      isModalActive: false,
      currentOpeningButton: null,
      mobileImage: this.brickContent.image ? this.brickContent.image.image.image_style_uri['2_3_mobile'] : false,
    };
  },
  methods: {
    closeVideoModal() {
      this.isModalActive = false;
      this.$el.querySelector(".g_playVideo").focus();
    },
    openVideoModal() {
      this.isModalActive = true;
    },
    goToAnchor(dest) {
      if ($(dest).length !== 0) {
        $("html, body").animate({ scrollTop: $(dest).offset().top }, 800);
      }
    }
  },
  mounted() {
    if(this.brickContent.image && this.brickContent.image.field_override_mobile){
      this.mobileImage = this.brickContent.image.mobile_image.image_style_uri.scale_only;
    }
  }
};
</script>

<style lang="scss">
.g {
  .g_hero .g_content.g_event-page {
    padding: 150px 0 100px 0 !important;
    height: 100%;
  }
  .g_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .pictogram {
      margin: 0 auto 10px;
    }
    .g_h1 {
      max-width: 650px;
      font-size: 36px;
      letter-spacing: 9px;
      line-height: 48px;
      margin: 30px 0 0;

      @media screen and (min-width: 600px) {
        font-size: 48px;
        margin: 0 auto;
        letter-spacing: 12px;
        line-height: 64px;
      }
    }
    .g_place {
      display: block;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 2px;
      line-height: 32px;
      margin-top: 20px;

      @media screen and (min-width: 600px) {
        font-size: 24px;
        margin-top: 40px;
      }
    }
    .social-button.white {
      margin: 15px 0 0;

      @media screen and (min-width: 600px) {
        margin: 25px 0 0;
      }
    }
  }
}
.g_mainHeader {
  color: #fff;
  .g_anchors{
    display: flex;
    margin-top: 1.5rem;
    @media screen and (min-width: 996px) {
      margin-top: 3.5rem;
    }
  }
  &--light {
    color: #000;
  }
  .backlink_masterclass {
    max-width: 85rem;
    left: 0;
    right: 0;
  }
  .g_restrict{
    .g_content{
      padding: 0 !important;
      height: 100%;
    }
  }
}
</style>
