<template>
  <div class="map">
    <div class="map__wrapper">
      <GmapMap
        ref="map"
        :center="center"
        :zoom="zoom"
        :options= "{
          styles: styles
        }">
          <GmapMarker
            :key="index"
            v-for="(mark, index) in markers"
            :position="google && new google.maps.LatLng(parseFloat(mapData.lat), parseFloat(mapData.lng))"
            :clickable="true"
            @click="onMarkerClick(mark)"
            :icon="{ url: 'https://www.nespresso.com/storelocator/img/markers/retail.png' }"
          />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import { gmapApi } from "vue2-google-maps";
import MAP_THEME from "@/map_theme.json";

export default {
  name: "MasterclassEventMap",
  props: {
    mapData: { type: Object, default: null }
  },
  data() {
    return {
      ...this.mapData,
      center: { lat: this.mapData.lat, lng: this.mapData.lng },
      zoom: 15,
      styles: MAP_THEME,
      markers: [
        {
          position: { lat: this.mapData.lat, lng: this.mapData.lng },
        }
      ],
      markerOptions: {
        fillColor: "#000000",
      }
    }
  },
  computed: {
    google: gmapApi,
  },
  mounted() {
    this.$refs.map.$mapPromise.then((map) => (this.map = map));
  },
  methods: {
    onMarkerClick(marker) {
      (this.center = marker.position), (this.zoom = this.map.getZoom() + 1);
    },
  },
};
</script>

<style lang="scss">
.g {
  .map {
    position: relative;
    z-index: 1;
    width: 100%;
    box-shadow: 10px 12px 44px 0 rgba(0,0,0,0.07);
    background: black;
    margin: 40px 0 0;

    &::before {
      content: "\0020";
      display: block;
      padding-bottom: 100%;
    }

    @media screen and (min-width: 900px) {
      height: 428px;
      width: 428px;
      margin: 0;
    }

    &__wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .vue-map-container { height: 100%; }
  }
}
</style>
