<template>
    <VideoPlayer :isEmbeded="isEmbeded" v-if="videoMedia" :source="videoMedia.url" :sourceType="videoMedia.type"  :poster="poster" :autoplay="autoplay" :loop="loop" :aspectRatio="aspectRatio"/>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer';
export default {
  name: 'Video',
  props: {
    brickContent: { type: Object, default: null },
    aspectRatio: { type: String, default: '16:9' }
  },
  components: {
    VideoPlayer,
  },
  data() {
    return {
      loop: null,
      autoplay: null,
      videoMedia: null,
      poster: null,
      isEmbeded: false,
      posterMobile: null,
      posterModifier: null,
    };
  },
  mounted() {
    // Set data video information
    this.loop = this.brickContent.autoloop ? this.brickContent.autoloop : false;
    this.autoplay = this.brickContent.autoplay ? this.brickContent.autoplay : false;
    this.poster = this.brickContent.fallback_image ? this.brickContent.fallback_image.image.uri.full_url : null;

    this.posterMobile = this.brickContent.fallback_image_mobile ? this.brickContent.fallback_image_mobile.image.uri.full_url : null;

    // Set Modifier for mobile poster
    this.posterModifier = this.posterMobile ? 'video-player--has-mobile-poster' : '';

    const hasInternal = this.brickContent.internal_video !== null;
    const hasExternal = this.brickContent.external_video !== null;
    const hasYoutube = this.brickContent.youtube_video !== null;

    if (hasInternal) {
      this.videoMedia = {
        url: this.brickContent.internal_video.media_video_file.uri.full_url,
        type: 'internal',
      };
    } else if (hasYoutube) {
      // Set Youtube video url with options
      const muted = this.autoplay ? '&amp;mute=1' : '';
      const autoplay = this.autoplay ? '&amp;autoplay=1' : '';
      const loop = this.loop ? '&amp;loop=1' : '';
      const embedURL = `http://www.youtube.com/embed/${this.brickContent.youtube_video}?${
        (loop, autoplay, muted)
      }&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;controls=0&fs=0`;
      this.videoMedia = {
        url: embedURL,
        type: 'youtube',
      };
      this.isEmbeded = !this.isEmbeded;
    } else if (hasExternal) {
      this.videoMedia = {
        url: this.brickContent.external_video.uri,
        type: 'external',
      };
    }
  },
};
</script>

<style lang="scss">
.video-player {
  //Overload the background of the video poster tag for mobile
  &--has-mobile-poster {
    .plyr__poster {
      @media (max-width: 767px) {
        background-image: var(--background-image-sm) !important;
      }
    }
  }

  .plyr__poster {
    background-size: cover !important;
    z-index: 15;
    transition: all 500ms ease-in-out;
  }

  .plyr--paused {
    .plyr__poster {
      opacity: 1;
    }
  }

  .plyr--playing {
    .plyr__poster {
      pointer-events: none;
    }
  }

  .plyr__control--overlaid[data-plyr='play'] {
    color: #000;
    background: #fff;
    padding: 1rem;
    border-radius: 50%;
    z-index: 20;

    @media (min-width: 768px) {
      padding: 2.5rem;
    }
  }
}
</style>
