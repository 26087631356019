<template>
  <div
    :class="etiData.type == 'ratings' ? 'eti__ratings' : ''"
    class="eti"
  >
    <div class="eti__header">
      <event-date
        class="eti__date"
        :ed-data="eiData.date"
        :cl-data="true"
        v-if="etiData.type == 'ratings'"
      />
      <event-meta
        :em-data="metaData"
        :ed-data="eiData.date"
        v-if="etiData.type == 'ratings'"
      />

      <div
        class="eti__meta"
        v-if="etiData.type == 'ratings'"
      >
        <span v-if="etiData.content && etiData.content.at" class="eti__place">{{ etiData.content.at + " " }}</span>
        <span v-if="eiData.meta && eiData.meta.city" class="eti__place">{{ eiData.meta.city }}</span>
      </div>
    </div>

    <div class="eti__content" v-if="etiData.type == 'ratings'">
      <span class="eti__large-text">{{ etiData.content.title }}</span>
      <event-ratings
        :readable-data="true"
        @change-rate="updateRating"
      />
      <span class="eti__large-text">{{ etiData.content.subtitle }}</span>

      <div class="eti__form">
        <textarea
          class="eti__textarea"
          name="eti__textarea"
          placeholder="Text"
          v-model="comment"
        />
        <p class="eti__paragraph">{{ etiData.content.paragraph }}</p>

        <button
          @click="ctaActions(etiData.type)"
          class="eti__button"
          :class="{'eti__button--loading': isLoading}"
        >
          {{ etiData.content.cta }}
        </button>
      </div>
    </div>

    <div class="eti__content" v-if="etiData.type == 'confirm' || etiData.type == 'cancel'">
      <span class="eti__icon">
        <svg v-if="etiData.type == 'confirm'" width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g transform="translate(-152.000000, -466.000000)" fill="#3D8705" fill-rule="nonzero">
                    <g transform="translate(15.000000, 417.000000)">
                        <g transform="translate(128.000000, 40.000000)">
                            <g transform="translate(11.000000, 11.000000)">
                                <path d="M33,-1.83333333 C13.7620812,-1.83333333 -1.83333333,13.7620812 -1.83333333,33 C-1.83333333,52.2379188 13.7620812,67.8333333 33,67.8333333 C52.2379188,67.8333333 67.8333333,52.2379188 67.8333333,33 C67.8333333,13.7620812 52.2379188,-1.83333333 33,-1.83333333 Z M33,1.83333333 C50.2128747,1.83333333 64.1666667,15.7871253 64.1666667,33 C64.1666667,50.2128747 50.2128747,64.1666667 33,64.1666667 C15.7871253,64.1666667 1.83333333,50.2128747 1.83333333,33 C1.83333333,15.7871253 15.7871253,1.83333333 33,1.83333333 Z"></path>
                                <polygon points="19.0204244 31.2067699 14.6666667 35.7251126 26.0781926 47.6666667 49.8666667 22.8595426 45.5204394 18.3333333 26.0857413 38.6002477"></polygon>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>

        <svg v-if="etiData.type == 'cancel'" width="70px" height="70px" viewBox="0 0 70 70" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g transform="translate(-924.000000, -637.000000)" fill="#DD3232">
                  <g transform="translate(915.000000, 628.000000)">
                      <g transform="translate(7.333333, 7.333333)" fill-rule="nonzero">
                          <path d="M36.6666667,1.83333333 C17.4287479,1.83333333 1.83333333,17.4287479 1.83333333,36.6666667 C1.83333333,55.9045855 17.4287479,71.5 36.6666667,71.5 C55.9045855,71.5 71.5,55.9045855 71.5,36.6666667 C71.5,17.4287479 55.9045855,1.83333333 36.6666667,1.83333333 Z M36.6666667,5.5 C53.8795414,5.5 67.8333333,19.453792 67.8333333,36.6666667 C67.8333333,53.8795414 53.8795414,67.8333333 36.6666667,67.8333333 C19.453792,67.8333333 5.5,53.8795414 5.5,36.6666667 C5.5,19.453792 19.453792,5.5 36.6666667,5.5 Z"></path>
                      </g>
                      <g transform="translate(17.600000, 14.666667)">
                          <path d="M28.2333333,11 L28.2326667,27.5 L44.7333333,27.5 L44.7333333,31.1666667 L28.2326667,31.166 L28.2333333,47.6666667 L24.5666667,47.6666667 L24.5666667,31.166 L8.06666667,31.1666667 L8.06666667,27.5 L24.5666667,27.5 L24.5666667,11 L28.2333333,11 Z" transform="translate(26.400000, 29.333333) rotate(45.000000) translate(-26.400000, -29.333333) "></path>
                      </g>
                  </g>
              </g>
          </g>
        </svg>
      </span>
      <span class="eti__large-text">{{ etiData.content.title }}</span>

      <div class="eti__meta">
        <span v-if="etiData.content && etiData.content.at" class="eti__place">{{ etiData.content.at + " "}}</span>
        <span v-if="eiData.meta && eiData.meta.city" class="eti__place">{{ eiData.meta.city }}</span>
        <event-date v-if="eiData.date" class="eti__date" :ed-data="eiData.date" />
      </div>
      <event-meta :em-data="metaData" :ed-data="eiData.date" />

      <button
        @click="ctaActions(etiData.type)"
        class="eti__button"
        :class="{'eti__button--loading': isLoading}"
      >
        {{ etiData.content.cta }}
      </button>
    </div>
  </div>
</template>

<script>
import { submitMasterclassForms } from '@/services/masterclass'
import EventDate from "@/components/masterclass/EventDate"
import EventMeta from "@/components/masterclass/EventMeta"
import EventRatings from "@/components/masterclass/EventRatings"

export default {
  created() {
    window.scrollTo(0, 0)
  },
  name: 'EventTicket',
  components: {
    EventDate,
    EventMeta,
    EventRatings
  },
  props: {
    eiData: { type: Object, default: null },
    etiData: { type: Object, default: null }
  },
  data() {
    return {
      ...this.eiData,
      ...this.etiData,
      star: 0,
      comment: null,
      brick:  this.$drupalPage.bricks.find(bricks => bricks.type == "brick--masterclass"),
      isLoading: false,
    }
  },
  computed: {
    metaData: function () {
      return {
        time: this.eiData.meta ? this.eiData.meta.time : null,
        lang: this.eiData.meta ? this.eiData.meta.lang : null,
      }
    }
  },
  methods: {
    updateRating: function (value) {
      this.star = value
    },
    ctaActions: function (where) {
      if ( where == 'confirm' ) {
        this.$ics.addEvent(
          this.eiData.meta.lang,
          this.eiData.title,
          this.eiData.description,
          this.eiData.meta.address,
          this.$moment(this.eiData.date.start).format('LLL'),
          this.$moment(this.eiData.date.end).format('LLL'),
          null,
          'Nespresso',
          null
        )

        this.$ics.download('nespresso-masterclass')
      } else if ( where == 'cancel' ) {
        let cancellation = {
          webform_id: 'event_cancellation',
          event_id: this.$route.params.id,
          registration_id: parseInt(this.$route.params.sid)
        }

        this.isLoading = true;
        submitMasterclassForms(cancellation)
          .then(response => {
            this.isLoading = false;
            this.$router.push('/')
            return response
          }).catch(()=>{
            this.isLoading = false
          })
      } else if ( where == 'ratings' ) {
        let rating = {}

        switch (this.brick.events_type) {
          case "external_event":
            rating = {
              webform_id: "event_external_rating",
              rating: this.star,
              comments: this.comment,
              registration: parseInt(this.$route.params.sid),
              event: this.$route.params.id,
              email: this.$route.params.email
            }
            break;
          case "internal_event":
            rating = {
              webform_id: "event_internal_rating",
              rating: this.star,
              comments: this.comment,
              registration: parseInt(this.$route.params.sid),
              event: this.$route.params.id,
              email: this.$route.params.email
            }
            break;
          default:
            rating = {
              webform_id: "event_rating",
              rating: this.star,
              comments: this.comment,
              registration: parseInt(this.$route.params.sid),
              event: this.$route.params.id,
              email: this.$route.params.email
            }
            break;
        }

        this.isLoading = true;
        submitMasterclassForms(rating)
          .then(response => {
            this.$router.push('/')
            this.isLoading = false;
            return response
          }).catch(()=>{
            this.isLoading = false;
          })
      }
    }
  }
}
</script>

<style lang="scss">
.g {
  .eti {
    position: relative;
    background-color: white;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.11);

    &__header {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 60px 0 0;

      .eti__meta {
        position: absolute;
        top: 20px;
        right: 20px;
        font-weight: 300;
        letter-spacing: 1px;
        font-size: 18px;
        margin: 0;

        @media (min-width: 600px) { font-size: 16px; }
      }
    }

    &__date {
      font-size: 18px;
      color: #000000;
      font-weight: 700;
      letter-spacing: 2px;
      line-height: 1.33;

      @media (min-width: 600px) { font-size: 24px; }
    }

    &__large-text {
      display: block;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      letter-spacing: 2px;
      line-height: 1.33;
      font: {
        size: 18px;
        weight: 300;
      }

      @media (min-width: 600px) { font-size: 24px; }
    }

    &__content {
      width: calc(100% - 40px);
      max-width: 500px;
      margin: 0 auto;
      padding: 0 0 50px;
      text-align: center;

      @media (min-width: 600px) { padding: 0 0 100px; }

      .er { margin: 25px 0 30px; }

      .eti__meta {
        margin: 30px 0 0;

        .eti__place {
          font-size: 18px;
          letter-spacing: 1px;
        }

        .eti__date {
          margin-left: 8px;
        }
      }

      .em {
        display: flex;
        justify-content: center;
        margin: 0 0 30px;
      }
    }

    &__icon {
      display: inline-block;
      margin: 0 0 20px;
    }

    &__form {
      textarea {
        margin: 20px 0;
        width: 100%;
        max-width: 300px;
        padding: 14px 10px;
        min-height: 100px;
        font-size: 14px;
        border: 1px solid rgba(#666666, 0.25);
      }
    }

    &__paragraph {
      width: 100%;
      max-width: 400px;
      margin: 0 auto 40px;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 1.33;
    }

    &__ratings {
      .eti__header {
        padding: 60px 0 10px;
        margin: 0 0 25px;
        border-bottom: 1px solid #e6e6e6;

        @media (min-width: 600px) { padding: 25px 0 15px; }
      }
    }

    &__button {
      -webkit-appearance: none;
      border: 1px solid transparent;
      display: inline-block;
      padding: 12px 20px;
      min-width: 200px;
      background: #000000;
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 4px;
      text-align: center;
      transition: all 300ms;

      &--loading{
        opacity: 0.5;
        pointer-events: none;
        position: relative;
        &::before{
          content: "...";
          animation-name: threedot;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          position: absolute;
          right: 1em;
          text-align: left;
          width: 1em;
        }
        &::after{
          content: "...";
          opacity: 0;
        }
      }
      
      &:hover {
        background: #a0804f;
        box-shadow: 0 0.25em 0.5em rgba(0,0,0,.3);
      }
    }
  }
}
@keyframes threedot {
  0%   {content: ""}
  33%   {content: "."}
  66%   {content: ".."}
  100% {background-color: "...";}
}

</style>
