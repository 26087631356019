<template>
  <div>
    <transition name="slide-inout">
      <div class="boutique-detail clean-scroll-bar" v-if="isDetailPage">
        <router-link :to="{ name: 'index', query: $route.query }" @click="handleBackClick" class="boutique-detail__back">
          <svg fill="none" height="40" viewBox="0 0 40 40" width="40" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="m22 25.6569 1.4142-1.4143-4.2426-4.2426 4.2426-4.2426-1.4142-1.4143-4.2426 4.2427-1.4142 1.4142 1.4142 1.4142z" fill="currentColor" fill-rule="evenodd"/></svg>
        </router-link>
        <template v-if="loading">
          <span v-html="'Loading...'"/>
        </template>
        <template v-else>
          <template v-if="boutique">
            <div class="boutique-detail__slider" v-if="!transition && pictures.length > 1">
              <Slick ref="slick" :options="slickOptions">
                <img referrerpolicy="no-referrer" v-for="(picture, index) in pictures" :alt="boutique.name" :src="picture" :key="boutique.id+'-pic-'+index">
              </Slick>
              <div class="slick__arrow__wrapper">
                <i class="slick__arrow slick__arrow--prev">
                  <svg @click="prev" width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 12.1569L7.41421 10.7426L3.17157 6.5L7.41421 2.25736L6 0.843146L1.75736 5.08579L0.343144 6.5L1.75736 7.91421L6 12.1569Z" fill="white"/>
                  </svg>
                </i>
                <i class="slick__arrow slick__arrow--next">
                  <svg @click="next" width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 12.1569L0.585788 10.7426L4.82843 6.5L0.585788 2.25736L2 0.843146L6.24264 5.08579L7.65686 6.5L6.24264 7.91421L2 12.1569Z" fill="white"/>
                  </svg>
                </i>
              </div>
            </div>
            <div v-else-if="pictures.length" class="boutique-detail__picture" :style="{backgroundImage: `url(${pictures[0]})`}">
            </div>
            <div v-else class="boutique-detail__no-picture">
            </div>
            <div class="boutique-detail__content" >
              <div class="boutique-detail__meta">
                <div class="boutique-detail__meta__logo"><img :src="$distUrl + '/front-app/dist/' + logoNespresso" alt=""></div>
                <div class="boutique-detail__meta__star">
                  <EventRatings v-if="boutique.rating"  :er-data="boutique.rating" />
                </div>
                <span class="boutique-detail__meta__status" v-if="boutique.primaryStoreType" v-text="boutique.primaryStoreType.title"/>
              </div>
              <h3 class="boutique-detail__title" v-html="boutique.title"/>
              <div class="boutique-detail__info">
                <div class="boutique-detail__info__distance" v-if="userPosition">{{distance}}</div>
                <p class="boutique-detail__info__address" v-if="boutique.address.address_line1" v-html="boutique.address.address_line1"/>
                <p class="boutique-detail__info__city" v-if="boutique.address.locality" v-html="boutique.address.postal_code+' - '+boutique.address.locality"/>
                <p class="boutique-detail__info__phone" v-if="boutique.phone">
                  {{ this.$i18nSL.tel }}: <a :href="`tel:${boutique.phone}`">{{boutique.phone}}</a>
                </p>
                <ul class="boutique-detail__action">
                  <li v-for="(action, index) in computedActions" :key="index" @click="action.method">
                    <i class="boutique-detail__action__icon" :class="action.label == 'Share' ? 'boutique-detail__action__icon--share' : ''">
                      <img :src="$distUrl+ '/front-app/dist/'+action.icon" alt="">
                    </i>
                    <span v-if="index == '0'" class="boutique-detail__action__label" v-html="brickContent.field_global_texts_directions" />
                    <span v-if="index == '1'" class="boutique-detail__action__label" v-html="brickContent.field_global_texts_share" />
                    <span v-if="index == '2'" class="boutique-detail__action__label" v-html="brickContent.field_global_texts_chat" />
                  </li>
                </ul>
              </div>
              <div class="boutique-detail__opening-time" v-if="boutique.openingTime">
                <hr class="separator" />
                <h3 class="boutique-detail__title" v-html="titles.openingHoursTitle"/>
                <div v-html="boutique.openingTime" />
                <p v-html="titles.specialHoursTitle + '<br/>' + titles.openingHoursMightChangeTitle" />
              </div>
              <BoutiqueService :services="boutique.services"/>
              <div class="boutique-detail__payment-methods" v-if="boutique.paymentMethods.length > 0">
                <hr class="separator" />
                <h3 class="boutique-detail__title" v-text="$i18nSL.paymentMethods"/>
                <ul class="boutique-detail__payment-methods__wrapper">
                  <li v-for="(paymentMethod, index) in boutique.paymentMethods" :key="index" class="boutique-detail__payment-methods__item">
                    <PaymentCard :payment-method="paymentMethod"/>
                  </li>
                </ul>
              </div>
            </div>
          </template>
          <div v-else v-html="'Boutique not found'" />
        </template>
        <portal to="storelocator-share">
          <ShareModale :modale-status="modaleShare" @close="toggleModaleShare"/>
        </portal>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from "vuex"
import EventRatings from '@/components/masterclass/EventRatings'
import Slick from 'vue-slick'
import '@/../node_modules/slick-carousel/slick/slick.css'
import PaymentCard from './PaymentCard.vue'
import ShareModale from './ShareModale.vue'
import {getBoutiqueDistanceDisplay} from "@/services/utils"
import BoutiqueService from './BoutiqueService.vue'
import { $ } from "@/jquery";

export default {
  name: 'BoutiqueDetail',
  components: {
    EventRatings,
    Slick,
    PaymentCard,
    ShareModale,
    BoutiqueService
  },
  data(){
    return{
      boutique: null,
      loading: true,
      transition: false,
      isDetailPage: false,
      distance: 0,
      slug: "null",
      slickOptions: {
        arrows: false,
        dots: false
      },
      logoNespresso: require('../../../icons/logo_nespresso.svg'),
      actions:{
          direction: {
            icon:  require('../../../icons/actions/direction.svg'),
            label: this.$i18nSL.directions,
            method: this.actionDirection
          },
          view3d: {
            icon: require('../../../icons/actions/3dView.svg'),
            label: this.$i18nSL.view3d,
            method: this.action3D
          },
          share: {
            icon: require('../../../icons/actions/share.svg'),
            label: this.$i18nSL.share,
            method: this.toggleModaleShare
          },
          chat: {
            icon: require('../../../icons/actions/chat.svg'),
            label: this.$i18nSL.chat,
            method: this.actionChat
          }
      },
      modaleShare: false
    }
  },
  inject: ['brickContent'],
  watch: {
    $route(to, from) {
      this.onRouteChange(to, from)
    },
    userPosition(to){
      if(to && typeof to.lat !== "undefined" && this.boutique){
        this.distance = getBoutiqueDistanceDisplay(this.boutique, to)
      }
    },

  },
  computed: {
    ...mapState("store_locator", ["userPosition", "storeLocatorService"]),
    titles() {
      let openingHoursTitle = 'opening hours'
      let specialHoursTitle = 'Special hours during public holidays and the day before public holidays.'
      let openingHoursMightChangeTitle = '*Opening hours might change for bank holidays';
      this.$drupalPage.bricks.forEach(brick => {
        if (brick.type === 'brick--store_locator') {
          openingHoursTitle = brick.field_opening_hours || openingHoursTitle;
          specialHoursTitle = brick.field_special_hours || specialHoursTitle;
          openingHoursMightChangeTitle = brick.field_opening_hours_might_change || openingHoursMightChangeTitle;
       }
      });
      return {
        openingHoursTitle,
        specialHoursTitle,
        openingHoursMightChangeTitle
      };
    },
    computedActions(){
      if(!this.boutique || this.loading) return [];
      if(this.boutique.link_3d_view){
        return [
          this.actions.direction,
          this.actions.view3d,
          this.actions.share,
          this.actions.chat
        ]
      }else{
        return [
          this.actions.direction,
          this.actions.share,
          this.actions.chat
        ]
      }
    },
    pictures(){
      if(this.boutique.images.length){
        return this.boutique.images.map(image=>image+'-w991');
      }else if(this.boutique.primaryStoreType && this.boutique.primaryStoreType.picture){
        return [this.boutique.primaryStoreType.picture.url]
      }else{
        return []
      }
    }
  },
  methods: {
    ...mapMutations("store_locator", ["toggleResultList", "updateZoom", "setActiveMarker"]),
    ...mapActions("store_locator", ["boutiqueBySlug", "updateCenter"]),
    convertTo24HourFormat(openingTime) {
      return openingTime.replace(/\b(\d{1,2}):(\d{2})\s*([APMapm]{2})\b/g, (match, p1, p2, p3) => {
        let hour = parseInt(p1, 10);
        const minute = p2;
        
        if (p3.toLowerCase() === "pm" && hour < 12) {
          hour += 12;
        } else if (p3.toLowerCase() === "am" && hour === 12) {
          hour = 0;
        }
        
        return `${hour.toString().padStart(2, '0')}:${minute}`;
      });
    },
    translateOpeningTime(openingTime, langId) {
      const translationMaps = {
        'fr': {
          'Monday': 'Lundi',
          'Tuesday': 'Mardi',
          'Wednesday': 'Mercredi',
          'Thursday': 'Jeudi',
          'Friday': 'Vendredi',
          'Saturday': 'Samedi',
          'Sunday': 'Dimanche',
          'Closed': 'Fermé'
        },
        'de': {
          'Monday': 'Montag',
          'Tuesday': 'Dienstag',
          'Wednesday': 'Mittwoch',
          'Thursday': 'Donnerstag',
          'Friday': 'Freitag',
          'Saturday': 'Samstag',
          'Sunday': 'Sonntag',
          'Closed': 'Geschlossen'
        },
        'it': {
          'Monday': 'Lunedì',
          'Tuesday': 'Martedì',
          'Wednesday': 'Mercoledì',
          'Thursday': 'Giovedì',
          'Friday': 'Venerdì',
          'Saturday': 'Sabato',
          'Sunday': 'Domenica',
          'Closed': 'Chiuso'
        }
      };
      
      const translationMap = translationMaps[langId] || {};
    
      let translatedOpeningTime = openingTime;
    
      Object.keys(translationMap).forEach(key => {
        const regex = new RegExp(key, 'g');
        translatedOpeningTime = translatedOpeningTime.replace(regex, translationMap[key]);
      });
    
      return translatedOpeningTime;
    },
    async loadBoutique(){
      let langId = 'en';
      if (this.storeLocatorService && this.storeLocatorService.lang) {
        langId= this.storeLocatorService.lang;
      } 
      this.boutique =  await this.boutiqueBySlug(this.slug);
      if (this.userPosition && typeof this.userPosition.lat !== "undefined") {
        this.distance = getBoutiqueDistanceDisplay(this.boutique, this.userPosition);
      }

      if (this.boutique && this.boutique.openingTime) {
        const convertedTime = this.convertTo24HourFormat(this.boutique.openingTime);
        this.boutique.openingTime = this.translateOpeningTime(convertedTime, langId);
      }
      
      this.loading = false;
    },
    handleBackClick(){
      this.setActiveMarker(null);
    },
    async onRouteChange(route, from, onCreated=false){
      this.isDetailPage = (route.name === "boutiqueDetail");
      if (this.isDetailPage && from && from.params.slug === route.params.slug)
        return
      this.loading = true;
      this.show = false;
      this.slug =  encodeURI(decodeURI(route.params.slug)).toLowerCase();
      if (this.isDetailPage && this.slug) {
        await this.loadBoutique()
        this.updateCenter(this.boutique.position)
        this.setActiveMarker(this.boutique)
        if (onCreated) {
          this.updateZoom(17)
        }
      }
    },
    next() { this.$refs.slick.next() },
    prev() { this.$refs.slick.prev() },
    actionDirection() {
      window.open(`https://www.google.com/maps/dir/?api=1&destination=${this.boutique.position.lat},${this.boutique.position.lng}`, "_blank")
    },
    action3D() {
      window.open(this.boutique.link_3d_view, "_blank")
    },
    toggleModaleShare() {
      this.modaleShare = !this.modaleShare
    },
    actionChat() {
      $("body")
          .removeClass("livechat-collapsed")
          .addClass("livechat-expanded");
      $("#nespresso-conversational-window")
          .removeClass("nlc-collapse")
          .addClass("nlc-expand");
    }
  },
  created() {
    this.onRouteChange(this.$route, null, true);
  },
}
</script>
<style lang="scss">
.g {
  .separator { margin: 16px 0px; border-color: #E6E6E6; opacity: 0.5; }

  .boutique-detail {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    color: black;
    background: white ;
    z-index: 40;
    overflow: auto;

    &__back {
      svg {
        position: absolute;
        top: 0; left: 0;
        z-index: 41;
        color: black;
        background: white;
      }
    }
    &__picture{
      height: 200px;
      background-size: cover;
      background-position: center;
      img{
        display: block;
        width: auto;
      }
    }
    &__no-picture{
      height: 100px;
    }
    &__slider {
      position: relative;
      height: 200px;
      overflow: hidden;
     .slick-slider, .slick-list, .slick-track, .slick-slide, .slick-slide > div, a, img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .slick__arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: 0; bottom: 0;
        width: 40px;
        height: 40px;

        &--prev { transform: translate(-100%, 0); }

        svg { cursor: pointer; }
      }
    }

    &__content { padding: 0 16px; }

    &__meta {
      line-height: 21px;
      padding-bottom: 8px;

      &__logo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 56px;
        height: 56px;
        background: #000;
        margin: -40px 0 -10px 0;
      }

      &__status {
        color: #666666;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 1px;
        text-transform: uppercase;
      }

      &__star {
        display: flex;
        justify-content: flex-end;
        min-height: 21px;
      }
    }

    &__title {
      margin: 0;
      padding-bottom: 4px;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 3px;
      text-transform: uppercase;
      color: #000;
    }

    &__info {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 1px;
      color: #666666;
      a{
        color: inherit
      }
      &__distance{
        background-color: #f2f2f2;
        color: #666666;
        border-radius: 2px;
        padding: 5px;
        float: right;
        margin-top: -5px;
      }
    }

    &__action {
      display: flex;
      justify-content: space-between;
      padding: 13px 0;
      cursor: pointer;

      & > li {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 25%;
      }

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border: 1px solid #8F7247;
        border-radius: 50%;

        &--share svg { margin-right: 2px; }
      }

      &__label {
        margin-top: 4px;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: #8F7247;
        pointer-events: none;
      }
    }

    &__opening-time {
      font-size: 12px;
      line-height: 16px;
      color: #666666;
    }

    &__payment-methods {
      &__wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 8px;
      }

      &__item {
        width: 25%;
        padding-right: 5%;
        padding-bottom: 2.5%;
        padding-top: 2.5%;
        display: flex;
        justify-content: flex-start;
        &:last-child { padding-right: 0; }
      }
    }

    &__cta {
      display: flex;
      align-items: center;
      padding: 16px 0 0 0;
      justify-content: space-between;
    }
  }

  .slide-inout {
    $duration: 0.3s;
    &-leave-active,
    &-enter-active { transition: transform $duration ease-in-out; }

    // Do not merge similar rules
    &-leave-active { transform: translateX(0); }

    &-enter-active { transform: translateX(-100%); }

    &-enter-to { transform: translateX(0); }

    &-leave-to { transform: translateX(-100%); }
  }
}
</style>
