<template>
    <div class="g_popin g_popinRecipe" @click="closeModal">
        <div class="g_popinOverlay"></div>
        <div class="g_popinFixed">
            <div class="g_popinRestrict" :id="'recipe-' + recipe.sku">

                <!-- Close button -->
                <button class="g_btnRoundS g_btnClose" :aria-label="$t('recipes.modalClose')" @click="closeModal">
                    <span>{{ $t('recipes.modalClose') }}</span>
                    <i class="fn_close"></i>
                </button>

                <div class="g_popinContent">
                    <article class="g_popinContent">

                        <!-- Modal image/video (displayed on small screen only) -->
                        <recipe-modal-header
                            v-if="!isLarge"
                            :video="recipe.attributes.video.url"
                            :image="recipe.attributes.pictures.original"/>

                        <!-- Left column -->
                        <div class="g_leftCol">
                            <div class="g_table">
                                <div class="g_header g_tableRow">
                                    <div class="g_tableCell">
                                        <div class="g_recipeTitle">

                                            <!-- Recipe title -->
                                            <p tabindex="0" class="g_h2" v-html="recipe.attributes.title"></p>
                                            <div class="g_recipeInfos">

                                                <!-- Recipe time -->
                                                <time class="g_recipeInfo g_recipeTime" v-if="recipe.attributes.preparation_time">
                                                    <i class="fn_time"></i>
                                                    <span>{{ recipe.attributes.preparation_time.digits }} {{ recipe.attributes.preparation_time.units }}</span>
                                                </time>

                                                <!-- Recipe difficulty -->
                                                <div class="g_recipeInfo g_recipeDifficulty" v-if="recipe.attributes.difficulty">
                                                    <i class="fn_bean"></i>
                                                    <span>{{ $t('recipes.difficulty') }}: {{ recipe.attributes.difficulty }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="g_main g_tableRow">
                                    <div class="g_tableCell">
                                        <div class="g_naturalScrollContainer">
                                            <div class="g_naturalScroll">
                                                <div class="g_naturalScrollOverflow">
                                                    <div class="g_naturalScrollPadding">
                                                        <div class="g_recipeContent">

                                                            <!-- Recipe ingredients -->
                                                            <div class="g_ingredients" v-if="recipe.attributes.ingredients">
                                                                <p class="g_h3">{{ $t('recipes.ingredients') }}</p>
                                                                <div class="g_txt_S">
                                                                    <p v-html="recipe.attributes.ingredients"></p>
                                                                </div>
                                                            </div>

                                                            <!-- Recipe preparation instructions -->
                                                            <div class="g_preparation" v-if="recipe.attributes.preparation">
                                                                <p class="g_h3">{{ $t('recipes.realisation') }}</p>
                                                                <div class="g_txt_S" v-html="recipe.attributes.preparation"></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Right column -->
                        <div class="g_rightCol">
                            <article class="g_table">
                                <header class="g_tableRow g_headerRow">
                                    <div class="g_tableCell">

                                        <!-- Modal image/video (displayed on large screen only) -->
                                        <recipe-modal-header
                                            v-if="isLarge"
                                            :video="recipe.attributes.video.url"
                                            :image="recipe.attributes.pictures.original"/>
                                    </div>
                                </header>
                                <section class="g_tableRow g_sectionRow" v-if="coffees.length > 0">
                                    <div class="g_tableCell">
                                        <div class="g_naturalScrollContainer">
                                            <div class="g_naturalScroll">
                                                <div class="g_naturalScrollOverflow">
                                                    <div class="g_naturalScrollPadding">
                                                        <div class="g_recipeSection">

                                                            <!-- Recommended coffees -->
                                                            <p class="g_h4">{{ $t('recipes.recommendedCoffee') }}</p>
                                                            <ul class="g_productList">
                                                                <li class="g_productItem" v-for="coffee in coffees" :key="coffee.index">

                                                                    <!-- Coffee information -->
                                                                    <div class="g_table">
                                                                        <div class="g_cell">

                                                                            <!-- Capsule image -->
                                                                            <img v-lazy="'https://www.nespresso.com/' + coffee.images.icon">
                                                                        </div>
                                                                        <div class="g_cell">
                                                                            <div>

                                                                                <!-- Coffee name -->
                                                                                <p class="g_h5" v-html="coffee.name"></p>

                                                                                <!-- Coffee intensity -->
                                                                                <p class="g_txt_XS">
                                                                                    <span>{{ $t('recipes.intensity') }} </span>
                                                                                    <span>{{ coffee.capsuleProperties.intensity }}</span>
                                                                                </p>

                                                                                <!-- Coffee description -->
                                                                                <p class="g_txt_XS" v-html="coffee.headline"></p>
                                                                            </div>
                                                                        </div>

                                                                        <div class="g_cell" :id="'coffee-' + coffee.urlFriendlyName">
                                                                            <div class="g_addToCart g_mini">
                                                                                <div class="g_priceAndButton track-impression-product">
                                                                                    <p class="g_productPrice">{{ currency }} {{ coffee.unitPrice }}</p>
                                                                                    <div :data-product-id="coffee.id" class="add-to-bag" data-button-size="small"></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <footer class="g_tableRow g_footerRow">
                                    <div class="g_tableCell"></div>
                                </footer>
                            </article>
                        </div>
                    </article>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { $ } from "@/jquery";
import RecipeModalHeader from '@/components/recipes/RecipeModalHeader';
import { nextLine } from '@/services/utils';
import { setTimeout } from 'timers';
import { CURRENCY_PREFIX } from '../../services/params';

export default {
    name: 'RecipeModal',
    props: {
        recipe: { type: Object, default: null },
    },
    components: {
        RecipeModalHeader
    },
    data() {
        return {
            isLarge: window.innerWidth > 767 ? true : false,
            coffees: [],
            isDevMode: process.env.NODE_ENV == 'development',
            currency: CURRENCY_PREFIX
        }
    },
    mounted() {
        this.recipe.attributes.preparation = nextLine(this.recipe.attributes.preparation);
        this.recipe.attributes.ingredients = nextLine(this.recipe.attributes.ingredients);

        setTimeout(() => $('.g_popin').addClass('g_popinOpened'), 20);
        $('html').addClass('g_scrollLock');

        // Listen to window resize
        window.addEventListener('resize', this.handleResize);
        this.handleResize();

        this.getCoffees();
    },
    methods: {
        // Get recommended coffees from the recipe
        getCoffees() {
            var $this = this;
            this.recipe.included.forEach(function(inc) {
                if (inc.attributes.sku) {
                    $this.getCoffeeDetails(inc.attributes.sku);
                }
            });
        },
        // Get coffee details from napi (only works on the Nespresso website)
        getCoffeeDetails(sku) {
            window.napi.catalog().getProduct(sku).then((resp) => {
                resp.unitPrice = parseFloat(resp.unitPrice).toFixed(2);
                this.coffees.push(resp);
                this.initMosaic(resp);
            });
        },
        initMosaic(coffee) {
            if (document.getElementById('coffee-' + coffee.urlFriendlyName) !== null) {
                window.mosaic.initializeAllFreeHTMLModules(document.getElementById('coffee-' + coffee.urlFriendlyName));
            } else {
                setTimeout(() => {
                    this.initMosaic(coffee);
                }, 50);
            }
        },
        handleResize() {
            if (this.isLarge === true && window.innerWidth <= 767) {
                // If window is resized under 768: move recipe header to top of modal
                this.isLarge = false;
            } else if (this.isLarge === false && window.innerWidth > 767) {
                // If window is resized over 767: move recipe header in right column
                this.isLarge = true;
            }
        },
        closeModal: function(event) {
            var container = $('.g_popinContent');
            if (!container.is(event.target) && container.has(event.target).length === 0)  {
                $('html').removeClass('g_scrollLock');
                $('.g_popin').removeClass('g_popinOpened');
                setTimeout(() => this.$emit('close'), 300);
            }
        }
    }
}
</script>
