<template>
  <section class="g_section g_light g_products g_commercialOffer g_sust-vote">
    <div v-if="!hasVotedFor && isReady" class="g_content">
      <div class="g_text">
        <div class="v_swiper v-swiper-on" tabindex="0">
          <div class="v_swiperOverflow">
            <div class="swiper-container" :id="'swiper-' + brickContent.id">
              <ul class="swiper-wrapper">
                <li v-for="(project, index) in brickContent.counter_parent" :key="index"
                  :class="'swiper-slide g_sust-vote-slide g_sust-vote-slide-' + project.type">
                  <div class="g_sust-vote-slide-content" :style="getBackgroundStyle(project)">
                    <div class="g_restrict">
                      <div class="g_sust-project-content" v-scroll-reveal="{ delay: 200, duration: 400, mobile: false}"
                        data-swiper-parallax="-100" data-swiper-parallax-opacity="0">
                        <template v-for="component in project.components">
                          <div v-if="component.type === 'custom_entity--description_component_earth'" :key="component.id">
                            <h2 data-swiper-parallax="-200" class="g_h2" v-html="component.title"></h2>
                            <p data-swiper-parallax="-200" class="g_sust-short-description g_txt_L" v-if="component && component.text" v-html="component.text.value"></p>
                            <button v-scroll-reveal="{ delay: 100, mobile: false }"
                                v-if="component && component.video" @click="openVideoModal(this, component.video)"
                                aria-haspopup="true" class="g_playVideo g_sust-video-container"
                                :style="'background-image: url(https://img.youtube.com/vi/' + component.video.video_embed.video_id + '/sddefault.jpg)'">
                                <i class="fn_videoCircle"></i>
                            </button>
                          </div>
                          <div class="g_sust-vote-accordion" v-if="component.type === 'custom_entity--accordion_component_earth'" :key="component.id">
                            <img v-if="component && component.media_image" class="g_sust-project-logo" :src="component.media_image.image.uri.full_url" :alt="component.media_image.image.resourceIdObjMeta.alt" />
                            <div v-if="component && component.text" class="g_sust-long-description g_richText g_wysiwyg g_txt_S" :id="'g_sust-long-description-' + project.type">
                                <div v-html="component.text.value"></div>
                            </div>
                            <button v-if="component" @click="toggleClamp(project.type)" :id="'g_sust-see-more-' + project.type" class="g_sust-see-more" :data-type="project.type">
                                <span>{{ component.see_more_label }}</span>
                                <i class="fn_angleLink"></i>
                            </button>
                          </div>
                          <div v-if="component.type === 'custom_entity--project_earth_component'" :key="component.id" class="g_sust-vote-content">
                            <div class="g_sust-vote-details" v-scroll-reveal="{ mobile: false }">
                              <div v-if="component && component.text" class="g_sust-legend g_txt_S" v-html="component.text.value"></div>
                              <img class="g_sust-pictogram" v-if="component && component.image" :src="component.image.image.uri.full_url" :alt="component.image.image.resourceIdObjMeta.alt" />
                              <div v-if="component && component.legend" class="g_sust-legend g_txt_S" v-html="component.legend.value"></div>
                            </div>
                          </div>
                          <div v-if="component.type === 'custom_entity--cta_earth_component'" :key="component.id" class="g_sust-vote-action">
                            <a class="g_sust-cta g_txt_S" v-if="component && !component.vote_enable && component.link"
                                :href="component.link.uri" v-html="component.label" v-scroll-reveal="{ mobile: false, distance: '50px' }"></a>
                            <button :id="'g_sust-cta-' + index" class="g_sust-cta g_txt_S"
                                :disabled="isSendingVote" v-if="component && component.vote_enable"
                                @click="submitVote(project, 'g_sust-cta-' + index)" v-scroll-reveal="{ mobile: false, distance: '50px' }">
                                <span>{{ component.label }}</span>
                                <div class="g_sust-loader-container">
                                  <div class="g_sust-loader"></div>
                                </div>
                            </button>
                            <div v-if="component" :id="'form-errors-' + project.type"  class="form-errors"></div>
                          </div>
                          <div v-if="component.type === 'custom_entity--counter_earth_component'" :key="component.id">
                              <div class="g_sust-vote-amount" :id="'g_sust-vote-amount-' + project.type" :data-amount="project.vote_amount"></div>
                              <div v-if="component" class="g_sust-vote-legend g_txt_S" v-html="component.legend"></div>
                          </div>
                        </template>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="swiper-pagination" :id="'swiper-pagination-' + brickContent.id"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sustainability-thanks v-if="hasVotedFor" :height="thanksHeight" :project="hasVotedFor" />
    <video-modal
      v-if="isModalActive"
      :video="selectedVideo"
      @close="closeVideoModal" />
  </section>
</template>

<script>
import { $ } from "@/jquery";
import VideoModal from "@/components/VideoModal";
import Swiper from "swiper/bundle";
import { napiUser, napiAddresses } from "./../../../public/json/napi_user";
import SustainabilityThanks from "@/components/sustainability/SustainabilityThanks";
import { SustainabilityLoader } from '@/services/sustainability.js';

export default {
  name: "SustainabilityVote",
  components: {
    VideoModal,
    SustainabilityThanks
  },
  props: {
    brickContent: { type: Object, default: null },
    pageType: { type: String, default: null }
  },
  data() {
    return {
      isModalActive: false,
      selectedVideo: null,
      viewed: false,
      userZip: null,
      userId: null,
      userLoggedIn: false,
      hasVotedFor: null,
      isSendingVote: false,
      isReady: false,
      firstLoad: true,
      thanksHeight: null,
      sustLoader: SustainabilityLoader,
      refreshVoteDelay: 1000 * 60 * 30
    };
  },
  mounted() {
    this.init();
    this.sustLoader = new SustainabilityLoader();

    if (this.brickContent.counter_parent) {
      this.sustLoader.loadData('project_vote').then(response => {
        this.brickContent.counter_parent.forEach(project => {
          const projectVote = response.data.find(item => item.id === project.subject.id);
          project.type = projectVote.attributes.name;
        });
        this.getVoteAmounts();
      });
    }

    $(".g_sust-vote-amount").each(function() {
      let val = $(this).attr("data-amount").toString();
      var display = "";
      for (var i = 0; i < val.length; i++) {
        if (i == val.length - 4 || i == val.length - 7) {
          display += val.charAt(i) + "’";
        } else {
          display += val.charAt(i);
        }
      }
      $(this).text(display);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.equalizeHeading);
  },
  methods: {
    init() {
      if (this.isReady) {
        window.addEventListener("resize", this.equalizeHeading);
        window.addEventListener("scroll", this.handleScroll);
        this.initSwiper();
        this.displaySeeMore();
        this.equalizeHeading();

        if (this.pageType === "email") {
          this.userId = this.$route.query.userId ? this.$route.query.userId : "";
          this.userZip = this.$route.query.zipCode ? this.$route.query.zipCode : "";
        } else {
          this.getUserInfos();
        }
      } else {
        setTimeout(() => this.init(), 500);
      }
    },
    equalizeHeading() {
        let viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        $('.g_h2').css('height', 'auto');
        $('.g_sust-short-description').css('height', 'auto');

        if (viewportWidth > 768) {
          let counterH2 = 0;
          let counterDesc = 0;
          $('.g_sust-vote-slide').each(function() {
            let highestH2 = 0;
            let highestDesc = 0;
            $('.g_h2', this).each(function() {
              counterH2++;
              if ($(this).height() > highestH2) {
                highestH2 = $(this).height(); 
              }
            });
    
            if (counterH2 === 2) {
              $('.g_h2').height(highestH2);
            }
    
            $('.g_sust-short-description', this).each(function() {
              counterDesc++;
              if ($(this).height() > highestDesc) {
                highestDesc = $(this).height(); 
              }
            });
    
            if (counterDesc === 2) {
              $('.g_sust-short-description').height(highestDesc);
            }
          });
        }

    },
    displaySeeMore() {
        const types = ['Water', 'Tree'];
        types.forEach(type => {
          if ($('#g_sust-long-description-' + type) && $('#g_sust-long-description-' + type + '> div').height() < 84) {
            $('#g_sust-see-more-' + type).hide();
          }
        });
    },
    toggleClamp(type) {
      let $label = $("#g_sust-see-more-" + type).children("span").first();
      let $text = $("#g_sust-long-description-" + type);
      const project = this.brickContent.counter_parent.find(p => p.type === type);
      const accordionComponent = project.components.find(item => item.type === 'custom_entity--accordion_component_earth');

      if ($text.hasClass("show-all")) {
        $text.removeClass("show-all");
        const labelMore = accordionComponent.see_more_label;
        $label.text(labelMore);
      } else {
        $text.addClass("show-all");
        const labelLess = accordionComponent.see_less_label;
        $label.text(labelLess);
      }
    },
    getUserInfos() {
      if (typeof window.napi === "undefined") {
        this.userId = napiUser.memberNumber;
        const billingAddress =
          napiUser.checkoutPreferences.defaultBillingAddress;
        napiAddresses.forEach(item => {
          if (item.id === billingAddress) {
            this.userZip = item.zipCode;
          }
        });
      } else {
        window.napi.customer().read().then((value) => {
            this.userLoggedIn = true;
            this.userId = value.memberNumber;
            const billingAddress = value.checkoutPreferences.defaultBillingAddress;
            window.napi
              .customer()
              .getAddresses()
              .then((adressValue) => {
                adressValue.forEach(item => {
                  if (item.id == billingAddress) {
                    this.userZip = item.zipCode;
                  }
                });
              })
              .catch(error => {
                console.log("No addresses found", error);
                this.userZip = null;
              });
          })
          .catch(error => {
            console.log("User not logged in", error);
            this.userLoggedIn = false;
          });
      }
    },
    submitVote(project, buttonId) {
      this.isSendingVote = true;
      const $button = $("#" + buttonId);
      const $voteContainer = $(".g_sust-vote");
      $('.form-errors').text('');
      $('.form-errors').removeClass("show");
      const $formErrors = $("#form-errors-" + project.type);

      const minThanksHeight = $voteContainer.height();
      $voteContainer.css("min-height", minThanksHeight + "px");
      $voteContainer.css("height", "100%");

      this.thanksHeight = minThanksHeight;

      $(".g_sust-cta").removeClass("is-clicked");

      $button.addClass("is-clicked");

      if ((!this.userZip || !this.userId) && this.pageType !== 'email') {
        $formErrors.text(this.$t("vote.voteLoggedIn"));
        $formErrors.addClass("show");
        this.isSendingVote = false;
        return;
      }

      this.sustLoader.submitSustainabilityVote(this.userZip, this.userId, project, this.pageType)
        .then(response => {
          this.isSendingVote = false;

          if (response.data && response.status === 201) {
            $('.form-errors').text('');
            $('.form-errors').removeClass("show");
            this.hasVotedFor = project;
            //a11y focus on success message
            setTimeout(function() {
              $(".g_sust-thanks-message").focus();
            }, 300);
          } else {
            console.error("Error while sending vote", response.data);
            $formErrors.text(this.$t("vote.voteError"));
            $formErrors.addClass("show");

            //a11y focus on error message
            setTimeout(function() {
              $(".form-errors").focus();
            }, 300);
          }
          return response;
        })
        .catch(error => {
          this.isSendingVote = false;
          console.error("Error while sending vote", error);
          $formErrors.text(this.$t("vote.voteError"));
          $formErrors.addClass("show");
          //a11y focus on error message
          $(".form-errors").focus();

          return error;
        });
    },
    getBackgroundStyle(project) {
      const backgroundComponent = project.components.find(comp => comp.type === 'custom_entity--background_earth_component');
      if (backgroundComponent && backgroundComponent.image && backgroundComponent.image.image) {
        return "background-image: url(" + backgroundComponent.image.image.uri.full_url + ");";
      } else {
        return "";
      }
    },
    getVoteAmounts() {
        this.sustLoader.loadData('project_vote_amount').then((response) => {
            this.counter = this.counter + 1;
            this.brickContent.counter_parent.forEach((project) => {
                const projectId = project.subject.id;
                const vote = response.data.find(v => v.relationships.project_vote.data.id === projectId);
                const oldProjectVoteAmount = project.vote_amount ? project.vote_amount : 0;
                if (project.type === 'Water') {
                    project.vote_amount = vote.attributes.number_vote * 14;
                } else if (project.type === 'Tree') {
                    project.vote_amount = vote.attributes.number_vote / 20;
                }

                $("#g_sust-vote-amount-" + project.type).each(function() {
                    $(this).prop("Counter", oldProjectVoteAmount).animate(
                      { Counter: project.vote_amount },
                      {
                        duration: 1000,
                        easing: "swing",
                        step: function(now) {
                          let val = Math.ceil(now).toString();
                          var display = "";
                          for (var i = 0; i < val.length; i++) {
                              if (i == val.length - 4 || i == val.length - 7) {
                                  display += val.charAt(i) + "’";
                              } else {
                                  display += val.charAt(i);
                              }
                          }
                          $(this).text(display);
                        }
                    });
                });
            });

            if (this.firstLoad) {
              this.isReady = true;
              this.firstLoad = false;
            }

            setTimeout(() => this.getVoteAmounts(), this.refreshVoteDelay);
        }).catch(error => {
            console.log('Could not get vote amounts', error);
        });
    },
    isScrolledIntoView(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();
      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();
      return elemBottom <= docViewBottom && elemTop >= docViewTop;
    },
    handleScroll() {
      if ($('.g_sust-vote-amount').length && this.isScrolledIntoView($(".g_sust-vote-amount")) && !this.viewed && !this.hasVotedFor) {
        this.viewed = true;
        $(".g_sust-vote-amount").each(function() {
          $(this).prop("Counter", 0).animate(
              { Counter: $(this).attr("data-amount") },
              { duration: 1000,
                easing: "swing",
                step: function(now) {
                  let val = Math.ceil(now).toString();
                  var display = "";
                  for (var i = 0; i < val.length; i++) {
                    if (i == val.length - 4 || i == val.length - 7) {
                      display += val.charAt(i) + "’";
                    } else {
                      display += val.charAt(i);
                    }
                  }
                  $(this).text(display);
                }
              }
            );
        });
        window.removeEventListener("scroll", this.handleScroll);
      }
    },
    closeVideoModal() {
      this.isModalActive = false;
      this.$el.querySelector(".g_playVideo").focus();
    },
    openVideoModal(el, video) {
      this.isModalActive = true;
      this.selectedVideo = video.video_embed;
    },
    initSwiper() {
      var $this = this;
      if (this.brickContent.counter_parent.length) {
        var brickId = this.brickContent.id;
        var swiper = new Swiper("#swiper-" + brickId, {
          a11y: false,
          direction: "horizontal",
          loop: false,
          slidesPerView: 1,
          speed: 700,
          parallax: true,
          spaceBetwen: 0,
          watchSlidesVisibility: true,
          keyboard: true,
          breakpoints: {
            767: {
              slidesPerView: 3,
              parallax: false
            }
          },
          navigation: {
            nextEl: ".swiper-pagination-" + brickId + "-0",
            prevEl: ".swiper-pagination-" + brickId + "-1"
          },
          pagination: {
            el: "#swiper-pagination-" + brickId,
            clickable: true,
            renderBullet: function(index, className) {
              return (
                '<span class="' + className + " swiper-pagination-" + brickId + "-" + index + '"></span>'
              );
            }
          },
          on: {
            init: function() {
              $this.showSlider = true;
              setTimeout(() => {
                updateTabIndex();
              }, 300);
            }
          }
        });
      } else {
        setTimeout(() => {
          this.initSwiper();
        }, 50);
      }

      swiper.on("slideChange", function() {
        setTimeout(() => {
          $(".swiper-slide-active", $("#swiper-" + $this.brickContent.id)).focus();
          updateTabIndex();
        }, 300);
      });

      function updateTabIndex() {
        var $currrentSWiper = $("#swiper-" + $this.brickContent.id);
        $(".swiper-slide").attr("tabindex", "-1");
        $(".swiper-slide-visible", $currrentSWiper).attr("tabindex", "0");
        var $btnPrev = $(".swiper-pagination-" + $this.brickContent.id + "-0");
        var $btnNext = $(".swiper-pagination-" + $this.brickContent.id + "-1");
        $btnPrev.attr("tabindex", "0");
        $btnNext.attr("tabindex", "0");
        $(".swiper-button-disabled").attr("tabindex", "-1");
      }
    }
  }
};
</script>
