<template>
  <div class="boutique-teaser-mobile" v-if="activeMarker">
    <Cross :onClick="closeModale" />
    <Boutique :data-boutique="activeMarker" :isTeaser="true"/>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex"
import Cross from './Cross'
import Boutique from './Boutique'
export default {
  name: 'BoutiqueTeaserMobile',
  components: {
    Cross,
    Boutique
  },
  data() {
    return {
      boutique: null,
      loading: true,
      isTeaserPage: false,
      slug: "null"
    }
  },
  computed: {
    ...mapState('store_locator', ['activeMarker']),
    isOpen() { return this.$store.getters["store_locator/getBoutiqueTeaserMobileOpen"] }
  },
  methods: {
    ...mapMutations("store_locator", ["toggleResultList", "updateCenter", "updateZoom", "setActiveMarker"]),
    ...mapActions("store_locator", ["boutiqueBySlug"]),
    closeModale() {
      this.setActiveMarker(null)
    }
  },
}
</script>

<style lang="scss">
.g {
  .boutique-teaser-mobile {
    display: block;
    position: absolute;
    bottom: 20px; left: 50%;
    width: 95%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 20;
    padding: 20px;

    @media (min-width: 992px) { display: none; }

    .boutique { &__meta__star { float: none; } }
  }
}
</style>
