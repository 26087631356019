var napiUser = {
  "type": "PersonInfo",
  "memberNumber": "2291094",
  "email": "emilie.bourquin@nespresso.com",
  "checkoutPreferences": {
    "defaultDeliveryAddress": "3029953",
    "defaultBillingAddress": "3029953",
    "defaultPaymentMethod": {
      "id": "8796388019759"
    },
    "expressCheckout": false
  },
  "currency": "CHF",
  "tariff": "195",
  "taxSystem": "1",
  "clubCredit": 0,
  "preferredTechnology": "nesclub2.ch.b2c/machineTechno/original",
  "enabledTechnologies": ["nesclub2.ch.b2c/machineTechno/original"],
  "orderHistorySize": 0,
  "clubMemberStatus": false,
  "eligibleForClubMembership": true,
  "recaptureConsent": false,
  "optIns": ["Email"],
  "limitedProducts": {},
  "extraProductSelections": ["64", "3624"],
  "productSelections": ["64", "3624"],
  "status": "Active",
  "languageOfCorrespondence": "fr_ch",
  "signupDate": "2016-06-09",
  "persistentBasketLoaded": true,
  "selectionIDs": ["15293"],
  "firstName": "Emilie",
  "lastName": "Test",
  "userGroups": {
    "userGroups": [
      "CH_2018_YEP_eligible",
      "npm_usergroup_test",
      "CH_2015_Coop",
      "welcome_offer_2011_CH_eligible_UG",
      "CH_2019_SP_eligible",
      "CH_2017_SP17_eligible",
      "CH_2018_SP18_eligible",
      "CH_2014_YEP",
      "CH_2016_YEP",
      "CH_2019_COOP_eligible",
      "CH_2017_Testing_UG",
      "CH_2019_YEP_eligible",
      "CH_2019_BONUSCARD_eligible",
      "CH_2019_SOHO_PIXIE_eligible",
      "CH_CH_DiscoOffer_campaign_1055_initial_trigger",
      "CH_2019_FALL_eligible",
      "CH_2019_SOHO_LATTISSIMA_eligible",
      "CH_2019_PKZ_eligible",
      "CH_2017_RecyclingBag_marketing_segment",
      "CH_2020_SP_eligible"
    ]
  }
};

var napiAddresses = [{
    "zipCode": "1094",
    "country": {
        "id": "ch",
        "label": "Suisse"
    },
    "civility": {
        "id": "2",
        "label": "Madame"
    },
    "secondPhone": null,
    "phoneticName": "NESPRESSO",
    "city": "Paudex",
    "contactName": "Madame Bourquin Emilie",
    "companyName": "Nespresso",
    "remark": null,
    "type": "COMPANY",
    "firstPhone": {
        "type": {
        "id": "PRIVATE",
        "label": ""
        },
        "country": {
        "id": "ch",
        "label": "Suisse"
        },
        "number": "0213217863"
    },
    "firstName": "Emilie",
    "defaultBilling": true,
    "formattedAddress": "<span><strong>Nespresso Madame Bourquin Emilie</strong></span><span>c/o Nespresso SA</span><span>Route du Lac 3</span><span>1094 Paudex</span><span>Suisse</span>",
    "name": "Nespresso",
    "defaultDelivery": true,
    "addressLine1": "c/o Nespresso SA",
    "addressLine2": "Route du Lac 3",
    "id": "3029953",
    "region": {
        "id": "SR",
        "label": "Suisse Romande"
    },
    "additionalName": null
}];

export { napiUser, napiAddresses };