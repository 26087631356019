<template>
    <section :class="[brickContent.center ? 'h_listofels_center' : '',  brickContent.text_color_black_or_white ? 'h_listofels_dark' : '']" :id="brickContent.anchor_identifier" class="h_listofels" :style="'background-color: ' + brickContent.backgroundcolor.color">
        <div class="h_listofels__container">
          <div class="h_listofels_infos">
            <h3 class="h_head_caption">{{ brickContent.caption }}</h3>
            <h2 class="h_head_title" v-if="brickContent.title" v-html="brickContent.title"></h2>
            <div class="h_listofels_text" v-if="brickContent.text" v-html="brickContent.text.processed"></div>
          </div>
          <div class="h_listofels_content">
            <!-- Swiper for elements aligned left -->
            <div class="v_swiper v_swiper-on" v-if="!brickContent.center" tabindex="0">
              <div class="v_swiperOverflow">
                <div class="swiper-container" :id="'swiper-' + brickContent.id">
                  <ul class="swiper-wrapper">
                    <li class="h_element swiper-slide" v-for="feature in brickContent.key_features" :key="feature.index">
                      <a class="h_element__link" :href="feature.link.uri" v-if="feature.link" style="text-decoration: none;">
                        <img v-if="feature.pictogram && feature.pictogram.image.uri" v-lazy="feature.pictogram.image.uri.full_url" :alt="feature.pictogram.alt">
                        <h4 class="h_element_title" v-if="feature.title" v-html="feature.title"></h4>
                        <div class="h_element_description" v-if="!feature.is_description_hidden && feature.description" v-html="feature.description.processed"></div>
                      </a>

                      <template v-if="!feature.link">
                        <img v-if="feature.pictogram && feature.pictogram.image.uri" v-lazy="feature.pictogram.image.uri.full_url" :alt="feature.pictogram.alt">
                        <h4 class="h_element_title" v-if="feature.title" v-html="feature.title"></h4>
                        <div class="h_element_description" v-if="!feature.is_description_hidden && feature.description" v-html="feature.description.processed"></div>
                      </template>
                    </li>
                  </ul>
                  <div class="swiper-pagination"></div>
                </div>
              </div>
            </div>

            <!-- Simple list for centered elements -->
            <ul v-if="brickContent.center">
              <li class="h_element" v-for="feature in brickContent.key_features" :key="feature.index">
                <a class="h_element__link" :href="feature.link.uri" v-if="feature.link" style="text-decoration: none;">
                  <img v-if="feature.pictogram && feature.pictogram.image.uri" v-lazy="feature.pictogram.image.uri.full_url" :alt="feature.pictogram.alt">
                  <h4 class="h_element_title" v-if="feature.title" v-html="feature.title"></h4>
                  <div class="h_element_description" v-if="!feature.is_description_hidden && feature.description" v-html="feature.description.processed"></div>
                </a>

                <template v-if="!feature.link">
                  <img v-if="feature.pictogram && feature.pictogram.image.uri" v-lazy="feature.pictogram.image.uri.full_url" :alt="feature.pictogram.alt">
                  <h4 class="h_element_title" v-if="feature.title" v-html="feature.title"></h4>
                  <div class="h_element_description" v-if="!feature.is_description_hidden && feature.description" v-html="feature.description.processed"></div>
                </template>
              </li>
            </ul>
          </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/bundle';
import { $ } from "@/jquery";

export default {
    name: 'ListOfElements',
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            listOfElsSwiper: null
        }
    },
    mounted() {
        if (!this.brickContent.center) {
            // Listen to window resize
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        }
    },
    methods: {
        handleResize() {
            if (window.innerWidth <= 995) {
                this.initSwiper();
            } else {
                if (this.listOfElsSwiper) {
                    this.listOfElsSwiper.destroy(true, true);
                }
            }
        },
        initSwiper() {
            var $this = this;
            if (this.brickContent.key_features.length) {
                $this.listOfElsSwiper = new Swiper('#swiper-' + this.brickContent.id, {
                    a11y:false,
                    direction: 'horizontal',
                    loop: false,
                    slidesPerView: 1.5,
                    spaceBetwen: 0,
                    watchSlidesVisibility:true,
                    pagination: {
                        el: ".swiper-pagination",
                    },
                    keyboard:true,
                    on: {
                        init: function () {
                            $this.showSlider = true;
                            setTimeout(() => {
                                updateTabIndex();
                            }, 300);
                        }
                    }
                });
            } else {
                setTimeout(() => {
                    this.initSwiper();
                }, 50);
            }

            $('#swiper-' + $this.brickContent.id).on('slideChange', function() {
                setTimeout(() => {
                    $('.swiper-slide-active', $('#swiper-' + $this.brickContent.id)).focus();
                    updateTabIndex();
                }, 300);
            })

            function updateTabIndex() {
                var $currrentSWiper = $('#swiper-' + $this.brickContent.id);
                $('.swiper-slide').attr('tabindex', '-1');
                $('.swiper-slide-visible', $currrentSWiper).attr('tabindex','0');
                var $btnPrev = $('#swiper-button-prev-' + $this.brickContent.id);
                var $btnNext = $('#swiper-button-next-' + $this.brickContent.id);
                $btnPrev.attr('tabindex', '0');
                $btnNext.attr('tabindex', '0');
                $('.swiper-button-disabled').attr('tabindex', '-1');
            }
        }
    }
}
</script>
