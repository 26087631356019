<template>
  <div>
    <StoreLocator v-if="!loading" :brickContent="brickContent"/>
    <div v-else>
        <div class="acq-loader-container"><Spinner /></div>
    </div>
  </div>
</template>

<script>
import StoreLocator from '@/components/store-locator/StoreLocator'
import Spinner from '@/components/store-locator/partials/Spinner'
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
  name: 'StoreLocatorPage',
  props:{
    brickContent: {type: Object}
  },
  data(){
    return {
      loading: true,
    }
  },
  components: {
    StoreLocator,
    Spinner
  },
  computed: {...mapGetters('store_locator', ['getBoutiques'])},
  methods:{
    ...mapActions('store_locator', ['fetchData', 'initStoreLocatorVuex']),
    ...mapMutations('store_locator', ['setDefaultFilter']),
    async fetch(){
      try {
        await this.fetchData()
        this.setDefaultFilter(this.brickContent.default_filters)
        this.loading = false;
      } catch (ex) {
        console.error(ex);
        console.error(ex.message);
      }
    }
  },
  mounted(){
    this.fetch()
    this.initStoreLocatorVuex()
  }
}
</script>
<style lang="scss">
  .store-locator {
    background: #ffffff;
  }
  .acq-loader-container{width:100%;height:300px;background-color:#000;display:flex;align-items:center;justify-content:center}

</style>

