<template>
    <section :id="brickContent.anchor_identifier" class="g_section g_products g_center g_light">
        <div class="g_restrict" :id="'brick-' + brickContent.id">
            <div class="g_content">
                <div class="g_text">
                    <h2 class="g_h2" v-if="brickContent.title" v-html="brickContent.title"></h2>
                    <!-- Wysiwyg content / Intro to products add to card -->
                    <div class="g_wysiwyg g_products_intro g_txt_XL" v-if="brickContent.description" v-html="brickContent.description"></div>

                    <div class="v_swiper" :class="showSlider ? 'v_swiper-on' : 'v_swiper-off'" tabindex="0">
                        <p class="g_visually_hidden">
                            {{ $t('productsAddToCart.useButtons') }}
                        </p>
                        <button
                            :class="{ 'hide-mobile': !this.showMobilePagination, 'hide-desktop': !this.showPagination }"
                            :id="'swiper-button-prev-' + brickContent.id"
                            class="swiper-button-prev swiper-button-disabled"
                        >
                            <span class="g_visually_hidden">{{ $t('productsAddToCart.previousSlide') }} </span>
                        </button>
                        <div class="v_swiperOverflow">
                            <div class="swiper-container" :id="'swiper-' + brickContent.id">
                                <ul class="swiper-wrapper" v-if="this.brickContent.products.length === this.slides.length">
                                    <li v-for="(product, index) in this.brickContent.products" :key="product.index" class="swiper-slide">
                                        <span class="g_visually_hidden">{{ $t('productsAddToCart.currentSlide') }} {{ index + 1 }} / {{ product.length }}</span>
                                        <div class="g_bannerProduct g_itemTable" v-if="getProductData(product).status !== false && brickContent.display_button === true && brickContent.display_popin !== true">
                                            <product :slide="getProductData(product)" :brickContent="brickContent" />
                                        </div>
                                        <button class="g_bannerProduct g_itemTable" v-if="getProductData(product).status !== false && brickContent.display_popin === true" @click="openProductModal(getProductData(product), $event)">
                                            <product :slide="getProductData(product)" :brickContent="brickContent" />
                                        </button>
                                        <a
                                            class="g_bannerProduct g_itemTable"
                                            v-if="getProductData(product).status !== false && brickContent.display_button === false && brickContent.display_popin !== true"
                                            :href="getProductData(product).pdpURLs.desktop"
                                        >
                                            <product :slide="getProductData(product)" :brickContent="brickContent" />
                                        </a>
                                        <div v-if="getProductData(product).status === false">
                                            <div class="g_txt_M not-available">
                                                Product "{{ getProductData(product).sku }}" is not available in this preview but will be shown in a Nespresso page if the SKU is correct
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button :class="{ 'hide-mobile': !this.showMobilePagination, 'hide-desktop': !this.showPagination }" :id="'swiper-button-next-' + brickContent.id" class="swiper-button-next">
                            <span class="g_visually_hidden">{{ $t('productsAddToCart.nextSlide') }}</span>
                        </button>
                        <div :class="{ 'hide-mobile': !this.showMobilePagination, 'hide-desktop': !this.showPagination }" class="swiper-pagination" :id="'swiper-pagination-' + brickContent.id"></div>
                    </div>
                </div>
            </div>
        </div>
        <products-add-to-card-modal :product="selectedProduct" v-if="isModalActive" @close="closeProductModal" />
    </section>
</template>

<script>
import { $ } from '@/jquery';
import Swiper from 'swiper/bundle';
import { setTimeout } from 'timers';
import { CURRENCY_PREFIX } from '../../services/params';
import ProductsAddToCardModal from '@/components/products-add-to-cart/ProductsAddToCardModal';
import Product from '@/components/products-add-to-cart/Product';

export default {
    name: 'ProductsAddToCart',
    props: {
        brickContent: { type: Object, default: null },
    },
    components: {
        ProductsAddToCardModal,
        Product,
    },
    data() {
        return {
            slides: [],
            counter: 0,
            showSlider: false,
            showMobilePagination: false,
            slidesPerView: 0,
            showPagination: false,
            isModalActive: false,
            mosaicInitiated: false,
            currency: CURRENCY_PREFIX,
            selectedProduct: null,
            currentModalButton: null,
        };
    },
    mounted() {
        this.getProducts();
        this.showMobilePagination = this.brickContent.products.length > 1;
        this.showPagination = this.brickContent.display_on_two_lines ? this.brickContent.products.length > 6 : this.brickContent.products.length > 3;

        if (this.brickContent.display_on_two_lines) {
            this.slidesPerView = this.brickContent.products.length / 2 > 3 ? 3 : Math.ceil(this.brickContent.products.length / 2);
        } else {
            this.slidesPerView = this.brickContent.products.length > 3 ? 3 : this.brickContent.products.length;
        }
    },
    methods: {
        getProductData(product){
          return this.slides.find(p => p.legacyId === product.sku);
        },
        closeProductModal() {
            this.isModalActive = false;
            this.currentModalButton.focus();
        },
        // Get products to show on the slider
        getProducts() {
            this.brickContent.products.forEach((product) => {
                this.getProductDetails(product);
            });
        },
        // Get product details from napi (only works on the Nespresso website)
        getProductDetails(product) {
            this.counter++;
            window.napi
                .catalog()
                .getProduct(product.sku)
                .then((resp) => {
                  if(resp !== undefined){
                    resp.unitPrice = parseFloat(resp.unitPrice).toFixed(2);
                    resp.offer_price = product.offer_price;
                    resp.strikethrough = product.strikethrough;
                    resp.modal_description = product.modal_description;
                    resp.product = product;
                    this.slides.push(resp);
                    if (this.counter >= this.brickContent.products.length) {
                      setTimeout(() => {
                        this.initSwiper();
                      }, 1000);
                    }
                  }
                });
        },
        openProductModal(product) {
            const btnAddToBag = 'AddToBagButtonSmall__icon-sign';
            if (this.brickContent.display_popin && !event.target.classList.contains(btnAddToBag)) {
                this.currentModalButton = event.currentTarget;
                this.selectedProduct = product;
                this.isModalActive = true;
            }
        },
        initMosaic() {
            if (typeof window.mosaic !== 'undefined' && !this.mosaicInitiated) {
                window.mosaic.initializeAllFreeHTMLModules(document.getElementById('brick-' + this.brickContent.id));
                this.mosaicInitiated = true;
            }
        },
        initSwiper() {
            var $this = this;
            var swiper = new Swiper('#swiper-' + this.brickContent.id, {
                a11y: false,
                direction: 'horizontal',
                loop: false,
                slidesPerView: 1,
                watchSlidesVisibility: true,
                keyboard: true,
                watchOverflow: true,
                pagination: {
                    el: `#swiper-pagination-${this.brickContent.id}`,
                    type: 'bullets',
                    clickable: true,
                },
                navigation: {
                    nextEl: '#swiper-button-next-' + this.brickContent.id,
                    prevEl: '#swiper-button-prev-' + this.brickContent.id,
                },
                breakpoints: {
                    767: {
                        slidesPerView: this.slidesPerView,
                        slidesPerColumn: this.brickContent.display_on_two_lines ? 2 : 1,
                        slidesPerColumnFill: this.brickContent.display_on_two_lines ? 'row' : null,
                    },
                },
                on: {
                    init: function () {
                        $this.showSlider = true;
                        $this.initMosaic();
                        setTimeout(() => {
                            updateTabIndex();
                        }, 300);
                    },
                },
            });

            swiper.on('slideChange', function () {
                setTimeout(() => {
                    $('.swiper-slide-active', $('#swiper-' + $this.brickContent.id)).focus();
                    updateTabIndex();
                }, 300);
            });

            function updateTabIndex() {
                var $currrentSWiper = $('#swiper-' + $this.brickContent.id);
                $('.swiper-slide').attr('tabindex', '-1');
                $('.swiper-slide-visible', $currrentSWiper).attr('tabindex', '0');
                var $btnPrev = $('#swiper-button-prev-' + $this.brickContent.id);
                var $btnNext = $('#swiper-button-next-' + $this.brickContent.id);
                $btnPrev.attr('tabindex', '0');
                $btnNext.attr('tabindex', '0');
                $('.swiper-button-disabled').attr('tabindex', '-1');
            }
        },
    },
};
</script>
<style lang="scss">
.g_products_intro {
    margin-bottom: 60px !important;
}

.swiper-pagination .swiper-pagination-bullet {
    border: 1px solid black;
    border-radius: 100%;
    margin: 0 16px;
    cursor: pointer;

    &.swiper-pagination-bullet-active {
        background-color: black;
    }
}

.v_swiper .swiper-container .swiper-wrapper {
    display: flex !important;
}

.v_swiper .swiper-container .swiper-wrapper .swiper-slide .g_itemTable {
    display: flex;
    justify-content: center;
}

.swiper-slide {
  .g_visual {
    display: flex !important;
    justify-content: center;
    .g_image {
      width: 150px !important;
      @media screen and (min-width: 768px) {
        width: 180px !important;
      }
    }
  }
}

  .swiper-button-prev,
  .swiper-button-next,
  .swiper-pagination {
    @media screen and (max-width: 767px) {
      &.hide-mobile {
        display: none;
      }
    }

    @media screen and (min-width: 768px) {
      &.hide-desktop {
        display: none;
      }
    }
  }

  .g_productPrice.g_productPrice-striked {
    text-decoration: line-through;
    font-weight: 400;
    color: black;
  }

  .g_productPrice.g_productPrice-withoffer {
    font-weight: 400;
    color: black;
  }

  .g_addToCart {
    min-height: 5.875rem;
  }

  button.g_bannerProduct {
    text-align: center;
  }

  .g_products .g_bannerProduct .g_image{
    min-width: 8.125rem;
  }
</style>
