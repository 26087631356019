import { DrupalApi } from "./drupal_api";

export class SustainabilityLoader {
  constructor(){
    this.lang = document.documentElement.lang;
    this.drupalApi = new DrupalApi();
  }

  async loadData(type) {
    let response;
    if (type === 'project_vote') {
      response = await this.getProjectVote();
    } else if (type === 'project_vote_amount') {
      response = await this.getProjectVoteAmounts();
    } else if (type === 'forms_token') {
      response = await this.getSustainabilityFormsToken();
    }
    return response.data;
  }

  getProjectVote() {
    return this.drupalApi.loadTerms(`project_vote`, {cacheBuster: 15});
  }

  getProjectVoteAmounts() {
    const fallbackEndpoint = `/jsonapi/voting/vote_counter`;
    return this.drupalApi.load("all", "vote_counter", fallbackEndpoint, {
      forceLang: "en",
      cacheBuster: 15
    })
  }

  getSustainabilityFormsToken() {
    return this.drupalApi.getApi( `/session/token`);
  }

  async submitSustainabilityVote(userZip, userId, project, canal) {
    const token = await this.loadData('forms_token');
    
    let headers = {
      "Content-Type": "application/vnd.api+json",
      "X-CSRF-Token": token,
      "Accept": "application/vnd.api+json"
    };

    const endpoint = `/jsonapi/voting/vote`;
    let now = new Date();

    const data = {
      "data": {
        "type": "vote",
        "attributes": {
          "title": "Vote",
          "user_id": userId ? parseInt(userId, 10) : null,
          "zipcode": userZip ? parseInt(userZip, 10) : null,
          "canal": canal,
          "created": now.toISOString().split('.')[0]+"Z"
        },
        "relationships": {
          "project": {
            "data": project.subject
          }
        }
      }
    };
    const drupalApi = new DrupalApi();
    return drupalApi.postApi(endpoint, data, { headers });
  }
}
