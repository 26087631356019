<template>
    <div class="top-bar">
      <SearchBar />
      <FilterList />
  </div>
</template>

<script>
import SearchBar from '@/components/store-locator/partials/SearchBar.vue'
import FilterList from '@/components/store-locator/partials/FilterList.vue'


export default {
  name: 'TopBar',
  components: {
    SearchBar,
    FilterList
  }
}
</script>

<style lang="scss">
  .g {
    .top-bar {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
  }
</style>
