<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_light g_products g_commercialOffer">

        <div class="g_restrict">
            <div class="g_content">

                <div class="g_text">
                    <div class="v_swiper v-swiper-on" tabindex="0">

                        <button :id="'swiper-button-prev-' + brickContent.id" class="swiper-button-prev swiper-button-disabled" aria-disabled="true">
                            <span class="g_visually_hidden">{{ $t('productsAddToCart.previousSlide') }} </span>
                        </button>

                        <div class="v_swiperOverflow">
                            <div class="swiper-container"
                                :id="'swiper-' + brickContent.id">
                                <ul class="swiper-wrapper">

                                    <!-- Offers -->
                                    <li v-for="offer in brickContent.offers" :key="offer.index" class="swiper-slide">
                                        <div class="g_offerSlide" :class="{g_oneSlide: brickContent.offers.length == 1}">

                                            <!-- Offer image -->
                                            <div class="g_offerImage">
                                                <img v-if="offer.offer_reference && offer.offer_reference.image && offer.offer_reference.image.image" v-lazy="offer.offer_reference.image.image.uri.full_url"/>
                                            </div>

                                            <div class="g_offerText">

                                                <!-- Offer title -->
                                                <h2 class="g_h2" v-html="offer.offer_reference.name"></h2>

                                                <!-- WYSIWYG content -->
                                                <div class="g_richText g_wysiwyg" v-if="offer.text" v-html="offer.text.value"></div>

                                                <!-- Button to open modal -->
                                                <button v-if="offer.modal_link_label" class="g_link" @click="openOfferModal(offer, $event)">
                                                    <span v-html="offer.modal_link_label"></span>
                                                    <span class="g_visually_hidden">{{ $t('commercialOffer.openModal') }}</span>
                                                    <span class="fn_angleRight"></span>
                                                </button>

                                                <!-- WYSIWYG content after button -->
                                                <div class="g_richText g_wysiwyg" v-if="offer.text_after_modal_link" v-html="offer.text_after_modal_link.value"></div>

                                                <div class="g_richText g_wysiwyg  g_commercialOffer_striked" v-if="offer.offer_reference.strikethrough">
                                                    <!-- Regular price -->
                                                    <div class="g_wysiwyg g_txt_M g_regularPrice">{{ currency }} {{offer.offer_reference.regular_price}}</div>

                                                    <!-- Offer price -->
                                                    <div class="g_wysiwyg g_txt_M g_offerPrice">{{ currency }} {{offer.offer_reference.offer_price}}</div>
                                                </div>

                                                <div class="g_richText g_wysiwyg" v-if="!offer.offer_reference.strikethrough">
                                                    <!-- Offer price -->
                                                    <div class="g_wysiwyg g_txt_M g_offerPrice">{{ currency }} {{offer.offer_reference.offer_price}}</div>

                                                    <!-- Regular price -->
                                                    <div class="g_wysiwyg g_txt_M g_regularPrice">{{ $t('commercialOffer.insteadOf') }}
                                                      {{ currency }} {{offer.offer_reference.regular_price}}</div>
                                                </div>

                                                <!-- Add to cart button -->
                                                <div class="g_addToCart" :id="'model-' + offer.offer_reference.sku">
                                                    <div :data-product-id="offer.offer_reference.sku" class="add-to-bag" data-button-size="large"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <button :id="'swiper-button-next-' + brickContent.id" class="swiper-button-next" aria-disabled="false">
                            <span class="g_visually_hidden">{{ $t('productsAddToCart.nextSlide') }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <offer-lightbox
            :content="selectedContent"
            :popinItems="selectedContent.rubrics"
            v-if="isModalActive"
            @close="closeOfferModal"/>
    </section>
</template>

<script>
import OfferLightbox from '@/components/commercial-offer/OfferLightbox';
import Swiper from 'swiper/bundle';
import { $ } from "@/jquery";
import { CURRENCY_PREFIX } from '../../services/params';

export default {
    name: 'CommercialOffer',
    props: {
        brickContent: { type: Object, default: null }
    },
    components: {
        OfferLightbox
    },
    data() {
        return {
            isModalActive: false,
            selectedContent: null,
            currency: CURRENCY_PREFIX
        }
    },
    mounted() {
        this.initSwiper();
        this.brickContent.offers.forEach((offer) => {
            this.initMosaic(offer);
        });
    },
    methods: {
        closeOfferModal() {
            this.isModalActive = false;
            this.currentModalButton.focus();
        },
        openOfferModal(content,event) {
            this.currentModalButton=event.target;
            this.selectedContent = content;
            this.isModalActive = true;
        },

        // Initialize buy button for each model and color, as they're all unique (different SKUs)
        initMosaic(offer) {
            if (typeof window.mosaic !== 'undefined') {
                if (document.getElementById('model-' + offer.offer_reference.sku) !== null) {
                    window.mosaic.initializeAllFreeHTMLModules(document.getElementById('model-' + offer.offer_reference.sku));
                } else {
                    setTimeout(() => {
                        this.initMosaic(offer);
                    }, 50);
                }
            }
        },
        initSwiper() {
            var $this = this;
            if (this.brickContent.offers.length) {
                new Swiper('#swiper-' + this.brickContent.id, {
                    a11y:false,
                    direction: 'horizontal',
                    loop: false,
                    slidesPerView: 1,
                    spaceBetwen: 0,
                    watchSlidesVisibility:true,
                    keyboard:true,
                    breakpoints: {
                        767: {
                            slidesPerView: this.brickContent.offers.length
                        }
                    },
                    navigation: {
                        nextEl: '#swiper-button-next-' + this.brickContent.id,
                        prevEl: '#swiper-button-prev-' + this.brickContent.id
                    },
                    on: {
                        init: function () {
                            $this.showSlider = true;
                            setTimeout(() => {
                                updateTabIndex();
                            }, 300);
                        }
                    }
                });
            } else {
                setTimeout(() => {
                    this.initSwiper();
                }, 50);
            }

            $('#swiper-' + $this.brickContent.id).on('slideChange', function() {
                setTimeout(() => {
                    $('.swiper-slide-active', $('#swiper-' + $this.brickContent.id)).focus();
                    updateTabIndex();
                }, 300);
            })

            function updateTabIndex() {
                var $currrentSWiper = $('#swiper-' + $this.brickContent.id);
                $('.swiper-slide').attr('tabindex', '-1');
                $('.swiper-slide-visible', $currrentSWiper).attr('tabindex','0');
                var $btnPrev = $('#swiper-button-prev-' + $this.brickContent.id);
                var $btnNext = $('#swiper-button-next-' + $this.brickContent.id);
                $btnPrev.attr('tabindex', '0');
                $btnNext.attr('tabindex', '0');
                $('.swiper-button-disabled').attr('tabindex', '-1');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.g_commercialOffer_striked {
    .g_regularPrice {
        text-decoration: line-through;
        margin-top: 1rem;
        margin-bottom: 0;
    }

    .g_offerPrice {
        margin: 0 !important;
    }
}
</style>
