<template>
  <div class="bp">
    <!-- bg for background parallax -->
    <div
      v-if="cpData"
      class="bp__wrapper"
      :id="this.trigger"
      :style="
        'background-image: url(' +
          $distUrl +
          '/front-app/public/img/marble-background.png);'
      "
    >
      <div
        v-for="(capsule, index) in capsules"
        :key="index"
        :class="capsule.class + ' ' + capsule.start"
        class="bp__caps"
      >
        <img :src="$distUrl + '/front-app/public/' + capsule.url" alt="image" />
      </div>

      <slot />
    </div>
    <slot v-else />
  </div>
</template>

<script>
import Vue from "vue";
import VueScrollmagic from "vue-scrollmagic";

Vue.use(VueScrollmagic, {
  vertical: true,
  globalSceneOptions: {},
  loglevel: 2,
  refreshInterval: 100
});

export default {
  name: "BackgroundParallax",
  props: {
    cpData: { type: Object, default: null }
  },
  plugins: [
    {
      src: "~js/scrollmagic/uncompressed/ScrollMagic.js",
      ssr: false
    }
  ],
  mounted() {
    if (!this.cpData) return;
    for (let i = 0; i < this.capsules.length; i++) {
      this["scene" + this.trigger + i] = this.$scrollmagic
        .scene({
          triggerElement: "#" + this.trigger,
          triggerHook: 0.5,
          duration: 1000
        })
        .setTween(
          "#" + this.trigger + " ." + this.capsules[i].class.split(" ")[0],
          {
            css: {
              top: this.capsules[i].y + "%"
            }
          }
        );
      this.$scrollmagic.addScene(this["scene" + this.trigger + i]);
    }
  },
  computed: {
    capsules: function() {
      return this.cpData.capsules;
    },
    trigger: function() {
      return this.cpData.trigger;
    }
  }
};
</script>

<style lang="scss">
.g {
  .bp {
    &__wrapper {
      background-size: cover;
      position: relative;
      //padding: 0 0 70px;

      @media (min-width: 600px) {
        padding: 0;
      }
    }

    &__caps {
      scroll-behavior: smooth;
      position: absolute;
      z-index: 2;
      display: none;

      @media (min-width: 1400px) {
        display: block;
      }

      &--left {
        @media (min-width: 1200px) {
          left: -3%;
        }
        @media (min-width: 1600px) {
          left: 6%;
        }
      }

      &--right {
        @media (min-width: 1200px) {
          right: -7%;
        }
        @media (min-width: 1600px) {
          right: 5%;
        }
      }
      &.one-caps {
        @media (min-width: 1200px) {
          width: 170px;
        }
      }
      &.two-caps {
        @media (min-width: 1200px) {
          width: 280px;
        }
        @media (min-width: 1600px) {
          width: 300px;
        }
      }
      &.two-caps-little {
        display: block;
        width: 150px;
      }
      &.main-header {
        @media (min-width: 600px) {
          display: none;
        }
      }

      img {
        display: block;
        width: 100%;
      }
    }
  }
  @for $i from 1 through 30 {
    .top-#{$i * 5} {
      top: #{$i * 5 + "%"};
    }
  }
}
</style>
