<template>
  <div :class="element['#attributes'] && element['#attributes'].class ? element['#attributes'].class : 'g_col50'">
    <!--
        Text
        Email
        Number
        Phone number
      -->
    <div
      v-if="element['#type'] === 'email' || element['#type'] === 'number' || element['#type'] === 'textfield' || element['#type'] === 'tel'"
      class="input-group input-group-generic">
      <label :for="getInputUniqueId(index)" class="desktop-label"
        :class="element['#required'] ? 'c-required' : ''">{{ element['#title'] }}
        <span v-if="element['#required']" class="required">*</span>
      </label>
      <div>
        <input :type="element['#type'] === 'textfield' ? 'text' : element['#type']" :id="getInputUniqueId(index)"
          :title="element['#title']" :placeholder="element['#title']" :name="index" :pattern="element['#pattern']"
          :class="element.hasError ? 'form-control invalid' : 'form-control'"
          :required="element['#required'] ? true : false" :aria-required="element['#required'] ? true : false"
          :aria-invalid="element.hasError ? true : false" :readonly="element['#readonly'] ? true : false"
          :disabled="element['#disabled'] ? true : false" />
        <span class="mobile-label">{{ element['#title'] }}<span v-if="element['#required']"
            class="required">*</span></span>
      </div>
    </div>

    <!--
              Date
          -->
    <div v-if="element['#type'] === 'date'" class="input-group input-group-generic date-input">
      <label :for="getInputUniqueId(index)" class="desktop-label"
        :class="element['#required'] ? 'c-required' : ''">{{ element['#title'] }}<span v-if="element['#required']"
          class="required">*</span></label>
      <div>
        <input type="date" :title="element['#title']" :placeholder="element['#title']" :name="index"
          :id="getInputUniqueId(index)" :min="formatDate(element['#date_date_min'])"
          :max="formatDate(element['#date_date_max'])"
          :class="element.hasError ? 'form-control invalid' : 'form-control'"
          :required="element['#required'] ? true : false" :aria-required="element['#required'] ? true : false"
          :aria-invalid="element.hasError ? true : false" :readonly="element['#readonly'] ? true : false"
          :disabled="element['#disabled'] ? true : false" />
        <span class="g_visually_hidden"> {{ $t('form.dateInstructions') }}</span>
        <span class="mobile-label">{{ element['#title'] }}<span v-if="element['#required']"
            class="required">*</span></span>
      </div>
    </div>

    <!--
              Single checkbox
          -->
    <div v-if="element['#type'] === 'checkbox'" class="input-group input-group-generic">
      <div class="checkbox-container">
        <input type="checkbox" :id="index" :name="index" :class="element.hasError ? 'invalid' : ''" value="true"
          :required="element['#required'] ? true : false" :aria-required="element['#required'] ? true : false"
          :aria-invalid="element.hasError ? true : false" :readonly="element['#readonly'] ? true : false"
          :disabled="element['#disabled'] ? true : false" />
        <label :for="index" class="checkbox-value">{{ element['#title'] }}</label>
      </div>
    </div>
    <!--
              Radios button
          -->
    <div v-if="element['#type'] === 'radios'" class="input-group input-group-generic">
      <span class="mobile-label radio-label">{{ element['#title'] }}<span v-if="element['#required']"
          class="required">*</span></span>
      <legend :for="getInputUniqueId(index)" class="desktop-label" :class="element['#required'] ? 'c-required' : ''">
        {{ element['#title'] }}<span v-if="element['#required']" class="required">*</span>
      </legend>
      <div class="radio-container"
        :class="element['#options_display'] === 'buttons_horizontal' ? 'radio-container-horizontal' : ''">
        <div v-for="(name, value) in element['#options']" :key="name.index">
          <input type="radio" :class="element.hasError ? 'invalid' : ''" :name="getInputUniqueId(index)" :value="value"
            :id="value" :required="element['#required'] ? true : false"
            :aria-required="element['#required'] ? true : false" :aria-invalid="element.hasError ? true : false" />
          <label :for="value" class="radio-value">{{ name }}</label>
        </div>
      </div>
    </div>

    <!--
              Multiple checkboxes
          -->
    <fieldset v-if="element['#type'] === 'checkboxes' && element['#options'] !== 'yes_no'" class="input-group input-group-generic">
      <span class="mobile-label checkbox-label">{{ element['#title'] }}<span v-if="element['#required']"
          class="required">*</span></span>
      <legend class="desktop-label" :class="element['#required'] ? 'c-required' : ''">{{ element['#title'] }}<span
          v-if="element['#required']" class="required">*</span></legend>
      <div class="checkbox-container">
        <div v-for="(name, value) in element['#options']" :key="name.index">
          <input type="checkbox" :name="index" :value="value" :id="value"
            :required="element['#required'] ? true : false" :aria-required="element['#required'] ? true : false"
            :aria-invalid="element.hasError ? true : false" />
          <label :for="value" class="checkbox-value">{{ name }}</label>
        </div>
      </div>
    </fieldset>

  <!--  Yes/no option  -->
    <fieldset v-if="element['#type'] === 'checkboxes' && element['#options'] === 'yes_no'" class="input-group input-group-generic">
        <span class="mobile-label checkbox-label">{{ element['#title'] }}<span v-if="element['#required']"
                                                                               class="required">*</span></span>
      <legend class="desktop-label" :class="element['#required'] ? 'c-required' : ''">{{ element['#title'] }}<span
        v-if="element['#required']" class="required">*</span></legend>
      <div class="checkbox-container">
        <div v-for="(item, key) in element['options']" :key="key">
          <input type="radio" :name="index" :value="item" :id="index +'_'+ item"
                 :required="element['#required'] ? true : false" :aria-required="element['#required'] ? true : false"
                 :aria-invalid="element.hasError ? true : false" />
          <label :for="index +'_'+ item" class="checkbox-value">{{ item }}</label>
        </div>
      </div>
    </fieldset>
    <!--
              Select
          -->
    <div v-if="element['#type'] === 'select'" class="input-group input-group-generic">
      <label :for="getInputUniqueId(index)" class="desktop-label" :class="element['#required'] ? 'c-required' : ''">
        {{ element['#title'] }}<span v-if="element['#required']" class="required">*</span>
      </label>
      <div>
        <div class="dropdown dropdown--input" :class="element.focus ? 'focus' : ''">
          <button tabindex="-1" :title="element['#title']" type="button"
            class="btn btn-primary btn-white btn-icon-right">
            <i class="fn_angleRight"></i>
          </button>
          <select :id="getInputUniqueId(index)" :name="index" :class="element.hasError ? 'invalid' : ''"
            :required="element['#required'] ? true : false" :readonly="element['#readonly'] ? true : false"
            :disabled="element['#disabled'] ? true : false" :aria-required="element['#required'] ? true : false"
            :aria-invalid="element.hasError ? true : false" @focus="focusOnDropdown(element)"
            @blur="blurOnDropdown(element)">
            <option value="">&nbsp;</option>
            <template v-if="element.options">
              <option v-for="(name, value) in element.options" :key="name.index" :value="value">{{ name }}</option>
            </template>
            <template v-if="!element.options">
              <option v-for="(name, value) in element['#options']" :key="name.index" :value="value">{{ name }}</option>
            </template>
          </select>
        </div>
        <span class="mobile-label">{{ element['#title'] }}<span v-if="element['#required']"
            class="required">*</span></span>
      </div>
    </div>

    <!--
              Textarea
          -->
    <div v-if="element['#type'] === 'textarea'" class="input-group input-group-generic">
      <label :for="getInputUniqueId(index)" class="desktop-label" :class="element['#required'] ? 'c-required' : ''">
        {{ element['#title'] }}<span v-if="element['#required']" class="required">*</span>
      </label>
      <div>
        <textarea :id="getInputUniqueId(index)" :name="index" :rows="element['#rows'] ? element['#rows'] : 8"
          :title="element['#title']" :minlength="element['#minlength']" :maxlength="element['#maxlength']"
          :class="element.hasError ? 'form-control invalid' : 'form-control'"
          :required="element['#required'] ? true : false" :readonly="element['#readonly'] ? true : false"
          :disabled="element['#disabled'] ? true : false" :aria-required="element['#required'] ? true : false"
          :aria-invalid="element.hasError ? true : false">
                  </textarea>
        <span class="mobile-label"> {{ element['#title'] }}<span v-if="element['#required']" class="required">*</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { DrupalApi } from "@/services/drupal_api";

export default {
  name: 'FormFields',
  data() {
    return {
      brickContent: null,
    };
  },
  props: {
    element: { type: Object, default: null },
    index: { type: String, default: null },
    uid: { type: Number, default: null },
  },
  mounted() {
    if (this.element['#popin']) {
      this.fetchBrickContent();
    }
  },
  methods: {
    getInputUniqueId(name) {
      return 'input_' + name + '_' + this.uid;
    },
    // Format needed for input validation is YYYY-mm-dd
    formatDate(date) {
      if (date) {
        let d = date.split('-');
        // Prepend 0 on month if needed
        d[1] = d[1].length < 2 ? '0' + d[1] : d[1];

        // Prepend 0 to day if needed
        d[2] = d[2].length < 2 ? '0' + d[2] : d[2];
        return d[0] + '-' + d[1] + '-' + d[2];
      } else {
        return '';
      }
    },
    async fetchBrickContent() {
      const popinContent = this.element['#popin_content'];
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = popinContent;

      const anchorTag = tempDiv.querySelector('a[data-entity-uuid]');
      const brickUUID = anchorTag ? anchorTag.getAttribute('data-entity-uuid') : null;

      if (brickUUID) {
        try {
          var drupalApi = new DrupalApi();
          const response = await drupalApi.getApi(`/jsonapi/brick/popin/${brickUUID}`);
          this.brickContent = response.data.data.attributes;
          this.brickContent.id = response.data.data.id;
        } catch (error) {
          console.error('Failed to load brick content:', error);
        }
      }
    }
  }
};
</script>
