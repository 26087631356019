<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_light">
        <ArticlesPagination v-if="totalPages > 1"
          :current-page="currentPage"
          :total-pages="totalPages"
          :getPaginationLabel="getPaginationLabel"
          @page-changed="goToPage"
        />
        <div :id="'brick-' + brickContent.id">
            <div class="g_articlesList">
                <ul>
                    <li class="g_articlesList_element" v-for="article in articleList" :key="article.nid">
                        <div class="g_articlesList_imageContainer">
                            <div class="g_articlesList_image">
                                <image-responsive
                                  v-if="article.image_teaser && article.image_teaser.image && article.image_teaser.image.image_style_uri"
                                  :image="article.image_teaser.image.image_style_uri['article_desktop']"
                                  :imageMobile="article.image_teaser.image.image_style_uri['article_mobile']"
                                />
                                <image-responsive
                                  v-else-if="article.image_header && article.image_header.image && article.image_header.image.image_style_uri"
                                  :image="article.image_header.image.image_style_uri['article_desktop']"
                                  :imageMobile="article.image_header.image.image_style_uri['article_mobile']"
                                />
                            </div>
                        </div>
                        <div class="g_articlesList_content">
                            <div class="g_articlesList_topics">
                                <template v-for="topic in article.topics">
                                    <a v-if="topic.list_of_articles && topic.list_of_articles.url" :key="topic.index" :href="topic.list_of_articles.uri">{{ topic.name }}</a>
                                    <span v-if="!topic.list_of_articles || !topic.list_of_articles.url" :key="topic.index">{{ topic.name }}</span>
                                </template>
                            </div>
                            <h2 v-html="article.title"></h2>
                            <p v-if="article.is_publication_date_display && article.author == null" class="g_articlesList_date">
                              {{ formatDate(article.field_article_date)  }}
                            </p>
                            <author
                              v-if="article.author && article.author.name"
                              :picture="article.author.field_author_picture"
                              :name="article.author.name"
                              :date="article.is_publication_date_display ? formatDate(article.field_article_date) : null"
                              mode="teaser"
                            />
                            <p v-if="article && article.introduction" v-html="article.introduction.processed"></p>
                            <a v-if="article.nespresso_prod_uri" :href="getArticleUrl(article.nespresso_prod_uri.uri, article.field_b2b_website)" class="g_articlesList_discover">
                              {{ getCtaLabel(article) }}
                            </a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <ArticlesPagination v-if="totalPages > 1"
          :current-page="currentPage"
          :total-pages="totalPages"
          :getPaginationLabel="getPaginationLabel"
          @page-changed="goToPage"
        />
    </section>
</template>

<script>
import { DrupalApi } from "@/services/drupal_api";
import ImageResponsive from '@/components/ImageResponsive.vue';
import Author from '@/components/Author.vue';
import { generateNespressoUrl } from "@/services/utils";
import moment from "moment";
import ArticlesPagination from "@/components/articles/ArticlesPagination.vue";

export default {
  name: 'ArticlesList',
  components: {ArticlesPagination, Author, ImageResponsive },
  props: {
      brickContent: { type: Object, default: null }
  },
  data() {
    return {
      defaultConfiguration: null,
      lang: 'en',
      articleList: [],
      currentPage: 1,
      articlesPerPage: 12,
      totalPages: 1
    };
  },
  async mounted() {
    this.fetchArticles();
  },
  methods: {
    formatDate(date){
      const langcode = this.brickContent.langcode;
      let format = false;
      switch (langcode){
        case 'en':
          format = "MMMM DD, YYYY";
          break;
        case 'de':
          format = "DD. MMMM YYYY";
          break;
        default:
          format = "DD MMMM, YYYY";
          break;
      }
      return moment(date).format(format);
    },
    getArticleUrl(url, isB2B){
      return generateNespressoUrl(url, isB2B, this.lang);
    },
    getDatePublished(article) {
       return article.created;
    },
    async fetchConfiguration() {
      const drupalApi = new DrupalApi();
      this.lang = drupalApi.lang || 'en';
      const response = await drupalApi.getApi('api/article/default_config');
      if (response.status === 200) {
        this.defaultConfiguration = response.data;
      }
    },
    getCtaLabel(article) {
      if (article && article.field_label_cta && article.field_label_cta.value) {
        return article.field_label_cta.value;
      }
      if (this.defaultConfiguration && this.defaultConfiguration.cta) {
        return this.defaultConfiguration.cta[this.lang] || this.defaultConfiguration.cta['en'];
      }
      return "Read more";
    },
    getPaginationLabel(type) {
      if (this.defaultConfiguration && this.defaultConfiguration.cta && this.defaultConfiguration.cta[type]) {
        return this.defaultConfiguration.cta[type][this.lang] || this.defaultConfiguration.cta[type]['en'];
      }
      return type === "previous" ? "Previous" : "Next";
    },
    fetchArticles() {
      const enablePagination = this.brickContent.field_enable_pagination ? this.brickContent.field_enable_pagination : false;
      var drupalApi = new DrupalApi();
      this.articlesPerPage = this.brickContent.field_pagination ? this.brickContent.field_pagination : 12;

      let apiUrl = `${this.lang}/api/article-list`;

      // If topics
      if (this.brickContent.topic && this.brickContent.topic.length > 0) {
        let topic_ids = this.brickContent.topic
          .map(value => value.resourceIdObjMeta.drupal_internal__target_id)
          .join(',');
        apiUrl += `?topic_ids=${topic_ids}`;
      }

      // If sort by article dates
      else if (this.brickContent.articles_list && this.brickContent.field_sort_publication_date) {
        let article_ids = this.brickContent.articles_list
          .map(article => article.drupal_internal__nid)
          .join(',');
        apiUrl += `?article_ids=${article_ids}`;
      }

      // If not sort by article dates nor topics
      else if (this.brickContent.articles_list && !this.brickContent.field_sort_publication_date) {
        this.totalPages = enablePagination ? Math.ceil(this.brickContent.articles_list.length / this.articlesPerPage) : 1;
        if (enablePagination) {
          this.currentPage = 1;
          this.updateLocalPagination();
        } else {
          this.articleList = this.brickContent.articles_list;
        }
        return;
      }

      if (enablePagination) {
        apiUrl += `&limit=${this.articlesPerPage}&page=${this.currentPage}`;
      }

      drupalApi.getApi(apiUrl).then(response => {
        if (response.data.articles && response.data.articles.length > 0) {
          this.articleList = response.data.articles;
          this.totalPages = response.data.pagination.total_pages;
        }
      });
    },
    // local pagination if not sort by article dates nor topics
    updateLocalPagination() {
      const start = (this.currentPage - 1) * this.articlesPerPage;
      const end = start + this.articlesPerPage;
      this.articleList = this.brickContent.articles_list.slice(start, end);
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
        if (this.brickContent.field_sort_publication_date || (this.brickContent.topic && this.brickContent.topic.length > 0)) {
          this.fetchArticles();
        } else {
          this.updateLocalPagination();
        }

        this.$nextTick(() => {
          let brickId = '#brick-' + this.brickContent.id;
          let articlesList = document.querySelector(brickId);
          if (articlesList) {
            const absoluteTop = window.scrollY + articlesList.getBoundingClientRect().top;
            window.scrollTo({
              top: absoluteTop - 180,
              behavior: "smooth"
            });
          }
        });
      }
    }
  },
  async created() {
    await this.fetchConfiguration();
  },
}
</script>
