<template>
  <div class="social-button" :class="'social-button--' + theme">
    <social-sharing
      :url="windowUrl"
      :title="eiData.title"
      :description="eiData.title"
      :quote="facebookQuote"
      network-tag="button"
      inline-template
    >
      <ul class="social-button__content">
        <li>
          <network network="email">
            <svg
              width="26px"
              height="26px"
              viewBox="0 0 26 26"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  transform="translate(-887.000000, -457.000000)"
                  fill="#000000"
                  fill-rule="nonzero"
                  class="color-change"
                >
                  <g transform="translate(0.000000, 153.000000)">
                    <g transform="translate(887.000000, 304.000000)">
                      <path
                        d="M13,0 C20.1797017,0 26,5.82029825 26,13 C26,20.1797017 20.1797017,26 13,26 C5.82029825,26 0,20.1797017 0,13 C0,5.82029825 5.82029825,0 13,0 Z M18.6213333,7 L7.37866667,7 L7.21580855,7.00552596 C5.97806713,7.08980157 5,8.12752037 5,9.3952739 L5,9.3952739 L5,16.6047261 L5.00548765,16.7687213 C5.08917895,18.0151043 6.1197029,19 7.37866667,19 L7.37866667,19 L18.6213333,19 L18.7841915,18.994474 C20.0219329,18.9101984 21,17.8724796 21,16.6047261 L21,16.6047261 L21,9.3952739 L20.9945123,9.23127874 C20.9108211,7.98489574 19.8802971,7 18.6213333,7 L18.6213333,7 Z M15.701,13.663 L18.441,17.388 L7.526,17.388 L10.349,13.719 L10.3863639,13.7579462 L10.5500152,13.9118051 C11.2216167,14.5052105 12.092585,14.8325486 12.99775,14.8325486 C13.9783455,14.8325486 14.9188072,14.4483809 15.6022226,13.7649451 L15.6022226,13.7649451 L15.701,13.663 Z M19.4,9.872 L19.4,15.986 L16.836,12.499 L19.4,9.872 Z M6.599,9.877 L9.217,12.559 L6.599,15.961 L6.599,9.877 Z M18.387,8.611 L14.4678815,12.6287168 L14.3373194,12.7476484 C13.9743384,13.0489237 13.4967209,13.2213778 12.99775,13.2213778 C12.4364077,13.2213778 11.9020909,13.0031156 11.5207226,12.6217359 L11.5207226,12.6217359 L7.607,8.611 L18.387,8.611 Z"
                        id="Combined-Shape"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </network>
        </li>
        <li>
          <network network="facebook">
            <svg
              width="26px"
              height="26px"
              viewBox="0 0 26 26"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Design"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  transform="translate(-927.000000, -457.000000)"
                  fill="#000000"
                  class="color-change"
                >
                  <g transform="translate(0.000000, 153.000000)">
                    <g transform="translate(887.000000, 304.000000)">
                      <g transform="translate(40.000000, 0.000000)">
                        <path
                          d="M11.1648333,20.072 L14.0872333,20.072 L14.0872333,12.9991333 L16.0368,12.9991333 L16.2950667,10.5620667 L14.0872333,10.5620667 L14.0902667,9.3418 C14.0902667,8.70653333 14.1509333,8.3655 15.0626667,8.3655 L16.2812,8.3655 L16.2812,5.928 L14.3312,5.928 C11.9890333,5.928 11.1648333,7.11056667 11.1648333,9.0987 L11.1648333,10.5620667 L9.70493333,10.5620667 L9.70493333,12.9995667 L11.1648333,12.9995667 L11.1648333,20.072 L11.1648333,20.072 Z M13,26 C5.82053333,26 0,20.1794667 0,13 C0,5.8201 5.82053333,0 13,0 C20.1794667,0 26,5.8201 26,13 C26,20.1794667 20.1794667,26 13,26 Z"
                          id="Shape"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </network>
        </li>
        <li>
          <network network="twitter">
            <svg
              width="25px"
              height="25px"
              viewBox="0 0 25 25"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  transform="translate(-968.000000, -457.000000)"
                  fill="#000000"
                  fill-rule="nonzero"
                  class="color-change"
                >
                  <g transform="translate(0.000000, 153.000000)">
                    <g transform="translate(887.000000, 304.000000)">
                      <g transform="translate(81.000000, 0.000000)">
                        <path
                          d="M12.5005076,0 C5.59667398,0 0,5.59768925 0,12.5005076 C0,19.4043413 5.59667398,25 12.5005076,25 C19.4043413,25 25,19.4043413 25,12.5005076 C25,5.59768925 19.4038337,0 12.5005076,0 Z M18.79721,9.98010071 C18.8038093,10.1151316 18.8063475,10.2506701 18.8063475,10.3877315 C18.8063475,14.5508447 15.6376909,19.350532 9.84354695,19.350532 C8.06479451,19.350532 6.40838207,18.829191 5.01441683,17.9357537 C5.26112736,17.9646889 5.51139133,17.9794103 5.76571637,17.9794103 C7.24191845,17.9794103 8.60034925,17.4758366 9.67805799,16.6311322 C8.29982943,16.6052429 7.13683804,15.6940383 6.73580653,14.4427185 C6.9276925,14.4802835 7.12516244,14.499066 7.32821637,14.499066 C7.61553769,14.499066 7.89372157,14.461501 8.15819932,14.3889092 C6.71651641,14.0995573 5.63068551,12.8253939 5.63068551,11.3004589 C5.63068551,11.2867528 5.63068551,11.2735543 5.63119314,11.2598481 C6.05557586,11.4958983 6.54138239,11.6375284 7.05815465,11.6542804 C6.21243502,11.0882675 5.65657489,10.1252843 5.65657489,9.03183886 C5.65657489,8.45465806 5.81191114,7.9130117 6.08298814,7.44801819 C7.63685835,9.35367934 9.95827242,10.608045 12.5766529,10.7390148 C12.5228436,10.5090562 12.4949237,10.2679297 12.4949237,10.0212191 C12.4949237,8.28206222 13.9051332,6.87134503 15.6453054,6.87134503 C16.5514336,6.87134503 17.3697409,7.25410169 17.9443835,7.86580166 C18.6621792,7.72518681 19.3363182,7.46223197 19.9449724,7.10130361 C19.7089222,7.83737411 19.2099172,8.45465806 18.5591293,8.84401397 C19.1972263,8.76786875 19.8043575,8.59831871 20.3688475,8.34805474 C19.9475106,8.98107537 19.4129711,9.53592024 18.79721,9.98010071 Z"
                          id="Shape"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </network>
        </li>
        <li>
          <network network="linkedin">
            <svg
              width="26px"
              height="26px"
              viewBox="0 0 26 26"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g
                  transform="translate(-1007.000000, -457.000000)"
                  fill="#000000"
                  class="color-change"
                >
                  <g transform="translate(0.000000, 153.000000)">
                    <g transform="translate(887.000000, 304.000000)">
                      <g transform="translate(120.000000, 0.000000)">
                        <path
                          d="M20.2960333,19.1260333 L20.2960333,14.0508333 C20.2960333,11.3316667 18.8443667,10.0663333 16.9091,10.0663333 C15.3473667,10.0663333 14.6475333,10.9256333 14.2575333,11.5284 L14.2575333,10.2743333 L11.3152,10.2743333 C11.3542,11.1050333 11.3152,19.1260333 11.3152,19.1260333 L14.2575333,19.1260333 L14.2575333,14.1825667 C14.2575333,13.9186667 14.2766,13.6543333 14.3546,13.4654 C14.5669333,12.9367333 15.0509667,12.3894333 15.8634667,12.3894333 C16.9286,12.3894333 17.3541333,13.2010667 17.3541333,14.3905667 L17.3541333,19.1264667 L20.2960333,19.1264667 L20.2960333,19.1260333 Z M8.21556667,9.0662 C9.24126667,9.0662 9.88043333,8.38586667 9.88043333,7.5361 C9.86136667,6.66813333 9.24126667,6.0073 8.23506667,6.0073 C7.22886667,6.0073 6.57063333,6.6677 6.57063333,7.5361 C6.57063333,8.38586667 7.20936667,9.0662 8.19693333,9.0662 L8.21556667,9.0662 Z M13,26 C5.82053333,26 0,20.1794667 0,13 C0,5.8201 5.82053333,0 13,0 C20.1794667,0 26,5.8201 26,13 C26,20.1794667 20.1794667,26 13,26 Z M9.68673333,19.1260333 L9.68673333,10.2743333 L6.74483333,10.2743333 L6.74483333,19.1260333 L9.68673333,19.1260333 Z"
                          id="Shape"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </network>
        </li>
      </ul>
    </social-sharing>
  </div>
</template>

<script>
export default {
  name: "MasterclassEventSocial",
  props: {
    eiData: { type: Object, required: true }
  },
  data() {
    return {
      ...this.clData,
      windowUrl: null,
      facebookQuote: this.eiData.title
    };
  },
  computed: {
    theme() {
      return this.eiData.theme ? this.eiData.theme : "dark";
    }
  },
  created() {
    this.$root.$on("social_shares_open", network =>
      this.$emit("share", network)
    );
  },
  mounted() {
    this.windowUrl =
      process.env.APP_ENV === "local"
        ? "https://nescareerpage.suisse.sqli.com" + window.location.pathname
        : window.location.href;
  }
};
</script>

<style lang="scss">
.g {
  .social-button {
    display: flex;
    justify-content: center;
    margin: 24px 0 0;
    &--dark {
      svg .color-change {
        fill: white;
      }
    }
    &--light {
      svg .color-change {
        fill: black;
      }
    }
    &__content {
      li {
        display: inline-block;
      }

      $icon-size: 30px;

      button {
        -webkit-appearance: none;
        border: 0;
        background: transparent;
        color: black;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: $icon-size;
        height: $icon-size;
        line-height: 1;
        margin: 0 7px;
        padding: 0;
        cursor: pointer;
        position: relative;

        svg {
          width: $icon-size;
          height: $icon-size;

          .color-change {
            transition: all 0.5s;
          }
        }

        &:hover {
          svg {
            .color-change {
              fill: #8f7247 !important;
            }
          }
        }
      }
    }
  }
}
</style>
