<template>
    <div :id="brickContent.anchor_identifier">
        <div class="g_breadcrumb">
            <nav class="g_nav" aria-labelledby="intro-breadcrumb">
                <p id="intro-breadcrumb" class="g_visually_hidden">{{brickContent.title}}</p>
                <div class="g_restrict">
                    <div class="g_fil">
                        <!-- Left links (breadcrumbs) -->
                        <ul>
                            <li v-for="link in brickContent.crumbs_links" :key="link.index">
                                <a :href="link.url"><span v-html="link.title"></span></a>
                            </li>

                            <!-- Current page (not a link) -->
                            <li class="breadcrumb-current-page" aria-current="page">
                                <span v-html="this.brickContent.current_page_title"></span>
                            </li>
                        </ul>
                    </div>

                    <!-- Mobile
                        Only the right links and current page title are displayed (no breadcrumbs) -->
                    <div class="g_mobileMenu">
                        <button class="g_btnMenu g_txt_S" @click="toggleDropdownMenu()">
                            <span v-html="this.brickContent.current_page_title"></span>
                            <i v-if="this.brickContent.right_links && this.brickContent.right_links.length > 0" class="fn_angleDown"></i>
                        </button>

                        <div class="g_mobileMenuList">
                            <ul>
                                <li v-for="link in this.brickContent.right_links" :key="link.index">
                                    <a :href="link.url"><span v-html="link.title"></span></a>
                                </li>
                                <li aria-current="page">
                                    <a v-if="this.brickContent.button_link" :href="this.brickContent.button_link.url" class="g_btn g_btnBuy"><span v-html="this.brickContent.button_link.title"></span></a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Right links (extra links and "buy" button) -->
                    <div class="g_menu">
                        <ul>
                            <li v-for="link in this.brickContent.right_links" :key="link.index">
                                <a :href="link.url" class="g_btn"><span v-html="link.title"></span></a>
                            </li>
                            <li>
                                <!-- This button can either be an anchor link or a normal link -->
                                <a
                                  v-if="this.brickContent.button_link"
                                  :href="this.brickContent.button_link.url"
                                  class="g_btn g_btnBuy"
                                  :class="gold_button">
                                  <span v-html="this.brickContent.button_link.title" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div tabindex="-1" aria-hidden="true"></div>
        </div>
    </div>
</template>

<script>
  import { $ } from "@/jquery";

  export default {
    name: 'StickyBreadcrumbMenu',
    props: {
      brickContent: { type: Object, default: null }
    },
    data() {
      return {
        gold_button: null
      }
    },
    mounted() {
      $(window).scroll(this.scrollHandler);
      this.scrollHandler();
    },
    destroyed(){
      $(window).off("scroll", this.scrollHandler);
    },
    created() {
      if ( this.brickContent.button_style == '.g_mainCta' ) {
        this.gold_button = this.brickContent.button_style.split('.')[1];
      }
    },
    methods: {
      toggleDropdownMenu() {
        if (this.brickContent.right_links && this.brickContent.right_links.length > 0) {
          if ($('.g_nav').hasClass('g_opened')) {
            $('html').removeClass('g_scrollLock');
            $('.g_nav').removeClass('g_opened');
          } else {
            $('html').addClass('g_scrollLock');
            $('.g_nav').addClass('g_opened');
          }
        }
      },
      scrollHandler() {
        const header = $('#header');
        const $nav = $('.g_nav');

        if($(window).scrollTop() >= header.height()){
          $nav.addClass('g_fixed');
        }

        if($(window).scrollTop() <= header.height()){
          $nav.removeClass('g_fixed');
        }

      }
    }
  }
</script>

<style lang="scss">
.g {
  .g_nav { z-index: 5; }
  .g_mainCta,
  .g_mainCta:active {
    background: #8f7247;
  }
}
</style>
