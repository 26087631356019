<template>
  <div class="g_section g_light mc-event-page">
    <main-header v-if="eventHeader" :brick-content="eventHeader" :i18n-strings="i18nStrings" :displayTitle="true"/>
    <div class="g_restrict">
      <div class="mc-event-page__infos">
        <div class="mc-event-page__expert-picture--full ">
          <image-responsive
            v-if="eventCoffeeExpert"
            :image="eventCoffeeExpert.picture.image_style_uri['2_3_mobile']"
            :imageMobile="eventCoffeeExpert.picture.image_style_uri['2_3_mobile']" />
        </div>
        <div class="mc-event-page__text-infos">
          <event-meta
            v-if="eventMeta && eventDate"
            :em-data="eventMeta"
            :ed-data="eventDate"
            :cl-data="classMeta"
            :is-event-single="true"
          />
          <h3 class="mc-event-page__title">{{ eventThematic }}</h3>
          <p class="mc-event-page__coffee-expert" v-if="eventCoffeeExpert">
            <span class="mc-event-page__coffee-expert-prefix">{{ withLabel }}</span>
            {{ eventCoffeeExpert.name }}
          </p>
          <div class="mc-event-page__expert-picture--mobile" v-if="eventCoffeeExpert">
            <image-responsive
              :image="eventCoffeeExpert.picture.image_style_uri['60_31_desktop']"
              :imageMobile="eventCoffeeExpert.picture.image_style_uri['2_3_mobile']" />
          </div>
          <simple-text
            v-if="simpleTextData"
            :brick-content="simpleTextData"
            :cl-data="classMeta"
          />

          <div class="form mc-event-page__map-form">
            <event-register-form :em-data="eventForm" />
            <event-location v-if="eventLocation" :lc-data="eventLocation" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchMasterclassEventSingle, setBricks } from "@/services/masterclass";
import MainHeader from "@/components/MainHeader";
import EventMeta from "@/components/masterclass/EventMeta";
import SimpleText from "@/components/masterclass/SimpleText";
import EventLocation from "@/components/masterclass/EventLocation";
import EventRegisterForm from "@/components/masterclass/EventRegisterForm";
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
  name: "MasterclassEventPage",
  components: {
    ImageResponsive,
    MainHeader,
    EventMeta,
    SimpleText,
    EventLocation,
    EventRegisterForm,
  },
  props: {
    eventId: { type: String, default: null }
  },
  data() {
    return {
      ...this.eventId,
      classMeta: "event-page",
      BackgroundParallaxProps: {
        capsules: [
          {
            url: "img/capsules-blue-brown.png",
            class: "bp__caps--left two-caps",
            start: "top-20",
            y: "0"
          },
          {
            url: "img/capsule-brown.png",
            class: "bp__caps--right one-caps",
            start: "top-50",
            y: "60"
          }
        ],
        trigger: "bpWrapperEventPage"
      },
      eventLocation: null,
      simpleTextData: null,
      eventMeta: null,
      eventDate: null,
      eventForm: null,
      eventThematic: null,
      eventHeader: {},
      eventSteps: {},
      withLabel: '',
      eventCoffeeExpert: null,
      i18nStrings: {}
    };
  },
  created() {
    this.fetchEventSingle(this.eventId);
    this.$store.dispatch("event/fetchInfoUser");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    fetchEventSingle: async function(eventId) {
      const bricks = this.$drupalPage.bricks.find(
        bricks => bricks.type == "brick--masterclass"
      );
      setBricks(bricks);
      this.$store.commit("event/setBricks", bricks);
      const values = await fetchMasterclassEventSingle(eventId);
      this.constructEventData(values, bricks);
    },
    constructEventData: function (a, bricks) {
      this.eventLocation = {
        title: a.boutique.title,
        address:
          a.boutique.address +
          ", " +
          a.boutique.postal_code +
          " " +
          a.boutique.city,
        tel: a.boutique.phone,
        lat: a.boutique.location.lat,
        lng: a.boutique.location.lng
      };

      this.eventSteps = {
        steps: a.thematic.steps ? a.thematic.steps : [],
        steps_title: a.thematic.steps_title ? a.thematic.steps_title : null,
        steps_picture: a.thematic.steps_picture
          ? a.thematic.steps_picture.image.uri.full_url
          : ""
      };

      this.simpleTextData = {
        title: null,
        introduction: a.thematic.description ? a.thematic.description.value : ""
      };

      this.eventDate = {
        start: a.event_date_and_time.value,
        end: a.event_date_and_time.end_value
      };

      this.eventThematic = a.thematic.name;

      this.eventMeta = {
        capacity: a.hosting_capacity,
        lang: a.language_event
      };

      this.eventForm = {
        eventId: this.eventId,
        eventInternalId: a.drupal_internal__nid,
        eventAvailability: a.capacity_available
      };

      this.eventHeader = {
        isMasterclass: true,
        image: {
          image: {
            image_style_uri: a.thematic.picture && a.thematic.picture.image.image_style_uri
              ? {
                '21_9_desktop': a.thematic.picture.image.image_style_uri['21_9_desktop'] || '',
                '2_3_mobile': a.thematic.picture.image.image_style_uri['2_3_mobile'] || ''
              }
              : {}
          }
        },
        image_mobile: {
          image: {
            image_style_uri: a.thematic.picture && a.thematic.picture.image.image_style_uri
              ? {
                '21_9_desktop': a.thematic.picture.image.image_style_uri['21_9_desktop'] || '',
                '2_3_mobile': a.thematic.picture.image.image_style_uri['2_3_mobile'] || ''
              }
              : {}
          }
        },
        backlink: {
          visible: true,
          text: bricks.field_back_masterclass_text,
          route: "/"
        },
      };

      if (a && a.coffee_expert && a.coffee_expert.id !== 'missing') {
        this.eventCoffeeExpert = {
          name: a.coffee_expert.name,
          desc: a.coffee_expert.description,
          picture: a.coffee_expert.expert_picture
        };
      }

      if (bricks && bricks.field_with_label) {
        this.withLabel = bricks.field_with_label;
      }
    }
  }
};
</script>

<style lang="scss">
.g {
  .mc-event-page {
    background-color: #f2ede5;
    .g_light {
      background-color: #f2ede5;
    }
    .bl {
      position: absolute;
      left: 1.5rem;
      bottom: 2rem;
      margin: 0;
      @media screen and (min-width: 993px) {
        left: 2rem;
        top: 50%;
        bottom: unset;
      }
    }
    .g_mainHeader {
      height: 18.75rem;
      display: flex;
      align-items: center;
      @media screen and (min-width: 992px) {
        height: 31.25rem;
      }

      .responsive-image{
        margin: auto;
      }

      .g_content {
        height: 300px;
        padding: 0 !important;
        padding-top: 60px !important;
      }
    }
    &__coffee-expert {
      font-weight: 200;
      font-size: 1.125rem;
      font-style: italic;
      text-align: center;
      @media screen and (min-width: 996px) {
        text-align: left;
      }

      &-prefix {
        margin-right: .125rem;
        text-transform: lowercase;
      }
    }
    &__expert-picture {
      &--full {
        display: none;

        @media screen and (min-width: 996px) {
          display: block;
        }
      }

      &--mobile {
        display: block;
        margin: 2rem 0 0;

        @media screen and (min-width: 996px) {
          display: none;
        }
      }
    }
    &__title {
      font-size: 24px;
      line-height: 30px;
      letter-spacing: 2px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 5px 0;
      text-align: center;
      @media screen and (min-width: 996px) {
        text-align: left;
      }
    }
    &__text-infos {
      display: flex;
      flex-direction: column;
      @media screen and (min-width: 996px) {
        display: initial;
      }
    }
    &__map-form {
      @media screen and (min-width: 996px) {
        display: flex;
        margin-top: 20px;
        column-gap: 2.5rem;
      }
    }

    &__infos {
      display: flex;
      margin: 1.5rem 0;
      flex-direction: column;

      .simple__text {
        p {
          margin: 0;
        }
      }

      .responsive-image {
        display: flex;
          img {
            width: 100%;
            @media screen and (min-width: 992px) {
              min-width: 21.875rem;
            }
          }
      }
      .simple__text {
        margin: 2.5rem 0;
        padding: 0;
        background-color: #f2ede5;
      }

      @media screen and (min-width: 996px) {
        display: flex;
        column-gap: 3rem;
        margin: 3rem 0;
        flex-direction: row;
        .responsive-image {
          max-width: 25rem;
        }
        .simple__text {
          margin: 2.5rem 0 0 0;

          p {
            text-align: initial;
            font-size: 18px;
            letter-spacing: 0;
          }
        }
      }
    }
  }

  .less-top {
    margin-top: -150px;
  }

  .g_restrict {
    &.large-top {
      padding-top: 250px;
    }
  }

  #formRegister {
    @media (max-width: 600px) {
      padding: 9.375rem 0 0;
    }
  }
}
</style>
