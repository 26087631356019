<template>
  <div class="modale" :class="dataModale ? 'modale--open' : ''">
    <Cross :onClick="this.$parent.toggleModaleFilter" />
    <div class="modale__spinner" v-if="loading">
      <Spinner />
    </div>
    <h2 class="modale__title" v-html="brickContent.field_global_texts_to_filter_loc" />
    <div class="modale__section" v-for="(section, key, index) in filters" :key="index">
      <h3 class="modale__section__title">
        {{sectionTitle(key)}}
      </h3>
      <template v-if="index == 0">
        <span class="modale__clear" @click="clearAllFilter">{{$i18nSL.clearAllFilters}}</span>
      </template>
      <div class="modale__items">
        <template v-if="key !== 'distance'">
          <div class="checkbox__container">
            <div class="checkbox__column" v-for="(filter, index) in section" :key="index">
              <Checkbox :data-label="filter.label" v-model="filter.active" :key="index" />
            </div>
          </div>
        </template>
        <template v-else>
          <div class="modale__slider">
            <span class="slider__info" v-if="filters.distance" :style="{ left: positionFrame+'%' }">{{section ? section : '...'}} km</span>
            <RangeSlider class="slider" :min="minDistance" :max="maxDistance" :step="minDistance" v-model="filters.distance"/>
            <span class="slider__min">{{ minDistance }} km</span>
            <span class="slider__max">{{ maxDistance }} km</span>
            <button class="slider__clear" v-if="filters.distance" @click="clearDistanceFilter">{{$i18nSL.clear}}</button>
          </div>
        </template>
      </div>
    </div>
    <button class="modale__button" v-html="brickContent.field_global_texts_apply_filters" @click="handleApplyFilters"/>
  </div>
</template>

<script>
import _ from 'lodash';
import RangeSlider from 'vue-range-slider';
import 'vue-range-slider/dist/vue-range-slider.css';
import { mapActions, mapState } from 'vuex';
import Cross from '@/components/store-locator/partials/Cross';
import Checkbox from '@/components/store-locator/partials/Checkbox';
import Spinner from '@/components/store-locator/partials/Spinner'

export default {
  name: 'FilterModale',
  components: {
    Cross,
    Checkbox,
    RangeSlider,
    Spinner
  },
  props: {
    dataModale: {Type: Boolean}
  },
  inject: ['brickContent'],
  data() {
    return {
      loading: false,
      filters: _.cloneDeep(this.$store.getters["store_locator/getFilter"]),
      minDistance: 2,
      maxDistance: 300,
      sectionTitles: {
        services: this.brickContent.field_global_texts_i_want_to,
        storeTypes: this.brickContent.field_global_texts_location_type,
        distance: this.brickContent.field_global_texts_distance,
        paymentMethods: this.brickContent.field_global_texts_payment_metho
      }
    }
  },
  computed: {
    ...mapState('store_locator', ['defaultFilter']),
    positionFrame() { return this.filters.distance * 100 / (this.maxDistance - this.minDistance) },
    filterWatcher() { return this.$store.getters["store_locator/getFilter"] }
  },
  watch: {
    filterWatcher(val) { this.filters = _.cloneDeep(val) }
  },
  methods: {
    ...mapActions('store_locator', ['applyFilters', 'updateUrlParams']),
    sectionTitle(sectionKey) { return this.sectionTitles[sectionKey] ? this.sectionTitles[sectionKey] : sectionKey },
    clearDistanceFilter() { this.filters.distance = 0 },
    handleApplyFilters() {
      this.loading = true;
      setTimeout(()=>{
        this.applyFilters(_.cloneDeep(this.filters))
        this.updateUrlParams({router: this.$router, route: this.$route})
        this.$parent.toggleModaleFilter()
        this.loading = false;
      }, 100)
    },
    async clearAllFilter() {
      this.loading = true;
      await this.$store.commit("store_locator/resetFilters")
      this.applyFilters(_.cloneDeep(this.filters))
      this.updateUrlParams({router: this.$router, route: this.$route})
      this.loading = false;
    }
  },
  created(){
    const query = this.$route.query
    const toCheck = [
        { query: 'st', filter: "storeTypes" },
        { query: 'pm', filter: "paymentMethods" },
        { query: 'se', filter: "services" },
    ]
    toCheck.forEach(item => {
      if (query[item.query]) {
        this.filters[item.filter].forEach(storetype => {
          if (query[item.query].includes(storetype.value)) {
            storetype.active = true
          }
        })
        this.applyFilters(_.cloneDeep(this.filters))
        this.updateUrlParams({router: this.$router, route: this.$route})
      } else {
        this.filters[item.filter].forEach(storetype => {
          this.defaultFilter.forEach(defaultFilter => {
            if (defaultFilter.id == storetype.value)
              storetype.active = true
          })
        })
        this.applyFilters(_.cloneDeep(this.filters))
      }
    })

  }
}
</script>

<style lang="scss">
.g {
  .modale {
    position: fixed;
    top: 110px; left: 0;
    z-index: 21;
    width: 100%;
    height: 100%;
    max-height: 0px;
    padding: 0 10% 0 10%;
    background: #ffffff;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease-in-out;
    overflow: hidden;

    @media (min-width: 992px) {
      position: absolute;
      top: 100%; left: 2px;
      width: calc(100% - 2px);
      height: auto;
    }

    &--open {
      max-height: calc(100vh - 110px);
      padding: 40px 10% 24px 10%;
      overflow: auto;

      @media (min-width: 992px) { max-height: calc(100vh - 250px); }
    }

    &__section__title, &__title { text-transform: uppercase; }

    &__section {
      position: relative;

      &__title {
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 3px;
        margin: 24px 0 16px;
      }
    }

    &__title {
      text-align: center;
      font-weight: 300;
      font-size: 36px;
      line-height: 48px;
      letter-spacing: 8px;
    }

    &__clear {
      position: absolute;
      top: 0;
      right: 0;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-decoration: underline;
      letter-spacing: 1px;
      cursor: pointer;
    }

    .checkbox {
      &__container { display: flex; justify-content: space-between; flex-wrap: wrap;}

      &__column {
        width: 100%;
        display: flex;
        @media (min-width: 600px) { width: 33%; }

        .checkbox__custom { padding: 8px 0; }
      }
    }

    &__slider {
      position: relative;
      display: inline;

      .slider__info {
        position: absolute;
        bottom: calc(100% + 15px);
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 30px;
        border-radius: 4px;
        background: #000;
        color: #ffffff;
        font-size: 14px;
        letter-spacing: 1px;
        transform: translate(-50%, 0);

        &::after {
          content: '';
          position: absolute;
          top: 100%;
          display: block;
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid #000;
        }
      }

      .slider {
        width: 100%;
        max-width: 323px;
        padding: 0;
        margin: 25px 0 15px 0;

        .range-slider-rail { background: rgba(155, 155, 155, 0.75); }

        .range-slider-fill { background: #000000; }

        .range-slider-knob { position: relative; border: 2px solid rgba(155, 155, 155, 0.75); }

        &__min, &__max {
          position: absolute;
          top: 12px;
          font-size: 14px;
          line-height: 21px;
        }

        &__min { left: 0; }

        &__max { right: 0; }

        &__clear{
          position: absolute;
          left: calc(100% + 10px);
          top: 0px;
          transform: translateY(-50%);
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          text-decoration: underline;
          letter-spacing: 1px;
          cursor: pointer;
        }
      }
    }

    &__button {
      display: block;
      width: 223px;
      height: 40px;
      margin: 32px auto 0 auto;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-transform: uppercase;
      border-radius: 4px;
      background: #000000;
      color: #ffffff;
    }
  }
}
</style>
