<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section"
        :class="'g_' + (brickContent.theme ? brickContent.theme : 'light') + ' g_' + brickContent.cta_alignment">

        <div class="g_restrict">
            <div class="g_content g_ctaContainer">
                <div class="g_text">

                    <!-- CTA Button -->
                    <div class="g_richText g_wysiwyg t-center" v-if="brickContent.button_link">
                        <a :href="brickContent.button_link.url" :class="brickContent.button_style.substr(1)" v-html="brickContent.button_link.title"></a>
                    </div>

                    <!-- Link -->
                    <div class="g_richText g_wysiwyg t-center" v-if="brickContent.text_link">
                        <a :href="brickContent.text_link.url" class="g_link">
                            <span>{{brickContent.text_link.title}}</span> 
                            <i class="fn_angleLink"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Cta',
    props: {
        brickContent: { type: Object, default: null }
    }
}
</script>
