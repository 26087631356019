var $ = window.$ ? window.$ : window.jQuery;

const setJquery = () => {
  return new Promise(resolve => {
    if ($) {
      resolve($);
      return;
    }
    var jqueryCheckInterval = setInterval(() => {
      $ = window.$ ? window.$ : window.jQuery;
      if ($) {
        clearInterval(jqueryCheckInterval);
        resolve($);
      }
    }, 20);
  });
};

export { setJquery, $ };
