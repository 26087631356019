<template>
  <GmapMarker 
  v-bind="$attrs"
  v-on="$listeners"
  :icon="dynamicIcon"
  @mouseover="isHover = true"
  @mouseout="isHover = false"
  />
</template>
<script>
import {GmapMarker} from 'vue2-google-maps/src/components/marker';
export default {
  name: 'CustomMarker',
  extends: GmapMarker,
  data() {
    return {
      isHover: false,
    }
  },
  props: {
    'hoverIcon': {
      type: Object
    },
    'icon': {
      type: Object,
    }
  },
  computed: {
    dynamicIcon() {
      return this.isHover ? this.hoverIcon: this.icon;
    }
  },
}
</script>