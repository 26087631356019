<template>
  <div class="cl">
    <!-- cl for category list -->
    <h4 class="cl__title">{{ btnTitleData }}</h4>
    <VueMultiselect
      v-model="value"
      :searchable="false"
      :multiple="true"
      :options="categoryOptions"
      track-by="uuid"
      label="label"
      @input="changeMultiSelect"
    />
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

export default {
  name: "CategoriesList",
  components: {
    VueMultiselect
  },
  props: {
    defaultFilters: { type: Array, default: null },
    btnTitleData: { type: String, default: null },
    alias: { type: Object, default: null },
  },
  data() {
    return {
      value: [],
      hide: false
    };
  },
  computed: {
    categoryOptions() {
      const categories = this.$store.getters["event/getTheme"];
      const options = [];
      for(var i = 0; i < categories.length; i++){
        const option = {
          uuid: categories[i].id,
          label: categories[i].name
        };
        options.push(option);
      }
      return options;
    },
  },
  watch: {
    categoryOptions: {
      deep: true,
      immediate: true,
      handler() {
        if(this.alias !== null){
          this.initOptionValue();
        }
      }
    },
    $route (){
      if(this.$route.query[this.alias.label] === undefined || !this.$route.query[this.alias.label]){
        this.value = [];
      }
    }
  },
  methods: {
    changeMultiSelect(){
      const values = [];
      if(this.alias !== null && this.alias !== undefined){
        for(let i = 0; i < this.value.length; i ++){
          const label = this.alias.values.filter(filter_value => filter_value.uuid === this.value[i].uuid);
          values.push(label[0].label);
        }
        this.$emit("input", values);
        this.$parent.applyFilters();
      }
    },
    initOptionValue() {
      if (this.$route.query[this.alias.label] !== undefined && this.$route.query[this.alias.label].length && this.$route.query[this.alias.label] !== "" && this.categoryOptions.length) {
        const preset = this.$route.query[this.alias.label].split('+');
        for (let i = 0; i < preset.length; i++) {
          this.value.push(this.alias.values.filter(a => a.label === preset[i])[0]);
        }
      }
    }
  },
};
</script>

<style lang="scss">
.g {
  .cl {
    &__title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: .875rem;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        font-size: .875rem;
      }
    }

    @media (min-width: 768px) {
      text-align: center;
    }

    &__button {
      display: inline-flex;
      align-items: center;
      font: {
        weight: bold;
        size: 14px;
      }
      color: #000;
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 21px;

      svg {
        margin-left: 10px;
        transform: rotate(180deg);
        transition: all 0.2s;
      }

      &--up {
        svg {
          transform: rotate(0);
        }
      }
    }

    &__panel {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
      text-align: left;
      opacity: 1;
      max-width: 800px;
      @media (min-width: 768px) {
        margin: 0 auto;
      }

      > * {
        @media (min-width: 768px) {
          width: 33%;
        }
        width: 100%;
        min-width: 150px;
        padding: 15px;
      }
    }
  }
}
</style>
