<template>
  <div class="geoloacated-denied-modal" v-if="value" @click="close">
    <div class="geoloacated-denied-modal__backdrop"></div>
    <div class="geoloacated-denied-modal__content">
      {{this.$i18nSL.denyGeolocWarning}}
    </div>
  </div>
</template>
<script>

export default {
  props: { value: {type: Boolean, required: true} },
  methods: {
    close(){ this.$emit('input', false) }
  },
}
</script>
<style lang="scss">
.g {
  .geoloacated-denied-modal {
    &__backdrop {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 9998;
      background-color: rgba(#000, 0.7);
    }

    &__content {
      width: 80%;
      max-width: 360px;
      position: fixed;
      top: 0;
      z-index: 9999;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 20px;
      border-radius: 3px;
      color: red;
      background-color: #fff;
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
    }
  }
}
</style>
