<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_simpleTitle g_center"
        :class="['g_' + brickContent.field_theme]">
            <div class="g_restrict">
                <div class="g_content">
                    <div class="g_text">

                        <!-- Title -->
                        <h2 class="g_h2" v-if="brickContent.field_title_format === 'h2'" v-html="brickContent.title"></h2>
                        <h1 class="g_h1" v-if="brickContent.field_title_format === 'h1'" v-html="brickContent.title"></h1>
                    </div>
                </div>
            </div>
    </section>
</template>

<script>

export default {
    name: 'SimpleTitle',
    props: {
        brickContent: { type: Object, default: null }
    }
}
</script>
