<template>
  <div>
    <sticky-breadcrumb-menu v-if="breadcrumb" :brick-content="breadcrumb" />
    <slot />
  </div>
</template>
<script>
import StickyBreadcrumbMenu from "@/components/StickyBreadcrumbMenu.vue"
export default {
  components: {
    StickyBreadcrumbMenu
  },
  data() {
    return {
      breadcrumb: false
    }
  },
  created() {
    this.findBreadcrumb()
  },
  methods: {
    findBreadcrumb () {
      this.breadcrumb = this.$drupalPage.bricks.find(brick=>brick.type === 'brick--breadcrumbs')
    }
  }
}
</script>
