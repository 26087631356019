<template>
  <div class="map-control">
    <button class="map-control__fullscreen" @click="toggleFullscreen">
      <svg  height="26" viewBox="0 0 28 26" width="28" xmlns="http://www.w3.org/2000/svg"><path d="m25.2.400024h2.8v8.399996h-2.8v-5.6h-5.6v-2.799996zm-22.4 0h5.6v2.799996h-5.6v5.6h-2.8v-8.399996zm22.4 22.399976v-5.6h2.8v8.4h-8.4v-2.8zm-22.4 0h5.6v2.8h-8.4v-8.4h2.8z" fill="currentColor"/></svg>
    </button>
    <div class="map-control__zoom">
      <button class="map-control__zoom__btn" @click="zoom++">
        <svg fill="none" height="29" viewBox="0 0 28 29" width="28" xmlns="http://www.w3.org/2000/svg"><g fill="currentColor"><path clip-rule="evenodd" d="m14.75 8h-1.5v5.25h-5.25v1.5h5.25v5.25h1.5v-5.25h5.25v-1.5h-5.25z" fill-rule="evenodd"/><path d="m13.25 8v-.5h-.5v.5zm1.5 0h.5v-.5h-.5zm-1.5 5.25v.5h.5v-.5zm-5.25 0v-.5h-.5v.5zm0 1.5h-.5v.5h.5zm5.25 0h.5v-.5h-.5zm0 5.25h-.5v.5h.5zm1.5 0v.5h.5v-.5zm0-5.25v-.5h-.5v.5zm5.25 0v.5h.5v-.5zm0-1.5h.5v-.5h-.5zm-5.25 0h-.5v.5h.5zm-1.5-4.75h1.5v-1h-1.5zm.5 4.75v-5.25h-1v5.25zm-5.75.5h5.25v-1h-5.25zm.5 1v-1.5h-1v1.5zm4.75-.5h-5.25v1h5.25zm.5 5.75v-5.25h-1v5.25zm1-.5h-1.5v1h1.5zm-.5-4.75v5.25h1v-5.25zm5.75-.5h-5.25v1h5.25zm-.5-1v1.5h1v-1.5zm-4.75.5h5.25v-1h-5.25zm-.5-5.75v5.25h1v-5.25z"/></g></svg>
      </button>
      <button class="map-control__zoom__btn" @click="zoom--">
        <svg fill="none" height="28" viewBox="0 0 28 28" width="28" xmlns="http://www.w3.org/2000/svg"><path d="m8 13.5h12v1.5h-12z"  fill="currentColor"/></svg>
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  computed: {
    zoom: {
      get() { return this.$store.state.store_locator.zoom },
      set(value) { this.$store.commit("store_locator/updateZoom", value) },
    },
  },
  methods:{
    ...mapActions('store_locator', ['toggleFullscreen'])
  }
};
</script>
<style lang="scss" scoped>
.g {
  .map-control {
    display: inline-flex;
    flex-direction: column;
    padding-top: 30px;
    position: relative;

    &__zoom {
      position: relative;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
      border-radius: 2px;
      margin-top: 15px;

      &::after {
        content: "\00020";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        width: 22px;
        height: 1px;
        background-color: #E6E6E6;
      }

      &__btn {
        color: #666666;

        &:hover { color: #000; }
      }
    }

    &__fullscreen { color: #000; }
  }
}
</style>
