import EventDetail from "@/pages/masterclass/EventDetail";
import EventRegistration from "@/pages/masterclass/EventRegistration";
import DefaultPage from "../pages/DefaultPage";

const masterclassRoutes = [
  { path: "/", component: DefaultPage },
  { path: "/event/:id", name: 'eventDetail', component: EventDetail },
  { path: "/registration/success/:id", name: 'eventRegistrationSuccess', component: EventRegistration },
  { path: "/registration/cancel/:id/:sid", name: 'eventRegistrationCancel', component: EventRegistration },
  { path: "/registration/review/:id/:sid/:email", name: 'eventRegistrationReview', component: EventRegistration },
];

export default masterclassRoutes;
