<template>
    <section :id="brickContent.anchor_identifier" class="h h_quote" :class="['h_quote_' + brickContent.image_position, !brickContent.style ? 'h_quote_iscomplex' : '']" :style="'background-color: ' + brickContent.backgroundcolor.color + '; color: ' + (brickContent.text_color_black_or_white ? 'black' : 'white')">
      <div class="h_quote_complex" :style="'background-color: ' + brickContent.background_color_2.color"></div>
      <div class="h_quote_content">
        <div class="h_quote_media" :class="brickContent.videos ? 'h_quote_media--video' : 'h_quote_media--image'">
          <Video v-if="brickContent.videos" :brickContent="brickContent.videos" :aspect-ratio="aspectRatio"></Video>
          <div class="h_quote_image" :style="'height: ' + this.heightOfImage + 'px; width: ' + this.widthOfImage + 'px'" v-if="!brickContent.videos && (brickContent.image && brickContent.image.image)">
            <image-responsive v-if="!brickContent.videos && (brickContent.image && brickContent.image.image)"
              :image="brickContent.image.image.image_style_uri[this.desktopImageStyle]"
              :imageMobile="mobileImage" />
          </div>
        </div>

        <div class="h_quote_text" :class="brickContent.text_color_black_or_white ? 'h_quote_text_dark' : 'h_quote_text_light'">
          <span class="h_quote_opening">“</span>
          <div class="h_quote_quote" v-html="brickContent.quote_text.processed"></div>
          <span class="h_quote_closing">”</span>
          <div class="h_quote_author" v-html="brickContent.quote_author.value"></div>
        </div>
      </div>
      <div class="h_quote_complex h_quote_complex_2" v-if="brickContent.image_position === 'right' || brickContent.image_position === 'left' || brickContent.image_position === 'bottom'" :style="'background-color: ' + brickContent.background_color_2.color"></div>
    </section>
</template>

<script>
import Video from "@/components/Video";
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
    name: 'Quote',
    components: {
      ImageResponsive,
      Video
    },
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            isModalActive: false,
            display: 'h_quote_right',
            heightOfImage: null,
            widthOfImage: null,
            desktopImageStyle: '2_3_mobile',
            mobileImage: this.brickContent.image.image.image_style_uri['2_3_mobile'],
            aspectRatio: '7:4',
        }
    },
    mounted() {
      switch(this.brickContent.image_position){
        case 'bottom':
        case 'top':
          this.desktopImageStyle = '60_31_desktop';
          break;
        default:
          this.desktopImageStyle = '2_3_mobile';
      }

      if(this.brickContent.image.field_override_mobile){
        this.mobileImage = this.brickContent.image.mobile_image.image_style_uri.scale_only;
      }
    },
}
</script>
