<template>
  <div class="news-card" :class="'news-card--'+align">
    <img  class="news-card__image" v-if="image" :src="image" />
    <div class="news-card__content">
      <h3 class="news-card__title">{{news.title}}</h3>
      <div class="news-card__desc" v-html="news.text.value"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    news: { type: Object, required: true },
    align: { type: String, required: false, default: 'left' },
  },
  computed: {
    image() {
      if (this.news.image && this.news.image.length) {
        return this.news.image[0].image.uri.full_url;
      }
      return null;
    },
  },
}
</script>
<style lang="scss" scoped>
.news-card{
  width: 100%;
  background-color: white;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow: hidden;

  &--left{ text-align: left; }

  &--center {
    text-align: center;

    .news-card__desc { margin: auto; }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__content {
    color: #000;
    padding: 24px;
  }

  &__title {
    margin-bottom: 8px;
    word-break: break-word;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
  }

  &__desc {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 1px;
    max-width: 445px;
    ::v-deep p{
      &:last-child{
        .g_link{
          margin-bottom: 0;
        }
      }
      &:not(:last-child){
        margin-bottom: 0.5em;
      }
    }
  }

  &__link {
    text-align: center;
    display: inline-flex;
    justify-content: center;
    margin-top: 8px;
  }
  ::v-deep .g_link{
    word-break: break-word;
  }
}
</style>
