// Replace the '↵' character with a <br/> *
// This will only work if the string is added in v-html
function nextLine(string) {
    return string.replace(/(\r\n|\n|\r)/gm, "<br />");
}
function deg2rad(deg) {
  return deg * (Math.PI/180);
}

/**
 * Get distance in km between two coordinate point {lat: value, lng: value}
 * @param {Object} point1
 * @param {Object} point2
 */
function getDistanceLngLat(point1, point2) {
  if(!point1 || !point2){
    return 0;
  }
  const lat1 = point1.lat;
  const lon1 = point1.lng;
  const lat2 = point2.lat;
  const lon2 = point2.lng;
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2-lat1);  // deg2rad below
  const dLon = deg2rad(lon2-lon1);
  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d = R * c; // Distance in km
  return d;
}

function getBoutiqueDistance(boutique, userPosition){
  return getDistanceLngLat(
    boutique.position,
    userPosition
  );
}
function getBoutiqueDistanceDisplay(boutique, userPosition){
  const km = getBoutiqueDistance(boutique, userPosition);
  if (km>1){
    return Math.round(km * 10) / 10 + " km";
  }

  return Math.round(km * 1000) + " m";
}

function getScriptUrl() {
    // Search for the build.js file in the page
    const scriptUrl = document.querySelector('script[src$="build.js"]').getAttribute('src');

    // Create a temporary a element to get the origin URL of the script
    const tmp = document.createElement('a');
    tmp.href = scriptUrl;

    // IE11 fix because it doesn't know tmp.origin
    if (tmp.origin === undefined) {
        tmp.origin = tmp.protocol + "//" + tmp.hostname;
    }

    return tmp.origin;
}

const arrayHaveCommonElements = (arr1, arr2) => {
  const [shortArr, longArr] = (arr1.length < arr2.length) ? [arr1, arr2] : [arr2, arr1];
  const longArrSet = new Set(longArr);
  return shortArr.some(el => longArrSet.has(el));
};

const generateNespressoUrl = (url, isB2B = false, lang = 'en') => {
  url = url.replace('internal:/', '/');
  let baseUrl = 'https://www.nespresso.com';
  if(isB2B){
    baseUrl = `${baseUrl}/pro`;
  }
  baseUrl = `${baseUrl}/ch/${lang}${url}`;
  return baseUrl;
};


export { nextLine, getScriptUrl, getDistanceLngLat, getBoutiqueDistance, arrayHaveCommonElements, getBoutiqueDistanceDisplay, generateNespressoUrl };
