<template>
    <div class="g_sust-thanks" :style="getBackgroundStyle(project)">
        <div class="g_restrict">
            <div class="g_content">
                <div class="g_sust-thanks-content">
                    <div class="g_sust-thanks-col">
                        <div class="g_sust-thanks-col-inner">
                            <div class="g_sust-thanks-message">
                                <h3 class="g_sust-thanks-message-title">{{ project.title_thanks_message }}</h3>
                                <div class="g_richText g_wysiwyg g_txt_L" v-if="project.description_thanks_message" v-html="project.description_thanks_message.value"></div>
                                <img class="g_sust-thanks-message-logo" v-if="project.media_image && project.media_image.image" :src="project.media_image.image.uri.full_url" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="g_sust-thanks-col">
                        <div class="g_sust-thanks-col-inner">
                            <div class="g_sust-thanks-share-logo">
                                <img class="g_sust-thanks-share-logo-img" v-if="project.image_share && project.image_share.image" :src="project.image_share.image.uri.full_url" alt="">
                            </div>
                            <div class="g_sust-thanks-share-container">
                                <h4 class="g_sust-thanks-share-legend g_txt_L">{{ project.legend_share }}</h4>
                                <div class="g_sust-thanks-share-message g_txt_L">{{ project.share_message }}</div>
                                <div class="g_sust-thanks-share-bottom">
                                    <h4 class="g_sust-thanks-share-description g_txt_L">{{ project.description_share }}</h4>
                                    <div class="g_sust-thanks-share-socials">
                                        <social-sharing v-if="project.link_share && project.link_share.url" :url="project.link_share.url" network-tag="button" inline-template>
                                            <ul>
                                                <li>
                                                    <network network="facebook">
                                                        <svg width="13" height="25" viewBox="0 0 13 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.1491 14.0625L12.8109 9.57032H8.60363V6.64065C8.60363 5.37111 9.17091 4.19921 11.0618 4.19921H13V0.341751C13 0.341751 11.2509 0 9.59637 0C6.14545 0 3.87639 2.19724 3.87639 6.10347V9.57032H0V14.0625H3.87639V25H8.60363V14.0625H12.1491Z" fill="white"/>
                                                        </svg>
                                                    </network>
                                                </li>
                                                <li>
                                                    <network network="twitter">
                                                        <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M22.4553 5.46383C23.4344 4.74093 24.3147 3.87352 25 2.86145C24.1189 3.24697 23.0917 3.53613 22.0645 3.63249C23.1407 3.00603 23.9231 2.04218 24.3147 0.837384C23.3365 1.41563 22.2114 1.84937 21.0855 2.09032C20.1073 1.07833 18.7864 0.5 17.3187 0.5C14.4811 0.5 12.1821 2.76508 12.1821 5.5602C12.1821 5.9458 12.231 6.33132 12.3289 6.71685C8.07234 6.47591 4.25633 4.45176 1.71232 1.41563C1.272 2.13854 1.02741 3.00603 1.02741 3.96988C1.02741 5.70478 1.90805 7.24695 3.32682 8.16266C2.49506 8.11444 1.66337 7.92164 0.978458 7.53612V7.58434C0.978458 10.0421 2.73973 12.0662 5.08802 12.5482C4.69665 12.6445 4.20738 12.741 3.76706 12.741C3.42464 12.741 3.1311 12.6928 2.7886 12.6445C3.42464 14.6687 5.33261 16.1144 7.58307 16.1626C5.82188 17.512 3.62029 18.3313 1.22313 18.3313C0.782815 18.3313 0.391367 18.2831 0 18.2349C2.25046 19.6807 4.94124 20.5 7.87662 20.5C17.3187 20.5 22.4553 12.8373 22.4553 6.13852C22.4553 5.89758 22.4553 5.70478 22.4553 5.46383Z" fill="white"/>
                                                        </svg>
                                                    </network>
                                                </li>
                                                <li>
                                                    <network network="linkedin">
                                                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0)">
                                                                <path d="M4.91118 22.5V7.78409H0.343956V22.5H4.91118ZM2.60259 5.81541C4.07587 5.81541 5.25432 4.58501 5.25432 3.10846C5.25432 1.68116 4.07587 0.5 2.60259 0.5C1.17846 0.5 0 1.68116 0 3.10846C0 4.58501 1.17846 5.81541 2.60259 5.81541ZM21.9517 22.5H22V14.4284C22 10.491 21.1164 7.43961 16.5 7.43961C14.2905 7.43961 12.8172 8.67002 12.1785 9.80201H12.1293V7.78409H7.75945V22.5H12.3259V15.2159C12.3259 13.2964 12.6698 11.4754 15.0267 11.4754C17.3845 11.4754 17.4336 13.641 17.4336 15.3636V22.5H21.9517Z" fill="white"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0">
                                                                    <rect width="22" height="22" fill="white" transform="translate(0 0.5)"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </network>
                                                </li>
                                            </ul>
                                        </social-sharing>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { $ } from "@/jquery";

export default {
    name: 'SustainabilityThanks',
    components: {
    },
    props: {
        project: { type: Object, default: null },
        height: { type: Number, default: 0 }
    },
    mounted() {
      const $thanksContainer = $(".g_sust-thanks");
      $thanksContainer.css("min-height", this.height + "px");
    },
    methods: {
        getBackgroundStyle(project) {
            const backgroundComponent = project.components.find(comp => comp.type === 'custom_entity--background_earth_component');
            if (backgroundComponent && backgroundComponent.image && backgroundComponent.image.image) {
            return "background-image: url(" + backgroundComponent.image.image.uri.full_url + ");";
            } else {
            return "";
            }
        }
    }
}
</script>
