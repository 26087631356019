import Vue from 'vue'
import Vuex from 'vuex'
import event from './masterclass/event.module'
import store_locator from './store-locator/store-locator.module'

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        event: event,
        store_locator: store_locator
    }
})
