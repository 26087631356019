<template>
    <picture class="responsive-image">
      <source media="(max-width: 995px)" :srcset="this.imageMobile" />
      <source v-if="imageTablet" media="(min-width: 996px) and (max-width: 1367px)" :srcset="this.imageTablet" />
      <source v-if="imageTablet" media="(min-width: 1368px)" :srcset="this.image" />
      <source v-if="imageTablet === null" media="(min-width: 996px)" :srcset="this.image" />
      <img
        :src="this.imageMobile"
        alt="" />
    </picture>
</template>

<script>
export default {
    name: 'ImageResponsive',
    props: {
        imageMobile: { type: String, default: null },
        imageTablet: { type: String, default: null },
        image: { type: String, default: null },
    },
}
</script>
