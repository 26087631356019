<template>
    <div>
        <!-- Product image -->
        <div class="g_itemRow">
            <div class="g_visual">
                <div class="g_image" v-lazy:background-image="'https://www.nespresso.com' + slide.responsiveImages.standard"></div>
            </div>
        </div>

        <!-- Product name -->
        <div class="g_itemRow">
            <div class="g_description">
                <h3 class="g_h3" v-html="slide.name"></h3>
            </div>
        </div>

        <div class="g_itemRow">
            <div class="g_cta">
                <div class="g_addToCart g_mini">
                    <div class="g_priceAndButton track-impression-product">
                        <p class="g_productPrice g_offerPrice" v-if="slide.offer_price && !slide.strikethrough">{{ currency }} {{ slide.offer_price }}</p>
                        <p
                            class="g_productPrice"
                            :class="[slide.offer_price && slide.strikethrough ? 'g_productPrice-striked' : '', slide.offer_price && !slide.strikethrough ? 'g_productPrice-withoffer' : '']"
                        >
                            <span v-if="slide.offer_price && !slide.strikethrough">{{ $t('commercialOffer.insteadOf') }}</span> {{ currency }} {{ slide.unitPrice }}
                        </p>
                        <p class="g_productPrice g_offerPrice" v-if="slide.offer_price && slide.strikethrough">{{ currency }} {{ slide.offer_price }}</p>
                        <div :data-product-id="slide.id" v-if="brickContent.display_button" class="g_priceAndButton track-impression-product add-to-bag" data-button-size="small"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { CURRENCY_PREFIX } from '../../services/params';

export default {
    name: 'Product',
    props: {
        slide: { type: Object, default: null },
        brickContent: { type: Object, default: null },
    },
    data() {
        return {
            currency: CURRENCY_PREFIX,
        };
    },
};
</script>
