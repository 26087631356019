<template>
  <div class="gmap-autocomplete">
    <SearchAutocompleteResults
      :results="results"
      :searchQuery="searchString"
      :visible="resultVisible"
      :loading="resultLoading"
      v-model="searchString"
      @input="handleInput"
      @select_place="handleSelectPlace"
    />
  </div>
</template>
<script>
import { gmapApi } from "vue2-google-maps";
import { mapActions, mapMutations, mapState } from "vuex";
import SearchAutocompleteResults from "@/components/store-locator/partials/SearchAutocompleteResults.vue";
export default {
  components:{SearchAutocompleteResults},
  data() {
    return {
      autocomplete: null,
      init: false,
      geocoder: null,
      sessionToken: null,
      resultLoading:true,
      resultVisible: false,
      service: null,
      inputTimeout: null,
      resultsPlaces: [],
      resultsBoutiques: [],
      resultsFilters: [],
      allFilters: [],
      results: {places: [], boutiques: [], filters:[]}
    }
  },
  computed: {
    ...mapState("store_locator", ["map", "filters", "allBoutiques", "searchQuery"]),
    searchString: {
      get(){
        return this.searchQuery;
      },
      set(val){
        this.setSearchQuery(val)
      }
    },
    google: gmapApi
  },
  watch: {
    map() {
      if (!this.init)
        this.initAutocompleteInput()
    },
  },
  mounted() {
    if (this.map)
      this.initAutocompleteInput()
  },
  methods: {
    ...mapMutations('store_locator', ['updateZoom', 'setGeolocated', "setSearchQuery"]),
    ...mapActions('store_locator', ['updateUserPosition', "updateCenter"]),
    initAutocompleteInput() {
      this.init = true;
      const google = this.google;
      this.sessionToken = new google.maps.places.AutocompleteSessionToken();
      this.service = new google.maps.places.AutocompleteService();
      this.geocoder = new this.google.maps.Geocoder();
    },
    handleInput() {
      clearTimeout(this.inputTimeout);
      this.inputTimeout = setTimeout(() => {
        if (this.searchString.length >= 3){
           this.doSearch()
        }else{
          this.clearResults()
        }
      }, 800);
    },
    getPredictionSuggestion(){
      return new Promise((resolve) => {
        this.service.getPlacePredictions({
            input: this.searchString,
            // types: ['geocode'], // (https://developers.google.com/places/supported_types#table3)
            componentRestrictions: {country: 'ch'},
            sessionToken: this.sessionToken
        }, (place,status) => {
            if (status != this.google.maps.places.PlacesServiceStatus.OK) {
                resolve([])
            } else {
                resolve(place);
            }
        });
      })
    },
    async doSearch() {
      const promises = []
      this.resultLoading = true;
      promises.push(this.getPlaceResults())
      promises.push(this.getBoutiquesResults())
      promises.push(this.getFilterResults())
      await Promise.all(promises);
      this.resultLoading = false;
      this.setResults();
    },
    clearResults(){
      this.resultsPlaces = []
      this.resultsFilters = []
      this.resultsBoutiques = []
      this.setResults();
    },
    async geocode(placeId){
      return new Promise(resolve => {
        this.geocoder.geocode({placeId}, (results, status)=>{
          if (status === "OK") {
            if(results[0]){
              resolve( {
                type: results[0].types,
                position: {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng(), }
               })
            }else{
              console.error("No result found")
            }
          }else{
            console.error(status)
          }
        })
      })
    },
    setResults() { 
      this.results = { 
        places: this.resultsPlaces, 
        boutiques: this.resultsBoutiques, 
        filters:this.resultsFilters 
      } 
    },
    async getBoutiquesResults(){
      const onlyDigit = /^\d+$/.test(this.searchString);
      if(onlyDigit && this.searchString.length === 4){
        this.resultsBoutiques = this.getBoutiquesResultsByPostCode();
      }else{
        this.resultsBoutiques = this.getBoutiqueResultsByTitle()
      }
    },
    filterMax(array, condition, max = 5){
      const results  = []
      for(let i = 0; i<array.length; i++){
        if(condition(array[i])) results.push(array[i]);
        if(results.length >=max) i=array.length;
      }
      return results
    },
    getBoutiquesResultsByPostCode(){
      return this.filterMax(this.allBoutiques, boutique=>{
          if(!boutique.address || !boutique.address.postal_code){
            return false;
          }
          return boutique.address.postal_code === this.searchString;
      })
    },
    getBoutiqueResultsByTitle(){
      return this.filterMax(this.allBoutiques, boutique => {
          return boutique.title ? boutique.title.toLowerCase().includes(this.searchString.toLowerCase()) : false;
      })
    },
    async getFilterResults(){
      const allFilters = [...this.filters.paymentMethods, ...this.filters.services, ...this.filters.storeTypes];
      this.resultsFilters =  allFilters.filter(filter=>{
        return filter.label.toLowerCase().includes(this.searchString.toLowerCase())
      })
    },
    async getPlaceResults(){
      this.resultsPlaces = await this.getPredictionSuggestion()
    },
    async handleSelectPlace(item){
     const result = await this.geocode(item.key)
     this.updateCenter(result.position);
     if (result.type.includes("route"))
       this.updateZoom(16)
     else if (
       result.type.includes("colloquial_area") ||
       result.type.includes("sublocality") ||
       result.type.includes("neighborhood")
       )
        this.updateZoom(15)
     else
       this.updateZoom(13)
      this.updateUserPosition(result.position)
      this.setGeolocated(false)
    },
  
  },
};
</script>
<style lang="scss" scoped>
.gmap-autocomplete {
  margin: 0 16px;
  flex-grow: 1;

 
}
</style>
