<template>
  <div class="popin" :id="brickContent.id">
    <div class="popin__backdrop" @click="closePopin" :aria-controls="brickContent.id"></div>
    <div class="popin__body">
      <aside class="popin__wrapper">
        <div class="popin__top">
          <button :id="'button' + brickContent.id" :aria-label="$t('recipes.modalClose')" @click="closePopin"></button>
        </div>
        <div class="popin__content">
          <h4>{{ brickContent.title }}</h4>
          <article v-html="brickContent.popin_description.value" />
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
import { $ } from '@/jquery';
export default {
    name: 'Popin',
    props: {
        brickContent: { type: Object, default: null },
    },
    mounted() {
        $('a[data-entity-type="brick"]').on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.openPopin(e.target.dataset.entityUuid);
        });
    },
    methods: {
        openPopin(popinID) {
            $('html').addClass('g_scrollLock');
            $('#' + popinID).addClass('popin--is-open');
            if($('#button' + popinID).length){
              $('#button' + popinID)[0].focus();
            }
        },
        closePopin() {
            console.log(this);
            if ($('#' + this.$el.id).hasClass('popin--is-open')) {
                $('html').removeClass('g_scrollLock');
                $('#' + this.$el.id).removeClass('popin--is-open');
            }
        },
    },
};
</script>
<style lang="scss">
.popin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: none;
    align-items: center;
    justify-content: center;
    color: #000000;
    z-index: 10000;
    &--is-open {
      display: flex;
    }
    &__backdrop{
      background: rgba(#000000, 0.7);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__body{
      background-color: #fff;
      position: relative;
    }
    &__wrapper {
        display: grid !important;
        gap: 0.5rem;
        margin: auto;
        padding: 2rem;
        min-width: 350px;
        max-width: 60%;
        line-height: 1.5;
        text-align: center;
        background: #fff;
        max-height: 85vh;
        overflow-y: auto;

        h4 {
            text-transform: uppercase;
            font-size: 1.75rem;
            letter-spacing: 3px;
            font-weight: 700;
            margin: 0 0 1.25rem;
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;

        button {
            position: absolute;
            top: 0;
            right: 0;
            appearance: none;
            padding: 0.5rem;
            transform: translate(0.75rem, -1rem);
            height: 20px;
            width: 20px;

            &::before,
            &::after {
                content: ' ';
                display: block;
                width: 0.125rem;
                background-color: #000;
                height: 1rem;
                position: absolute;
                top: 0;
            }

            &::before {
                transform: rotateZ(-45deg);
            }

            &::after {
                transform: rotateZ(45deg);
            }
        }
    }

    a {
        color: #8f7247;

        &:hover {
            opacity: 0.9;
        }
    }
}
</style>
