<template>
  <div class="pagination-articles">
    <div class="pagination-articles__container">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage <= 1">
        {{ getPaginationLabel("previous") }}
      </button>

      <button
        v-for="page in totalPages"
        :key="page"
        @click="changePage(page)"
        class="btnPage"
        :class="{ active: currentPage === page }"
      >
        {{ page }}
      </button>

      <button @click="changePage(currentPage + 1)" :disabled="currentPage >= totalPages">
        {{ getPaginationLabel("next") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentPage: { type: Number, required: true },
    totalPages: { type: Number, required: true },
    getPaginationLabel: { type: Function, required: true }
  },
  methods: {
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit('page-changed', page);
      }
    }
  }
};
</script>

<style>
.pagination-articles {
  display: flex;
  justify-content: center;
  padding: 1rem;

  .btnPage {
    height: 2rem;
    border-radius: 50%;
    width: 2rem;
    padding: 7px 12px;

    &.active {
      border: 1px solid black;
    }
  }
}

.pagination-articles__container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px;
}
</style>
