<template>
  <div class="lang">
    <button class="lang__button" :class="hide ? 'lang__button--up' : ''" @click="toggleVisibility">
      <h4>{{btnTitleData}}</h4>
      <svg
        width="10px"
        height="6px"
        viewBox="0 0 10 6"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>_icons/minus copy</title>
        <g id="Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g
            id="Desktop---Design---Masterclass-"
            transform="translate(-1042.000000, -1289.000000)"
            fill="#000000"
          >
            <g id="_icons/minus-copy" transform="translate(1027.000000, 1272.000000)">
              <polygon
                id="color"
                transform="translate(20.000000, 20.000000) scale(1, -1) translate(-20.000000, -20.000000) "
                points="23.8213209 17.2776389 23.9627423 17.2776389 24.6698491 17.9847457 24.6698491 18.126167 20.073655 22.7223611 19.926345 22.7223611 15.3301509 18.126167 15.3301509 17.9847457 16.0372577 17.2776389 16.1786791 17.2776389 20 21.0989598"
              />
            </g>
          </g>
        </g>
      </svg>
    </button>
    <div class="lang__panel" :class="hide ? 'lang__panel--hide' : ''">
      <event-button
        v-for="(lang, index) in arrayLangData"
        :key="index"
        :name-data="lang"
        class="lang__button__radio"
        :class="value.includes(lang) ? 'lang__button__radio--selected' : ''"
        :selected-data.sync="value"
      />
    </div>
  </div>
</template>

<script>
import EventButton from "@/components/masterclass/EventButton"

export default {
  name: "EventLanguage",
  components: {
    EventButton,
  },
  props: {
    btnTitleData: { type: String, default: null },
    value: Array
  },
  data() {
    return {
      ...this.btnTitleData,
      ...this.value,
      hide: false,
    }
  },
  computed: {
    arrayLangData () {
      return this.$store.getters["event/getLang"]
    }
  },
  methods: {
    toggleVisibility: function () {
      this.hide ? (this.hide = false) : (this.hide = true)
      this.$parent.applyFilters();
    }
  },
}
</script>

<style lang="scss">
.g {
  .lang {
    @media (min-width: 768px) { text-align: center; }
    &__button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      padding-bottom: 8px;
      font: {
        weight: bold;
        size: 14px;
      }
      text-transform: uppercase;
      letter-spacing: 1px;
      line-height: 21px;
      svg {
        margin-left: 10px;
        transform: rotate(180deg);
        transition: all 0.2s;
      }
      &--up {
        svg {
          transform: rotate(0);
        }
      }
    }
    &__button__radio {
      padding: 8px;
    }
    &__panel {
      display: flex;
      @media (min-width: 768px) { justify-content: center; }
      margin: 0 -10px;
      transition: all 0.2s ease-in-out;
      transform-origin: top;
      height: auto;
      max-height: 350px;
      &--hide {
        opacity: 0;
        transform: scale(1, 0);
        max-height: 100px;
        margin-bottom: -25%;
        @media (min-width: 768px) { margin-bottom: 0%; }
      }
    }
  }
}
</style>
