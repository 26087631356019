<template>
  <span class="close-icon">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
      <path id="Layer 1" class="s0" d="m1 7h14v2h-14z"/>
      <path id="Layer 1 copy" class="s0" d="m9 1v14h-2v-14z"/>
    </svg>
  </span>
</template>

<script>
  export default {
    name: "CloseIcon"
  }
</script>

<style lang="scss">
  .s0 { fill: #000000 }
  .close-icon{
    width: 1rem;
    height: 1rem;
    svg{
      transform: rotate(45deg);
      filter: invert(44%) sepia(24%) saturate(755%) hue-rotate(357deg) brightness(98%) contrast(85%);
    }
  }
</style>
