<template>
  <section :id="brickContent.anchor_identifier" class="h h_textmedia" :class="['h_textmedia_'+classes[this.brickContent.field_text_layout], !brickContent.style ? 'h_textmedia_iscomplex' : '', brickContent.image === null && this.textAlign === 'h_textmedia_cover' ? 'h_textmedia_cover--no-image' : '']" :style="'background-color: ' + brickContent.backgroundcolor.color + '; color: ' + (brickContent.text_color ? 'black' : 'white') + '; --text-color:'+ (brickContent.text_color ? 'black' : 'white')">
    <image-responsive
      v-if="textAlign === 'h_textmedia_cover' && brickContent.image !== null"
      :image="brickContent.image.image.image_style_uri['21_9_desktop']"
      :imageMobile="mobileImage" />
    <div class="h_textmedia_content">
      <div v-if="(textAlign === 'h_textmedia_center' || textAlign === 'h_textmedia_center_right') && brickContent.cta" class="h_textmedia_cta_container">
        <a class="h_head_cta h-cta" :class="ctaStyle" v-if="brickContent.cta" :href="brickContent.cta">
          {{ brickContent.cta_label }}
        </a>
      </div>
      <div class="h_textmedia_media" v-if="this.textAlign !== 'h_textmedia_cover' && (brickContent.videos || (brickContent.image && brickContent.image.image))">
        <div class="h_textmedia_video" v-if="brickContent.videos">
          <Video :brickContent="brickContent.videos" :aspect-ratio="aspectRatio"></Video>
        </div>
        <div class="h_textmedia_image"  v-if="!brickContent.videos && brickContent.image && brickContent.image.image && this.textAlign !== 'h_textmedia_cover'">
          <image-responsive
            :v-if="brickContent.image"
            :image="brickContent.image.image.image_style_uri[imageStyleDesktop]"
            :imageMobile="mobileImage"
          />
        </div>
      </div>

      <div class="h_textmedia_text">
        <h3 class="h_head_caption" v-if="brickContent.field_caption">{{ brickContent.field_caption }}</h3>
        <h2 class="h_head_title" v-if="brickContent.title" v-html="brickContent.title"></h2>
        <div class="h_head_text" v-if="brickContent.text" v-html="sanitizedText"></div>
        <a class="h_head_cta h-cta" :class="ctaStyle" v-if="brickContent.cta && (textAlign !== 'h_textmedia_center' && textAlign !== 'h_textmedia_center_right')" :href="brickContent.cta">{{ brickContent.cta_label }}</a>
      </div>
    </div>

    <!-- Only displayed if complex component -->
    <div class="h_textmedia_complex" v-if="!brickContent.style" :style="'background-color: ' + brickContent.background_color_2.color"></div>
  </section>
</template>

<script>
import Video from "@/components/Video";
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
  name: 'BasicTextImageContent',
  components: {
    ImageResponsive,
    Video
  },
  data() {
    return {
      textAlign: 'h_textmedia_center',
      ctaColor: '#fff',
      ctaStyle: 'h-cta--'+this.brickContent.field_cta_style,
      imageStyleDesktop: '2_3_desktop',
      mobileImage: this.brickContent.image ? this.brickContent.image.image.image_style_uri['2_3_mobile'] : false,
      aspectRatio: '16:9',
      classes: {'0': 'right', '1': 'left', '2': 'center_right', '3': 'center', '4': 'cover'}
    }
  },
  props: {
    brickContent: { type: Object, default: null }
  },
  mounted() {
    switch (this.brickContent.field_text_layout){
      case '0':
        this.textAlign = 'h_textmedia_right';
        break;
      case '1':
        this.textAlign = 'h_textmedia_left';
        break;
      case '2':
        this.textAlign = 'h_textmedia_center_right'
        break;
      case '3':
        this.textAlign = 'h_textmedia_center';
        break;
      case '4':
        this.textAlign = 'h_textmedia_cover';
        break;
    }

    if(
      !this.brickContent.style &&
      (this.textAlign === 'h_textmedia_center' || this.textAlign === 'h_textmedia_center_right'))
    {
      this.ctaColor = this.brickContent.backgroundcolor.color;
    }

    if(this.textAlign === 'h_textmedia_center' || this.textAlign === 'h_textmedia_center_right'){
      this.aspectRatio = '7:4';
    }else{
      this.aspectRatio = '16:9';
    }

    if(this.textAlign === 'h_textmedia_center' || this.textAlign === 'h_textmedia_center_right'){
      this.imageStyleDesktop = '60_31_desktop';
      this.aspectRatio = '16:9';
    }

    if(this.brickContent.image && this.brickContent.image.field_override_mobile){
      this.mobileImage = this.brickContent.image.mobile_image.image_style_uri.scale_only;
    }

  },
  computed: {
    sanitizedText() {
      // Prevents double character encoding
      // (for example: encodes a space into %20 and % into 25 so we get %2520 instead of %20)
      return this.brickContent.text.processed.replace(/%25([0-9A-Fa-f]{2})/g, '%$1');
    }
  }
}
</script>
