<template>
  <div class="all-events">
    <h3 id="anchorTitleMasterclass">{{ aeData.title }}</h3>
    <div class="controls">
      <event-number-results
        v-if="eventData"
        :enr-length="totalCount"
        :enr-data="aeData.results"
        @clearFilters="$emit('clearFilters')"
      />
      <event-order :eo-data="aeData.order" @triggerOrder="reorderList"/>
    </div>

    <event-list
      :elData="eventData"
      :ecateg-data="ecData"
      :key="eventListKey"
    />

    <event-pagination
      :totalItems="totalCount"
      @changePage="changePage"
    />
    <div class="goToFilter">
      <a href="#filterAnchor">{{ bricks.filters_anchor_text }}</a>
    </div>
  </div>
</template>

<script>
import EventNumberResults from "@/components/masterclass/EventNumberResults";
import EventOrder from "@/components/masterclass/EventOrder";
import EventPagination from "@/components/masterclass/EventPagination";
import EventList from "@/components/masterclass/EventList";

export default {
  name: "AllEvents",
  components: {
    EventNumberResults,
    EventOrder,
    EventList,
    EventPagination
  },
  props: {
    aeData: { type: Object, default: null},
    ecData: { type: Array, default: null },
    filters: { type: Object, required: true },
    aliases: { type: Object, default: null },
  },
  data() {
    return {
      ...this.ecData,
      currentPage: 1,
      eventListKey: 0,
      ordered: "latest",
      activeIndex: 0
    };
  },
  watch: {
    deep: true,
    immediate: true,
    filters() {
      this.$store.dispatch("event/fetchQueryEvents", {values: this.filters, aliases:  this.aliases, page: 1, order: this.ordered});
      this.currentPage = 1;
    },
  },
  computed: {
    eventData() {
      return this.$store.getters["event/queryEvent"];
    },
    bricks() {
      return this.$store.getters["event/getBricks"];
    },
    totalCount() {
      return this.$store.getters["event/queryEventTotal"];
    }
  },
  methods: {
    changePage: function(target){
      if(target === 'next'){
        this.currentPage++;
      }else if(target === 'prev'){
        this.currentPage--;
      }else{
        this.currentPage = target;
      }
      this.$store.dispatch("event/fetchQueryEvents", {values: this.filters, aliases: this.aliases, page: this.currentPage, order: this.ordered});
    },
    reorderList: function(method) {
      this.ordered = method;
      this.$store.dispatch("event/fetchQueryEvents", {values: this.filters, aliases: this.aliases, page: this.currentPage, order: this.ordered});
    },
  }
};
</script>

<style lang="scss">
.g {
  .all-events {
    > h3 {
      font: {
        size: 30px;
        weight: 300;
      }
      text: {
        transform: uppercase;
        align: center;
      }
      letter-spacing: 6px;
      line-height: 40px;
      margin: 0 0 25px;
      @media (min-width: 768px) {
        margin: 0 0 50px;
        letter-spacing: 5px;
      }
    }

    .controls {
      display: flex;
      align-items: center;
      padding: 10px 0;
      margin: 0 0 20px;
    }
    .pagination {
      justify-content: center;
      margin-top: 40px;
    }
    .goToFilter {
      text-align: center;
      margin: 25px 0px 60px;
      a {
        color: #8f7247;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 19px;
        text-decoration: underline;
      }
    }
  }
}
</style>
