<template>
    <div class="g_article_author" :class="`g_article_author--${this.mode}`" rel="author">
        <!--  Author picture  -->
        <div class="g_article_author__picture">
            <image-responsive
                :image="this.picture.image_style_uri['1_1_desktop']"
                :imageMobile="this.picture.image_style_uri['1_1']" />
        </div>
        <!--  Full informations    -->
        <div class="g_article_author__content" v-if="this.mode === 'full'">
            <h3 class="g_article_author__content__title">{{ this.name }}</h3>
            <p class="g_article_author__content__location">{{ this.position }}</p>
            <div class="g_article_author__content__text" v-if='this.description' v-html="this.description"></div>
        </div>
        <!--  Teaser informations    -->
        <div class="g_article_author__content" v-if="this.mode === 'teaser'">
          <h3 class="g_article_author__content__title">{{ this.name }}</h3>
          <p class="g_article_author__content__date" v-if="this.date !== null">
            {{ this.date }}
          </p>
        </div>
    </div>
</template>

<script>
import ImageResponsive from '@/components/ImageResponsive.vue';
export default {
    name: 'Author',
    components: {
        ImageResponsive
    },
    props: {
        picture: { type: Object, default: null },
        name: { type: String, default: null },
        position: { type: String, default: null },
        description: { type: String, default: null },
        mode: { type: String, default: 'full'},
        date: { type: String, default: null }
    },
}
</script>
