<template>
  <div class="eb" :class="flData ? 'full' : ''">
    <!-- Classic Button -->
    <button v-if="nameData" class="eb__button" @click="ChangeStatus" :disabled="disable">
      <span>{{ nameData }}</span>
    </button>
    <!-- eb for event button -->
    <button
      v-if="ebData || ebData == 0"
      :class="ebData <= 0 ? 'eb__button--ghost' : ''"
      class="eb__button"
    >
      <span v-if="ebData <= 0">{{bricks.button_waiting_text}}</span>
      <span v-if="ebData > 0">{{bricks.button_register_text}}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: "EventButton",
  props: {
    ebData: { type: Number, default: null },
    flData: { type: Boolean, default: false },
    nameData: { type: String, default: null },
    enableData: { type: Array, default: null },
    selectedData: { type: [Array,Boolean], default: false },
  },
  data() {
    return {
      ...this.ebData,
      ...this.nameData,
      ...this.flData,
      ...this.selectedData
    };
  },
  computed: {
    disable () {
      if (this.$store.getters["event/getLang"].includes(this.nameData)) {
        if ( !this.$store.getters["event/getLangEnable"].includes(this.nameData) ) {
            return true
        } else {
          return false
        }
      } else {
        return false
      }
    },
    bricks () {
      return this.$store.getters["event/getBricks"];
    }
  },
  methods: {
    ChangeStatus: function () {
      if (this.nameData == "Apply Filter") {
        this.$parent.refreshData()
      }
      if (this.selectedData) {
        this.selectedData.includes(this.nameData) ? this.selectedData.splice(this.selectedData.indexOf(this.nameData), 1) : this.selectedData.splice(0, 0, this.nameData)
        this.$emit("update:selectedData", this.selectedData);
        this.$parent.$parent.applyFilters();
      }
    }
  }
};
</script>

<style lang="scss">
.g {
  .eb {
    &__button {
      -webkit-appearance: none;
      border: 1px solid transparent;
      display: inline-block;
      padding: 12px 20px;
      min-width: 200px;
      background: #8f7247;
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: uppercase;
      border-radius: 4px;
      text-align: center;
      transition: all 300ms;
      &:hover {
        background: #a0804f;
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.3);
      }

      &.eb__button--ghost {
        background: transparent;
        border-color: #8f7247;
        color: #8f7247;

        &:hover {
          background: #a0804f;
          border-color: #a0804f;
          color: #ffffff;
          box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.3);
        }
      }
    }
    &.full {
      .eb__button {
        width: 100%;
      }
    }
    &.lang__button__radio {
      .eb__button {
        background-color: transparent;
        color: rgba($color: #666666, $alpha: 0.8);
        padding: 10px 22px;
        font: {
          size: 14px;
          weight: bold;
        }
        letter-spacing: 1px;
        line-height: 21px;
        text-align: center;
        border: 1px solid rgba($color: #666666, $alpha: 0.25);
        min-width: 50px;
        &:disabled {
          background-color: rgba($color: #666666, $alpha: 0.20);
          &:hover {
            box-shadow: none;
          }
        }
      }
      &--selected {
        .eb__button {
          background-color: #000000;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
