<template>
    <section
        :id="brickContent.anchor_identifier"
        class="g_section g_recipes g_light"
        :class="recipesClass"
        v-if="recipes">

        <!-- Title (Accessibility only) -->
        <h2 class="g_visually_hidden" aria-hidden="false">{{ brickContent.title }}</h2>

        <div class="g_imageGrid">
            <!-- List of recipes -->
            <div v-for="recipe in recipes" :key="recipe.index" :class="'recipeId-' + recipe.id"  v-lazy:background-image="recipe.attributes.pictures.original">
                <!-- Recipe title -->
                <h3 class="g_h3"><span v-html="recipe.attributes.title"></span></h3>
                <button @click="openRecipeModal(recipe)"></button>
            </div>
        </div>
        <recipe-modal
            :recipe="selectedRecipe"
            v-if="isModalActive"
            @close="closeRecipeModal"/>
    </section>
</template>

<script>
import RecipeModal from '@/components/recipes/RecipeModal';
import axios from "axios";

export default {
    name: 'Recipes',
    components: {
        RecipeModal
    },
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            recipes: [],
            selectedRecipe: null,
            isModalActive: false,
            recipesClass: 'g_recipes' + (this.brickContent.recipes.length > 3 ? '4' : this.brickContent.recipes.length)
        }
    },
    mounted() {
        this.getRecipes();
    },
    methods: {
        getRecipes() {
            var lang = document.documentElement.lang; 
            var $this = this;
            this.brickContent.recipes.forEach(function(recipe) {
                // Connect to the Recipes API to get the recipes informations from their SKUs
                axios.get('https://www.nespresso.com/recipes/api/v2/ch/' + (lang ? lang : 'en') + '/recipes/' + recipe.sku + '?key=D84NF843NFN4382349').then(response => {
                    $this.recipes.push(response.data.data);
                }).catch(function(error) {
                    console.warn(error);
                });
            });
        },
        closeRecipeModal() {
            this.isModalActive = false;
        },
        openRecipeModal(recipe) {
            this.selectedRecipe = recipe;
            this.isModalActive = true;
        }
    }
}
</script>
