<template>
  <button @click="turnGealocationOn" class="geolocated">
    <div class="geolocated__icon geolocated__icon--target">
     <img :src="$distUrl + '/front-app/dist/'+geolocatedIcon" alt="">
    </div>
    <h5 class="geolocated__title" v-html="title" />
    <div>
      <div class="geolocated__icon geolocated__icon--checked" v-if="geolocated">
        <img :src="$distUrl + '/front-app/dist/' + checkIcon" alt="">
      </div>
    </div>
    <portal to="storelocator-bottom-denied-modal">
      <GeolocatedDeniedModal v-model="showDeniedPermissionInfo" />
    </portal>
  </button>
</template>

<script>
import GeolocatedDeniedModal from '@/components/store-locator/partials/GeolocatedDeniedModal';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import helpers from '@/services/store_locator_helpers';
import { gmapApi } from "vue2-google-maps";

export default {
  name: "GeolocatedButton",
  components: {
    GeolocatedDeniedModal
  },
  data() {
    return {
      showDeniedPermissionInfo: false,
      geolocatedIcon: require('../../../icons/geolocated.svg'),
      checkIcon: require('../../../icons/check.svg'),
      geolocOptions: {
        timeout:10000,
        enableHighAccuracy: true,
        maximumAge: Infinity
      }
    };
  },
  computed: {
    ...mapState("store_locator", ["google", "userPosition"]),
    ...mapGetters("store_locator", ["geolocated"]),
    title() {
      let title = this.geolocated ? this.$i18nSL.geolocated : this.$i18nSL.geolocateMe;
      return title;
    },
    google: gmapApi
  },
  methods:{
    ...mapMutations('store_locator', ['setGeolocated', 'setSearchQuery']),
    ...mapActions('store_locator', ["updateUserPosition", "updateCenter"]),
    async initGeoloc(){
      const allow = await helpers.geolocIsGranted();
      if(allow){
        this.turnGealocationOn();
      }
    },
    async geocode(lat, lng){
      return new Promise((resolve, reject) => {
        const latlng = new this.google.maps.LatLng(lat, lng);
        const geocoder = new this.google.maps.Geocoder();
        geocoder.geocode({ 'latLng': latlng },  (results, status)  =>{
            if (status !== this.google.maps.GeocoderStatus.OK) {
                reject(status);
            }
            // This is checking to see if the Geoeode Status is OK before proceeding
            if (status == this.google.maps.GeocoderStatus.OK) {
                resolve(results)
            }
        });
      })

    },
    async turnGealocationOn() {
      try{
        const position = await helpers.getCurrentPosition()
        this.geolocSuccess(position);
        if(!this.searchQuery){
          const positionResults = await this.geocode(position.lat, position.lng);
          const result = positionResults.find(item => item.types.some( t => ["street_address","postal_code","sublocality_level_1", "administrative_area_level_1"].includes(t)))
          this.setSearchQuery(result.formatted_address)
        }

      }catch(e){
        if(e==='USER_DENIED'){
          this.showDeniedPermissionInfo = true;
        }
      }
    },
    geolocSuccess(position){
        this.updateUserPosition(position);
        this.updateCenter(position);
        this.setGeolocated(true)
    },
  },
  mounted() {
    this.initGeoloc()
  }
};
</script>

<style lang="scss">
.g {
  .geolocated {
    display: flex;
    align-items: center;
    padding: 0 7px;
    background-color: #8f7247;
    border-radius: 4px;

    @media (min-width: 768px) { min-width: 187px; }

    &__title {
      display: none;
      font-weight: 800;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;

      @media (min-width: 768px) { display: block; }
    }

    &__icon {
      display: flex;
      align-items: center;
      &--checked{
         @media (min-width: 768px) { 
          position: relative;
          top: -2px;
          left: 8px
        }
      }
      &--target {
         @media (min-width: 768px) { margin-right: 10px; }
      }
    }
  }
}
</style>
