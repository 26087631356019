<template>
    <div v-if="content && content.status">
        <sustainability-kiosk v-if="content.type == 'voting--kiosk'" :brickContent="content" />
        <sustainability-call-center v-if="content.type == 'voting--call_center'" :brickContent="content" />
        <ArticlePage v-if="content.type == 'node--article'" :brickContent="content" />

        <template v-for="brick in $drupalPage.bricks">
            <!-- Brick: Tabulation -->
            <tabulation v-if="brick.type == 'brick--tabulation'" :key="brick.id" :brickContent="brick" />
            <!-- All other brick types -->
            <Brick v-if="brick.type != 'brick--tabulation'" :key="brick.id" :brick="brick" :pageType="content.type_of_page" :backgroundParallaxHeader="backgroundParallaxHeader" />
        </template>
    </div>
</template>

<script>
import Brick from '@/pages/Brick.vue';
import Tabulation from '@/components/Tabulation.vue';
import SustainabilityCallCenter from '@/components/sustainability/SustainabilityCallCenter';
import SustainabilityKiosk from '@/components/sustainability/SustainabilityKiosk';
import ArticlePage from '@/components/articles/ArticlePage';

import { $ } from '@/jquery';

export default {
    name: 'DefaultPage',
    components: {
        Brick,
        Tabulation,
        SustainabilityCallCenter,
        SustainabilityKiosk,
        ArticlePage,
    },
    data() {
        return {
            content: false,
            backgroundParallaxHeader: !this.$isMasterclass
                ? null
                : {
                      capsules: [
                          {
                              url: 'img/capsules_2.png',
                              class: 'bp__caps--left two-caps-little main-header',
                              start: 'top-100',
                              y: '70',
                          },
                      ],
                      trigger: 'bpWrapperMainHeader',
                  },
        };
    },
    mounted() {
        // Page to load for the Masterclass demo
        this.content = this.$drupalPage.content;
        this.bricks = this.$drupalPage.bricks;

        if (this.bricks) {
            let onlyClosestPoibrick = this.bricks.some((brick) => brick.type !== 'closest_poi');
            if (onlyClosestPoibrick) {
                document.getElementById('app').classList.add('app--closestpoi');
            }
        }

        // a11y detect keydown tab
        $(document).on('keydown', 'body:not(.a11y-navigation)', function (event) {
            var code = event.keyCode || event.which;
            $('body').toggleClass('a11y-navigation', code === 9);
        });

        $(document).on('mousedown', 'body.a11y-navigation', function () {
            $('body').removeClass('a11y-navigation');
        });

        $(document).on('click', 'a[href^="#"]:not([href^="#/"])', function (event) {
            event.preventDefault();
            $('html, body').animate({ scrollTop: $($.attr(this, 'href')).offset().top }, 500);
        });

        $(document).on('click', '.g_popinOverlay', function(){
            const closeButton = $(this).siblings('.g_popinFixed').find('.g_btnClose');
            closeButton.trigger('click');
        });
    },
};
</script>
