<template>
  <div class="assert" :class="positionClass">
    <div class="assert__container"
         v-html="insertText"
         :style="{ backgroundColor: assertTextBackgroundColor, color: assertTextColor }">
    </div>
  </div>
</template>
<script>
export default {
  name: 'AssertBox',
  props: {
    position: { type: String, default: 'Left'},
    insertText: { type: String, required: true},
    assertTextBackgroundColor: { type: String, default: '#FFFFFF' },
    assertTextColor: { type: String, default: '#000000' }
  },
  computed: {
    positionClass() {
      const positionMap = {
        'Right': 'assert__container--right',
        'Left': 'assert__container--left',
        '0': 'assert--top-left',
        '1': 'assert--bottom-left',
        '2': 'assert--center-top',
        '3': 'assert--center-middle',
        '4': 'assert--center-bottom',
        '5': 'assert--top-right',
        '6': 'assert--bottom-right',
      };
      return positionMap[this.position] || '';
    }
  }
}
</script>

<style lang="scss">
.assert{
  &__container {
    width: 100%;
    max-width: 600px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1.12px;
    padding: 2.375rem 4.25rem;

    @media screen and (min-width: 992px){
      max-width: 23rem;
      padding: 3.875rem 4.25rem;
      margin: 0;
    }

    .g_txt_S {
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      margin-top: 20px;
    }

    h2, h3 {
      font-size: 58px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 4.06px;
      margin-bottom: 17px;
    }
  }
}
</style>
