<template>
    <section :class="[brickContent.text_align ? 'h_textmediaslider_left' : '']" :id="brickContent.anchor_identifier" class="h h_textmediaslider" :style="'--custom-background:' + brickContent.backgroundcolor.color + '; --custom-text-color: ' + (brickContent.text_color_black_or_white ? 'black' : 'white')+'; --arrow-background-color: '+ this.brickContent.arrow_background.color">
        <div class="h_textmediaslider__container">
            <div class="v_swiper v_swiper-on" tabindex="0">
              <div class="v_swiperOverflow">
                <div class="swiper-container" :id="'swiper-' + brickContent.id">
                  <ul class="swiper-wrapper">
                    <li class="h_element swiper-slide" v-for="(element, index) in brickContent.text_media_elements" :key="element.index">
                      <div class="h_element_media">
                        <button :id="'swiper-button-prev-' + brickContent.id" class="swiper-button-prev">
                          <img :src="$distUrl + 'front-app/dist/'+ arrow" alt="">
                        </button>
                        <div class="h_element_video" v-if="element.videos">
                          <Video :brickContent="element.videos"></Video>
                        </div>
                        <div class="h_element_image"  v-if="!element.videos && element.image && element.image.image">
                          <image-responsive
                            :image="element.image.image.image_style_uri['3_2_desktop']"
                            :imageMobile="element.image_mobile ? element.image_mobile.image.image_style_uri['2_3_mobile'] : element.image.image.image_style_uri['2_3_mobile']" />
                        </div>
                        <button :id="'swiper-button-next-' + brickContent.id" class="swiper-button-next" :style="'background-color: ' + brickContent.arrow_background.color">
                          <img :src="$distUrl + '/front-app/dist/'+ arrow" alt="">
                        </button>
                      </div>
                      <div class="h_element_content">
                        <h4 class="h_element_title" v-if="element.title">{{ element.title }}</h4>
                        <div class="h_element_text" v-html="element.text.value"></div>
                        <div class="h_element_numbering" :style="'color: ' + brickContent.arrow_background.color">
                          {{ index + 1 > 9 ? (index + 1) : '0' + (index + 1) }} <span class="h_element_numbering_total">| {{ brickContent.text_media_elements.length > 9 ? brickContent.text_media_elements.length : '0' + brickContent.text_media_elements.length }}</span></div>
                      </div>
                    </li>
                  </ul>
                  <div :id="'swiper-pagination-' + brickContent.id " class="swiper-pagination"></div>
                </div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper/bundle';
import { $ } from "@/jquery";
import Video from "@/components/Video";
import ImageResponsive from '@/components/ImageResponsive.vue';

export default {
    name: 'TextMediaSlider',
    components: {
      ImageResponsive,
      Video
    },
    props: {
        brickContent: { type: Object, default: null }
    },
    data() {
        return {
            arrow_dark: require('../icons/arrow_slider.svg'),
            arrow_light: require('../icons/arrow_slider_light.svg'),
            arrow: null
        }
    },
    mounted() {
        this.arrow = this.brickContent.arrow_color_black_or_white ? this.arrow_dark : this.arrow_light;
        this.initSwiper();
        $(`#swiper-pagination-${this.brickContent.id} .swiper-pagination-bullet`).css('background-color', this.brickContent.arrow_background.color);
        $(`#swiper-pagination-${this.brickContent.id} .swiper-pagination-bullet`).css('border-color', this.brickContent.arrow_background.color);
    },
    methods: {
        initSwiper() {
            var $this = this;
            if (this.brickContent.text_media_elements.length) {
                $this.textmediasliderSwiper = new Swiper('#swiper-' + this.brickContent.id, {
                    a11y:false,
                    direction: 'horizontal',
                    loop: false,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    watchSlidesVisibility:true,
                    pagination: {
                        el: "#swiper-pagination-" + this.brickContent.id,
                    },
                    paginationClickable: true,
                    breakpoints: {
                        996: {
                            pagination: false
                        }
                    },
                    navigation: {
                        prevEl: "#swiper-button-prev-" + this.brickContent.id,
                        nextEl: "#swiper-button-next-" + this.brickContent.id
                    },
                    keyboard: true,
                    on: {
                        init: function () {
                            $this.showSlider = true;
                            setTimeout(() => {
                                updateTabIndex();
                            }, 300);
                        }
                    }
                });
            } else {
                setTimeout(() => {
                    this.initSwiper();
                }, 50);
            }

            $('#swiper-' + $this.brickContent.id).on('slideChange', function() {
                setTimeout(() => {
                    $('.swiper-slide-active', $('#swiper-' + $this.brickContent.id)).focus();
                    updateTabIndex();
                }, 300);
            })

            function updateTabIndex() {
                var $currrentSWiper = $('#swiper-' + $this.brickContent.id);
                $('.swiper-slide').attr('tabindex', '-1');
                $('.swiper-slide-visible', $currrentSWiper).attr('tabindex','0');
                var $btnPrev = $('#swiper-button-prev-' + $this.brickContent.id);
                var $btnNext = $('#swiper-button-next-' + $this.brickContent.id);
                $btnPrev.attr('tabindex', '0');
                $btnNext.attr('tabindex', '0');
                $('.swiper-button-disabled').attr('tabindex', '-1');
            }
        }
    }
}
</script>
