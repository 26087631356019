import Vue from 'vue';
import localforage from 'localforage';
import Jsona from 'jsona';
import { DrupalApi } from './drupal_api';


export class StoreLocatorService{
  constructor(){
    this.version = 1; // version number need to be an integer
    this.lang = document.documentElement.lang;
    this.regionsIndex = {
      west: 0,
      zurich: 1,
      southEast: 2,
      bern: 3
    };
    this.regions = [
      {
        name: "West",
        lat: 46.28222408678796,
        lng: 6.030906381417855,
        zoom: 11,
      },
      {
        name: "Zurich",
        lat: 47.35500417948069,
        lng: 8.47546634156589,
        zoom: 12
      },
       {
        name: "SouthEast",
        lat: 45.992207950075354,
        lng: 8.8594898064516,
        zoom: 12
      },
      {
        name: "Bern",
        lat: 46.94054591150992,
        lng: 7.3614544408301485,
        zoom: 12
      }
    ];
    this.services = {};
    this.paymentMethods = {};
    this.storeTypes = {};
    this.boutiques = [];
    this.dataFormatter = new Jsona();
    this.lastFetchStorageKey = this.lang + "_lastApiFetch";
    this.storageKey = this.lang +"_storeLocatorData";
    this.needRefetch = this.getNeedRefetch();
    this.initialRegion = this.getInitialRegion();
    this.drupalApi = new DrupalApi();
    this.baseUrl = this.lang+"/jsonapi";
  }
  deserialize(data){
    return this.dataFormatter.deserialize(data);
  }
  getNeedRefetch(){
    const lastFeed = localStorage.getItem(this.lastFetchStorageKey);
    const oneDay = 24 * 60 * 60 * 1000;
    if(!lastFeed){
      return true;
    }
    return ((Date.now() - parseInt(lastFeed)) >  oneDay - oneDay);
  }
  getInitialRegion(){
    switch(this.lang){
      case "fr":
        return this.regions[this.regionsIndex.west];
      case "en":
      case "de":
        return this.regions[this.regionsIndex.zurich];
      case "it":
        return this.regions[this.regionsIndex.southEast];
      default:
        return this.regions[this.regionsIndex.bern];
    }
  }
  async initDataFromStorage(){
    const data = await this.getFromStorage();
    const storageVersion = data && data.version ? data.version : 0;
    if( parseInt(storageVersion) !== this.version ){
      this.needRefetch = true;
      return false;
    }else{
      this.services = data.services;
      this.paymentMethods = data.paymentMethods;
      this.storeTypes = data.storeTypes;
      this.boutiques = data.boutiques;
      return true;
    }
  }
  async fetchI18n(){
    const result = await this.drupalApi.axios.get(`${Vue.prototype.$publicUrl}/json/store-locator/i18n-${this.lang}.json`);
    Vue.prototype.$i18nSL = result.data;
  }
  async fetchTaxonomies(){
    await this.fetchServices();
    await this.fetchPaymentMethods();
    await this.fetchStoreTypes();
  }
  async fetchInitialData(){
    if(!this.needRefetch){
      const success = await this.initDataFromStorage();
      if(success){
        return true;
      }
    }

    await this.fetchTaxonomies();
    await this.fetchInitialBoutique();

  }
  async fetchSecondaryData(){
    if(this.needRefetch){
      await this.fetchAllBoutiques();
      await this.saveInStorage();
    }
  }
  async fetchAll(){
    if(!this.needRefetch){
      const success = await this.initDataFromStorage();
      if(success){
        return true;
      }
    }
    await this.fetchTaxonomies();
    await this.fetchAllBoutiques();
    await this.saveInStorage();

  }
  async saveInStorage(){
    await localforage.setItem(this.storageKey, {
      storeTypes: this.storeTypes,
      services: this.services,
      paymentMethods: this.paymentMethods,
      boutiques: this.boutiques,
      version: this.version
    });
    localStorage.setItem(this.lastFetchStorageKey, Date.now());
    return true;
  }
  async getFromStorage(){
    return await localforage.getItem(this.storageKey);
  }
  async fetchServices(){
    const items = await this.loadTerms("services_store_type");
    for(let i =0; i<items.length; i++){
      this.services[items[i].id] = this.serviceAdapter(items[i]);
    }
  }
  async loadTerms(taxonomy){
    const response = await this.drupalApi.loadTerms(taxonomy);
    return this.deserialize(response.data);

  }
  async fetchPaymentMethods(){
    const items = await this.loadTerms("payment_method");
    for(let i =0; i<items.length; i++){
      this.paymentMethods[items[i].id] = this.paymentMethodsAdapter(items[i]);
    }
  }
  async fetchStoreTypes(){
    const items = await this.loadTerms("store_type");
    for(let i =0; i<items.length; i++){
      this.storeTypes[items[i].id] = this.storeTypeAdapter(items[i]);
    }
  }

  async fetchInitialBoutique(){
    const region = this.initialRegion.name;
    // GET FROM JSON FAILED, TRY TO GET FROM API
    const fetchUrl = `${this.baseUrl}/custom_entity/poi?filter[from_import][value]=1&filter[region][value]=${region}&page[limit]=300`;
    const response = await this.drupalApi.get(fetchUrl);
    const items = response.data.data;
    this.pushBoutiques(items);
  }
  pushBoutiques(items){
    for (let i =0; i<items.length; i++) {
      this.boutiques.push(this.boutiqueAdapter(items[i]));
    }
  }

  async fetchAllBoutiques(){
    // GET FROM JSON FAILED, TRY TO GET FROM API
    const limit = 300;
    const fetchUrl = `${this.baseUrl}/custom_entity/poi?filter[from_import][value]=1&page[limit]=${limit}`;
    const firstResponse = await this.drupalApi.get(fetchUrl);
    const total  =  parseInt( firstResponse.data.meta.count);
    const nbPageLeft = Math.ceil(total / limit) - 1;
    const promises = [];
    for (let i =0; i<nbPageLeft; i++) {
      const offset = (i + 1) * limit;
      const nextUrl = `${this.baseUrl}/custom_entity/poi?filter[from_import][value]=1&page[offset]=${offset}&page[limit]=${limit}`;
      promises.push(this.drupalApi.get(nextUrl));
    }

    const responses  = await Promise.all(promises);
    responses.push(firstResponse);
    this.boutiques= [];
    for (let i =0; i<responses.length; i++) {
      const items = responses[i].data.data;
      for (let j =0; j<items.length; j++) {
        this.boutiques.push(this.boutiqueAdapter(items[j]));
      }
    }
  }

  getRelationItems(itemRelationObject, relationCollection){
    const relations = [];
    if (!itemRelationObject.data.length) { return [];}
    itemRelationObject.data.forEach(relation=>{
      if (relationCollection[relation.id]) {
        relations.push(relationCollection[relation.id]);
      }
    });
    return relations;
  }

  // =================================
  //        ADAPTERS
  // =================================
  mediaAdapter(media){
    if(!media || !media.image || !media.image.uri) return null;
    return {
      url: media.image.uri.full_url,
      filemime: media.image.filemime,
    };
  }
  serviceAdapter(drupaldata){
    return {
      id: drupaldata.id,
      title: drupaldata.name,
      filter_label: drupaldata.filter_label,
      icon: this.mediaAdapter(drupaldata.icon),
      weight: drupaldata.weight,
      link: drupaldata.link
    };
  }

  paymentMethodsAdapter(drupaldata){
    return {
      id: drupaldata.id,
      title: drupaldata.name,
      weight: drupaldata.weight,
      icon: this.mediaAdapter(drupaldata.icon)
    };
  }

  storeTypeAdapter(drupaldata){
    return {
      id: drupaldata.id,
      title: drupaldata.name,
      paymentMethods:  this.getRelationItems({data: drupaldata.payment_method}, this.paymentMethods),
      services:  this.getRelationItems({data: drupaldata.services}, this.services),
      weight: drupaldata.weight,
      icon: this.mediaAdapter(drupaldata.icon),
      marker: this.mediaAdapter(drupaldata.marker),
      markerHover: this.mediaAdapter(drupaldata.marker_over),
      picture: this.mediaAdapter(drupaldata.picture),
    };
  }

  boutiqueAdapter(drupaldata){
    const storeTypes = this.getRelationItems(drupaldata.relationships.store_type, this.storeTypes);
    const services = [];
    const paymentMethods = [];

    storeTypes.forEach(storeType => {
      storeType.services.forEach( storeTypeService => {
        if(!services.find(service=>service.id === storeTypeService.id)){
          services.push(storeTypeService);
        }
      });
      storeType.paymentMethods.forEach( storeTypePaymentMethods => {
        if(!paymentMethods.find(paymentMethod=>paymentMethod.id === storeTypePaymentMethods.id)){
          paymentMethods.push(storeTypePaymentMethods);
        }
      });
    });

    storeTypes.sort((a,b) => b.weight - a.weight);
    services.sort((a,b) => b.weight - a.weight);
    paymentMethods.sort((a,b) => b.weight - a.weight);
    return {
      id: drupaldata.id,
      title: drupaldata.attributes.title,
      images: drupaldata.attributes.field_imgs_gmb? drupaldata.attributes.field_imgs_gmb.split(',').filter(v=>!!v) : [],
      slug: drupaldata.attributes.slug,
      address: drupaldata.attributes.address,
      phone:  drupaldata.attributes.phone,
      region: drupaldata.attributes.region,
      openingTime: drupaldata.attributes.opening_hours? drupaldata.attributes.opening_hours.processed : null,
      position: {
        lat: drupaldata.attributes.geolocation.lat,
        lng: drupaldata.attributes.geolocation.lng,
      },
      rating: drupaldata.attributes.rating ? parseFloat(drupaldata.attributes.rating.value) : null,
      storeTypes,
      services: services,
      paymentMethods: paymentMethods,
      relationships: drupaldata.relationships,
      primaryStoreType: storeTypes[0],
      link_3d_view: drupaldata.attributes['3d_view_link']
    };
  }

  getByIds(ids){
    return this.boutiques.filter(boutique => ids.includes(boutique.id));
  }

}
