<template>
  <div class="coffee-experts" v-if="coffeeExpertsData.length > 0">
    <h4 class="coffee-experts__button-title" v-html="title"></h4>
    <VueMultiselect
      v-model="value"
      :searchable="false"
      :multiple="true"
      :options="expertOptions"
      track-by="uuid"
      label="label"
      @input="changeMultiSelect"
    />
  </div>
</template>

<script>
import VueMultiselect from 'vue-multiselect'

export default {
  name: "CoffeeExperts",
  components: { VueMultiselect },
  props: {
    title: { type: String, default: null },
    alias: { type: Object, default: null }
  },
  data(){
    return {
      value: [],
      hide: false,
    }
  },
  computed: {
    coffeeExpertsData () {
      return this.$store.getters["event/getCoffeeExperts"]
    },
    expertOptions () {
      const experts = this.$store.getters["event/getCoffeeExperts"]
      const options = [];
      for(let i = 0; i < experts.length; i++){
        const option = {
          uuid: experts[i].id,
          label: experts[i].name
        }
        options.push(option);
      }
      return options;
    }
  },
  watch: {
    expertOptions: {
      deep: true,
      immediate: true,
      handler() {
        if(this.alias !== null){
          this.initOptionValue();
        }
      }
    },
    '$route.query': {
      handler: function(){
        if(this.alias.label !== undefined && !this.$route.query[this.alias.label]){
          this.value = [];
        }
      },
      deep: true,
    }
  },
  methods: {
    changeMultiSelect() {
      const values = [];
      if (this.alias !== null && this.alias !== undefined) {
        for (let i = 0; i < this.value.length; i++) {
          const label = this.alias.values.filter(filter_value => filter_value.uuid === this.value[i].uuid);
          values.push(label[0].label);
        }
        this.$emit("input", values);
        this.$parent.applyFilters();
      }
    },
    initOptionValue() {
      if (this.alias && this.$route.query[this.alias.label] !== undefined && (this.$route.query[this.alias.label].length && this.$route.query[this.alias.label] !== "")) {
        const preset = this.$route.query[this.alias.label].split('+');
        for (let i = 0; i < preset.length; i++) {
          this.value.push(this.alias.values.filter(a => a.label === preset[i])[0]);
        }
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.coffee-experts {
  @media (min-width: 768px) {
    text-align: center;
  }

  &__button {
    display: inline-flex;
    align-items: center;
    column-gap: .25rem;
    color: #000;
    line-height: 21px;

    &-title {
      text-transform: uppercase;
      font-weight: bold;
      font-size: .875rem;
      letter-spacing: 1px;
      @media screen and (min-width: 768px) {
        font-size: .875rem;
      }
    }

    svg {
      margin-left: 10px;
      transform: rotate(180deg);
      transition: all 0.2s;
    }

    &--up {
      svg {
        transform: rotate(0);
      }
    }
  }

  &__panel {
    padding-top: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    max-width: 48.125rem;
    width: 100%;
    margin: auto;

    > * {
      @media screen and (min-width: 768px) {
        width: calc(33% - (3rem / 3));
      }
    }
  }

  &__title {
    width: 100%;
  }
}
</style>
