<template>
  <section :id="brickContent.anchor_identifier"
    class="g_section g_contactUs g_light"
    :class="'g_contactUs__'+brickContent.webform.status"
  >
    <div class="g_restrict">
      <div class="g_content" v-if="brickContent.webform.status === 'open'">
        <form class="form" v-on:submit.prevent="submit()" >
          <div class="clearfix">
            <h2 class="g_h2" v-html="brickContent.title"></h2>

            <!-- WYSIWYG content -->
            <div v-if="brickContent.intro && brickContent.intro.processed" class="g_richText g_wysiwyg" v-html="brickContent.intro.processed"></div>


            <!-- Form required Fields Instructions -->
            <div class="g_richText g_wysiwyg" style="margin-bottom: 2em">
              <p class="g_wysiwyg g_txt_L">{{ $t('form.requiredFieldsInstructions') }}</p>
            </div>

            <!-- Spinner for form sending -->
            <div v-if="isSending" class="spinner"></div>

            <!-- Form errors -->
            <div v-if="errors" class="form-errors" tabindex="-1">
              <span v-for="error in errors" :key="error.index">{{ error }}</span>
            </div>

            <!-- Form success -->
            <div v-if="isSent">
              <div class="g_richText g_wysiwyg form-sent" tabindex="-1">
                <i class="fn_tick"></i>
                <div class="g_txt_L"
                  v-html="brickContent.webform.settings.confirmation_message ? brickContent.webform.settings.confirmation_message : brickContent.field_success_message">
                </div>
              </div>
            </div>

            <!-- Form content -->
            <div class="g_row" v-if="brickContent.webform.status === 'open' && !isSent"
              :class="isSending ? 'is-form-hidden' : ''">
              <template v-for="(element, key) of brickContent.webform.elements">
                <div :key="element.index" v-if="element['#type'] === 'webform_markup'"
                  class="g_col100 form_section form_section--title" v-html="element['#markup']"></div>

                <div :key="element.index" v-if="element['#type'] === 'webform_flexbox'" class="g_col100 form_section">
                  <div class="row">
                    <template v-for="(subelement, subkey) of element">
                      <div class="g_col100 form_section form_section--title" v-if="subelement['#type'] === 'webform_markup'" v-html="subelement['#markup']" :key="subelement.index" :index="subkey">
                      </div>
                      <form-fields
                        v-if="!subelement.fields && subelement['#type'] && subelement['#type'] !== 'webform_horizontal_rule' && subelement['#type'] !== 'webform_flexbox' && subelement['#type'] !== 'webform_markup'"
                        :element="subelement" :uid="_uid" :key="subelement.index" :index="subkey" />
                      <!--
                          Separator
                      -->
                      <div class="g_col100"
                        v-if="subelement['#type'] === 'webform_horizontal_rule' && subelement['#type'] !== 'webform_flexbox'"
                        :key="subelement.index">
                        <hr />
                      </div>
                    </template>
                  </div>
                </div>
                <form-fields
                  v-if="!element.fields && element['#type'] !== 'webform_horizontal_rule' && element['#type'] !== 'webform_flexbox'"
                  :element="element" :uid="_uid" :key="element.index" :index="key" />
                <!--
                    Separator
                -->
                <div class="g_col100"
                  v-if="element['#type'] === 'webform_horizontal_rule' && element['#type'] !== 'webform_flexbox'"
                  :key="element.index">
                  <hr />
                </div>
              </template>
            </div>
            <div v-if="brickContent.webform.status !== 'open'">
              <div class="g_richText g_wysiwyg">
                <div class="g_txt_L">This form is closed.</div>
              </div>
            </div>
          </div>
          <div v-if="brickContent.webform.status === 'open' && brickContent.webform.settings.form_exception_message">
            <div class="form__consent__message" v-html="brickContent.webform.settings.form_exception_message" v-if="brickContent.webform.settings.form_exception_message"></div>
            <Popin :brickContent="consentPopin.attributes" v-if="brickContent.webform.settings.form_exception_message && consentPopin"/>
          </div>
          <div v-if="brickContent.webform.status === 'open' && !isSending && !isSent" class="form-button">
            <button class="g_secondaryCta" type="submit">
              <!-- If no label is defined for submit button, use trad key for SUBMIT -->
              {{
                  brickContent.webform.elements.actions && brickContent.webform.elements.actions['#submit__label']
                      ? brickContent.webform.elements.actions['#submit__label']
                      : $t('form.submit')
              }}
            </button>
          </div>
        </form>
      </div>
      <div class="g_contactUs__closed" v-if="brickContent.webform.status === 'closed'" v-html="brickContent.webform.settings.form_close_message"></div>
    </div>
  </section>
</template>

<script>
import { $ } from '@/jquery';
import { DrupalApi } from '../../services/drupal_api';
import FormFields from './FormFields.vue';
import { SustainabilityLoader } from '../../services/sustainability';
import Popin from '@/components/Popin.vue';
import { fetchMasterclassConsentPopin } from '../../services/masterclass';
export default {
  components: { Popin, FormFields },
  name: 'ContactForm',
  props: {
    brickContent: { type: Object, default: null },
  },
  data() {
    return {
      isSending: false,
      isSent: false,
      errors: null,
      drupalApi: new DrupalApi(),
      consentPopin: false,
    };
  },
  mounted() {
    var that = this;
    Object.keys(this.brickContent.webform.elements).forEach(function (element) {
      that.brickContent.webform.elements[element].focus = false;
    });

    $('input, textarea').on('change paste keyup', function () {
      if ($(this)[0].value !== undefined && $(this)[0].value !== null && $(this)[0].value !== '') {
        $(this).parent().parent().addClass('is-dirty');
      } else {
        $(this).parent().parent().removeClass('is-dirty');
      }
    });

    $('select').on('change paste keyup', function () {
      if ($(this)[0].value !== undefined && $(this)[0].value !== null && $(this)[0].value !== '') {
        $(this).parent().parent().parent().addClass('is-dirty');
      } else {
        $(this).parent().parent().parent().removeClass('is-dirty');
      }
    });

    $('input, textarea').on('focus', function () {
      $(this).parent().parent().addClass('is-focused');
    });

    $('input, textarea').on('blur', function () {
      $(this).parent().parent().removeClass('is-focused');
    });

    $('select').on('focus', function () {
      $(this).parent().parent().parent().addClass('is-focused');
    });

    $('select').on('blur', function () {
      $(this).parent().parent().parent().removeClass('is-focused');
    });

    $(':required').one('blur keydown', function () {
      $(this).addClass('touched');
    });

    if(this.brickContent.webform.settings.form_exception_message){
      this.fetchPopinContent(this.brickContent.webform.settings.form_exception_message);
    }
  },
  methods: {
    fetchPopinContent: async function(element){
      var parser = new DOMParser();
      var doc = parser.parseFromString(element, "text/html");
      var popinLink = doc.querySelector('a[data-entity-uuid]');
      if (popinLink.getAttribute('data-entity-uuid')) {
        const data = await fetchMasterclassConsentPopin(popinLink.getAttribute('data-entity-uuid'));
        data.data.data.attributes.id = popinLink.getAttribute('data-entity-uuid');
        this.consentPopin = data.data.data;
      }
    },
    submit() {
      this.isSending = true;
      var that = this;

      // Reset errors
      Object.keys(this.brickContent.webform.elements).forEach(function (element) {
        that.brickContent.webform.elements[element].hasError = false;
      });

      this.errors = null;

      let form = {
        webform_id: this.brickContent.webform.drupal_internal__id,
      };
      Object.keys(this.brickContent.webform.elements).forEach((element) => {
        if (that.brickContent.webform.elements[element]['#type'] === 'webform_flexbox') {
          Object.keys(this.brickContent.webform.elements[element]).forEach((subelement) => {
            if (this.brickContent.webform.elements[element][subelement]['#type']) {
              form = this.handleFormElement(subelement, this.brickContent.webform.elements[element][subelement], form);
            }
          });
        } else {
          form = this.handleFormElement(element, that.brickContent.webform.elements[element], form);
        }
      });
      const token = this.loadData('forms_token');
      return this.drupalApi
        .postApi('webform_rest/submit', form, { headers: { 'Content-Type': 'application/json', "X-CSRF-Token": token } })
        .then((response) => {
          this.isSending = false;

          if (response.data && response.data.sid) {
            this.isSent = true;
            //a11y focus on success message
            setTimeout(function () {
              $('.form-sent').focus();
            }, 300);
          } else {
            console.error('Error in the form', response.data.error);

            this.errors = response.data.error;
            var that = this;

            //a11y focus on error message
            setTimeout(function () {
              $('.form-errors').focus();
            }, 300);

            // To highlight the input in red
            Object.keys(this.errors).forEach(function (error) {
              that.brickContent.webform.elements[error].hasError = true;
            });
          }
          return response;
        })
        .catch((error) => {
          this.isSending = false;
          console.error('Error while sending form', error);

          //a11y focus on error message
          $('.form-errors').focus();

          return error;
        });
    },
    async loadData(type) {
      const sustainabilityLoader = new SustainabilityLoader;
      return sustainabilityLoader.loadData(type);
    },
    handleFormElement(key, value, form) {
      if (value['#type'] === 'checkbox') {
        form[key] = $('input[name="' + key + '"]')[0].checked;
      } else if (value['#type'] === 'radios') {
        $('input[name="input_' + key + '_' + this._uid + '"]').each((index, item) => {
          if ($(item)[0].checked) {
            form[key] = $(item)[0].value;
          }
        });
      } else if (value['#type'] === 'checkboxes') {
        form[key] = [];
        $('input[name="' + key + '"]').each((index, item) => {
          if ($(item)[0].checked) {
            form[key].push($(item)[0].value);
          }
        });
      } else {
        if ($('[name=' + key + ']').val() !== undefined) {
          form[key] = $('[name=' + key + ']').val();
        }
      }

      return form;
    },
    focusOnDropdown(element) {
      element.focus = true;
    },
    blurOnDropdown(element) {
      element.focus = false;
    },
  },
};
</script>
<style lang="scss">
.g_contactUs .form_section {
  margin-bottom: 16px;

  &--title {
    &:not(:first-of-type) {
      margin: 1rem 0;
    }

    h3 {
      margin: 0;
      font-size: 1.28571em;
      line-height: 1.33333;
      letter-spacing: 0.16667em;
      font-weight: 800;
      text-transform: uppercase;

      +p {
        margin: 1rem 0 0;
      }
    }

    p {
      font-size: 1.28571em;
      line-height: 1.44444;
      letter-spacing: 0.05556em;
      font-weight: 300;
    }
  }

  >.row {
    margin-left: -1em;
    margin-right: -1em;
  }

  @media screen and (max-width: 767px) {
    >.row {
      margin-left: -1.42857em;
      margin-right: -1.42857em;
    }
  }
}

.g_contactUs .radio-container-horizontal {
  display: flex;
  flex-wrap: wrap;

  >div {
    margin-right: 1.5em;
  }
}
.g_contactUs{
  &__closed{
    padding-top: 2.5rem;
  }
}

.g_contactUs{
  .form__consent__message{
    a{
      color: #000;
      text-decoration: underline;
    }
  }
}
</style>
